import {
    Action, ActionWithPayload, createAction, withMatcher,
} from '../../utils/reducer/reducer.utils';
import { FiltersState } from './filters.reducer';
import { FILTERS_ACTION_TYPES } from './filters.types';

export type SetFilters = ActionWithPayload<
    FILTERS_ACTION_TYPES.SET_FILTERS,
    Partial<FiltersState>
>;

export type ResetFilters = Action<
    FILTERS_ACTION_TYPES.RESET_FILTERS
>;

export const setFilters = withMatcher(
    (filters: Partial<FiltersState>): SetFilters =>
        createAction(FILTERS_ACTION_TYPES.SET_FILTERS, filters)
);

export const resetFilters = withMatcher(
    (): ResetFilters =>
        createAction(FILTERS_ACTION_TYPES.RESET_FILTERS)
);