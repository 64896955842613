import { createSelector } from 'reselect';

import { RootState } from '../store';
import { ParamsState } from './params.reducer';

export const selectParamsReducer = (state: RootState): ParamsState => state.params;

export const selectParams = createSelector(
    selectParamsReducer,
    (params) => params.params
);



