import { all, call, put, select, takeLatest } from 'typed-redux-saga/macro';
import { ADD_ELEMENT_SERVICE_PATH, GET_NEXT_ELEMENT_ID_BY_CATEGORY, GET_NEXT_ELEMENT_ID_BY_MATRIX, GET_TECHNICIAN_ELEMENTS_PATH, MONITORING_ELEMENTS_URL, MONITORING_REPORTS_URL, UPDATE_ELEMENT_SERVICES_APIVIEW_URL } from '../../constants/api.constants';
import { PATHS_LAYER_CLASS, POINTS_LAYER_CLASS, ZONES_LAYER_CLASS } from '../../constants/map.constants';
import { apiRequestWrapper } from '../../utils/api/api.utils';
import { setIsError } from '../error/error.action';
import { setIsLoading } from '../loading/loading.action';
import { selectMonitoringCategories, selectMonitoringMatrices } from '../monitoring/monitoring.selector';
import { AddElementServiceStart, GetElementServiceReportsStart, GetElementStart, GetNextElementNameByCategoryStart, GetNextElementNameByMatrixStart, GetTechnicianElementsStart, SaveNewElementStart, UpdateElementServicesStart, addElementServiceFailed, addElementServiceSuccess, getElementFailed, getElementServiceReportsFailed, getElementServiceReportsSuccess, getElementSuccess, getNextElementNameByCategoryFailed, getNextElementNameByCategorySuccess, getNextElementNameByMatrixFailed, getNextElementNameByMatrixSuccess, getTechnicianElementsFailed, getTechnicianElementsSuccess, saveNewElementFailed, updateElementServicesFailed, updateElementServicesSuccess } from './element.action';
import { ELEMENT_ACTION_TYPES } from './element.types';

export function* getElement({
    payload: { id, onComplete },
}: GetElementStart) {
    yield* put(setIsLoading(true));
    try {
        const element = yield* call(
            apiRequestWrapper, `${MONITORING_ELEMENTS_URL}${id}/`, 'GET', null
        );

        if (element) {
            yield* put(getElementSuccess(element));
            onComplete(element);
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getElementFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* addElementService({
    payload: { elementId, elementClassName, serviceId, dateStart, daysInterval, daysForExpiration, onComplete },
}: AddElementServiceStart) {
    yield* put(setIsLoading(true));
    try {
        const elementService = yield* call(
            apiRequestWrapper,
            `${MONITORING_ELEMENTS_URL}${elementId}/${ADD_ELEMENT_SERVICE_PATH}`,
            'POST', { serviceId, dateStart, daysInterval, daysForExpiration }
        );

        if (elementService) {
            yield* put(addElementServiceSuccess({ elementId, elementClassName, elementServiceId: elementService.result }));
            onComplete();
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(addElementServiceFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* getElementServiceReports({
    payload: { id, onComplete },
}: GetElementServiceReportsStart) {
    yield* put(setIsLoading(true));
    try {
        const elementServiceReports = yield* call(
            apiRequestWrapper, `${MONITORING_REPORTS_URL}?element_service=${id}`, 'GET', null
        );

        if (elementServiceReports) {
            yield* put(getElementServiceReportsSuccess(elementServiceReports));
            onComplete(elementServiceReports);
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getElementServiceReportsFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* getNextElementNameByCategory({
    payload: { className, categoryId, onComplete },
}: GetNextElementNameByCategoryStart) {
    yield* put(setIsLoading(true));
    try {
        const categories = yield* select(selectMonitoringCategories);
        const category = categories.find(c => c.id === categoryId);

        const nextElementIdResponse = yield* call(
            apiRequestWrapper,
            `${MONITORING_ELEMENTS_URL}${GET_NEXT_ELEMENT_ID_BY_CATEGORY}?category_id=${categoryId}&class_name=${className}`, 'GET', null
        );

        if (nextElementIdResponse && category) {
            let nextElementName = '';
            switch (className) {
                case POINTS_LAYER_CLASS:
                    nextElementName += 'Punto';
                    break;
                case ZONES_LAYER_CLASS:
                    nextElementName += 'Zona';
                    break;
                case PATHS_LAYER_CLASS:
                    nextElementName += 'Percorso';
                    break;
                default:
                    nextElementName += 'Elemento';
                    break;
            }
            nextElementName = `${nextElementName}_${category.name}_${nextElementIdResponse.result}`;
            yield* put(getNextElementNameByCategorySuccess(nextElementName));
            onComplete(nextElementName);
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getNextElementNameByCategoryFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* getNextElementNameByMatrix({
    payload: { class_name, matrix_id, onComplete },
}: GetNextElementNameByMatrixStart) {
    yield* put(setIsLoading(true));
    try {
        const matrices = yield* select(selectMonitoringMatrices);
        const matrix = matrices.find(c => c.id === matrix_id);

        const nextElementIdResponse = yield* call(
            apiRequestWrapper,
            `${MONITORING_ELEMENTS_URL}${GET_NEXT_ELEMENT_ID_BY_MATRIX}?matrix_id=${matrix_id}&class_name=${class_name}`,
            'GET', null
        );

        if (nextElementIdResponse && matrix) {
            let nextElementName = '';
            switch (class_name) {
                case POINTS_LAYER_CLASS:
                    nextElementName += 'Punto';
                    break;
                case ZONES_LAYER_CLASS:
                    nextElementName += 'Zona';
                    break;
                case PATHS_LAYER_CLASS:
                    nextElementName += 'Percorso';
                    break;
                default:
                    nextElementName += 'Elemento';
                    break;
            }
            nextElementName = `${nextElementName}_${matrix.name}_${nextElementIdResponse.result}`;
            yield* put(getNextElementNameByMatrixSuccess(nextElementName));
            onComplete(nextElementName);
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getNextElementNameByMatrixFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* saveNewElement(saveNewElementStartAction: SaveNewElementStart) {
    yield* put(setIsLoading(true));
    try {
        const elementPayload = saveNewElementStartAction.payload.elementPayload;
        const request = {
            ...elementPayload,
            zoneCoords: JSON.stringify(elementPayload.zoneCoords ?? []),
            pathCoords: JSON.stringify(elementPayload.pathCoords ?? [])
        };

        const response = yield* call(
            apiRequestWrapper, MONITORING_ELEMENTS_URL, 'POST', request, true
        );
        yield* put(setIsLoading(false));
        saveNewElementStartAction.payload.onComplete(response.id);
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(saveNewElementFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* getTechnicianElements({
    payload: { onComplete },
}: GetTechnicianElementsStart) {
    yield* put(setIsLoading(true));
    try {
        const elements = yield* call(
            apiRequestWrapper, `${MONITORING_ELEMENTS_URL}${GET_TECHNICIAN_ELEMENTS_PATH}`, 'GET', null
        );

        if (elements) {
            yield* put(getTechnicianElementsSuccess());
            onComplete(elements);
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getTechnicianElementsFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* updateElementServices({
    payload: { services, onComplete },
}: UpdateElementServicesStart) {
    yield* put(setIsLoading(true));
    try {
        yield* call(
            apiRequestWrapper, UPDATE_ELEMENT_SERVICES_APIVIEW_URL, 'PUT', services
        );
        yield* put(updateElementServicesSuccess());
        onComplete();
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(updateElementServicesFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onGetElementStart() {
    yield* takeLatest(ELEMENT_ACTION_TYPES.GET_ELEMENT_START, getElement);
}

export function* onAddElementServiceStart() {
    yield* takeLatest(ELEMENT_ACTION_TYPES.ADD_ELEMENT_SERVICE_START, addElementService);
}

export function* onGetElementServiceReportsStart() {
    yield* takeLatest(ELEMENT_ACTION_TYPES.GET_ELEMENT_SERVICE_REPORTS_START, getElementServiceReports);
}

export function* onGetNextElementNameByCategoryStart() {
    yield* takeLatest(ELEMENT_ACTION_TYPES.GET_NEXT_ELEMENT_NAME_BY_CATEGORY_START, getNextElementNameByCategory);
}

export function* onGetNextElementNameByMatrixStart() {
    yield* takeLatest(ELEMENT_ACTION_TYPES.GET_NEXT_ELEMENT_NAME_BY_MATRIX_START, getNextElementNameByMatrix);
}

export function* onSaveNewElementStart() {
    yield* takeLatest(ELEMENT_ACTION_TYPES.SAVE_NEW_ELEMENT_START, saveNewElement);
}

export function* onGetTechnicianElementsStart() {
    yield* takeLatest(ELEMENT_ACTION_TYPES.GET_TECHNICIAN_ELEMENTS_START, getTechnicianElements);
}

export function* onUpdateElementServicesStart() {
    yield* takeLatest(ELEMENT_ACTION_TYPES.UPDATE_ELEMENT_SERVICES_START, updateElementServices);
}

export function* elementSaga() {
    yield* all([
        call(onGetElementStart),
        call(onAddElementServiceStart),
        call(onGetElementServiceReportsStart),
        call(onGetNextElementNameByCategoryStart),
        call(onGetNextElementNameByMatrixStart),
        call(onSaveNewElementStart),
        call(onGetTechnicianElementsStart),
        call(onUpdateElementServicesStart),
    ]);
}
