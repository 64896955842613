import { useSelector } from 'react-redux';
import { DIALOG_TYPES } from '../../store/dialog/dialog.reducer';
import {
    selectDialogType,
    selectInputData,
    selectOnComplete,
} from '../../store/dialog/dialog.selector';
import GBDialog from '../ui/gb-dialog/gb-dialog.component';
import DialogAddAttachments from './dialog-add-attachments/dialog-add-attachments.component';
import DialogAddElementService from './dialog-add-element-service/dialog-add-element-service.component';
import DialogAddElement from './dialog-add-element/dialog-add-element.component';
import DialogAddImages from './dialog-add-images/dialog-add-images.component';
import DialogAddPeriodicInspection from './dialog-add-periodic-inspection/dialog-add-periodic-inspection.component';
import DialogAddPhase from './dialog-add-phase/dialog-add-phase.component';
import DialogAddReport from './dialog-add-report/dialog-add-report.component';
import DialogAddSimpleReport from './dialog-add-simple-report/dialog-add-simple-report.component';
import DialogAddTestReport from './dialog-add-test-report/dialog-add-test-report.component';
import DialogAssignReport from './dialog-assign-report/dialog-assign-report.component';
import DialogCloseReport from './dialog-close-report/dialog-close-report.component';
import DialogConfirmationCancellation from './dialog-confirm-cancellation/dialog-confirm-cancellation.component';
import DialogFilters from './dialog-filters/dialog-filters.component';
import DialogManageNewSimpleReport from './dialog-manage-new-simple-report/dialog-manage-new-simple-report';
import DialogPeriodicInspection from './dialog-periodic-inspection/dialog-periodic-inspection.component';
import DialogUpdateReportWithIntervention from './dialog-update-report-with-intervention/dialog-update-report-with-intervention.component';

const DialogWrapper = () => {
    const dialogType = useSelector(selectDialogType);
    const inputData = useSelector(selectInputData);
    const onComplete = useSelector(selectOnComplete);

    return (
        <GBDialog>
            {dialogType === DIALOG_TYPES.FILTERS && <DialogFilters />}
            {dialogType === DIALOG_TYPES.ADD_ELEMENT && inputData && (
                <DialogAddElement inputData={inputData} />
            )}
            {dialogType === DIALOG_TYPES.ADD_IMAGES && inputData && (
                <DialogAddImages
                    inputData={inputData}
                    onImageAdded={onComplete}
                />
            )}
            {dialogType === DIALOG_TYPES.ADD_ATTACHMENTS && inputData && (
                <DialogAddAttachments
                    inputData={inputData}
                    onAttachmentsAdded={onComplete}
                />
            )}
            {dialogType === DIALOG_TYPES.ADD_ELEMENT_SERVICE && inputData && (
                <DialogAddElementService
                    inputData={inputData}
                    onServiceAdded={onComplete}
                />
            )}
            {dialogType === DIALOG_TYPES.CONFIRM_CANCELLATION && inputData && (
                <DialogConfirmationCancellation
                    inputData={inputData}
                    onDeleted={onComplete}
                />
            )}
            {dialogType === DIALOG_TYPES.ADD_PHASE && <DialogAddPhase />}
            {dialogType === DIALOG_TYPES.ADD_TEST_REPORT && inputData && (
                <DialogAddTestReport
                    inputData={inputData}
                    onSaved={onComplete}
                />
            )}
            {dialogType === DIALOG_TYPES.ADD_REPORT && inputData && (
                <DialogAddReport inputData={inputData} onSaved={onComplete} />
            )}
            {dialogType === DIALOG_TYPES.ASSIGN_REPORT && inputData && (
                <DialogAssignReport
                    inputData={inputData}
                    onSaved={onComplete}
                />
            )}
            {dialogType === DIALOG_TYPES.CLOSE_REPORT && inputData && (
                <DialogCloseReport inputData={inputData} onSaved={onComplete} />
            )}
            {dialogType === DIALOG_TYPES.UPDATE_REPORT_WITH_INTERVENTION &&
                inputData && (
                    <DialogUpdateReportWithIntervention
                        inputData={inputData}
                        onSaved={onComplete}
                    />
                )}
            {dialogType === DIALOG_TYPES.ADD_PERIODIC_INSPECTION &&
                inputData && (
                    <DialogAddPeriodicInspection
                        inputData={inputData}
                        onSaved={onComplete}
                    />
                )}
            {dialogType === DIALOG_TYPES.ADD_SIMPLE_REPORT && inputData && (
                <DialogAddSimpleReport
                    inputData={inputData}
                    onSaved={onComplete}
                />
            )}
            {dialogType === DIALOG_TYPES.MANAGE_NEW_SIMPLE_REPORT &&
                inputData && (
                    <DialogManageNewSimpleReport
                        inputData={inputData}
                        onRefuse={onComplete}
                        onSave={onComplete}
                    />
                )}
            {dialogType === DIALOG_TYPES.OPEN_PERIODIC_INSPECTION &&
                inputData && <DialogPeriodicInspection inputData={inputData} />}
        </GBDialog>
    );
};

export default DialogWrapper;
