import { createSelector } from 'reselect';

import { RootState } from '../store';

import { MapState } from './map.reducer';

export const selecMapReducer = (state: RootState): MapState => state.map;

export const selectMapType = createSelector(
    selecMapReducer,
    (map) => map.mapType
);

export const selectMapZoom = createSelector(
    selecMapReducer,
    (map) => map.mapZoom
);

export const selectMapCenter = createSelector(
    selecMapReducer,
    (map) => map.mapCenter
);

export const selectGeocodingAddress = createSelector(
    selecMapReducer,
    (map) => map.geocodingAddress
);

export const selectGeocodingLatitude = createSelector(
    selecMapReducer,
    (map) => map.geocodingLatitude
);

export const selectGeocodingLongitude = createSelector(
    selecMapReducer,
    (map) => map.geocodingLongitude
);

export const selectGeocodingError = createSelector(
    selecMapReducer,
    (map) => map.geocodingError
);




