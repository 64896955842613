import {
    ArrowCircleLeft,
    ArrowCircleRight,
    Check,
    CheckBoxOutlineBlank,
    CheckBoxOutlined,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    MonitoringCampaign,
    MonitoringPath,
    MonitoringPoint,
    MonitoringZone,
} from '../../../../../models/monitoring';
import {
    selectMonitoringMatrices,
    selectMonitoringPaths,
    selectMonitoringPoints,
    selectMonitoringZones,
} from '../../../../../store/monitoring/monitoring.selector';
import {
    fromGeoJSONtoMonitoringPaths,
    fromGeoJSONtoMonitoringPoints,
    fromGeoJSONtoMonitoringZones,
} from '../../../../../utils/data/data.utils';
import {
    BoxFullW,
    FlexBoxFullWHColumn,
    FlexBoxFullWRowBetween,
} from '../../../../layout/layout.styles';
import MatricesButtonGroup from '../../../../matrices-button-group/matrices-button-group.component';

const DialogAddPhaseCampaigns = ({
    campaigns,
    onChange,
}: {
    campaigns: Partial<MonitoringCampaign>[];
    onChange: (campaigns: Partial<MonitoringCampaign>[]) => void;
}) => {
    const [campaignsUpdated, setCampaignsUpdated] =
        useState<Partial<MonitoringCampaign>[]>(campaigns);

    const monitoringPoints = useSelector(selectMonitoringPoints);
    const monitoringZones = useSelector(selectMonitoringZones);
    const monitoringPaths = useSelector(selectMonitoringPaths);
    const monitoringMatrices = useSelector(selectMonitoringMatrices);

    const matrices = monitoringMatrices
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .filter((cat) => {
            const uniqueMatrices = Array.from(
                new Set(campaigns.map((c) => c.matrix))
            );
            return uniqueMatrices.includes(cat.id);
        })
        .map((c) => c.id);

    const [matrixIndex, setMatrixIndex] = useState<number>(0);
    const [campaignIndex, setCampaignIndex] = useState<number>(0);

    const handlePreviousCampaign = () => {
        setCampaignIndex(campaignIndex - 1);
    };

    const handleNextCampaign = () => {
        setCampaignIndex(campaignIndex + 1);
    };

    const getCurrentCampaign = () => {
        return campaignsUpdated.filter(
            (c) => c.matrix === matrices[matrixIndex]
        )[campaignIndex];
    };

    const selectAllCampaignPoints = (campaign: Partial<MonitoringCampaign>) => {
        let campaignsModified = structuredClone(campaignsUpdated);
        fromGeoJSONtoMonitoringPoints(monitoringPoints)
            .filter((p) => p.matrix && p.matrix.id === matrices[matrixIndex])
            .forEach((point) => {
                const pointFound = campaign.points!.find((p) => p === point.id);
                if (!pointFound) {
                    campaignsModified
                        .find(
                            (c: Partial<MonitoringCampaign>) =>
                                c.name === campaign.name
                        )!
                        .points!.push(point.id);
                }
            });
        setCampaignsUpdated(campaignsModified);
        onChange(campaignsModified);
    };

    const unselectAllCampaignPoints = (
        campaign: Partial<MonitoringCampaign>
    ) => {
        let campaignsModified = structuredClone(campaignsUpdated);
        campaignsModified.find(
            (c: Partial<MonitoringCampaign>) => c.name === campaign.name
        )!.points = [];
        setCampaignsUpdated(campaignsModified);
        onChange(campaignsModified);
    };

    const selectAllCampaignZones = (campaign: Partial<MonitoringCampaign>) => {
        let campaignsModified = structuredClone(campaignsUpdated);
        fromGeoJSONtoMonitoringZones(monitoringZones)
            .filter((z) => z.matrix && z.matrix.id === matrices[matrixIndex])
            .forEach((zone) => {
                const zoneFound = campaign.zones!.find((z) => z === zone.id);
                if (!zoneFound) {
                    campaignsModified
                        .find(
                            (c: Partial<MonitoringCampaign>) =>
                                c.name === campaign.name
                        )!
                        .zones!.push(zone.id);
                }
            });
        setCampaignsUpdated(campaignsModified);
        onChange(campaignsModified);
    };

    const unselectAllCampaignZones = (
        campaign: Partial<MonitoringCampaign>
    ) => {
        let campaignsModified = structuredClone(campaignsUpdated);
        campaignsModified.find(
            (c: Partial<MonitoringCampaign>) => c.name === campaign.name
        )!.zones = [];
        setCampaignsUpdated(campaignsModified);
        onChange(campaignsModified);
    };

    const selectAllCampaignPaths = (campaign: Partial<MonitoringCampaign>) => {
        let campaignsModified = structuredClone(campaignsUpdated);
        fromGeoJSONtoMonitoringPaths(monitoringPaths)
            .filter((p) => p.matrix && p.matrix.id === matrices[matrixIndex])
            .forEach((path) => {
                const pathFound = campaign.paths!.find((p) => p === path.id);
                if (!pathFound) {
                    campaignsModified
                        .find(
                            (c: Partial<MonitoringCampaign>) =>
                                c.name === campaign.name
                        )!
                        .paths!.push(path.id);
                }
            });
        setCampaignsUpdated(campaignsModified);
        onChange(campaignsModified);
    };

    const unselectAllCampaignPaths = (
        campaign: Partial<MonitoringCampaign>
    ) => {
        let campaignsModified = structuredClone(campaignsUpdated);
        campaignsModified.find(
            (c: Partial<MonitoringCampaign>) => c.name === campaign.name
        )!.paths = [];
        setCampaignsUpdated(campaignsModified);
        onChange(campaignsModified);
    };

    const onChangeCampaign = (
        campaign: Partial<MonitoringCampaign>,
        point?: MonitoringPoint,
        zone?: MonitoringZone,
        path?: MonitoringPath
    ) => {
        let campaignsModified = structuredClone(campaignsUpdated);
        if (point) {
            const pointFound = campaign.points!.find((p) => p === point.id);
            if (pointFound) {
                campaignsModified.find(
                    (c: Partial<MonitoringCampaign>) => c.name === campaign.name
                )!.points = campaign.points!.filter((z) => z !== point.id);
            } else {
                campaignsModified
                    .find(
                        (c: Partial<MonitoringCampaign>) =>
                            c.name === campaign.name
                    )!
                    .points!.push(point.id);
            }
        }
        if (zone) {
            const zoneFound = campaign.zones!.find((z) => z === zone.id);
            if (zoneFound) {
                campaignsModified.find(
                    (c: Partial<MonitoringCampaign>) => c.name === campaign.name
                )!.zones = campaign.zones!.filter((z) => z !== zone.id);
            } else {
                campaignsModified
                    .find(
                        (c: Partial<MonitoringCampaign>) =>
                            c.name === campaign.name
                    )!
                    .zones!.push(zone.id);
            }
        }
        if (path) {
            const pathFound = campaignsModified
                .find(
                    (c: Partial<MonitoringCampaign>) => c.name === campaign.name
                )!
                .paths!.find((p: number) => p === path.id);
            if (pathFound) {
                campaignsModified.find(
                    (c: Partial<MonitoringCampaign>) => c.name === campaign.name
                )!.paths = campaign.paths!.filter((p) => p !== path.id);
            } else {
                campaignsModified
                    .find(
                        (c: Partial<MonitoringCampaign>) =>
                            c.name === campaign.name
                    )!
                    .paths!.push(path.id);
            }
        }
        setCampaignsUpdated(campaignsModified);
        onChange(campaignsModified);
    };

    return (
        <FlexBoxFullWHColumn>
            <Box>
                <BoxFullW p={1} height={60}>
                    <MatricesButtonGroup
                        matrices={monitoringMatrices
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .filter((c) => matrices.includes(c.id))}
                        matrixSelected={matrices[matrixIndex]!}
                        onSelectMatrix={(matrixId) => {
                            setMatrixIndex(matrices.indexOf(matrixId));
                            setCampaignIndex(0);
                        }}
                    />
                </BoxFullW>
            </Box>
            <Box height={10} />
            <Box>
                <FlexBoxFullWRowBetween alignItems={'center'}>
                    <IconButton
                        color="primary"
                        onClick={handlePreviousCampaign}
                        disabled={campaignIndex === 0}
                    >
                        <ArrowCircleLeft fontSize="large" />
                    </IconButton>
                    <Typography textAlign={'center'} variant="h4">
                        Campagna {getCurrentCampaign().name}
                        {' ('}
                        {campaignIndex + 1} /{' '}
                        {
                            campaignsUpdated.filter(
                                (c) => c.matrix === matrices[matrixIndex]
                            ).length
                        }
                        {')'}
                    </Typography>
                    <IconButton
                        color="primary"
                        onClick={handleNextCampaign}
                        disabled={
                            campaignIndex ===
                            campaignsUpdated.filter(
                                (c) => c.matrix === matrices[matrixIndex]
                            ).length -
                                1
                        }
                    >
                        <ArrowCircleRight fontSize="large" />
                    </IconButton>
                </FlexBoxFullWRowBetween>
            </Box>
            <Box height={10} />
            <Box display={'flex'} overflow={'hidden'} gap={2}>
                <Box flex={1 / 3} display={'flex'} flexDirection={'column'}>
                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        mb={0.5}
                    >
                        <Typography fontWeight={'bold'}>PUNTI</Typography>
                        <Box>
                            <Button
                                size="small"
                                variant="outlined"
                                sx={{
                                    minWidth: 0,
                                    mr: 1,
                                }}
                                onClick={() =>
                                    selectAllCampaignPoints(
                                        getCurrentCampaign()
                                    )
                                }
                            >
                                <CheckBoxOutlined fontSize="small" />
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                sx={{
                                    minWidth: 0,
                                }}
                                onClick={() =>
                                    unselectAllCampaignPoints(
                                        getCurrentCampaign()
                                    )
                                }
                            >
                                <CheckBoxOutlineBlank fontSize="small" />
                            </Button>
                        </Box>
                    </Box>
                    <List
                        sx={{
                            width: 1,
                            height: 400,
                            border: (theme) =>
                                `1px solid ${theme.palette.divider}`,
                            overflow: 'auto',
                        }}
                    >
                        {fromGeoJSONtoMonitoringPoints(monitoringPoints)
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .filter(
                                (p) =>
                                    p.matrix &&
                                    p.matrix.id === matrices[matrixIndex]
                            )
                            .map((p) => (
                                <ListItem
                                    key={`point-${getCurrentCampaign()
                                        .matrix!}-${p.id}`}
                                    disablePadding
                                    secondaryAction={
                                        getCurrentCampaign().points!.find(
                                            (point) => point === p.id
                                        ) !== undefined ? (
                                            <Check color="primary" />
                                        ) : null
                                    }
                                >
                                    <ListItemButton
                                        onClick={() =>
                                            onChangeCampaign(
                                                getCurrentCampaign(),
                                                p
                                            )
                                        }
                                    >
                                        <ListItemText primary={p.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                    </List>
                </Box>
                <Box flex={1 / 3} display={'flex'} flexDirection={'column'}>
                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        mb={0.5}
                    >
                        <Typography fontWeight={'bold'}>ZONE</Typography>
                        <Box>
                            <Button
                                size="small"
                                variant="outlined"
                                sx={{
                                    minWidth: 0,
                                    mr: 1,
                                }}
                                onClick={() =>
                                    selectAllCampaignZones(getCurrentCampaign())
                                }
                            >
                                <CheckBoxOutlined fontSize="small" />
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                sx={{
                                    minWidth: 0,
                                }}
                                onClick={() =>
                                    unselectAllCampaignZones(
                                        getCurrentCampaign()
                                    )
                                }
                            >
                                <CheckBoxOutlineBlank fontSize="small" />
                            </Button>
                        </Box>
                    </Box>
                    <List
                        sx={{
                            width: 1,
                            height: 400,
                            border: (theme) =>
                                `1px solid ${theme.palette.divider}`,
                            overflow: 'auto',
                        }}
                    >
                        {fromGeoJSONtoMonitoringZones(monitoringZones)
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .filter(
                                (z) =>
                                    z.matrix &&
                                    z.matrix.id === getCurrentCampaign().matrix
                            )
                            .map((z) => (
                                <ListItem
                                    key={`zone-${getCurrentCampaign()
                                        .matrix!}-${z.id}`}
                                    disablePadding
                                    secondaryAction={
                                        getCurrentCampaign().zones!.find(
                                            (zone) => zone === z.id
                                        ) !== undefined ? (
                                            <Check color="primary" />
                                        ) : null
                                    }
                                >
                                    <ListItemButton
                                        onClick={() =>
                                            onChangeCampaign(
                                                getCurrentCampaign(),
                                                undefined,
                                                z
                                            )
                                        }
                                    >
                                        <ListItemText primary={z.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                    </List>
                </Box>
                <Box flex={1 / 3} display={'flex'} flexDirection={'column'}>
                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        mb={0.5}
                    >
                        <Typography fontWeight={'bold'}>PERCORSI</Typography>
                        <Box>
                            <Button
                                size="small"
                                variant="outlined"
                                sx={{
                                    minWidth: 0,
                                    mr: 1,
                                }}
                                onClick={() =>
                                    selectAllCampaignPaths(getCurrentCampaign())
                                }
                            >
                                <CheckBoxOutlined fontSize="small" />
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                sx={{
                                    minWidth: 0,
                                }}
                                onClick={() =>
                                    unselectAllCampaignPaths(
                                        getCurrentCampaign()
                                    )
                                }
                            >
                                <CheckBoxOutlineBlank fontSize="small" />
                            </Button>
                        </Box>
                    </Box>
                    <List
                        sx={{
                            width: 1,
                            height: 400,
                            border: (theme) =>
                                `1px solid ${theme.palette.divider}`,
                            overflow: 'auto',
                        }}
                    >
                        {fromGeoJSONtoMonitoringPaths(monitoringPaths)
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .filter(
                                (p) =>
                                    p.matrix &&
                                    p.matrix.id === getCurrentCampaign().matrix
                            )
                            .map((p) => (
                                <ListItem
                                    key={`path-${getCurrentCampaign()
                                        .matrix!}-${p.id}`}
                                    disablePadding
                                    secondaryAction={
                                        getCurrentCampaign().paths!.find(
                                            (path) => path === p.id
                                        ) !== undefined ? (
                                            <Check color="primary" />
                                        ) : null
                                    }
                                >
                                    <ListItemButton
                                        onClick={() =>
                                            onChangeCampaign(
                                                getCurrentCampaign(),
                                                undefined,
                                                undefined,
                                                p
                                            )
                                        }
                                    >
                                        <ListItemText primary={p.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                    </List>
                </Box>
            </Box>
        </FlexBoxFullWHColumn>
    );
};

export default DialogAddPhaseCampaigns;
