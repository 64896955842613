import { AnyAction } from '@reduxjs/toolkit';
import { resetFilters, setFilters } from './filters.action';

export enum ViewMode {
    VIREO = 'vireo',
    SEGNALAZIONI_SMART = 'segnalazioni',
}

export type FiltersState = {
    readonly viewMode: ViewMode;
    readonly search: string;
    readonly showMonitoringPoints: boolean;
    readonly showMonitoringZones: boolean;
    readonly showMonitoringPaths: boolean;
    readonly monitoringCategoriesIds: number[];
    readonly monitoringMatricesIds: number[];
    readonly monitoringServicesIds: number[];
    readonly monitoringCampaignsIds: number[];
    readonly severityId: number | null;
};

const INITIAL_STATE: FiltersState = {
    viewMode: ViewMode.SEGNALAZIONI_SMART,
    search: '',
    showMonitoringPoints: true,
    showMonitoringZones: true,
    showMonitoringPaths: true,
    monitoringCategoriesIds: [],
    monitoringMatricesIds: [],
    monitoringServicesIds: [],
    monitoringCampaignsIds: [],
    severityId: -2,
};

export const filtersReducer = (state = INITIAL_STATE, action: AnyAction) => {
    if (
        setFilters.match(action)) {
        return { ...state, ...action.payload };
    }

    if (resetFilters.match(action)) {
        return { ...INITIAL_STATE, viewMode: state.viewMode };
    }

    return state;
};
