import { Box, Button, TextField } from '@mui/material';
import { ChangeEvent, Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SimpleReportPayload } from '../../../../../models/monitoring';
import { setIsError } from '../../../../../store/error/error.action';
import { FlexBoxFullWColumn } from '../../../../layout/layout.styles';
import GBDialogActions from '../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../ui/gb-dialog-title/gb-dialog-title.component';
import GBTextArea from '../../../../ui/gb-text-area/gb-text-area.component';

const DialogAddSimpleReportInfoStep = ({
    name,
    description,
    onNext,
}: {
    name: string | null | undefined;
    description: string | undefined;
    onNext: (properties: Partial<SimpleReportPayload>) => void;
}) => {
    const [nameInput, setNameInput] = useState<string | null | undefined>(name);
    const [descriptionInput, setDescriptionInput] = useState<
        string | undefined
    >(description);

    const dispatch = useDispatch();

    const handleNextButtonClick = () => {
        if (!nameInput) {
            dispatch(setIsError(true, 'Inserire il nome'));
            return;
        }
        onNext({ name: nameInput, notes: descriptionInput });
    };

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNameInput(event.target.value);
    };

    const handleDescriptionChange = (
        event: ChangeEvent<HTMLTextAreaElement>
    ) => {
        setDescriptionInput(event.target.value);
    };

    return (
        <Fragment>
            <GBDialogTitle title="INSERISCI INFORMAZIONI" />
            <GBDialogContent>
                <FlexBoxFullWColumn>
                    <TextField
                        fullWidth
                        required
                        error={!nameInput}
                        label="Nome"
                        value={nameInput || ''}
                        onChange={handleNameChange}
                    />
                    <Box my={1} />
                    <GBTextArea
                        label="Descrizione"
                        multiline
                        rows={6}
                        defaultValue={descriptionInput}
                        onChange={handleDescriptionChange}
                    />
                </FlexBoxFullWColumn>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handleNextButtonClick}>AVANTI</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogAddSimpleReportInfoStep;
