import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';

export const StyledContainer = styled(Container)(() => ({
    width: '100%!important',
    maxWidth: '100%!important',
    margin: '0!important',
    padding: '0!important',
    height: '100%',
    border: '1px solid #bfbfbfb2',
    borderRadius: '5px',
    overflow: 'auto',
}));

export const StyledTypography = styled(Typography)(() => ({
    position: 'absolute',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    fontSize: '25px',
    paddingLeft: 15,
    paddingRight: 15,
    margin: 0,
    marginTop: -20,
    marginLeft: 9,
}));
