import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { lighten } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';

export const UserBoxButton = styled(Button)(({ theme }) => ({
    padding: 0,
    height: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
}));

export const UserBoxText = styled(Box)(({ theme }) => ({
    textAlign: 'left',
    paddingLeft: theme.spacing(1),
}));

export const MenuUserBox = styled(Box)(({ theme }) => ({
    background: lighten(theme.colors.alpha.black[100], 0.92),
    padding: theme.spacing(2),
}));

export const MenuUserBoxLabel = styled(Typography)(() => ({
    color: '#6c7077',
}));

export const MenuUserBoxDescription = styled(Typography)(() => ({
    color: '#032C6E',
}));
