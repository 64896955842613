export const enum MAP_TYPES {
    ROADMAP = 'roadmap',
    SATELLITE = 'satellite'
}
export const MAP_TYPE = MAP_TYPES.ROADMAP;
export const MAP_ZOOM = 5;
export const MAP_ELEMENT_ZOOM = 18;
export const MAP_CENTER = [12.3711906, 41.9102088];
export const MAP_PROJECTION_WGS84 = 'EPSG:4326';
export const MAP_PROJECTION_OL = 'EPSG:3857';
export const MAPBOX_ROADMAP_URL = 'https://api.mapbox.com/styles/v1/netfarm/clf17lix0001q01mr2260m1pd?access_token=pk.eyJ1IjoibmV0ZmFybSIsImEiOiJja2Eyb2U5am0wN3o4M2xsOXplMDJxNXdlIn0.BSH7zdFf44g4HhZiagsZkg';
export const MAPBOX_SATELLITE_URL = 'https://api.mapbox.com/styles/v1/netfarm/clj5svchf003x01pi9ndj789y?access_token=pk.eyJ1IjoibmV0ZmFybSIsImEiOiJja2Eyb2U5am0wN3o4M2xsOXplMDJxNXdlIn0.BSH7zdFf44g4HhZiagsZkg';
export const MONITORING_LAYER_CLASS = 'monitoring';
export const MONITORINGS_LAYER_CLASS = 'monitorings';
export const GENERIC_POINT_LAYER_CLASS = 'generic_point';
export const POINTS_LAYER_CLASS = 'points';
export const ZONES_LAYER_CLASS = 'zones';
export const PATHS_LAYER_CLASS = 'paths';
export const SIMPLE_REPORTS_LAYER_CLASS = 'simpleReports';
export const REPORTS_LAYER_CLASS = 'reports';
export const ELEMENTS_LAYER_CLASSES = [POINTS_LAYER_CLASS, ZONES_LAYER_CLASS, PATHS_LAYER_CLASS];
export const WKTOPTIONS = {
    dataProjection: MAP_PROJECTION_WGS84,
    featureProjection: MAP_PROJECTION_OL
};
export const MONITORING_ZONE_COLORS = {
    fill: {
        color: {
            unselected: 'rgba(255, 236, 192, 0.15)',
        }
    },
    stroke: {
        color: {
            unselected: 'rgb(255, 135, 0)'
        },
        width: 1
    }
};

export const ZONES_COLORS = {
    fill: {
        color: {
            selected: 'rgba(0, 88, 202, 0.31)',
            unselected: 'rgba(255, 217, 128, 0.50)',
        }
    },
    stroke: {
        color: {
            selected: 'rgb(255, 178, 0)',
            unselected: 'rgb(255, 178, 0)'
        },
        width: 1
    }
};