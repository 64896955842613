import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../store/dialog/dialog.action';
import {
    resetFilters,
    setFilters,
} from '../../../store/filters/filters.action';
import { FiltersState, ViewMode } from '../../../store/filters/filters.reducer';
import {
    selectFiltersReducer,
    selectViewMode,
} from '../../../store/filters/filters.selector';
import GBDialogActions from '../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../ui/gb-dialog-title/gb-dialog-title.component';
import GBTabPanel from '../../ui/gb-tab-panel/gb-tab-panel.component';
import FilterCampaign from './filter-campaign/filter-campaign.component';
import FilterCategory from './filter-category/filter-category.component';
import FilterElementType from './filter-element-type/filter-element-type.component';
import FilterMatrix from './filter-matrix/filter-matrix.component';
import FilterService from './filter-service/filter-service.component';
import FilterSeverity from './filter-severity/filter-severity.component';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const DialogFilters = () => {
    const dispatch = useDispatch();

    const filters = useSelector(selectFiltersReducer);

    const [filtersToApply, setFiltersToApply] = useState<FiltersState>();

    useEffect(() => {
        setFiltersToApply(filters);
    }, [filters]);

    const [tabIndex, setTabIndex] = useState(0);

    const viewMode = useSelector(selectViewMode);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const handleChangeFilter = (filter: Partial<FiltersState>) => {
        setFiltersToApply((prevState) => {
            return {
                ...prevState!,
                ...filter,
            };
        });
    };

    const handleResetFilters = () => {
        dispatch(resetFilters());
        dispatch(closeDialog());
    };

    const handleApplyFilters = () => {
        dispatch(setFilters(filtersToApply!));
        dispatch(closeDialog());
    };

    return (
        <Fragment>
            <GBDialogTitle title="FILTRA PER" />
            <GBDialogContent>
                {filtersToApply && (
                    <Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={tabIndex}
                                onChange={handleTabChange}
                                aria-label="basic tabs example"
                            >
                                <Tab label="Elementi" {...a11yProps(0)} />
                                <Tab
                                    label={
                                        viewMode === ViewMode.VIREO
                                            ? 'Matrici'
                                            : 'Categorie'
                                    }
                                    {...a11yProps(1)}
                                />
                                {viewMode === ViewMode.SEGNALAZIONI_SMART && (
                                    <Tab label="Servizi" {...a11yProps(2)} />
                                )}
                                {viewMode === ViewMode.SEGNALAZIONI_SMART && (
                                    <Tab label="Conformità" {...a11yProps(3)} />
                                )}
                                {viewMode === ViewMode.VIREO && (
                                    <Tab label="Campagne" {...a11yProps(2)} />
                                )}
                            </Tabs>
                        </Box>
                        <GBTabPanel value={tabIndex} index={0}>
                            <FilterElementType
                                filtersToApply={filtersToApply}
                                onChange={handleChangeFilter}
                            />
                        </GBTabPanel>
                        <GBTabPanel value={tabIndex} index={1}>
                            {viewMode === ViewMode.VIREO && (
                                <FilterMatrix
                                    filtersToApply={filtersToApply}
                                    onChange={handleChangeFilter}
                                />
                            )}
                            {viewMode === ViewMode.SEGNALAZIONI_SMART && (
                                <FilterCategory
                                    filtersToApply={filtersToApply}
                                    onChange={handleChangeFilter}
                                />
                            )}
                        </GBTabPanel>
                        {viewMode === ViewMode.SEGNALAZIONI_SMART && (
                            <GBTabPanel value={tabIndex} index={2}>
                                <FilterService
                                    filtersToApply={filtersToApply}
                                    onChange={handleChangeFilter}
                                />
                            </GBTabPanel>
                        )}
                        {viewMode === ViewMode.SEGNALAZIONI_SMART && (
                            <GBTabPanel value={tabIndex} index={3}>
                                <FilterSeverity
                                    filtersToApply={filtersToApply}
                                    onChange={handleChangeFilter}
                                />
                            </GBTabPanel>
                        )}

                        {viewMode === ViewMode.VIREO && (
                            <GBTabPanel value={tabIndex} index={2}>
                                <FilterCampaign
                                    filtersToApply={filtersToApply}
                                    onChange={handleChangeFilter}
                                />
                            </GBTabPanel>
                        )}
                    </Box>
                )}
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handleResetFilters}>RESETTA FILTRI</Button>
                <Button onClick={handleApplyFilters}>APPLICA FILTRI</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogFilters;
