import { User } from './auth.reducer';

import { AUTH_ACTION_TYPES } from './auth.types';

import {
    createAction,
    withMatcher,
    Action,
    ActionWithPayload,
} from '../../utils/reducer/reducer.utils';

export type SignInStart = ActionWithPayload<
    AUTH_ACTION_TYPES.SIGN_IN_START,
    { email: string; password: string; }
>;

export type SignInSuccess = ActionWithPayload<
    AUTH_ACTION_TYPES.SIGN_IN_SUCCESS,
    { accessToken: string; }
>;

export type SignInFailed = ActionWithPayload<
    AUTH_ACTION_TYPES.SIGN_IN_FAILED,
    string
>;

export type GetUserProfileStart = ActionWithPayload<
    AUTH_ACTION_TYPES.GET_USER_PROFILE_START,
    { accessToken: string; }
>;

export type GetUserProfileSuccess = ActionWithPayload<
    AUTH_ACTION_TYPES.GET_USER_PROFILE_SUCCESS,
    { accessToken: string; userProfile: User; }
>;

export type GetUserProfileFailed = ActionWithPayload<
    AUTH_ACTION_TYPES.GET_USER_PROFILE_FAILED,
    string
>;

export type SignOut = Action<AUTH_ACTION_TYPES.SIGN_OUT>;


export const signInStart = withMatcher(
    (email: string, password: string): SignInStart =>
        createAction(AUTH_ACTION_TYPES.SIGN_IN_START, { email, password })
);

export const signInSuccess = withMatcher(
    (accessToken: string): SignInSuccess =>
        createAction(AUTH_ACTION_TYPES.SIGN_IN_SUCCESS, { accessToken })
);

export const signInFailed = withMatcher(
    (error: string): SignInFailed =>
        createAction(AUTH_ACTION_TYPES.SIGN_IN_FAILED, error)
);

export const getUserProfileStart = withMatcher(
    (accessToken: string): GetUserProfileStart =>
        createAction(AUTH_ACTION_TYPES.GET_USER_PROFILE_START, { accessToken })
);

export const getUserProfileSuccess = withMatcher(
    (accessToken: string, userProfile: User): GetUserProfileSuccess =>
        createAction(AUTH_ACTION_TYPES.GET_USER_PROFILE_SUCCESS, { accessToken, userProfile })
);

export const getUserProfileFailed = withMatcher(
    (error: string): GetUserProfileFailed =>
        createAction(AUTH_ACTION_TYPES.GET_USER_PROFILE_FAILED, error)
);

export const signOut = withMatcher(
    (): SignOut => createAction(AUTH_ACTION_TYPES.SIGN_OUT)
);
