import { AnyAction } from '@reduxjs/toolkit';
import { MAP_CENTER, MAP_TYPE, MAP_TYPES, MAP_ZOOM } from '../../constants/map.constants';
import { cleanGeocodingError, geocodeAddressFailed, geocodeAddressStart, geocodeAddressSuccess, resetMap, reverseGeocodeCoordsFailed, reverseGeocodeCoordsStart, reverseGeocodeCoordsSuccess, setMapCenter, setMapType, setMapZoom } from './map.action';

export enum MapOperatorViewMode {
    REPORTS = 'reports',
    PERIODIC_INSPECTIONS = 'periodic_inspections',
};

export type MapState = {
    readonly mapType: MAP_TYPES,
    readonly mapZoom: number;
    readonly mapCenter: number[];
    readonly geocodingAddress: string;
    readonly geocodingLatitude: string;
    readonly geocodingLongitude: string;
    readonly geocodingError: string | null;
};

const INITIAL_STATE: MapState = {
    mapType: MAP_TYPE,
    mapZoom: MAP_ZOOM,
    mapCenter: MAP_CENTER,
    geocodingAddress: '',
    geocodingLatitude: '',
    geocodingLongitude: '',
    geocodingError: null,
};

export const mapReducer = (state = INITIAL_STATE, action: AnyAction) => {
    if (
        setMapType.match(action) ||
        setMapZoom.match(action) ||
        setMapCenter.match(action) ||
        geocodeAddressSuccess.match(action) ||
        reverseGeocodeCoordsSuccess.match(action)
    ) {
        return { ...state, ...action.payload };
    }

    if (resetMap.match(action)) {
        return INITIAL_STATE;
    }

    if (geocodeAddressStart.match(action)) {
        return {
            ...state,
            geocodingAddress: '',
            geocodingLatitude: '',
            geocodingLongitude: '',
        };
    }

    if (reverseGeocodeCoordsStart.match(action)) {
        return {
            ...state,
            geocodingAddress: '',
            geocodingLatitude: '',
            geocodingLongitude: '',
        };
    }

    if (
        cleanGeocodingError.match(action)
    ) {
        return { ...state, geocodingError: null };
    }

    if (
        geocodeAddressFailed.match(action) ||
        reverseGeocodeCoordsFailed.match(action)
    ) {
        return { ...state, geocodingError: action.payload };
    }

    return state;
};
