import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useDispatch, useSelector } from 'react-redux';
import { selectDialogOpened } from '../../store/dialog/dialog.selector';
import { setIsError } from '../../store/error/error.action';
import {
    selectErrorMessage,
    selectIsError,
} from '../../store/error/error.selector';
import DialogWrapper from '../dialogs/dialog-wrapper.component';
import GbSnackbar from '../ui/gb-snackbar/gb-snackbar.component';
import ContentWrapperOperator from './content-wrapper/content-wrapper-operator.component';
import { FlexBoxFullWHRow } from './layout.styles';
import TopBarReporter from './top-bar/top-bar-reporter.component';

export default function LayoutReporter() {
    const dispatch = useDispatch();

    const error = useSelector(selectIsError);
    const errorMessage = useSelector(selectErrorMessage);
    const dialogOpened = useSelector(selectDialogOpened);

    const handleCloseSnackbar = () => {
        dispatch(setIsError(false, null));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <FlexBoxFullWHRow position={'relative'} overflow={'hidden'}>
                {error && (
                    <GbSnackbar
                        open={true}
                        msg={errorMessage}
                        onClose={handleCloseSnackbar}
                    />
                )}
                {dialogOpened && <DialogWrapper />}
                <TopBarReporter />
                <ContentWrapperOperator />
            </FlexBoxFullWHRow>
        </LocalizationProvider>
    );
}
