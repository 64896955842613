import { Coordinate } from 'ol/coordinate';
import { Fragment } from 'react';
import {
    PATHS_LAYER_CLASS,
    POINTS_LAYER_CLASS,
    ZONES_LAYER_CLASS,
} from '../../../../../constants/map.constants';
import { ElementPayload } from '../../../../../models/monitoring';
import DialogAddElementLocationPathMap from './dialog-add-element-location-path-map/dialog-add-element-location-path-map.component';
import DialogAddElementLocationPointMap from './dialog-add-element-location-point-map/dialog-add-element-location-point-map.component';
import DialogAddElementLocationZoneMap from './dialog-add-element-location-zone-map/dialog-add-element-location-zone-map.component';

const DialogAddElementLocationStep = ({
    pointAddress,
    pointLatitude,
    pointLongitude,
    zoneCoords,
    pathCoords,
    className,
    onPrevious,
    onNext,
}: {
    pointAddress: string | undefined;
    pointLatitude: string | undefined;
    pointLongitude: string | undefined;
    zoneCoords: Coordinate[][] | undefined;
    pathCoords: Coordinate[] | undefined;
    className: string;
    onPrevious: () => void;
    onNext: (properties: Partial<ElementPayload>) => void;
}) => {
    return (
        <Fragment>
            {className === POINTS_LAYER_CLASS && (
                <DialogAddElementLocationPointMap
                    pointAddress={pointAddress}
                    pointLatitude={pointLatitude}
                    pointLongitude={pointLongitude}
                    onPrevious={onPrevious}
                    onNext={onNext}
                />
            )}
            {className === ZONES_LAYER_CLASS && (
                <DialogAddElementLocationZoneMap
                    zoneCoords={zoneCoords}
                    onPrevious={onPrevious}
                    onNext={onNext}
                />
            )}
            {className === PATHS_LAYER_CLASS && (
                <DialogAddElementLocationPathMap
                    pathCoords={pathCoords}
                    onPrevious={onPrevious}
                    onNext={onNext}
                />
            )}
        </Fragment>
    );
};

export default DialogAddElementLocationStep;
