import { Box, Button, TextField } from '@mui/material';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import {
    MonitoringReportShort,
    ReportPayload,
} from '../../../../../models/monitoring';
import { selectSeverities } from '../../../../../store/monitoring/monitoring.selector';
import { FlexBoxFullWColumn } from '../../../../layout/layout.styles';
import GBDialogActions from '../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../ui/gb-dialog-title/gb-dialog-title.component';
import GBTextArea from '../../../../ui/gb-text-area/gb-text-area.component';

const DialogUpdateReportWithInterventionRecapStep = ({
    report,
    disablePrev,
    onPrevious,
    onNext,
}: {
    report: MonitoringReportShort;
    disablePrev?: boolean;
    onPrevious: () => void;
    onNext: (properties: Partial<ReportPayload>) => void;
}) => {
    const severities = useSelector(selectSeverities);

    const getSeverityName = (severityId: number | null) => {
        const severity = severities.find((s) => s.id === severityId);
        if (severity) {
            return `Non conforme con criticità ${severity.name}`;
        } else {
            return 'Conforme';
        }
    };

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    const handleNextButtonClick = () => {
        onNext({});
    };

    return (
        <Fragment>
            <GBDialogTitle title="RECAP SEGNALAZIONE" />
            <GBDialogContent>
                <FlexBoxFullWColumn>
                    <TextField
                        disabled
                        fullWidth
                        label="Nome Segnalazione"
                        defaultValue={report.report_name}
                    />
                    <Box my={1} />
                    <GBTextArea
                        disabled
                        label="Note Segnalazione"
                        multiline
                        rows={6}
                        defaultValue={report.report_reporting_notes}
                    />
                    <Box my={1} />
                    <TextField
                        disabled
                        fullWidth
                        label="Conformità Segnalazione"
                        defaultValue={getSeverityName(
                            report.report_reporting_severity
                        )}
                    />
                    <Box my={1} />
                    <GBTextArea
                        disabled
                        label="Note Assegnazione"
                        multiline
                        rows={6}
                        defaultValue={report.report_assignment_notes}
                    />
                    <Box my={1} />
                    <TextField
                        disabled
                        fullWidth
                        label="Conformità Assegnazione"
                        defaultValue={getSeverityName(
                            report.report_assignment_severity
                        )}
                    />
                </FlexBoxFullWColumn>
            </GBDialogContent>
            <GBDialogActions>
                {!disablePrev && (
                    <Button onClick={handlePreviousButtonClick}>
                        INDIETRO
                    </Button>
                )}
                <Button onClick={handleNextButtonClick}>AVANTI</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogUpdateReportWithInterventionRecapStep;
