import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { selectSearch } from '../../../store/filters/filters.selector';
import { setFilters } from '../../../store/filters/filters.action';

const GBSearchElementBox = () => {
    const search = useSelector(selectSearch);

    const dispatch = useDispatch();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
        dispatch(setFilters({ search: event.target.value }));

    return (
        <TextField
            value={search}
            onChange={handleChange}
            fullWidth
            label="Cerca"
            type="search"
        />
    );
};

export default GBSearchElementBox;
