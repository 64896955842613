import styled from '@mui/material/styles/styled';

export const ContentContainer = styled('main')(() => ({
    flexGrow: 1,
    p: {
        xs: 0, //1,
        sm: 0, //1,
        md: 2,
    },
    pt: {
        xs: 2, //3,
        sm: 1, //2,
        md: 3,
    },
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}));
