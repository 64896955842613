import { Button } from '@mui/material';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ManageMonitoringSimpleReportPayload } from '../../../../../models/monitoring';
import { setIsError } from '../../../../../store/error/error.action';
import GBDialogActions from '../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../ui/gb-dialog-title/gb-dialog-title.component';
import GBSeverityRadio from '../../../../ui/gb-severity-radio/gb-severity-radio.component';

const DialogManageNewSimpleReportSeverityStep = ({
    onPrevious,
    onNext,
}: {
    onPrevious: () => void;
    onNext: (properties: Partial<ManageMonitoringSimpleReportPayload>) => void;
}) => {
    const dispatch = useDispatch();

    const [severitySelected, setSeveritySelected] = useState<number | null>(-1);

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    const handleNextButtonClick = () => {
        if (severitySelected === -1) {
            dispatch(setIsError(true, 'Seleziona una conformità'));
            return;
        }
        onNext({ reporting_severity: severitySelected });
    };

    const handleSeverityChange = (value: number | null) => {
        setSeveritySelected(value);
    };

    return (
        <Fragment>
            <GBDialogTitle title="SELEZIONA CONFORMITÀ" />
            <GBDialogContent>
                <GBSeverityRadio
                    value={severitySelected}
                    onChange={handleSeverityChange}
                />
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handlePreviousButtonClick}>INDIETRO</Button>
                <Button onClick={handleNextButtonClick}>TERMINA E SALVA</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogManageNewSimpleReportSeverityStep;
