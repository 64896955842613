import { Outlet } from 'react-router-dom';
import { FlexBoxFullWHColumn } from '../layout.styles';
import { ContentContainer } from './content-wrapper.styles';

const ContentWrapperLogin = () => {
    return (
        <ContentContainer>
            <FlexBoxFullWHColumn
                justifyContent={'center'}
                marginTop={'80px'}
                p={2}
                overflow={'auto'}
            >
                <Outlet />
            </FlexBoxFullWHColumn>
        </ContentContainer>
    );
};

export default ContentWrapperLogin;
