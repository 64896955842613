import { all, call, put, takeLatest } from 'typed-redux-saga/macro';
import { ATTACHMENTS_URL } from '../../constants/api.constants';
import { apiRequestWrapper } from '../../utils/api/api.utils';
import { setIsError } from '../error/error.action';
import { setIsLoading } from '../loading/loading.action';
import { AddFilesStart, DeleteFileStart, addFilesFailed, addFilesSuccess, deleteFileFailed, deleteFileSuccess } from './files.action';
import { FILES_ACTION_TYPES } from './files.types';

export function* addFiles(addFilesStart: AddFilesStart) {
    yield* put(setIsLoading(true));
    try {
        const response = yield* call(
            apiRequestWrapper, ATTACHMENTS_URL, 'POST', addFilesStart.payload, true
        );
        yield* put(addFilesSuccess());
        yield* put(setIsLoading(false));
        addFilesStart.payload.onComplete(response);
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(addFilesFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* deleteFile(deleteFileStart: DeleteFileStart) {
    yield* put(setIsLoading(true));
    try {
        yield* call(
            apiRequestWrapper, `${ATTACHMENTS_URL}${deleteFileStart.payload.id}/`, 'DELETE', null
        );
        yield* put(deleteFileSuccess(deleteFileStart.payload.id));
        yield* put(setIsLoading(false));
        deleteFileStart.payload.onComplete();
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(deleteFileFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onAddFilesStart() {
    yield* takeLatest(FILES_ACTION_TYPES.ADD_FILES_START, addFiles);
}

export function* onDeleteFileStart() {
    yield* takeLatest(FILES_ACTION_TYPES.DELETE_FILE_START, deleteFile);
}

export function* filesSaga() {
    yield* all([
        call(onAddFilesStart),
        call(onDeleteFileStart),
    ]);
}
