import { Outlet } from 'react-router-dom';
import { ContentContainer } from './content-wrapper.styles';
import { BoxFullWH } from '../layout.styles';

const ContentWrapper = () => {
    return (
        <ContentContainer>
            <BoxFullWH marginTop={'140px'} p={2} overflow={'auto'}>
                <Outlet />
            </BoxFullWH>
        </ContentContainer>
    );
};

export default ContentWrapper;
