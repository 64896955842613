import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { GBDataGrid } from './gb-table.styles';

const GBTable = ({
    rows,
    columns,
    autoRowHeight,
    rowSelectedId,
    noRowSelection,
    onRowSelected,
}: {
    rows: GridRowsProp;
    columns: GridColDef[];
    autoRowHeight?: boolean;
    rowSelectedId: number | undefined;
    noRowSelection?: boolean;
    onRowSelected: (row: any) => void;
}) => {
    return (
        <GBDataGrid
            getRowHeight={() => (autoRowHeight ? 'auto' : 52)}
            hideFooter
            rows={rows}
            columns={columns}
            rowSelectionModel={rowSelectedId}
            onRowClick={(params) => onRowSelected(params.row)}
            rowSelection={!noRowSelection}
        />
    );
};

export default GBTable;
