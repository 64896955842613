import { all, call, put, takeLatest } from 'typed-redux-saga/macro';
import { MARK_NOTIFICATIONS_AS_READ_URL, MONITORING_APIVIEW_URL, MONITORING_TEST_REPORTS_URL, REQUIRED_ACTIONS_URL } from '../../constants/api.constants';
import { apiRequestWrapper } from '../../utils/api/api.utils';
import { setIsError } from '../error/error.action';
import { setFilters } from '../filters/filters.action';
import { setIsLoading } from '../loading/loading.action';
import { DeleteEntityStart, GetMonitoringStart, MarkNotificationsAsReadStart, deleteEntityFailed, deleteEntitySuccess, getMonitoringFailed, getMonitoringSuccess, getRequiredActionsFailed, getRequiredActionsSuccess, markNotificationsAsReadFailed, markNotificationsAsReadSuccess } from './monitoring.action';
import { MonitoringState } from './monitoring.reducer';
import { ENTITIES, MONITORING_ACTION_TYPES } from './monitoring.types';

export function* getMonitoring({ payload }: GetMonitoringStart) {
    yield* put(setIsLoading(true));
    try {
        const monitoring = yield* call(
            apiRequestWrapper, `${MONITORING_APIVIEW_URL}?view_mode=${payload.viewMode}`, 'GET', null
        );

        if (monitoring) {
            const categoriesIds = (monitoring.result as MonitoringState).monitoringCategories.map((c: any) => c.id);
            const matricesIds = (monitoring.result as MonitoringState).monitoringMatrices.map((c: any) => c.id);
            yield* put(setFilters({
                monitoringCategoriesIds: categoriesIds,
                monitoringMatricesIds: matricesIds,
                monitoringCampaignsIds: [],
            }));
            yield* put(getMonitoringSuccess(monitoring.result));
            yield* put(setIsLoading(false));
        }
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getMonitoringFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* markNotificationsAsRead({
    payload: notificationsIds,
}: MarkNotificationsAsReadStart) {
    try {
        const notifications = yield* call(
            apiRequestWrapper, MARK_NOTIFICATIONS_AS_READ_URL, 'PUT', notificationsIds
        );

        if (notifications) {
            yield* put(markNotificationsAsReadSuccess(notifications));
        }
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(markNotificationsAsReadFailed(errorResponse));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* deleteEntity({
    payload: { id, entity, onComplete },
}: DeleteEntityStart) {
    yield* put(setIsLoading(true));
    try {
        let url = '';
        switch (entity) {
            case ENTITIES.TEST_REPORTS:
                url = `${MONITORING_TEST_REPORTS_URL}${id}/`;
                break;
            default:
                console.error(`Delete entity ${entity} not implemented`);
                break;
        }

        yield* call(
            apiRequestWrapper, url, 'DELETE', null
        );
        yield* put(deleteEntitySuccess());
        yield* put(setIsLoading(false));
        onComplete();
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(deleteEntityFailed(errorResponse));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* getRequiredActions() {
    yield* put(setIsLoading(true));
    try {
        const actions = yield* call(
            apiRequestWrapper, REQUIRED_ACTIONS_URL, 'GET', null
        );

        if (actions) {
            yield* put(getRequiredActionsSuccess(actions));
            yield* put(setIsLoading(false));
        }
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getRequiredActionsFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onGetMonitoringStart() {
    yield* takeLatest(MONITORING_ACTION_TYPES.GET_MONITORING_START, getMonitoring);
}

export function* onMarkNotificationsAsReadStart() {
    yield* takeLatest(MONITORING_ACTION_TYPES.MARK_NOTIFICATIONS_AS_READ_START, markNotificationsAsRead);
}

export function* onDeleteEntityStart() {
    yield* takeLatest(MONITORING_ACTION_TYPES.DELETE_ENTITY_START, deleteEntity);
}

export function* onGetRequiredActionsStart() {
    yield* takeLatest(MONITORING_ACTION_TYPES.GET_REQUIRED_ACTIONS_START, getRequiredActions);
}

export function* monitoringSaga() {
    yield* all([
        call(onGetMonitoringStart),
        call(onMarkNotificationsAsReadStart),
        call(onDeleteEntityStart),
        call(onGetRequiredActionsStart),
    ]);
}
