import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import {
    ManageMonitoringSimpleReportPayload,
    MonitoringElementService,
} from '../../../../models/monitoring';
import { setIsError } from '../../../../store/error/error.action';
import MapWrapperSimpleReportAssignElement from '../../../map/map-wrapper/map-wrapper-simple-report-assign-element.component';
import GBDialogActions from '../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../ui/gb-dialog-title/gb-dialog-title.component';

const DialogManageNewSimpleReportAssignElementStep = ({
    simpleReportId,
    selectedElementId,
    selectedElementName,
    onElementSelected,
    onElementUnselected,
    onNext,
    onPrevious,
}: {
    simpleReportId: number;
    selectedElementId: number | null;
    selectedElementName: string | null;
    onElementSelected: (
        id: number,
        name: string,
        elementServices: MonitoringElementService[]
    ) => void;
    onElementUnselected: () => void;
    onNext: (properties: Partial<ManageMonitoringSimpleReportPayload>) => void;
    onPrevious: () => void;
}) => {
    const dispatch = useDispatch();

    const handlePrevButtonClick = () => {
        onPrevious();
    };

    const handleNextButtonClick = () => {
        if (selectedElementId == null) {
            dispatch(setIsError(true, 'Nessun elemento selezionato'));
            return;
        }
        onNext({});
    };

    const handleFeatureSelected = (
        id: number,
        name: string,
        elementServices: MonitoringElementService[]
    ) => {
        onElementSelected(id, name, elementServices);
    };

    const handleFeatureUnselected = () => {
        onElementUnselected();
    };

    return (
        <>
            <GBDialogTitle title="Selezionare l'elemento a cui associare la segnalazione"></GBDialogTitle>
            <GBDialogContent>
                <MapWrapperSimpleReportAssignElement
                    selectedElementName={selectedElementName}
                    simpleReportId={simpleReportId}
                    onFeatureSelected={handleFeatureSelected}
                    onFeatureUnselected={handleFeatureUnselected}
                />
            </GBDialogContent>
            <GBDialogActions>
                <Box>
                    <Button aria-label="close" onClick={handlePrevButtonClick}>
                        INDIETRO
                    </Button>
                </Box>
                <Button autoFocus onClick={handleNextButtonClick}>
                    AVANTI
                </Button>
            </GBDialogActions>
        </>
    );
};

export default DialogManageNewSimpleReportAssignElementStep;
