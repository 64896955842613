import MuiAppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import styled from '@mui/material/styles/styled';

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: '80px',
}));

export const Toolbar = styled(MuiToolbar, {
    shouldForwardProp: (prop) => prop !== 'sidebarOpened',
})<{ sidebarOpened: boolean }>(({ sidebarOpened }) => ({
    marginLeft: sidebarOpened ? '500px' : '150px',
    minHeight: '100%!important',
    height: '100%!important',
    display: 'flex',
    justifyContent: 'center',
}));

export const ToolbarOperator = styled(MuiAppBar)(() => ({
    minHeight: '100%!important',
    height: '100%!important',
    display: 'flex',
    justifyContent: 'center',
}));

export const ActionAppBar = styled(MuiAppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: '60px',
    display: 'block',
}));

export const ActionToolbar = styled(MuiToolbar)(({ theme }) => ({
    minHeight: '100%!important',
    height: '100%!important',
    display: 'flex',
    justifyContent: 'space-between',
}));
