import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
    MonitoringCampaign,
    PhasePayload,
} from '../../../../../models/monitoring';
import {
    selectMonitoringMatrices,
    selectMonitoringPaths,
    selectMonitoringPoints,
    selectMonitoringZones,
} from '../../../../../store/monitoring/monitoring.selector';
import {
    fromGeoJSONtoMonitoringPaths,
    fromGeoJSONtoMonitoringPoints,
    fromGeoJSONtoMonitoringZones,
} from '../../../../../utils/data/data.utils';

const DialogAddPhaseRecap = ({
    phase,
    campaigns,
}: {
    phase: Partial<PhasePayload>;
    campaigns: Partial<MonitoringCampaign>[];
}) => {
    const monitoringMatrices = useSelector(selectMonitoringMatrices);
    const monitoringPoints = useSelector(selectMonitoringPoints);
    const monitoringZones = useSelector(selectMonitoringZones);
    const monitoringPaths = useSelector(selectMonitoringPaths);

    const findPointById = (id: number) => {
        return fromGeoJSONtoMonitoringPoints(monitoringPoints).find(
            (p) => p.id === id
        );
    };

    const findZoneById = (id: number) => {
        return fromGeoJSONtoMonitoringZones(monitoringZones).find(
            (z) => z.id === id
        );
    };

    const findPathById = (id: number) => {
        return fromGeoJSONtoMonitoringPaths(monitoringPaths).find(
            (p) => p.id === id
        );
    };

    return (
        <Box display={'flex'} gap={2} marginTop={2}>
            <Box display={'flex'} flexDirection={'column'} flex={1}>
                <Typography mb={2} variant={'h4'}>
                    RECAP FASE
                </Typography>
                <Typography>
                    <Box width={1} display={'flex'}>
                        <Box width={1 / 6}>
                            <Typography fontWeight={'bold'}>Codice:</Typography>
                        </Box>
                        <Box>
                            <Typography>{phase.code}</Typography>
                        </Box>
                    </Box>
                </Typography>
                <Typography>
                    <Box width={1} display={'flex'}>
                        <Box width={1 / 6}>
                            <Typography fontWeight={'bold'}>Nome:</Typography>
                        </Box>
                        <Box>
                            <Typography>{phase.name}</Typography>
                        </Box>
                    </Box>
                </Typography>
                <Typography>
                    <Box width={1} display={'flex'}>
                        <Box width={1 / 6}>
                            <Typography fontWeight={'bold'}>
                                Data Inizio:
                            </Typography>
                        </Box>
                        <Box>
                            <Typography>
                                {moment(phase.start_date)
                                    .locale('it')
                                    .format('DD/MM/YYYY')}
                            </Typography>
                        </Box>
                    </Box>
                </Typography>
                <Typography>
                    <Box width={1} display={'flex'}>
                        <Box width={1 / 6}>
                            <Typography fontWeight={'bold'}>
                                Data Fine:
                            </Typography>
                        </Box>
                        <Box>
                            <Typography>
                                {moment(phase.end_date)
                                    .locale('it')
                                    .format('DD/MM/YYYY')}
                            </Typography>
                        </Box>
                    </Box>
                </Typography>
                <Typography>
                    <Box width={1} display={'flex'}>
                        <Box width={1 / 6}>
                            <Typography fontWeight={'bold'}>
                                Descrizione:
                            </Typography>
                        </Box>
                        <Box>
                            <Typography>{phase.description}</Typography>
                        </Box>
                    </Box>
                </Typography>
                <Typography mt={3} mb={1} variant={'h4'}>
                    RECAP CAMPAGNE
                </Typography>
                {campaigns.map((campaign) => (
                    <Box mb={2} key={campaign.name}>
                        <Typography>
                            <Box width={1} display={'flex'}>
                                <Box width={1 / 6}>
                                    <Typography fontWeight={'bold'}>
                                        Nome:
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography>{campaign.name}</Typography>
                                </Box>
                            </Box>
                        </Typography>
                        <Typography>
                            <Box width={1} display={'flex'}>
                                <Box width={1 / 6}>
                                    <Typography fontWeight={'bold'}>
                                        Categoria:
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography>
                                        {monitoringMatrices.find(
                                            (m) => m.id === campaign.matrix
                                        )?.code &&
                                            `(${
                                                monitoringMatrices.find(
                                                    (m) =>
                                                        m.id === campaign.matrix
                                                )?.code
                                            }) `}
                                        {
                                            monitoringMatrices.find(
                                                (m) => m.id === campaign.matrix
                                            )?.name
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                        </Typography>
                        <Typography>
                            <Box width={1} display={'flex'}>
                                <Box width={1 / 6}>
                                    <Typography fontWeight={'bold'}>
                                        Data Inizio:
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography>
                                        {moment(campaign.start_date)
                                            .locale('it')
                                            .format('DD/MM/YYYY')}
                                    </Typography>
                                </Box>
                            </Box>
                        </Typography>
                        <Typography>
                            <Box width={1} display={'flex'}>
                                <Box width={1 / 6}>
                                    <Typography fontWeight={'bold'}>
                                        Data Inizio:
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography>
                                        {moment(campaign.end_date)
                                            .locale('it')
                                            .format('DD/MM/YYYY')}
                                    </Typography>
                                </Box>
                            </Box>
                        </Typography>
                        {campaign.points!.length > 0 && (
                            <Typography>
                                <Box width={1} display={'flex'}>
                                    <Box width={1 / 6}>
                                        <Typography fontWeight={'bold'}>
                                            Punti:
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography>
                                            {campaign.points!.map((p) => (
                                                <Typography
                                                    key={campaign.name! + p}
                                                >
                                                    {findPointById(p)?.name}
                                                </Typography>
                                            ))}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Typography>
                        )}
                        {campaign.zones!.length > 0 && (
                            <Typography>
                                <Box width={1} display={'flex'}>
                                    <Box width={1 / 6}>
                                        <Typography fontWeight={'bold'}>
                                            Zone:
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography>
                                            {campaign.zones!.map((z) => (
                                                <Typography
                                                    key={campaign.name! + z}
                                                >
                                                    {findZoneById(z)?.name}
                                                </Typography>
                                            ))}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Typography>
                        )}
                        {campaign.paths!.length > 0 && (
                            <Typography>
                                <Box width={1} display={'flex'}>
                                    <Box width={1 / 6}>
                                        <Typography fontWeight={'bold'}>
                                            Percorsi:
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography>
                                            {campaign.paths!.map((p) => (
                                                <Typography
                                                    key={campaign.name! + p}
                                                >
                                                    {findPathById(p)?.name}
                                                </Typography>
                                            ))}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Typography>
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default DialogAddPhaseRecap;
