import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    useTheme,
} from '@mui/material';
import { Map, MapBrowserEvent } from 'ol';
import { transform } from 'ol/proj';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    GENERIC_POINT_LAYER_CLASS,
    MAP_PROJECTION_OL,
    MAP_PROJECTION_WGS84,
    MONITORING_LAYER_CLASS,
    POINTS_LAYER_CLASS,
} from '../../../../../../constants/map.constants';
import {
    selectGeocodingAddress,
    selectGeocodingError,
    selectGeocodingLatitude,
    selectGeocodingLongitude,
} from '../../../../../../store/map/map.selector';
import {
    selectMonitoring,
    selectMonitoringPoints,
} from '../../../../../../store/monitoring/monitoring.selector';
import {
    buildGenericPointLayer,
    buildMonitoringLayer,
    buildMonitoringPointsLayer,
    getPointStyle,
    initMap,
} from '../../../../../../utils/map/map.utils';
import { FlexBoxFullWHColumn } from '../../../../../layout/layout.styles';
import GBDialogActions from '../../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../../ui/gb-dialog-title/gb-dialog-title.component';

import {
    PARAMS_KEYS,
    PARAMS_MODULES,
} from '../../../../../../constants/params.constants';
import {
    ElementPayload,
    MonitoringElement,
} from '../../../../../../models/monitoring';
import { setIsError } from '../../../../../../store/error/error.action';
import { selectViewMode } from '../../../../../../store/filters/filters.selector';
import {
    cleanGeocodingError,
    geocodeAddressStart,
    reverseGeocodeCoordsStart,
} from '../../../../../../store/map/map.action';
import { selectParams } from '../../../../../../store/params/params.selector';
import { fromGeoJSONtoMonitoring } from '../../../../../../utils/data/data.utils';
import { applyViewModeFilterToElement } from '../../../../../../utils/filters/filters.utils';
import { getParamValue } from '../../../../../../utils/params/params.utils';

const DialogAddElementLocationPointMap = ({
    pointAddress,
    pointLatitude,
    pointLongitude,
    onPrevious,
    onNext,
}: {
    pointAddress: string | undefined;
    pointLatitude: string | undefined;
    pointLongitude: string | undefined;
    onPrevious: () => void;
    onNext: (properties: Partial<ElementPayload>) => void;
}) => {
    const theme = useTheme();

    const dispatch = useDispatch();

    const monitoring = useSelector(selectMonitoring);
    const monitoringPoints = useSelector(selectMonitoringPoints);
    const geocodingLatitude = useSelector(selectGeocodingLatitude);
    const geocodingLongitude = useSelector(selectGeocodingLongitude);
    const geocodingAddress = useSelector(selectGeocodingAddress);
    const geocodingError = useSelector(selectGeocodingError);
    const params = useSelector(selectParams);
    const viewMode = useSelector(selectViewMode);

    const mapElement = useRef<HTMLDivElement>(null);

    const [map, setMap] = useState<Map>();
    const [latitude, setLatitude] = useState<string>(pointLatitude ?? '');
    const [longitude, setLongitude] = useState<string>(pointLongitude ?? '');
    const [address, setAddress] = useState<string>(pointAddress ?? '');
    const [markerLatitude, setMarkerLatitude] = useState<string | undefined>(
        pointLatitude
    );
    const [markerLongitude, setMarkerLongitude] = useState<string | undefined>(
        pointLongitude
    );
    const [firstGeocoding, setFirstGeocoding] = useState<boolean>(true);

    useEffect(() => {
        if (markerLatitude && markerLongitude && map) {
            const transformedCoord = transform(
                [parseFloat(markerLatitude), parseFloat(markerLongitude)],
                MAP_PROJECTION_WGS84,
                MAP_PROJECTION_OL
            );
            map.getLayers().forEach((layer) => {
                if (
                    layer &&
                    layer.getClassName() === GENERIC_POINT_LAYER_CLASS
                ) {
                    map.removeLayer(layer);
                }
            });
            map.addLayer(buildGenericPointLayer(transformedCoord));
            setLatitude(markerLatitude);
            setLongitude(markerLongitude);
        }
    }, [markerLatitude, markerLongitude, map]);

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    const handleNextButtonClick = () => {
        if (!markerLatitude || !markerLongitude) {
            dispatch(
                setIsError(
                    true,
                    'Indicare sia la latitudine che la longitudine'
                )
            );
            return;
        }
        onNext({
            pointAddress: address,
            pointLat: markerLatitude,
            pointLng: markerLongitude,
        });
    };

    const reinitPageMap = useCallback(async () => {
        if (mapElement.current?.innerHTML) {
            mapElement.current.innerHTML = '';
        }
        const _map = await initMap(mapElement);
        _map.on('click', (event: MapBrowserEvent<UIEvent>) => {
            const clickedCoord = _map.getCoordinateFromPixel(event.pixel);
            const transformedCoord = transform(
                clickedCoord,
                MAP_PROJECTION_OL,
                MAP_PROJECTION_WGS84
            );
            setFirstGeocoding(false);
            dispatch(
                reverseGeocodeCoordsStart(
                    transformedCoord[0].toString(),
                    transformedCoord[1].toString()
                )
            );
        });
        const mapZoomParam = getParamValue(
            params,
            PARAMS_MODULES.core,
            PARAMS_KEYS.initialMapZoom
        );
        if (mapZoomParam) {
            _map.getView().setZoom(parseInt(mapZoomParam));
        }
        const mapCenterParam = getParamValue(
            params,
            PARAMS_MODULES.core,
            PARAMS_KEYS.initialMapCenter
        );
        if (mapCenterParam) {
            _map.getView().setCenter(
                transform(
                    mapCenterParam.split(',').map((v: any) => parseFloat(v)),
                    MAP_PROJECTION_WGS84,
                    MAP_PROJECTION_OL
                )
            );
        }
        setMap(_map);
    }, [dispatch, params]);

    useEffect(() => {
        reinitPageMap();
    }, [reinitPageMap]);

    useEffect(() => {
        if (map && monitoring && theme) {
            map.getLayers().forEach((layer) => {
                if (layer && layer.getClassName() === MONITORING_LAYER_CLASS) {
                    map.removeLayer(layer);
                }
            });
            map.addLayer(buildMonitoringLayer(monitoring));
        }
    }, [monitoring, map, theme]);

    useEffect(() => {
        if (map && monitoringPoints && theme) {
            map.getLayers().forEach((layer) => {
                if (layer && layer.getClassName() === POINTS_LAYER_CLASS) {
                    map.removeLayer(layer);
                }
            });
            const pointsLayer = buildMonitoringPointsLayer(
                monitoringPoints,
                theme,
                viewMode
            );
            pointsLayer
                .getSource()
                ?.getFeatures()
                .forEach((feature) => {
                    const element =
                        feature.getProperties() as MonitoringElement;
                    feature.setProperties({
                        hide: !applyViewModeFilterToElement(element, viewMode),
                    });
                    feature.setStyle(getPointStyle(feature, theme, viewMode));
                });
            map.addLayer(pointsLayer);
        }
    }, [monitoringPoints, map, theme, viewMode]);

    const handleGeocodingButtonClick = () => {
        if (!address) {
            dispatch(setIsError(true, 'Inserire indirizzo'));
            return;
        }
        setFirstGeocoding(false);
        dispatch(geocodeAddressStart(address));
    };

    useEffect(() => {
        if (
            geocodingLatitude &&
            geocodingLongitude &&
            geocodingAddress &&
            !firstGeocoding
        ) {
            const transformedCoord = transform(
                [parseFloat(geocodingLatitude), parseFloat(geocodingLongitude)],
                MAP_PROJECTION_WGS84,
                MAP_PROJECTION_OL
            );
            if (map) {
                let stop = false;
                var pixel = map.getPixelFromCoordinate(transformedCoord);
                map.forEachFeatureAtPixel(pixel, async (feature, layer) => {
                    if (
                        !stop &&
                        (layer.getClassName() === MONITORING_LAYER_CLASS ||
                            layer.getClassName() === POINTS_LAYER_CLASS)
                    ) {
                        stop = true;
                    }
                });
                if (stop) {
                    setAddress(geocodingAddress);
                    setMarkerLatitude(geocodingLatitude);
                    setMarkerLongitude(geocodingLongitude);
                } else {
                    dispatch(
                        setIsError(
                            true,
                            'Il punto si trova fuori dalla zona di monitoraggio'
                        )
                    );
                }
            }
        }
    }, [
        dispatch,
        geocodingLatitude,
        geocodingLongitude,
        geocodingAddress,
        firstGeocoding,
        map,
    ]);

    const handleReverseGeocodingButtonClick = () => {
        if (!latitude || !longitude) {
            dispatch(setIsError(true, 'Inserire coordinate'));
            return;
        }
        setFirstGeocoding(false);
        dispatch(reverseGeocodeCoordsStart(latitude, longitude));
    };

    useEffect(() => {
        if (geocodingError) {
            dispatch(setIsError(true, 'geocodifica non riuscita'));
            dispatch(cleanGeocodingError());
        }
    }, [geocodingError, dispatch]);

    return (
        <Fragment>
            <GBDialogTitle title="LOCALIZZA POSIZIONE" />
            <GBDialogContent height="80vh">
                <FlexBoxFullWHColumn>
                    <Box height={9 / 10}>
                        <Card
                            sx={{
                                height: '100%',
                            }}
                        >
                            <CardContent
                                sx={{
                                    p: 0,
                                    height: '100%',
                                    position: 'relative',
                                }}
                                className="noActions"
                                ref={mapElement}
                            />
                        </Card>
                    </Box>

                    <Grid container alignItems="center" direction={'row'}>
                        <Grid item xs={9}>
                            <Box>
                                <TextField
                                    label="Indirizzo"
                                    value={address}
                                    onChange={(event) =>
                                        setAddress(event.target.value)
                                    }
                                    sx={{
                                        width: '100%',
                                        marginTop: '20px',
                                        '& .MuiInputBase-root': {
                                            height: 40,
                                        },
                                        '& label': {
                                            top: -6,
                                        },
                                    }}
                                />
                            </Box>
                            <Grid
                                container
                                alignItems="center"
                                spacing={1}
                                sx={{ marginTop: '10px' }}
                            >
                                <Grid item xs={6}>
                                    <TextField
                                        label="Latitudine"
                                        value={latitude}
                                        onChange={(event) =>
                                            setLatitude(event.target.value)
                                        }
                                        sx={{
                                            width: '100%',
                                            '& .MuiInputBase-root': {
                                                height: 40,
                                            },
                                            '& label': {
                                                top: -6,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Longitudine"
                                        value={longitude}
                                        onChange={(event) =>
                                            setLongitude(event.target.value)
                                        }
                                        sx={{
                                            width: '100%',
                                            '& .MuiInputBase-root': {
                                                height: 40,
                                            },
                                            '& label': {
                                                top: -6,
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Box marginTop={2}>
                                <TextField
                                    label="Area di monitoraggio selezionata"
                                    disabled={true}
                                    value={
                                        markerLatitude && markerLongitude
                                            ? fromGeoJSONtoMonitoring(
                                                  monitoring
                                              ).name
                                            : ''
                                    }
                                    sx={{
                                        width: '100%',
                                        '& .MuiInputBase-root': {
                                            height: 40,
                                        },
                                        '& label': {
                                            top: -6,
                                        },
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs
                            height={1}
                            alignItems={'start'}
                            paddingX={2}
                        >
                            <Button
                                fullWidth
                                autoFocus
                                variant="contained"
                                onClick={handleGeocodingButtonClick}
                                sx={{
                                    float: 'right',
                                    marginTop: '20px',
                                    height: '40px',
                                }}
                            >
                                Codifica indirizzo
                            </Button>
                            <Button
                                fullWidth
                                autoFocus
                                variant="contained"
                                onClick={handleReverseGeocodingButtonClick}
                                sx={{
                                    float: 'right',
                                    marginTop: '19px',
                                    height: '40px',
                                }}
                            >
                                Decodifica
                            </Button>
                        </Grid>
                    </Grid>
                </FlexBoxFullWHColumn>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handlePreviousButtonClick}>INDIETRO</Button>
                <Button onClick={handleNextButtonClick}>AVANTI</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogAddElementLocationPointMap;
