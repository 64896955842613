import { PayloadAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { authReducer } from './auth/auth.reducer';
import { AUTH_ACTION_TYPES } from './auth/auth.types';
import { dialogReducer } from './dialog/dialog.reducer';
import { errorReducer } from './error/error.reducer';
import { filtersReducer } from './filters/filters.reducer';
import { loadingReducer } from './loading/loading.reducer';
import { mapReducer } from './map/map.reducer';
import { monitoringReducer } from './monitoring/monitoring.reducer';
import { paramsReducer } from './params/params.reducer';
import { tenantReducer } from './tenant/tenant.reducer';

const appReducer = combineReducers({
    loading: loadingReducer,
    error: errorReducer,
    auth: authReducer,
    tenant: tenantReducer,
    params: paramsReducer,
    monitoring: monitoringReducer,
    map: mapReducer,
    filters: filtersReducer,
    dialog: dialogReducer,
});

export const rootReducer = (state: any, action: PayloadAction) => {
    if (action.type === AUTH_ACTION_TYPES.SIGN_OUT) {
        state = undefined;
    }


    return appReducer(state, action);
};
