import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const BoxFullW = styled(Box)(() => ({
    width: '100%',
}));

export const BoxFullH = styled(Box)(() => ({
    height: '100%',
}));

export const BoxFullWH = styled(Box)(() => ({
    width: '100%',
    height: '100%',
}));

export const FlexBoxFullW = styled(BoxFullW)(() => ({
    display: 'flex',
}));

export const FlexBoxFullWColumn = styled(FlexBoxFullW)(() => ({
    flexDirection: 'column',
}));

export const FlexBoxFullH = styled(BoxFullH)(() => ({
    display: 'flex',
}));

export const FlexBoxFullWRowBetween = styled(FlexBoxFullW)(() => ({
    justifyContent: 'space-between',
}));

export const FlexBoxFullHColumnBetween = styled(FlexBoxFullH)(() => ({
    justifyContent: 'space-between',
}));

export const FlexBoxFullWHRow = styled(BoxFullWH)(() => ({
    display: 'flex',
}));

export const FlexBoxFullWHColumn = styled(BoxFullWH)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

export const FlexBoxFullWHColumnBetween = styled(FlexBoxFullWHColumn)(() => ({
    justifyContent: 'space-between',
}));
