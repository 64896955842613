import { Box, Button, Grid, TextField } from '@mui/material';
import { Fragment } from 'react';
import { ManageMonitoringSimpleReportPayload } from '../../../../models/monitoring';
import { Attachment } from '../../../../models/shared';
import GBDialogActions from '../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../ui/gb-dialog-title/gb-dialog-title.component';
import GBGallery from '../../../ui/gb-gallery/gb-gallery.component';
import GBTextArea from '../../../ui/gb-text-area/gb-text-area.component';

const DialogManageNewSimpleReportConfirmStep = ({
    id,
    name,
    notes,
    images,
    onNext,
    onPrevious,
}: {
    id: number;
    name: string;
    notes: string;
    images: Attachment[];
    onNext: (properties: Partial<ManageMonitoringSimpleReportPayload>) => void;
    onPrevious: () => void;
}) => {
    const handlePrevButtonClick = () => {
        onPrevious();
    };

    const handleNextButtonClick = () => {
        onNext({});
    };

    return (
        <Fragment>
            <GBDialogTitle title="INSERISCI INFORMAZIONI" />
            <GBDialogContent>
                <Grid container>
                    <Grid item xs={6}>
                        <Box>
                            <TextField
                                label="Nome segnalazione"
                                value={name || ''}
                                disabled
                                sx={{
                                    minWidth: '90%',
                                    maxWidth: '90%',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                }}
                            />
                        </Box>
                        <Box>
                            <GBTextArea
                                label="Note aggiuntive"
                                multiline
                                rows={6}
                                value={notes || ''}
                                disabled
                                sx={{
                                    minWidth: '90%',
                                    maxWidth: '90%',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box
                            sx={{
                                marginTop: '20px',
                                marginX: '20px',
                                height: 0.99,
                            }}
                        >
                            {
                                <GBGallery
                                    entityId={id}
                                    entityClassName="MonitoringSimpleReport"
                                    images={images}
                                    onImagesAdded={() => {}}
                                    onImageDeleted={() => {}}
                                    readonly={true}
                                />
                            }
                        </Box>
                    </Grid>
                </Grid>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handlePrevButtonClick}>RIFIUTA</Button>
                <Button onClick={handleNextButtonClick}>AVANTI</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogManageNewSimpleReportConfirmStep;
