import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Navigation from './routes/navigation/navigation.component';

import { Fragment, lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './App.scss';
import GBLoadingSpinner from './components/ui/gb-loading-spinner/gb-loading-spinner.component';
import { LOGIN_PAGE_ROUTE } from './constants/routes.constants';
import NavigationLogin from './routes/navigation/navigation-login.component';
import { selectAccessToken } from './store/auth/auth.selector';
import { selectIsLoading } from './store/loading/loading.selector';

const LoadedSSOPage = lazy(() => import('../src/routes/sso/sso.component'));
const LoadedLoginPage = lazy(
    () => import('../src/routes/login/login.component')
);
const LoadedHomePage = lazy(() => import('../src/routes/home/home.component'));
const LoadedMonitoringPage = lazy(
    () => import('../src/routes/monitoring/monitoring.component')
);
const LoadedElementPage = lazy(
    () => import('../src/routes/element/element.component')
);
const LoadedReportPage = lazy(
    () => import('../src/routes/report/report.component')
);
const LoadedPhasesPage = lazy(
    () => import('../src/routes/phases/phases.component')
);

const LoadedPhasePage = lazy(
    () => import('../src/routes/phase/phase.component')
);

function App() {
    const accessToken = useSelector(selectAccessToken);
    const isLoading = useSelector(selectIsLoading);
    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        if (!accessToken && location.pathname !== '/sso') {
            navigate(LOGIN_PAGE_ROUTE);
        }
    }, [accessToken, navigate, location.pathname]);

    return (
        <Fragment>
            {isLoading && <GBLoadingSpinner />}
            <Suspense fallback={<GBLoadingSpinner />}>
                <Routes>
                    <Route
                        path="/"
                        element={
                            accessToken ? <Navigation /> : <NavigationLogin />
                        }
                    >
                        <Route index element={<LoadedHomePage />} />
                        <Route path="sso" element={<LoadedSSOPage />} />
                        <Route path="login" element={<LoadedLoginPage />} />
                        <Route
                            path="monitoring"
                            element={<LoadedMonitoringPage />}
                        />
                        <Route path="element" element={<LoadedElementPage />} />
                        <Route path="report" element={<LoadedReportPage />} />
                        <Route path="phases/" element={<LoadedPhasesPage />} />
                        <Route
                            path="phases/:phaseId/"
                            element={<LoadedPhasePage />}
                        />
                        <Route
                            path="phases/:phaseId/:matrixId/"
                            element={<LoadedPhasesPage />}
                        />
                        <Route
                            path="phases/:phaseId/:matrixId/:campaignId"
                            element={<LoadedPhasesPage />}
                        />
                    </Route>
                </Routes>
            </Suspense>
        </Fragment>
    );
}

export default App;
