import { Box, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { selectUserProfile } from '../../../store/auth/auth.selector';
import { ViewMode } from '../../../store/filters/filters.reducer';
import { selectViewMode } from '../../../store/filters/filters.selector';
import { selectMonitoring } from '../../../store/monitoring/monitoring.selector';
import { selectTenant } from '../../../store/tenant/tenant.selector';
import { themeColors } from '../../../theme/greenbeat-theme';
import { fromGeoJSONtoMonitoring } from '../../../utils/data/data.utils';
import GBGreenbeatBox from '../../ui/gb-greenbeat-box/gb-greenbeat-box.component';
import GBNotificationsBox from '../../ui/gb-notifications-box/gb-notifications-box.component';
import GBRequiredActionsBox from '../../ui/gb-required-actions-box/gb-required-actions-box.component';
import GBSyncBox from '../../ui/gb-sync-box/gb-sync-box.component';
import GbUserBox from '../../ui/gb-user-box/gb-user-box.component';
import { FlexBoxFullWHRow } from '../layout.styles';
import { ActionAppBar, ActionToolbar, AppBar, Toolbar } from './top-bar.styles';

const TopBar = ({ sidebarOpened }: { sidebarOpened: boolean }) => {
    const userProfile = useSelector(selectUserProfile);

    const tenant = useSelector(selectTenant);
    const monitoringGeoJSON = useSelector(selectMonitoring);
    const monitoring = monitoringGeoJSON
        ? fromGeoJSONtoMonitoring(monitoringGeoJSON)
        : null;

    const viewMode = useSelector(selectViewMode);

    return (
        <Fragment>
            <AppBar
                sx={{
                    zIndex: 900,
                }}
            >
                <Toolbar sidebarOpened={sidebarOpened}>
                    <Box width={1 / 2} height={1}>
                        <FlexBoxFullWHRow
                            height={1}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            {tenant?.additional_logo && (
                                <Box
                                    height={'100%'}
                                    width={100}
                                    display={'flex'}
                                    flexShrink={0}
                                    alignItems={'center'}
                                    mr={2}
                                >
                                    <img
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                        }}
                                        src={`${process.env.REACT_APP_BASE_URL}${tenant.additional_logo}`}
                                        alt={'Logo secondario cliente'}
                                    />
                                </Box>
                            )}
                            {monitoring && (
                                <Typography
                                    fontWeight={'bold'}
                                    textAlign={'center'}
                                >
                                    {monitoring.name}
                                </Typography>
                            )}
                        </FlexBoxFullWHRow>
                    </Box>
                </Toolbar>
            </AppBar>
            {userProfile && (
                <ActionAppBar
                    position="fixed"
                    sx={{
                        top: 80,
                        backgroundColor: themeColors.actionBarBg,
                        zIndex: 800,
                    }}
                >
                    <ActionToolbar>
                        <Box />
                        <Box display={'flex'} alignItems={'center'} height={1}>
                            <GBSyncBox />
                            <Box mx={2} />
                            <GBNotificationsBox />
                            <Box mx={2} />
                            {viewMode === ViewMode.SEGNALAZIONI_SMART && (
                                <Fragment>
                                    <GBRequiredActionsBox />
                                    <Box mx={2} />
                                </Fragment>
                            )}

                            <GbUserBox />
                            <Box mx={2} />
                            <GBGreenbeatBox />
                        </Box>
                    </ActionToolbar>
                </ActionAppBar>
            )}
        </Fragment>
    );
};

export default TopBar;
