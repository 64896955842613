import { createSelector } from 'reselect';

import { RootState } from '../store';
import { TenantState } from './tenant.reducer';

export const selectTenantReducer = (state: RootState): TenantState => state.tenant;

export const selectTenant = createSelector(
    selectTenantReducer,
    (tenant) => tenant.tenant
);



