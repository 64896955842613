import { all, call, put, select, takeLatest } from 'typed-redux-saga/macro';
import { setFilters } from '../filters/filters.action';
import { selectMonitoringCategories, selectMonitoringMatrices } from '../monitoring/monitoring.selector';
import { FILTERS_ACTION_TYPES } from './filters.types';

export function* resetFilters() {
    const categories = yield* select(selectMonitoringCategories);
    const matrices = yield* select(selectMonitoringMatrices);
    yield* put(setFilters({ monitoringCategoriesIds: categories.map(c => c.id) }));
    yield* put(setFilters({ monitoringMatricesIds: matrices.map(c => c.id) }));
    yield* put(setFilters({ monitoringCampaignsIds: [] }));
}

export function* onResetFilters() {
    yield* takeLatest(FILTERS_ACTION_TYPES.RESET_FILTERS, resetFilters);
}

export function* filtersSaga() {
    yield* all([
        call(onResetFilters),
    ]);
}
