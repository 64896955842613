import { all, call, put, takeLatest } from 'typed-redux-saga/macro';
import { MONITORING_CAMPAIGNS_URL } from '../../constants/api.constants';
import { apiRequestWrapper } from '../../utils/api/api.utils';
import { setIsError } from '../error/error.action';
import { setIsLoading } from '../loading/loading.action';
import { getCampaignFailed, GetCampaignStart, getCampaignSuccess } from './campaign.action';
import { CAMPAIGN_ACTION_TYPES } from './campaign.types';


export function* getCampaign({
    payload: { id, onComplete },
}: GetCampaignStart) {
    yield* put(setIsLoading(true));
    try {
        const campaign = yield* call(
            apiRequestWrapper, `${MONITORING_CAMPAIGNS_URL}${id}`, 'GET', null
        );

        if (campaign) {
            yield* put(getCampaignSuccess(campaign));
            onComplete(campaign);
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getCampaignFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onGetCampaignStart() {
    yield* takeLatest(CAMPAIGN_ACTION_TYPES.GET_CAMPAIGN_START, getCampaign);
}

export function* campaignSaga() {
    yield* all([
        call(onGetCampaignStart),
    ]);
}
