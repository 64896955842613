import { all, call, put, takeLatest } from 'typed-redux-saga/macro';
import { ASSIGN_PATH, CLOSE_PATH, MONITORING_REPORTS_URL, UPDATE_WITH_INTERVENTION_PATH } from '../../constants/api.constants';
import { apiRequestWrapper } from '../../utils/api/api.utils';
import { setIsError } from '../error/error.action';
import { setIsLoading } from '../loading/loading.action';
import { AddReportStart, AssignReportStart, CloseReportStart, GetReportStart, UpdateReportWithInterventionStart, addReportFailed, addReportSuccess, assignReportFailed, assignReportSuccess, closeReportFailed, closeReportSuccess, getReportFailed, getReportSuccess, updateReportWithInterventionFailed, updateReportWithInterventionSuccess } from './report.action';
import { REPORT_ACTION_TYPES } from './report.types';


export function* getReport({
    payload: { id, onComplete },
}: GetReportStart) {
    yield* put(setIsLoading(true));
    try {
        const report = yield* call(
            apiRequestWrapper, `${MONITORING_REPORTS_URL}${id}`, 'GET', null
        );

        if (report) {
            yield* put(getReportSuccess(report));
            onComplete(report);
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getReportFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* addReport({
    payload: { report, onComplete },
}: AddReportStart) {
    yield* put(setIsLoading(true));
    try {
        yield* call(
            apiRequestWrapper, MONITORING_REPORTS_URL, 'POST', report, true
        );
        yield* put(addReportSuccess());
        onComplete();
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(addReportFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* assignReport({
    payload: { report, onComplete },
}: AssignReportStart) {
    yield* put(setIsLoading(true));
    try {
        yield* call(
            apiRequestWrapper, `${MONITORING_REPORTS_URL}${report.report_id}/${ASSIGN_PATH}`, 'PUT', report, true
        );
        yield* put(assignReportSuccess());
        onComplete();
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(assignReportFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* updateReportWithIntervention({
    payload: { report, report_id, onComplete },
}: UpdateReportWithInterventionStart) {
    yield* put(setIsLoading(true));
    try {
        yield* call(
            apiRequestWrapper, `${MONITORING_REPORTS_URL}${report_id}/${UPDATE_WITH_INTERVENTION_PATH}`, 'PUT', report, true
        );
        yield* put(updateReportWithInterventionSuccess());
        onComplete();
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(updateReportWithInterventionFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* closeReport({
    payload: { report, onComplete },
}: CloseReportStart) {
    yield* put(setIsLoading(true));
    try {
        yield* call(
            apiRequestWrapper, `${MONITORING_REPORTS_URL}${report.report_id}/${CLOSE_PATH}`, 'PUT', report, true
        );
        yield* put(closeReportSuccess());
        onComplete();
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(closeReportFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onGetReportStart() {
    yield* takeLatest(REPORT_ACTION_TYPES.GET_REPORT_START, getReport);
}

export function* onAddReportStart() {
    yield* takeLatest(REPORT_ACTION_TYPES.ADD_REPORT_START, addReport);
}

export function* onUpdateReportWithInterventionStart() {
    yield* takeLatest(REPORT_ACTION_TYPES.UPDATE_REPORT_WITH_INTERVENTION_START, updateReportWithIntervention);
}

export function* onAssignReportStart() {
    yield* takeLatest(REPORT_ACTION_TYPES.ASSIGN_REPORT_START, assignReport);
}

export function* onCloseReportStart() {
    yield* takeLatest(REPORT_ACTION_TYPES.CLOSE_REPORT_START, closeReport);
}

export function* reportSaga() {
    yield* all([
        call(onGetReportStart),
        call(onAddReportStart),
        call(onUpdateReportWithInterventionStart),
        call(onAssignReportStart),
        call(onCloseReportStart),
    ]);
}
