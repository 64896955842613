import axios, { AxiosResponse } from 'axios';
import { call, put, select } from 'typed-redux-saga/macro';
import { signOut } from '../../store/auth/auth.action';
import { selectAccessToken } from '../../store/auth/auth.selector';

export const axiosApiCall = async <T>(url: string, method: string, data: any) => {
    try {
        const response = await axios({ url, method, data });
        return response.data as T;
    } catch (error) {
        throw error;
    }
};

export function* apiRequestWrapper(url: string, method: string, data: any, formData: boolean = false) {

    const accessToken = yield* select(selectAccessToken);

    try {
        const response = yield* call(axios, {
            url, method, data, headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': formData ? 'multipart/form-data' : 'application/json',
            },
        });

        return (response as AxiosResponse).data;

    } catch (e: any) {

        const { response } = e;
        const status = response?.status;

        const isExpiredTokenError = status === 401;

        const isConnectionError = e.code === 'ERR_NETWORK';

        if (isExpiredTokenError || isConnectionError) {
            yield* put(signOut());
            throw e;
        }

        throw e;
    }
}