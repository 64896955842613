import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useDispatch, useSelector } from 'react-redux';
import { setIsError } from '../../store/error/error.action';
import {
    selectErrorMessage,
    selectIsError,
} from '../../store/error/error.selector';
import GbSnackbar from '../ui/gb-snackbar/gb-snackbar.component';
import ContentWrapperLogin from './content-wrapper/content-wrapper-login.component';
import { FlexBoxFullWHRow } from './layout.styles';
import TopBarLogin from './top-bar/top-bar-login.component';

export default function LayoutLogin() {
    const dispatch = useDispatch();

    const error = useSelector(selectIsError);
    const errorMessage = useSelector(selectErrorMessage);

    const handleCloseSnackbar = () => {
        dispatch(setIsError(false, null));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <FlexBoxFullWHRow position={'relative'} overflow={'hidden'}>
                {error && (
                    <GbSnackbar
                        open={true}
                        msg={errorMessage}
                        onClose={handleCloseSnackbar}
                    />
                )}
                <TopBarLogin />
                <ContentWrapperLogin />
            </FlexBoxFullWHRow>
        </LocalizationProvider>
    );
}
