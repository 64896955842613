import {
    Avatar,
    Button,
    List,
    ListItemAvatar,
    ListItemButton,
    Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SIZE_ICONS } from '../../../../../constants/theme.constants';
import { ElementPayload } from '../../../../../models/monitoring';
import { setIsError } from '../../../../../store/error/error.action';
import { selectMonitoringCategories } from '../../../../../store/monitoring/monitoring.selector';
import GBDialogActions from '../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../ui/gb-dialog-title/gb-dialog-title.component';
import GBSvgImage from '../../../../ui/gb-svg-image/gb-svg-image.component';

const DialogAddElementCategoryStep = ({
    categoryId,
    onNext,
}: {
    categoryId: number | undefined;
    onNext: (properties: Partial<ElementPayload>) => void;
}) => {
    const dispatch = useDispatch();

    const categories = useSelector(selectMonitoringCategories);

    const [categorySelectedId, setCategorySelectedId] = useState<
        number | undefined
    >(categoryId);

    const handleCategoryClickButton = (id: number) => {
        setCategorySelectedId(id);
    };

    const handleNextButtonClick = () => {
        if (!categorySelectedId) {
            dispatch(setIsError(true, 'Selezionare una categoria'));
            return;
        }
        onNext({ categoryId: categorySelectedId });
    };

    return (
        <Fragment>
            <GBDialogTitle title="SELEZIONA CATEGORIA" />
            <GBDialogContent>
                <List>
                    {categories.map((o: any) => (
                        <ListItemButton
                            key={o.id}
                            selected={categorySelectedId === o.id}
                            onClick={() => handleCategoryClickButton(o.id)}
                        >
                            <ListItemAvatar
                                sx={{
                                    marginRight: 0.5,
                                }}
                            >
                                <Avatar
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.colors.primary.main,
                                    }}
                                >
                                    <GBSvgImage
                                        height={SIZE_ICONS.services.wizard.h}
                                        width={SIZE_ICONS.services.wizard.w}
                                        svgString={o.icon}
                                        alt={'Icon of ' + o.name}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <Typography>{o.name}</Typography>
                        </ListItemButton>
                    ))}
                </List>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handleNextButtonClick}>AVANTI</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogAddElementCategoryStep;
