import { styled } from '@mui/material/styles';
import { Box, Switch, SwitchProps } from '@mui/material';
import { themeColors } from '../../theme/greenbeat-theme';
import { ReactComponent as ArrowTab } from '../../assets/arrow-tab.svg';
import vireoLogo from '../../assets/vireo-logo.png';
import segnalazioniSmartLogo from '../../assets/segnalazioni-smart-logo.png';

export const CustomSwitch = styled((props: SwitchProps) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 191,
    height: 61,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 4,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(93px)',
            color: '#fff',
            '& .MuiSwitch-thumb': {
                backgroundImage: `url(${segnalazioniSmartLogo})`,
                backgroundSize: '80%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            },
            '& + .MuiSwitch-track': {
                backgroundColor: themeColors.bodyBg,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundImage: `url(${vireoLogo})`,
        backgroundSize: '80%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        boxSizing: 'border-box',
        borderRadius: 100,
        width: 90,
        height: 53,
        boxShadow: '0px 3px 3px rgba(159, 162, 191, .18)',
    },
    '& .MuiSwitch-track': {
        borderRadius: 100,
        backgroundColor: themeColors.bodyBg,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '40%',
            height: '100%',
        },
        '&:before': {
            backgroundImage: `url(${vireoLogo})`,
            backgroundSize: '100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            color: theme.colors.alpha.white[100],
            left: 12,
            fontWeight: 'bold',
            fontSize: '0.6rem',
        },
        '&:after': {
            backgroundImage: `url(${segnalazioniSmartLogo})`,
            backgroundSize: '100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            right: 12,
            fontWeight: 'bold',
            fontSize: '0.6rem',
        },
    },
}));

export const BoxHorizontalCollapse = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'expanded',
})<{ expanded: boolean }>(({ theme, expanded }) => ({
    display: 'flex',
    alignItems: 'center',
    width: expanded ? '247px' : '54px',
    height: '77px',
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
    padding: theme.spacing(1),
    boxShadow:
        '0px 9px 16px rgba(159, 162, 191, .18), 0px 2px 2px rgba(159, 162, 191, 0.32)',
    position: 'absolute',
    right: -7,
    top: 0,
    zIndex: 999,
}));

export const ArrowTabPrimary = styled(ArrowTab, {
    shouldForwardProp: (prop) => prop !== 'expanded',
})<{ expanded: boolean }>(({ theme, expanded }) => ({
    transform: !expanded ? '' : 'rotate(180deg)',
    cursor: 'pointer',
    margin: '0 10px',
    height: '100%',
    '& path': {
        fill: theme.palette.primary.main,
    },
}));
