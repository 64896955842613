import ChevronRight from '@mui/icons-material/ChevronRight';
import { Avatar, Box } from '@mui/material';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { BoxFullWH, FlexBoxFullWHRow } from '../../layout/layout.styles';
import {
    StyledContainer,
    StyledTypography,
} from './gb-border-title-box.styles';

const GBBorderTitleBox = ({
    children,
    title,
    detailPath,
    action,
}: {
    children: ReactNode;
    title: string;
    detailPath?: string;
    action?: ReactNode;
}) => {
    return (
        <BoxFullWH sx={{ paddingTop: '30px!important', position: 'relative' }}>
            <Box position={'absolute'} right={11} top={10}>
                {action}
            </Box>
            <StyledContainer>
                <StyledTypography paddingX={2} variant="h4" gutterBottom>
                    {title}
                    {detailPath && (
                        <Avatar
                            style={{
                                backgroundColor: '#032C6E',
                                marginLeft: '10px',
                                width: '25px',
                                height: '25px',
                            }}
                        >
                            <NavLink to={detailPath}>
                                <ChevronRight style={{ color: 'white' }} />
                            </NavLink>
                        </Avatar>
                    )}
                </StyledTypography>
                <FlexBoxFullWHRow
                    sx={{ padding: '20px' }}
                    alignItems={'center'}
                >
                    {children}
                </FlexBoxFullWHRow>
            </StyledContainer>
        </BoxFullWH>
    );
};

export default GBBorderTitleBox;
