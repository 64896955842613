import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MonitoringGeoElement } from '../../models/monitoring';
import { selectFiltersReducer } from '../../store/filters/filters.selector';
import {
    selectMonitoringCampaigns,
    selectMonitoringPaths,
    selectMonitoringPoints,
    selectMonitoringZones,
} from '../../store/monitoring/monitoring.selector';
import {
    getElementClassName,
    mergeElements,
} from '../../utils/data/data.utils';
import { filterElements } from '../../utils/filters/filters.utils';
import ElementListItem from './element-list-item/element-list-item.component';

const ElementsList = () => {
    const monitoringPoints = useSelector(selectMonitoringPoints);
    const monitoringZones = useSelector(selectMonitoringZones);
    const monitoringPaths = useSelector(selectMonitoringPaths);
    const monitoringCampaigns = useSelector(selectMonitoringCampaigns);
    const filters = useSelector(selectFiltersReducer);

    const [monitoringElements, setMonitoringElements] = useState<
        MonitoringGeoElement[]
    >([]);

    const [monitoringElementsFiltered, setMonitoringElementsFiltered] =
        useState<MonitoringGeoElement[]>([]);

    useEffect(() => {
        if (monitoringPoints && monitoringZones && monitoringPaths) {
            setMonitoringElements(
                mergeElements(
                    monitoringPoints,
                    monitoringZones,
                    monitoringPaths
                )
            );
            setMonitoringElementsFiltered(
                mergeElements(
                    monitoringPoints,
                    monitoringZones,
                    monitoringPaths
                )
            );
        }
    }, [monitoringPoints, monitoringZones, monitoringPaths]);

    useEffect(() => {
        setMonitoringElementsFiltered(
            filterElements(monitoringElements, filters, monitoringCampaigns)
        );
    }, [filters, monitoringElements, monitoringCampaigns]);

    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                minHeight: 0,
                border: (theme) => `1px solid ${theme.colors.alpha.black[10]}`,
            }}
        >
            <List
                disablePadding
                sx={{
                    flexGrow: 1,
                    overflow: 'auto',
                    minHeight: '100%',
                }}
            >
                {monitoringElementsFiltered &&
                    monitoringElementsFiltered.map((e, index) => (
                        <ElementListItem
                            key={index}
                            item={e}
                            className={getElementClassName(e)}
                        />
                    ))}
            </List>
        </Box>
    );
};

export default ElementsList;
