import { AttachFile, Close } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ReportPayload } from '../../../../../models/monitoring';
import { setIsError } from '../../../../../store/error/error.action';
import {
    checkExtensionFile,
    getMegaBytesFromBytes,
    getRemainFilesSizeInAdd,
    getRemainFilesSizeInRemove,
} from '../../../../../utils/file/file.utils';
import { BoxFullW, FlexBoxFullWColumn } from '../../../../layout/layout.styles';
import GBDialogActions from '../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../ui/gb-dialog-title/gb-dialog-title.component';
import { DeleteImageButton } from '../../../dialog-add-attachments/dialog-add-attachments.styles';

const DialogAddReportAttachmentsStep = ({
    attachments,
    remainFilesSize,
    onPrevious,
    onNext,
}: {
    attachments: any[] | undefined;
    remainFilesSize: number;
    onPrevious: () => void;
    onNext: (
        properties: Partial<ReportPayload>,
        remainFilesSize: number
    ) => void;
}) => {
    const dispatch = useDispatch();

    const [attachmentsToAdd, setAttachmentsToAdd] = useState<any[]>(
        attachments ?? []
    );
    const [nextAttachmentId, setNextAttachmentId] = useState<number>(0);
    const [remainFilesSizeLocal, setRemainFilesSizeLocal] =
        useState<number>(remainFilesSize);

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    const handleNextButtonClick = () => {
        onNext({ attachments: attachmentsToAdd }, remainFilesSizeLocal);
    };

    const appendAttachment = (files: any) => {
        let index = nextAttachmentId;
        let attachmentsToAddTmp = [];
        for (let f of files) {
            // check file type
            if (!checkExtensionFile(f.name)) {
                dispatch(setIsError(true, 'Tipo file non ammesso: ' + f.name));
                return;
            }
            attachmentsToAddTmp.push({
                id: uuidv4(),
                file: f,
                imageUrl: URL.createObjectURL(f),
            });
            index++;
        }
        const remainFilesSizeTmp = getRemainFilesSizeInAdd(
            attachmentsToAddTmp.map((i) => i.file),
            remainFilesSizeLocal
        );
        if (remainFilesSizeTmp < 0) {
            dispatch(setIsError(true, 'Dimensione massima dei file raggiunta'));
            return;
        }
        setRemainFilesSizeLocal(remainFilesSizeTmp);
        setNextAttachmentId(index);
        setAttachmentsToAdd([...attachmentsToAdd, ...attachmentsToAddTmp]);
    };

    const handleFileChange = (event: any) => {
        appendAttachment(event.target.files);
        event.target.value = '';
    };

    return (
        <Fragment>
            <GBDialogTitle title="ALLEGA DOCUMENTAZIONE" />
            <GBDialogContent>
                <Typography textAlign={'center'} mb={2}>
                    Estensioni ammesse: .pdf, .docx, .txt
                    <br />
                    <br />
                    Dimensione residue:{' '}
                    {getMegaBytesFromBytes(remainFilesSizeLocal).toFixed(2)} MB
                </Typography>
                <Grid
                    container
                    flexDirection={'column'}
                    rowSpacing={1}
                    alignItems="center"
                >
                    <Grid item display="flex" justifyContent="center">
                        <FlexBoxFullWColumn>
                            <input
                                type="file"
                                //accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="file-upload"
                                multiple
                            />
                            <label htmlFor="file-upload">
                                <Button
                                    variant="contained"
                                    startIcon={<AttachFile />}
                                    component="span"
                                    sx={{
                                        height: '53px',
                                    }}
                                >
                                    Seleziona i file
                                </Button>
                            </label>
                        </FlexBoxFullWColumn>
                    </Grid>
                </Grid>
                <Box my={3} />
                {attachmentsToAdd.length > 0 && (
                    <Box>
                        {'Files caricati (' + attachmentsToAdd.length + '):'}
                    </Box>
                )}
                <BoxFullW>
                    {attachmentsToAdd.map((o: any, index: any) => (
                        <Box key={index} my={1}>
                            <DeleteImageButton
                                color="primary"
                                onClick={() => {
                                    setAttachmentsToAdd(
                                        attachmentsToAdd.filter(
                                            (p: any) => p.id !== o.id
                                        )
                                    );
                                    setRemainFilesSizeLocal(
                                        getRemainFilesSizeInRemove(
                                            o.file,
                                            remainFilesSizeLocal
                                        )
                                    );
                                }}
                                sx={{
                                    marginRight: (theme) => theme.spacing(1),
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <Close />
                            </DeleteImageButton>
                            {o.file.name}
                        </Box>
                    ))}
                </BoxFullW>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handlePreviousButtonClick}>INDIETRO</Button>
                <Button onClick={handleNextButtonClick}>AVANTI</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogAddReportAttachmentsStep;
