import { PARAMS_KEYS } from '../../constants/params.constants';
import { Param } from '../../store/params/params.reducer';

export function getParamValue(params: Param[] | null, module: string, key: string) {

    if (!params || !Object.keys(PARAMS_KEYS).includes(key)) {
        return null;
    }

    let obj = params.find(
        (s) => (s.module === module && s.key === key)
    );

    if (obj) {
        return obj.value;
    }
    return null;
}