export enum SIMPLE_REPORT_ACTION_TYPES {
    ADD_SIMPLE_REPORT_START = 'simpleReport/ADD_SIMPLE_REPORT_START',
    ADD_SIMPLE_REPORT_SUCCESS = 'simpleReport/ADD_SIMPLE_REPORT_SUCCESS',
    ADD_SIMPLE_REPORT_FAILED = 'simpleReport/ADD_SIMPLE_REPORT_FAILED',
    REFUSE_SIMPLE_REPORT_START = 'simpleReport/REFUSE_SIMPLE_REPORT_START',
    REFUSE_SIMPLE_REPORT_SUCCESS = 'simpleReport/REFUSE_SIMPLE_REPORT_SUCCESS',
    REFUSE_SIMPLE_REPORT_FAILED = 'simpleReport/REFUSE_SIMPLE_REPORT_FAILED',
    CONFIRM_SIMPLE_REPORT_START = 'simpleReport/CONFIRM_SIMPLE_REPORT_START',
    CONFIRM_SIMPLE_REPORT_SUCCESS = 'simpleReport/CONFIRM_SIMPLE_REPORT_SUCCESS',
    CONFIRM_SIMPLE_REPORT_FAILED = 'simpleReport/CONFIRM_SIMPLE_REPORT_FAILED',
}
