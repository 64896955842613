import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    MyAvailableElementService,
    ReportPayload,
} from '../../../models/monitoring';
import { closeDialog } from '../../../store/dialog/dialog.action';
import { setIsError } from '../../../store/error/error.action';
import { updateReportWithInterventionStart } from '../../../store/report/report.action';
import DialogUpdateReportWithInterventionAttachmentsStep from './steps/dialog-update-report-with-intervention-attachments-step/dialog-update-report-with-intervention-attachments-step.component';
import DialogUpdateReportWithInterventionImagesStep from './steps/dialog-update-report-with-intervention-images-step/dialog-update-report-with-intervention-images-step.component';
import DialogUpdateReportWithInterventionInfoStep from './steps/dialog-update-report-with-intervention-info-step/dialog-update-report-with-intervention-info-step.component';
import DialogUpdateReportWithInterventionRecapStep from './steps/dialog-update-report-with-intervention-recap-step/dialog-update-report-with-intervention-recap-step.component';
import DialogUpdateReportWithInterventionReportsStep from './steps/dialog-update-report-with-intervention-reports-step/dialog-update-report-with-intervention-reports-step';
import DialogUpdateReportWithInterventionServiceStep from './steps/dialog-update-report-with-intervention-service-step/dialog-update-report-with-intervention-service-step';
import DialogUpdateReportWithInterventionSeverityStep from './steps/dialog-update-report-with-intervention-severity-step/dialog-update-report-with-intervention-severity-step.component';

const DialogUpdateReportWithIntervention = ({
    inputData,
    onSaved,
}: {
    inputData: any;
    onSaved: () => void;
}) => {
    const [step, setStep] = useState<number>(inputData.reportSelected ? 2 : 0);
    const [reportToUpdate, setReportToUpdate] = useState<
        Partial<ReportPayload>
    >({
        report_selected: inputData.reportSelected,
    });
    const dispatch = useDispatch();

    const handleNextStep = (
        properties: Partial<ReportPayload>,
        save = false
    ) => {
        setReportToUpdate((prevState) => {
            return {
                ...prevState,
                ...properties,
            };
        });
        if (!save) {
            setStep(step + 1);
        }
        if (save) {
            handleSave({
                ...reportToUpdate,
                ...properties,
            });
        }
    };

    const handlePreviousStep = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const handleSave = async (report: Partial<ReportPayload>) => {
        if (!report || !report.report_selected?.report_id) {
            dispatch(setIsError(true, 'Dati insufficienti per il salvataggio'));
            return;
        }
        dispatch(
            updateReportWithInterventionStart(
                report,
                report.report_selected?.report_id,
                () => {
                    onSaved();
                    dispatch(closeDialog());
                }
            )
        );
    };

    return (
        <Fragment>
            {step === 0 && (
                <DialogUpdateReportWithInterventionServiceStep
                    elementServices={inputData.elementServices}
                    myAvailableServices={inputData.myAvailableServices}
                    elementServiceSelected={reportToUpdate.element_service}
                    onNext={handleNextStep}
                />
            )}
            {step === 1 && reportToUpdate.element_service && (
                <DialogUpdateReportWithInterventionReportsStep
                    reports={
                        inputData.myAvailableServices.find(
                            (s: MyAvailableElementService) =>
                                s.element_service_id ===
                                reportToUpdate.element_service
                        ).reports
                    }
                    reportSelected={reportToUpdate.report_selected}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 2 && reportToUpdate.report_selected && (
                <DialogUpdateReportWithInterventionRecapStep
                    report={reportToUpdate.report_selected}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                    disablePrev={inputData.reportSelected}
                />
            )}
            {step === 3 && reportToUpdate.report_selected && (
                <DialogUpdateReportWithInterventionInfoStep
                    report={reportToUpdate.report_selected}
                    description={reportToUpdate.description}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 4 && (
                <DialogUpdateReportWithInterventionImagesStep
                    images={reportToUpdate.images}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 5 && (
                <DialogUpdateReportWithInterventionAttachmentsStep
                    attachments={reportToUpdate.attachments}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 6 && (
                <DialogUpdateReportWithInterventionSeverityStep
                    onPrevious={handlePreviousStep}
                    onNext={(properties) => handleNextStep(properties, true)}
                />
            )}
        </Fragment>
    );
};

export default DialogUpdateReportWithIntervention;
