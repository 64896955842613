import Typography from '@mui/material/Typography';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { selectMonitoringServices } from '../../../../store/monitoring/monitoring.selector';
import { FiltersState } from '../../../../store/filters/filters.reducer';
import GBMultiSelect from '../../../ui/gb-multi-select/gb-multi-select.component';

const FilterService = ({
    filtersToApply,
    onChange,
}: {
    filtersToApply: FiltersState;
    onChange: (filtersChanged: Partial<FiltersState>) => void;
}) => {
    const monitoringServices = useSelector(selectMonitoringServices);

    return (
        <Fragment>
            <Typography gutterBottom={true} marginBottom={2}>
                Gli elementi verranno filtrati prendendo solo quelli che
                contengono tutti i servizi selezionati di seguito.
            </Typography>
            <GBMultiSelect
                options={monitoringServices.map((service) => {
                    return {
                        id: service.id,
                        label: service.name,
                    };
                })}
                defaultValues={monitoringServices
                    .filter((service) =>
                        filtersToApply.monitoringServicesIds.includes(
                            service.id
                        )
                    )
                    .map((s) => s.name)}
                title={null}
                label="Seleziona servizi"
                onSelect={(values) => {
                    onChange({
                        monitoringServicesIds: values.map((v) => v.id),
                    });
                }}
            />
        </Fragment>
    );
};

export default FilterService;
