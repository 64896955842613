import DialogContent from '@mui/material/DialogContent';
import { ReactNode } from 'react';

const GBDialogContent = ({
    children,
    height,
}: {
    children: ReactNode;
    height?: string;
}) => {
    const dialogContentHeight = height ?? '50vh';

    return (
        <DialogContent
            dividers
            sx={{ height: dialogContentHeight, overflow: 'auto' }}
        >
            {children}
        </DialogContent>
    );
};

export default GBDialogContent;
