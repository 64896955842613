import { FeatureLike } from 'ol/Feature';
import { PATHS_LAYER_CLASS, POINTS_LAYER_CLASS, ZONES_LAYER_CLASS } from '../../constants/map.constants';
import { MonitoringCampaign, MonitoringElement, MonitoringGeoElement } from '../../models/monitoring';
import { FiltersState, ViewMode } from '../../store/filters/filters.reducer';
import { getElementClassName } from '../data/data.utils';

export function applyViewModeFilterToElement(element: MonitoringElement, viewMode: ViewMode) {
    return (viewMode === ViewMode.VIREO && element.matrix?.id !== undefined) ||
        (viewMode === ViewMode.SEGNALAZIONI_SMART && element.category?.id !== undefined);
}

function applySearchStringFilterToElement(element: MonitoringElement, searchString: string) {
    return element.name.toLowerCase().includes(searchString.toLowerCase());
}

function applyTypeFilterToElement(filters: FiltersState, className: string) {
    if (className === POINTS_LAYER_CLASS) return filters.showMonitoringPoints;
    if (className === ZONES_LAYER_CLASS) return filters.showMonitoringZones;
    if (className === PATHS_LAYER_CLASS) return filters.showMonitoringPaths;
    return false;
}

function applyCategoryFilterToElement(element: MonitoringElement, categoriesIds: number[]) {
    return element.category !== null && categoriesIds.includes(element.category.id);
}

function applyMatrixFilterToElement(element: MonitoringElement, matricesIds: number[]) {
    return element.matrix !== null && matricesIds.includes(element.matrix.id);
}

function applyCampaignFilterToElement(element: MonitoringElement, className: string, campaignsIds: number[], campaigns: MonitoringCampaign[]) {
    if (campaignsIds.length === 0) return true;
    if (className === POINTS_LAYER_CLASS) {
        return campaigns.filter(c => campaignsIds.includes(c.id)).flatMap(c => c.points).includes(element.id);
    }
    if (className === ZONES_LAYER_CLASS) {
        return campaigns.filter(c => campaignsIds.includes(c.id)).flatMap(c => c.zones).includes(element.id);
    }
    if (className === PATHS_LAYER_CLASS) {
        return campaigns.filter(c => campaignsIds.includes(c.id)).flatMap(c => c.paths).includes(element.id);
    }
    return false;
}

function applyServiceFilterToElement(element: MonitoringElement, servicesIds: number[]) {
    return servicesIds.length === 0 || servicesIds.every(s => element.services.map(service => service.id).includes(s));
}

function applySeverityFilterToElement(element: MonitoringElement, severityId: number | null) {
    return severityId === -2 ||
        (severityId === null && element.severity === null) ||
        (severityId !== null && severityId >= 0 && element.severity?.id === severityId);
}

function filterElementsBySearchString(elements: MonitoringGeoElement[], searchString: string) {
    return elements.filter((e) =>
        applySearchStringFilterToElement(e, searchString)
    );
}

export function isFeatureVisible(feature: FeatureLike, filters: FiltersState, className: string, campaigns?: MonitoringCampaign[]) {
    const element = feature.getProperties() as MonitoringElement;
    element.id = feature.getId() as number;
    let risultato = applyViewModeFilterToElement(element, filters.viewMode) &&
        applySearchStringFilterToElement(element, filters.search) &&
        applyTypeFilterToElement(filters, className);
    if (filters.viewMode === ViewMode.VIREO) risultato &&= applyMatrixFilterToElement(element, filters.monitoringMatricesIds) && applyCampaignFilterToElement(element, className, filters.monitoringCampaignsIds, campaigns ?? []);
    else if (filters.viewMode === ViewMode.SEGNALAZIONI_SMART) risultato &&= applyCategoryFilterToElement(element, filters.monitoringCategoriesIds) &&
        applyServiceFilterToElement(element, filters.monitoringServicesIds) &&
        applySeverityFilterToElement(element, filters.severityId);
    return risultato;

}

export function filterElements(elements: MonitoringGeoElement[], filters: FiltersState, campaigns: MonitoringCampaign[]) {
    return filterElementsBySearchString(elements, filters.search).filter(e => {
        const className = getElementClassName(e);
        let risultato = applyViewModeFilterToElement(e, filters.viewMode) &&
            applySearchStringFilterToElement(e, filters.search) &&
            applyTypeFilterToElement(filters, className);
        if (filters.viewMode === ViewMode.VIREO) risultato &&= applyMatrixFilterToElement(e, filters.monitoringMatricesIds) && applyCampaignFilterToElement(e, className, filters.monitoringCampaignsIds, campaigns);
        else if (filters.viewMode === ViewMode.SEGNALAZIONI_SMART) risultato &&= applyCategoryFilterToElement(e, filters.monitoringCategoriesIds) &&
            applyServiceFilterToElement(e, filters.monitoringServicesIds) &&
            applySeverityFilterToElement(e, filters.severityId);
        return risultato;
    });
}
