import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ElementPayload } from '../../../models/monitoring';
import { closeDialog } from '../../../store/dialog/dialog.action';
import { saveNewElementStart } from '../../../store/element/element.action';
import { setIsError } from '../../../store/error/error.action';
import { ViewMode } from '../../../store/filters/filters.reducer';
import { selectViewMode } from '../../../store/filters/filters.selector';
import { createElementRoute } from '../../../utils/routes/routes.utils';
import DialogAddElementAttachmentsStep from './steps/dialog-add-element-attachments-step/dialog-add-element-attachments-step.component';
import DialogAddElementCategoryStep from './steps/dialog-add-element-category-step/dialog-add-element-category-step.component';
import DialogAddElementImagesStep from './steps/dialog-add-element-images-step/dialog-add-element-images-step.component';
import DialogAddElementInfoStep from './steps/dialog-add-element-info-segnalazioni-step/dialog-add-element-info-segnalazioni-step.component';
import DialogAddElementInfoVireoStep from './steps/dialog-add-element-info-vireo-step/dialog-add-element-info-vireo-step.component';
import DialogAddElementLocationStep from './steps/dialog-add-element-location-step/dialogs-add-element-location-step.component';
import DialogAddElementMatrixStep from './steps/dialog-add-element-matrix-step/dialog-add-element-matrix-step.component';
import DialogAddElementServicesStep from './steps/dialog-add-element-services-step/dialog-add-element-services-step.component';

const DialogAddElement = ({ inputData }: { inputData: any }) => {
    const [step, setStep] = useState<number>(0);
    const [elementToAdd, setElementToAdd] = useState<Partial<ElementPayload>>();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const viewMode = useSelector(selectViewMode);

    const handleNextStep = (
        properties: Partial<ElementPayload>,
        save = false
    ) => {
        setElementToAdd((prevState) => {
            return {
                ...prevState,
                ...properties,
            };
        });
        if (!save) {
            setStep(step + 1);
        }
        if (save) {
            handleSave({
                ...elementToAdd,
                ...properties,
            });
        }
    };

    const handlePreviousStep = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const handleSave = async (element: Partial<ElementPayload>) => {
        if (!element) {
            dispatch(setIsError(true, 'Dati insufficienti per il salvataggio'));
            return;
        }
        element.class_name = inputData.className;
        await new Promise(() => {
            dispatch(
                saveNewElementStart(element, (id: any) => {
                    dispatch(closeDialog());
                    navigate(createElementRoute(inputData.className, id));
                })
            );
        });
    };

    return (
        <Fragment>
            {step === 0 && viewMode === ViewMode.VIREO && (
                <DialogAddElementMatrixStep
                    matrixId={elementToAdd?.matrixId}
                    onNext={handleNextStep}
                />
            )}
            {step === 0 && viewMode === ViewMode.SEGNALAZIONI_SMART && (
                <DialogAddElementCategoryStep
                    categoryId={elementToAdd?.categoryId}
                    onNext={handleNextStep}
                />
            )}
            {step === 1 && elementToAdd?.matrixId && (
                <DialogAddElementInfoVireoStep
                    name={elementToAdd.name}
                    description={elementToAdd.description}
                    className={inputData.className}
                    matrixId={elementToAdd.matrixId}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 1 && elementToAdd?.categoryId && (
                <DialogAddElementInfoStep
                    name={elementToAdd.name}
                    description={elementToAdd.description}
                    className={inputData.className}
                    categoryId={elementToAdd.categoryId}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 2 && (
                <DialogAddElementLocationStep
                    pointAddress={elementToAdd?.pointAddress}
                    pointLatitude={elementToAdd?.pointLat}
                    pointLongitude={elementToAdd?.pointLng}
                    zoneCoords={elementToAdd?.zoneCoords}
                    pathCoords={elementToAdd?.pathCoords}
                    className={inputData.className}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 3 && (
                <DialogAddElementImagesStep
                    images={elementToAdd?.images}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 4 && (
                <DialogAddElementAttachmentsStep
                    attachments={elementToAdd?.attachments}
                    onPrevious={handlePreviousStep}
                    onNext={(properties) =>
                        handleNextStep(properties, viewMode === ViewMode.VIREO)
                    }
                />
            )}
            {step === 5 && (
                <DialogAddElementServicesStep
                    onPrevious={handlePreviousStep}
                    onNext={(properties) => handleNextStep(properties, true)}
                />
            )}
        </Fragment>
    );
};

export default DialogAddElement;
