import { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import { ElementPayload, MonitoringElement, MonitoringReport, MyAvailableElementService, UpdateElementServicePayload } from '../../models/monitoring';
import { Action, ActionWithPayload, createAction, withMatcher } from '../../utils/reducer/reducer.utils';
import { ELEMENT_ACTION_TYPES } from './element.types';


export type GetElementStart = ActionWithPayload<
    ELEMENT_ACTION_TYPES.GET_ELEMENT_START, {
        id: string;
        onComplete: (element: MonitoringElement) => void;
    }
>;

export type GetElementSuccess = ActionWithPayload<
    ELEMENT_ACTION_TYPES.GET_ELEMENT_SUCCESS,
    { element: MonitoringElement; }
>;

export type GetElementFailed = ActionWithPayload<
    ELEMENT_ACTION_TYPES.GET_ELEMENT_FAILED,
    string
>;

export type AddElementServiceStart = ActionWithPayload<
    ELEMENT_ACTION_TYPES.ADD_ELEMENT_SERVICE_START, {
        elementId: number,
        elementClassName: string,
        serviceId: number,
        dateStart: string,
        daysInterval: number,
        daysForExpiration: number,
        onComplete: () => void;
    }
>;

export type AddElementServiceSuccess = ActionWithPayload<
    ELEMENT_ACTION_TYPES.ADD_ELEMENT_SERVICE_SUCCESS,
    { newElementService: { elementId: number, elementClassName: string, elementServiceId: number; }; }
>;

export type AddElementServiceFailed = ActionWithPayload<
    ELEMENT_ACTION_TYPES.ADD_ELEMENT_SERVICE_FAILED,
    string
>;

export type GetElementServiceReportsStart = ActionWithPayload<
    ELEMENT_ACTION_TYPES.GET_ELEMENT_SERVICE_REPORTS_START, {
        id: number;
        onComplete: (elementServiceReports: MonitoringReport[]) => void;
    }
>;

export type GetElementServiceReportsSuccess = ActionWithPayload<
    ELEMENT_ACTION_TYPES.GET_ELEMENT_SERVICE_REPORTS_SUCCESS,
    { elementServiceReports: MonitoringReport[]; }
>;

export type GetElementServiceReportsFailed = ActionWithPayload<
    ELEMENT_ACTION_TYPES.GET_ELEMENT_SERVICE_REPORTS_FAILED,
    string
>;

export type GetNextElementNameByCategoryStart = ActionWithPayload<
    ELEMENT_ACTION_TYPES.GET_NEXT_ELEMENT_NAME_BY_CATEGORY_START, {
        className: string;
        categoryId: number;
        onComplete: (name: string) => void;
    }
>;

export type GetNextElementNameByCategorySuccess = ActionWithPayload<
    ELEMENT_ACTION_TYPES.GET_NEXT_ELEMENT_NAME_BY_CATEGORY_SUCCESS,
    { nextElementName: string; }
>;

export type GetNextElementNameByCategoryFailed = ActionWithPayload<
    ELEMENT_ACTION_TYPES.GET_NEXT_ELEMENT_NAME_BY_CATEGORY_FAILED,
    string
>;

export type GetNextElementNameByMatrixStart = ActionWithPayload<
    ELEMENT_ACTION_TYPES.GET_NEXT_ELEMENT_NAME_BY_MATRIX_START,
    {
        class_name: string;
        matrix_id: number;
        onComplete: (name: string) => void;
    }
>;

export type GetNextElementNameByMatrixSuccess = ActionWithPayload<
    ELEMENT_ACTION_TYPES.GET_NEXT_ELEMENT_NAME_BY_MATRIX_SUCCESS,
    { nextElementName: string; }
>;

export type GetNextElementNameByMatrixFailed = ActionWithPayload<
    ELEMENT_ACTION_TYPES.GET_NEXT_ELEMENT_NAME_BY_MATRIX_FAILED,
    string
>;

export type SaveNewElementStart = ActionWithPayload<
    ELEMENT_ACTION_TYPES.SAVE_NEW_ELEMENT_START, {
        elementPayload: Partial<ElementPayload>,
        onComplete: (id: string) => void;
    }
>;

export type SaveNewElementFailed = ActionWithPayload<
    ELEMENT_ACTION_TYPES.SAVE_NEW_ELEMENT_FAILED,
    string
>;

export type GetTechnicianElementsStart = ActionWithPayload<
    ELEMENT_ACTION_TYPES.GET_TECHNICIAN_ELEMENTS_START,
    {
        onComplete: (response: {
            points: GeoJSONFeatureCollection | null;
            zones: GeoJSONFeatureCollection | null;
            paths: GeoJSONFeatureCollection | null;
            my_available_services: MyAvailableElementService[];
        }) => void;
    }
>;

export type GetTechnicianElementsSuccess = Action<
    ELEMENT_ACTION_TYPES.GET_TECHNICIAN_ELEMENTS_SUCCESS
>;

export type GetTechnicianElementsFailed = ActionWithPayload<
    ELEMENT_ACTION_TYPES.GET_TECHNICIAN_ELEMENTS_FAILED,
    string
>;

export type UpdateElementServicesStart = ActionWithPayload<
    ELEMENT_ACTION_TYPES.UPDATE_ELEMENT_SERVICES_START, {
        services: UpdateElementServicePayload;
        onComplete: () => void;
    }
>;

export type UpdateElementServicesSuccess = Action<
    ELEMENT_ACTION_TYPES.UPDATE_ELEMENT_SERVICES_SUCCESS
>;

export type UpdateElementServicesFailed = ActionWithPayload<
    ELEMENT_ACTION_TYPES.UPDATE_ELEMENT_SERVICES_FAILED,
    string
>;

export const getElementStart = withMatcher(
    (id: string, onComplete: (element: MonitoringElement) => void): GetElementStart =>
        createAction(ELEMENT_ACTION_TYPES.GET_ELEMENT_START, { id, onComplete })
);

export const getElementSuccess = withMatcher(
    (element: MonitoringElement): GetElementSuccess =>
        createAction(ELEMENT_ACTION_TYPES.GET_ELEMENT_SUCCESS, { element })
);

export const getElementFailed = withMatcher(
    (error: string): GetElementFailed =>
        createAction(ELEMENT_ACTION_TYPES.GET_ELEMENT_FAILED, error)
);

export const addElementServiceStart = withMatcher(
    (elementId: number,
        elementClassName: string,
        serviceId: number,
        dateStart: string,
        daysInterval: number,
        daysForExpiration: number,
        onComplete: () => void
    ): AddElementServiceStart =>
        createAction(ELEMENT_ACTION_TYPES.ADD_ELEMENT_SERVICE_START, {
            elementId,
            elementClassName,
            serviceId,
            dateStart,
            daysInterval,
            daysForExpiration,
            onComplete
        })
);

export const addElementServiceSuccess = withMatcher(
    (newElementService: {
        elementId: number, elementClassName: string, elementServiceId: number;
    }): AddElementServiceSuccess =>
        createAction(ELEMENT_ACTION_TYPES.ADD_ELEMENT_SERVICE_SUCCESS, { newElementService })
);

export const addElementServiceFailed = withMatcher(
    (error: string): AddElementServiceFailed =>
        createAction(ELEMENT_ACTION_TYPES.ADD_ELEMENT_SERVICE_FAILED, error)
);

export const getElementServiceReportsStart = withMatcher(
    (id: number, onComplete: (elementServiceReports: MonitoringReport[]) => void): GetElementServiceReportsStart =>
        createAction(ELEMENT_ACTION_TYPES.GET_ELEMENT_SERVICE_REPORTS_START, { id, onComplete })
);

export const getElementServiceReportsSuccess = withMatcher(
    (elementServiceReports: MonitoringReport[]): GetElementServiceReportsSuccess =>
        createAction(ELEMENT_ACTION_TYPES.GET_ELEMENT_SERVICE_REPORTS_SUCCESS, { elementServiceReports })
);

export const getElementServiceReportsFailed = withMatcher(
    (error: string): GetElementServiceReportsFailed =>
        createAction(ELEMENT_ACTION_TYPES.GET_ELEMENT_SERVICE_REPORTS_FAILED, error)
);

export const getNextElementNameByCategoryStart = withMatcher(
    (className: string, categoryId: number, onComplete: (name: string) => void): GetNextElementNameByCategoryStart =>
        createAction(ELEMENT_ACTION_TYPES.GET_NEXT_ELEMENT_NAME_BY_CATEGORY_START, { className, categoryId, onComplete })
);

export const getNextElementNameByCategorySuccess = withMatcher(
    (nextElementName: string): GetNextElementNameByCategorySuccess =>
        createAction(ELEMENT_ACTION_TYPES.GET_NEXT_ELEMENT_NAME_BY_CATEGORY_SUCCESS, { nextElementName })
);

export const getNextElementNameByCategoryFailed = withMatcher(
    (error: string): GetNextElementNameByCategoryFailed =>
        createAction(ELEMENT_ACTION_TYPES.GET_NEXT_ELEMENT_NAME_BY_CATEGORY_FAILED, error)
);

export const getNextElementNameByMatrixStart = withMatcher(
    (class_name: string, matrix_id: number, onComplete: (name: string) => void): GetNextElementNameByMatrixStart =>
        createAction(ELEMENT_ACTION_TYPES.GET_NEXT_ELEMENT_NAME_BY_MATRIX_START, { class_name, matrix_id, onComplete })
);

export const getNextElementNameByMatrixSuccess = withMatcher(
    (nextElementName: string): GetNextElementNameByMatrixSuccess =>
        createAction(ELEMENT_ACTION_TYPES.GET_NEXT_ELEMENT_NAME_BY_MATRIX_SUCCESS, { nextElementName })
);

export const getNextElementNameByMatrixFailed = withMatcher(
    (error: string): GetNextElementNameByMatrixFailed =>
        createAction(ELEMENT_ACTION_TYPES.GET_NEXT_ELEMENT_NAME_BY_MATRIX_FAILED, error)
);

export const saveNewElementStart = withMatcher(
    (elementPayload: Partial<ElementPayload>, onComplete: (id: string) => void): SaveNewElementStart =>
        createAction(ELEMENT_ACTION_TYPES.SAVE_NEW_ELEMENT_START, { elementPayload: elementPayload, onComplete })
);

export const saveNewElementFailed = withMatcher(
    (error: string): SaveNewElementFailed =>
        createAction(ELEMENT_ACTION_TYPES.SAVE_NEW_ELEMENT_FAILED, error)
);

export const getTechnicianElementsStart = withMatcher(
    (onComplete: (response: {
        points: GeoJSONFeatureCollection | null;
        zones: GeoJSONFeatureCollection | null;
        paths: GeoJSONFeatureCollection | null;
        my_available_services: MyAvailableElementService[];
    }) => void): GetTechnicianElementsStart =>
        createAction(ELEMENT_ACTION_TYPES.GET_TECHNICIAN_ELEMENTS_START, { onComplete })
);

export const getTechnicianElementsSuccess = withMatcher(
    (): GetTechnicianElementsSuccess =>
        createAction(ELEMENT_ACTION_TYPES.GET_TECHNICIAN_ELEMENTS_SUCCESS)
);

export const getTechnicianElementsFailed = withMatcher(
    (error: string): GetTechnicianElementsFailed =>
        createAction(ELEMENT_ACTION_TYPES.GET_TECHNICIAN_ELEMENTS_FAILED, error)
);

export const updateElementServicesStart = withMatcher(
    (services: UpdateElementServicePayload, onComplete: () => void): UpdateElementServicesStart =>
        createAction(ELEMENT_ACTION_TYPES.UPDATE_ELEMENT_SERVICES_START, { services, onComplete })
);

export const updateElementServicesSuccess = withMatcher(
    (): UpdateElementServicesSuccess =>
        createAction(ELEMENT_ACTION_TYPES.UPDATE_ELEMENT_SERVICES_SUCCESS)
);

export const updateElementServicesFailed = withMatcher(
    (error: string): UpdateElementServicesFailed =>
        createAction(ELEMENT_ACTION_TYPES.UPDATE_ELEMENT_SERVICES_FAILED, error)
);