import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const GbSnackbar = ({
    open,
    msg,
    onClose,
}: {
    open: boolean;
    msg: string | null;
    onClose: () => void;
}) => {
    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        onClose();
    };

    return (
        <Snackbar
            autoHideDuration={6000}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                border: '1px solid red',
                borderRadius: '10px',
            }}
        >
            <Alert
                severity={'error'}
                sx={{
                    width: '100%',
                }}
            >
                {msg ?? ''}
            </Alert>
        </Snackbar>
    );
};

export default GbSnackbar;
