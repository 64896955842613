import { Card, Fab } from '@mui/material';
import styled from '@mui/material/styles/styled';

export const DeleteImageButton = styled(Fab)(() => ({
    '&': {
        width: 24,
        minHeight: 24,
        height: 24,
    },
    '& svg': {
        fontSize: 20,
        color: 'white',
    },
}));

export const DropAttachmentsCard = styled(Card)(({ theme }) => ({
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[100],
    width: '100%',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
}));
