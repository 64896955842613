const GBSvgImage = ({
    svgString,
    svgPath,
    width,
    height,
    alt,
}: {
    svgString?: string;
    svgPath?: string;
    width: number;
    height: number;
    alt: string;
}) => {
    const svgSrc = svgString
        ? 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgString)
        : svgPath
        ? svgPath
        : undefined;

    return <img src={svgSrc} width={width} height={height} alt={alt} />;
};

export default GBSvgImage;
