import { AssignReportPayload, CloseReportPayload, MonitoringReport, ReportPayload } from '../../models/monitoring';
import { Action, ActionWithPayload, createAction, withMatcher } from '../../utils/reducer/reducer.utils';
import { REPORT_ACTION_TYPES } from './report.types';


export type GetReportStart = ActionWithPayload<
    REPORT_ACTION_TYPES.GET_REPORT_START, {
        id: string;
        onComplete: (report: MonitoringReport) => void;
    }
>;

export type GetReportSuccess = ActionWithPayload<
    REPORT_ACTION_TYPES.GET_REPORT_SUCCESS,
    { report: MonitoringReport; }
>;

export type GetReportFailed = ActionWithPayload<
    REPORT_ACTION_TYPES.GET_REPORT_FAILED,
    string
>;

export type AddReportStart = ActionWithPayload<
    REPORT_ACTION_TYPES.ADD_REPORT_START,
    { report: Partial<ReportPayload>; onComplete: () => void; }
>;

export type AddReportSuccess = Action<
    REPORT_ACTION_TYPES.ADD_REPORT_SUCCESS
>;

export type AddReportFailed = ActionWithPayload<
    REPORT_ACTION_TYPES.ADD_REPORT_FAILED,
    string
>;

export type AssignReportStart = ActionWithPayload<
    REPORT_ACTION_TYPES.ASSIGN_REPORT_START,
    { report: AssignReportPayload, onComplete: () => void; }
>;

export type AssignReportSuccess = Action<
    REPORT_ACTION_TYPES.ASSIGN_REPORT_SUCCESS
>;

export type AssignReportFailed = ActionWithPayload<
    REPORT_ACTION_TYPES.ASSIGN_REPORT_FAILED,
    string>;


export type UpdateReportWithInterventionStart = ActionWithPayload<
    REPORT_ACTION_TYPES.UPDATE_REPORT_WITH_INTERVENTION_START,
    { report: Partial<ReportPayload>; report_id: number; onComplete: () => void; }
>;

export type UpdateReportWithInterventionSuccess = Action<
    REPORT_ACTION_TYPES.UPDATE_REPORT_WITH_INTERVENTION_SUCCESS
>;

export type UpdateReportWithInterventionFailed = ActionWithPayload<
    REPORT_ACTION_TYPES.UPDATE_REPORT_WITH_INTERVENTION_FAILED,
    string
>;

export type CloseReportStart = ActionWithPayload<
    REPORT_ACTION_TYPES.CLOSE_REPORT_START,
    { report: CloseReportPayload, onComplete: () => void; }
>;

export type CloseReportSuccess = Action<
    REPORT_ACTION_TYPES.CLOSE_REPORT_SUCCESS
>;

export type CloseReportFailed = ActionWithPayload<
    REPORT_ACTION_TYPES.CLOSE_REPORT_FAILED,
    string
>;

export const getReportStart = withMatcher(
    (id: string, onComplete: (report: MonitoringReport) => void): GetReportStart =>
        createAction(REPORT_ACTION_TYPES.GET_REPORT_START, { id, onComplete })
);

export const getReportSuccess = withMatcher(
    (report: MonitoringReport): GetReportSuccess =>
        createAction(REPORT_ACTION_TYPES.GET_REPORT_SUCCESS, { report })
);

export const getReportFailed = withMatcher(
    (error: string): GetReportFailed =>
        createAction(REPORT_ACTION_TYPES.GET_REPORT_FAILED, error)
);

export const addReportStart = withMatcher(
    (report: Partial<ReportPayload>, onComplete: () => void): AddReportStart =>
        createAction(REPORT_ACTION_TYPES.ADD_REPORT_START, { report, onComplete })
);

export const addReportSuccess = withMatcher(
    (): AddReportSuccess =>
        createAction(REPORT_ACTION_TYPES.ADD_REPORT_SUCCESS)
);

export const addReportFailed = withMatcher(
    (error: string): AddReportFailed =>
        createAction(REPORT_ACTION_TYPES.ADD_REPORT_FAILED, error)
);

export const assignReportStart = withMatcher(
    (report: AssignReportPayload, onComplete: () => void): AssignReportStart =>
        createAction(REPORT_ACTION_TYPES.ASSIGN_REPORT_START, { report, onComplete })
);

export const assignReportSuccess = withMatcher(
    (): AssignReportSuccess =>
        createAction(REPORT_ACTION_TYPES.ASSIGN_REPORT_SUCCESS)
);

export const assignReportFailed = withMatcher(
    (error: string): AssignReportFailed =>
        createAction(REPORT_ACTION_TYPES.ASSIGN_REPORT_FAILED, error)
);

export const updateReportWithInterventionStart = withMatcher(
    (report: Partial<ReportPayload>, report_id: number, onComplete: () => void): UpdateReportWithInterventionStart =>
        createAction(REPORT_ACTION_TYPES.UPDATE_REPORT_WITH_INTERVENTION_START, { report, report_id, onComplete })
);

export const updateReportWithInterventionSuccess = withMatcher(
    (): UpdateReportWithInterventionSuccess =>
        createAction(REPORT_ACTION_TYPES.UPDATE_REPORT_WITH_INTERVENTION_SUCCESS)
);

export const updateReportWithInterventionFailed = withMatcher(
    (error: string): UpdateReportWithInterventionFailed =>
        createAction(REPORT_ACTION_TYPES.UPDATE_REPORT_WITH_INTERVENTION_FAILED, error)
);

export const closeReportStart = withMatcher(
    (report: CloseReportPayload, onComplete: () => void): CloseReportStart =>
        createAction(REPORT_ACTION_TYPES.CLOSE_REPORT_START, { report, onComplete })
);

export const closeReportSuccess = withMatcher(
    (): CloseReportSuccess =>
        createAction(REPORT_ACTION_TYPES.CLOSE_REPORT_SUCCESS)
);

export const closeReportFailed = withMatcher(
    (error: string): CloseReportFailed =>
        createAction(REPORT_ACTION_TYPES.CLOSE_REPORT_FAILED, error)
);