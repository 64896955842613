import { all, call, put, takeLatest } from 'typed-redux-saga/macro';
import { MONITORING_CAMPAIGNS_URL, MONITORING_PHASES_URL, MONITORING_TEST_REPORTS_URL } from '../../constants/api.constants';
import { apiRequestWrapper } from '../../utils/api/api.utils';
import { setIsError } from '../error/error.action';
import { setIsLoading } from '../loading/loading.action';
import { addPhaseCampaignTestReportFailed, AddPhaseCampaignTestReportStart, addPhaseCampaignTestReportSuccess, addPhaseFailed, AddPhaseStart, addPhaseSuccess, getPhaseCampaignsFailed, GetPhaseCampaignsStart, getPhaseCampaignsSuccess, getPhaseCampaignTestReportsFailed, GetPhaseCampaignTestReportsStart, getPhaseCampaignTestReportsSuccess, getPhaseFailed, GetPhaseStart, getPhaseSuccess } from './phase.action';
import { PHASE_ACTION_TYPES } from './phase.types';


export function* getPhase({
    payload: { id, onComplete },
}: GetPhaseStart) {
    yield* put(setIsLoading(true));
    try {
        const phase = yield* call(
            apiRequestWrapper, `${MONITORING_PHASES_URL}${id}/`, 'GET', null
        );

        if (phase) {
            yield* put(getPhaseSuccess(phase));
        }
        yield* put(setIsLoading(false));
        onComplete(phase);
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getPhaseFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* addPhase({
    payload: { phase, campaigns, onComplete },
}: AddPhaseStart) {
    yield* put(setIsLoading(true));
    try {
        const response = yield* call(
            apiRequestWrapper, MONITORING_PHASES_URL, 'POST', { phase, campaigns }
        );

        if (response) {
            yield* put(addPhaseSuccess(response.newPhaseId));
        }
        yield* put(setIsLoading(false));
        onComplete(response.newPhaseId);
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(addPhaseFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* getPhaseCampaigns({
    payload: { phaseId, matrixId, onComplete },
}: GetPhaseCampaignsStart) {
    yield* put(setIsLoading(true));
    try {
        let url = `${MONITORING_CAMPAIGNS_URL}?phase=${phaseId}`;
        if (matrixId) {
            url += `&matrix=${matrixId}`;
        }
        const campaigns = yield* call(
            apiRequestWrapper, url, 'GET', null
        );

        if (campaigns) {
            yield* put(getPhaseCampaignsSuccess(campaigns));
        }
        yield* put(setIsLoading(false));
        onComplete(campaigns);
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getPhaseCampaignsFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* getPhaseCampaignTestReports({
    payload: { elementId, campaignId, onComplete },
}: GetPhaseCampaignTestReportsStart) {
    yield* put(setIsLoading(true));
    try {
        const testReports = yield* call(
            apiRequestWrapper, `${MONITORING_TEST_REPORTS_URL}?element=${elementId}&campaign=${campaignId}`, 'GET', null
        );

        if (testReports) {
            yield* put(getPhaseCampaignTestReportsSuccess(testReports));
        }
        yield* put(setIsLoading(false));
        onComplete(testReports);
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getPhaseCampaignTestReportsFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* addPhaseCampaignTestReport({
    payload: { testReport, onComplete },
}: AddPhaseCampaignTestReportStart) {
    yield* put(setIsLoading(true));
    try {
        const response = yield* call(
            apiRequestWrapper, MONITORING_TEST_REPORTS_URL, 'POST', testReport, true
        );

        if (response) {
            yield* put(addPhaseCampaignTestReportSuccess());
        }
        yield* put(setIsLoading(false));
        onComplete();
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(addPhaseCampaignTestReportFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onGetPhaseStart() {
    yield* takeLatest(PHASE_ACTION_TYPES.GET_PHASE_START, getPhase);
}

export function* onAddPhaseStart() {
    yield* takeLatest(PHASE_ACTION_TYPES.ADD_PHASE_START, addPhase);
}

export function* onGetPhaseCampaignsStart() {
    yield* takeLatest(PHASE_ACTION_TYPES.GET_PHASE_CAMPAIGNS_START, getPhaseCampaigns);
}

export function* onGetPhaseCampaignTestReportsStart() {
    yield* takeLatest(PHASE_ACTION_TYPES.GET_PHASE_CAMPAIGN_TEST_REPORTS_START, getPhaseCampaignTestReports);
}

export function* onAddPhaseCampaignTestReportStart() {
    yield* takeLatest(PHASE_ACTION_TYPES.ADD_PHASE_CAMPAIGN_TEST_REPORT_START, addPhaseCampaignTestReport);
}

export function* phaseSaga() {
    yield* all([
        call(onGetPhaseStart),
        call(onAddPhaseStart),
        call(onGetPhaseCampaignsStart),
        call(onGetPhaseCampaignTestReportsStart),
        call(onAddPhaseCampaignTestReportStart),
    ]);
}
