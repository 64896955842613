import { Add, Delete } from '@mui/icons-material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Fragment, useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useDispatch } from 'react-redux';
import { MAX_NUMBER_OF_IMAGES_AT_A_TIME } from '../../../constants/files.constants';
import { Attachment } from '../../../models/shared';
import { openDialog } from '../../../store/dialog/dialog.action';
import { DIALOG_TYPES } from '../../../store/dialog/dialog.reducer';
import { setIsError } from '../../../store/error/error.action';
import {
    BoxFullWH,
    FlexBoxFullWHColumn,
    FlexBoxFullWHColumnBetween,
    FlexBoxFullWRowBetween,
} from '../../layout/layout.styles';
import GBBorderTitleBox from '../gb-border-title-box/gb-border-title-box.component';
import GBCardHeader from '../gb-card-header/gb-card-header.component';
import GBCard from '../gb-card/gb-card.component';
import './gb-gallery.styles.scss';

const GBGalleryContent = ({
    images,
    currentIndex,
    onCarouselChange,
}: {
    images: Attachment[];
    currentIndex: number | undefined;
    onCarouselChange: (newIndex: number | undefined) => void;
}) => {
    const theme = useTheme();

    const handleOnCarouselChange = (newIndex: number | undefined) => {
        onCarouselChange(newIndex);
    };

    return (
        <BoxFullWH>
            {images.length === 0 && (
                <FlexBoxFullWHColumn justifyContent={'center'}>
                    <Typography textAlign={'center'}>
                        Nessuna immagine da mostrare
                    </Typography>
                </FlexBoxFullWHColumn>
            )}
            {images.length > 0 && (
                <Carousel
                    index={currentIndex}
                    onChange={handleOnCarouselChange}
                    fullHeightHover
                    navButtonsWrapperProps={{
                        className: 'carousel-nav-button-wrapper',
                    }}
                    navButtonsProps={{
                        className: 'carousel-nav-button',
                        style: {
                            background: theme.colors.primary.main,
                            marginTop: -10,
                        },
                    }}
                    activeIndicatorIconButtonProps={{
                        style: {
                            color: theme.colors.primary.main,
                        },
                    }}
                    indicatorContainerProps={{
                        style: {
                            position: 'absolute',
                            bottom: 0,
                            zIndex: 1,
                        },
                    }}
                    autoPlay={false}
                    sx={{ height: '100%' }}
                >
                    {images.map((image, i) => (
                        <img
                            key={i}
                            style={{
                                position: 'absolute',
                                maxWidth: '100%',
                                maxHeight: 'calc(100% - 20px)',
                                top: 'calc(50% - 10px)',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                            src={`${process.env.REACT_APP_BASE_URL}${image.file}`}
                            alt={image.file_name}
                        />
                    ))}
                </Carousel>
            )}
        </BoxFullWH>
    );
};

const GBGallery = ({
    entityId,
    entityClassName,
    images,
    boxedVersion,
    onImagesAdded,
    onImageDeleted,
    readonly,
}: {
    entityId: number;
    entityClassName: string;
    images: Attachment[];
    boxedVersion?: boolean;
    onImagesAdded: () => void;
    onImageDeleted: () => void;
    readonly?: boolean;
}) => {
    const dispatch = useDispatch();

    const [currentIndex, setCurrentIndex] = useState<number | undefined>();

    const handleOnCarouselChange = (now: number | undefined) => {
        if (now !== undefined) {
            setCurrentIndex(now);
        }
    };

    useEffect(() => {
        if (images.length > 0) {
            setCurrentIndex(0);
        } else {
            setCurrentIndex(undefined);
        }
    }, [images]);

    const handleAddImagesButtonClick = () => {
        if (images.length >= MAX_NUMBER_OF_IMAGES_AT_A_TIME) {
            dispatch(setIsError(true, 'Numero massimo di immagino raggiunto'));
            return;
        }

        dispatch(
            openDialog(
                DIALOG_TYPES.ADD_IMAGES,
                {
                    id: entityId,
                    className: entityClassName,
                    imagesLength: images.length,
                },
                'md',
                () => onImagesAdded()
            )
        );
    };

    const handleDeleteImageButtonClick = () => {
        if (currentIndex !== undefined) {
            dispatch(
                openDialog(
                    DIALOG_TYPES.CONFIRM_CANCELLATION,
                    {
                        id: images[currentIndex].id,
                        name: images[currentIndex].file_name,
                        entityId: entityId,
                        entityClassName: entityClassName,
                    },
                    'sm',
                    () => onImageDeleted()
                )
            );
        }
    };

    return (
        <Fragment>
            {boxedVersion ? (
                <GBBorderTitleBox
                    title="Gallery"
                    action={
                        !readonly && (
                            <Box>
                                <IconButton
                                    onClick={handleAddImagesButtonClick}
                                    sx={{ backgroundColor: 'white' }}
                                >
                                    <Add color="primary" />
                                </IconButton>
                                {currentIndex !== undefined && (
                                    <IconButton
                                        onClick={handleDeleteImageButtonClick}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <Delete color="error" />
                                    </IconButton>
                                )}
                            </Box>
                        )
                    }
                >
                    <FlexBoxFullWHColumnBetween>
                        <GBGalleryContent
                            images={images}
                            currentIndex={currentIndex}
                            onCarouselChange={handleOnCarouselChange}
                        />
                    </FlexBoxFullWHColumnBetween>
                </GBBorderTitleBox>
            ) : (
                <GBCard>
                    <FlexBoxFullWHColumnBetween>
                        <FlexBoxFullWRowBetween mb={1}>
                            <GBCardHeader>
                                <Typography variant="h3" py={0}>
                                    Gallery
                                </Typography>
                                {!readonly && (
                                    <Box>
                                        <IconButton
                                            onClick={handleAddImagesButtonClick}
                                        >
                                            <Add color="primary" />
                                        </IconButton>
                                        {currentIndex !== undefined && (
                                            <IconButton
                                                onClick={
                                                    handleDeleteImageButtonClick
                                                }
                                            >
                                                <Delete color="error" />
                                            </IconButton>
                                        )}
                                    </Box>
                                )}
                            </GBCardHeader>
                        </FlexBoxFullWRowBetween>

                        <GBGalleryContent
                            images={images}
                            currentIndex={currentIndex}
                            onCarouselChange={handleOnCarouselChange}
                        />
                    </FlexBoxFullWHColumnBetween>
                </GBCard>
            )}
        </Fragment>
    );
};

export default GBGallery;
