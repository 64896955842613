import { Close } from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    Fab,
    Grid,
    InputLabel,
    styled,
    TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MonitoringTestReport } from '../../../models/monitoring';
import { closeDialog } from '../../../store/dialog/dialog.action';
import { setIsError } from '../../../store/error/error.action';
import { addPhaseCampaignTestReportStart } from '../../../store/phase/phase.action';
import {
    checkExtensionFile,
    checkFileSize,
} from '../../../utils/file/file.utils';
import GBDialogActions from '../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../ui/gb-dialog-title/gb-dialog-title.component';

const DialogAddTestReport = ({
    inputData,
    onSaved,
}: {
    inputData: any;
    onSaved: () => void;
}) => {
    const { campaign, element } = inputData;

    const dispatch = useDispatch();

    const [testReport, setTestReport] = useState<Partial<MonitoringTestReport>>(
        {
            name: '',
            description: '',
            campaign: campaign.id,
            element: element.id,
            start_date: moment(campaign.start_date).format('YYYY-MM-DD'),
            end_date: moment(campaign.start_date).format('YYYY-MM-DD'),
        }
    );

    const handleSave = async () => {
        if (!testReport.name) {
            dispatch(setIsError(true, 'Alimentare i campi obbligatori'));
            return;
        }
        if (
            moment(testReport.end_date).diff(testReport.start_date, 'days') < 0
        ) {
            dispatch(
                setIsError(
                    true,
                    'La data fine non può essere inferiore alla data inizio'
                )
            );
            return;
        }
        const payload = {
            ...testReport,
            attachments: attachments,
        };
        dispatch(
            addPhaseCampaignTestReportStart(payload, () => {
                onSaved();
                dispatch(closeDialog());
            })
        );
    };

    const [attachments, setAttachments] = useState<any[]>([]);
    const [nextId, setNextId] = useState<number>(0);
    const [dragging, setDragging] = useState(false);

    const DeleteAttachmentButton = styled(Fab)(
        ({ theme }) => `
        & {
            width: 24px;
            min-height: 24px;
            height: 24px;
        }
        & svg{
            font-size: 20px;
            color: white;
        }
    `
    );

    const appendAttachment = (files: any) => {
        let index = nextId;
        let reportAttachments: any = [];
        for (let f of files) {
            if (!checkExtensionFile(f.name)) {
                dispatch(setIsError(true, 'Tipo file non ammesso: ' + f.name));
                return;
            }
            if (!checkFileSize(f)) {
                dispatch(setIsError(true, 'File troppo grande: ' + f.name));
                return;
            }
            reportAttachments.push({
                file: f,
            });
            index++;
        }

        setNextId(index);
        let tmp = attachments;
        tmp = tmp.concat(reportAttachments);
        setAttachments(tmp);
    };

    // Gestisci l'evento di trascinamento sopra l'area di "drag and drop"
    const handleDragOver = (e: any) => {
        e.preventDefault();
        setDragging(true);
    };

    // Gestisci l'evento di uscita dall'area di "drag and drop"
    const handleDragLeave = () => {
        setDragging(false);
    };

    // Gestisci l'evento di rilascio del file nell'area di "drag and drop"
    const handleDrop = (e: any) => {
        e.preventDefault();
        setDragging(false);
        appendAttachment(e.dataTransfer.files);
    };

    // Gestisci l'evento di caricamento file da pulsante
    const handleFileChange = (event: any) => {
        appendAttachment(event.target.files);
        // resetto il buffer altrimenti non mi fa ricaricare lo stesso file
        event.target.value = '';
    };

    return (
        <Fragment>
            <GBDialogTitle title="AGGIUNGI RAPPORTO DI PROVA" />
            <GBDialogContent height="100vh">
                <Box
                    width={1}
                    height={1}
                    display={'flex'}
                    flexDirection={'column'}
                >
                    <Box display={'flex'} gap={2} marginTop={2}>
                        <Box flex={1 / 2}>
                            <TextField
                                disabled
                                label="Campagna"
                                defaultValue={campaign.name}
                                sx={{
                                    width: '100%',
                                }}
                            />
                        </Box>
                        <Box flex={1 / 2}>
                            <TextField
                                disabled
                                label="Elemento"
                                defaultValue={element.name}
                                sx={{
                                    width: '100%',
                                }}
                            />
                        </Box>
                    </Box>
                    <Box display={'flex'} gap={2} marginTop={2}>
                        <Box flex={1}>
                            <TextField
                                required
                                label="Nome"
                                defaultValue={testReport.name}
                                onChange={(e) => {
                                    setTestReport((prevData) => ({
                                        ...prevData,
                                        name: e.target.value,
                                    }));
                                }}
                                sx={{
                                    width: '100%',
                                }}
                            />
                        </Box>
                    </Box>
                    <Box display={'flex'} gap={2} marginTop={2}>
                        <Box flex={1 / 2}>
                            <DatePicker
                                label="Data Inizio"
                                sx={{
                                    width: '100%',
                                    'input:disabled': {
                                        WebkitTextFillColor: (theme) =>
                                            `${theme.colors.alpha.black[100]}`,
                                    },
                                }}
                                slotProps={{
                                    textField: {
                                        disabled: true,
                                    },
                                    popper: {
                                        placement: 'bottom-end',
                                    },
                                }}
                                format="DD/MM/YYYY"
                                minDate={moment(campaign.start_date)}
                                maxDate={moment(campaign.end_date)}
                                value={moment(testReport.start_date)}
                                onChange={(newValue) => {
                                    setTestReport((prevData) => ({
                                        ...prevData,
                                        start_date: moment(newValue!).format(
                                            'YYYY-MM-DD'
                                        ),
                                    }));
                                }}
                            />
                        </Box>
                        <Box flex={1 / 2}>
                            <DatePicker
                                label="Data Fine"
                                sx={{
                                    width: '100%',
                                    'input:disabled': {
                                        WebkitTextFillColor: (theme) =>
                                            `${theme.colors.alpha.black[100]}`,
                                    },
                                }}
                                slotProps={{
                                    textField: {
                                        disabled: true,
                                    },
                                    popper: {
                                        placement: 'bottom-end',
                                    },
                                }}
                                format="DD/MM/YYYY"
                                minDate={moment(campaign.start_date)}
                                maxDate={moment(campaign.end_date)}
                                value={moment(testReport.end_date)}
                                onChange={(newValue) => {
                                    setTestReport((prevData) => ({
                                        ...prevData,
                                        end_date: moment(newValue!).format(
                                            'YYYY-MM-DD'
                                        ),
                                    }));
                                }}
                            />
                        </Box>
                    </Box>
                    <Box display={'flex'} gap={2} marginTop={2}>
                        <Box flex={1}>
                            <TextField
                                label="Descrizione fase"
                                multiline
                                rows={6}
                                value={testReport.description}
                                onChange={(e) => {
                                    setTestReport((prevData) => ({
                                        ...prevData,
                                        description: e.target.value,
                                    }));
                                }}
                                sx={{
                                    minWidth: '100%',
                                    maxWidth: '100%',
                                    justifyContent: 'start',
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        display={'flex'}
                        width={1}
                        flexGrow={1}
                        gap={2}
                        marginTop={2}
                    >
                        <Box
                            width={1}
                            sx={{
                                border: '1px solid rgba(75, 75, 75, 0.5);',
                                borderRadius: '10px',
                                padding: '16.5px 14px',
                                position: 'relative',
                            }}
                        >
                            <InputLabel
                                sx={{
                                    position: 'absolute',
                                    top: -10,
                                    left: 7,
                                    fontSize: '0.75em',
                                    paddingX: '5px',
                                    backgroundColor: 'white',
                                    color: 'rgba(75, 75, 75, 0.5)',
                                }}
                            >
                                Aggiungi Allegati
                            </InputLabel>
                            <Grid container alignItems="center">
                                <Grid
                                    item
                                    xs={4}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <input
                                            type="file"
                                            //accept="image/*"
                                            onChange={handleFileChange}
                                            style={{
                                                display: 'none',
                                            }}
                                            id="file-upload-attachments"
                                            multiple
                                        />
                                        <label
                                            style={{
                                                width: '100%',
                                            }}
                                            htmlFor="file-upload-attachments"
                                        >
                                            <Button
                                                variant="contained"
                                                component="span"
                                                sx={{
                                                    height: '52px',
                                                    width: 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                Seleziona i file
                                            </Button>
                                        </label>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    Oppure
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <Card
                                        className={`file-upload ${
                                            dragging ? 'dragging' : ''
                                        }`}
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleDrop}
                                        sx={{
                                            border: '1px solid',
                                            borderColor: (theme) =>
                                                theme.palette.grey[300],
                                            backgroundColor: (theme) =>
                                                theme.palette.grey[100],
                                            width: '100%',
                                            height: '52px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {'Trascina qui i file'}
                                    </Card>
                                </Grid>
                            </Grid>
                            {attachments.length > 0 && (
                                <Box
                                    sx={{
                                        paddingTop: '20px',
                                        paddingBottom: '20px',
                                    }}
                                >
                                    {'Files caricati (' +
                                        attachments.length +
                                        '):'}
                                </Box>
                            )}
                            <Box sx={{ width: '100%' }}>
                                {attachments.map((o: any, index: any) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            paddingTop: '5px',
                                            marginLeft: '10px',
                                        }}
                                    >
                                        <DeleteAttachmentButton
                                            color="primary"
                                            onClick={() => {
                                                let tmp: any[] = [];
                                                tmp = attachments.filter(
                                                    (a, i) => i !== index
                                                );
                                                setAttachments(tmp);
                                            }}
                                            sx={{
                                                marginRight: '20px',
                                                color: (theme) =>
                                                    theme.palette.grey[500],
                                            }}
                                        >
                                            <Close />
                                        </DeleteAttachmentButton>
                                        {o.file.name}
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handleSave}>TERMINA E SALVA</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogAddTestReport;
