import { User } from '../../store/auth/auth.reducer';

// per essere amministratore, basta avere nei permessi is_greenbeat_administrator,
// indipendentemente dalla presensza di altri permessi
export function isGreenbeatAdministrator(user: User): boolean {
    if (!user) return false;
    return user.roles.find(p => p === 'administrator') !== undefined;
}

// per essere tecnico, il livello più alto dei permessi deve essere is_greenbeat_technician,
// per cui non ci deve essere is_greenbeat_administrator
export function isGreenbeatTechnician(user: User): boolean {
    if (!user) return false;
    return user.roles.find(p => p === 'technician') !== undefined &&
        user.roles.find(p => p === 'administrator') === undefined;
}

// per essere operatore, il livello più alto dei permessi deve essere is_greenbeat_operator,
// per cui non ci deve essere ne is_greenbeat_administrator ne is_greenbeat_technician
export function isGreenbeatOperator(user: User): boolean {
    if (!user) return false;
    return user.roles.find(p => p === 'operator') !== undefined &&
        user.roles.find(p => p === 'administrator') === undefined &&
        user.roles.find(p => p === 'technician') === undefined;
}

// per essere operatore, il livello più alto dei permessi deve essere is_greenbeat_reporter,
// per cui non ci deve essere nessuno degli altri tre permessi
export function isGreenbeatReporter(user: User): boolean {
    if (!user) return false;
    return user.roles.find(p => p === 'reporter') !== undefined &&
        user.roles.find(p => p === 'administrator') === undefined &&
        user.roles.find(p => p === 'operator') === undefined &&
        user.roles.find(p => p === 'technician') === undefined;
}

// per essere visualizzatore, il livello più alto dei permessi deve essere is_greenbeat_viewer,
// per cui non ci deve essere nessuno degli altri tre permessi
export function isGreenbeatViewer(user: User): boolean {
    if (!user) return false;
    return user.roles.find(p => p === 'viewer') !== undefined &&
        user.roles.find(p => p === 'reporter') === undefined &&
        user.roles.find(p => p === 'administrator') === undefined &&
        user.roles.find(p => p === 'operator') === undefined &&
        user.roles.find(p => p === 'technician') === undefined;
}
