import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    MonitoringElementService,
    PeriodicInspectionPayload,
} from '../../../models/monitoring';
import { closeDialog } from '../../../store/dialog/dialog.action';
import { setIsError } from '../../../store/error/error.action';
import { addPeriodicInspectionStart } from '../../../store/periodic-inspection/periodic-inspection.action';
import DialogAddPeriodicInspectionAttachmentsStep from './steps/dialog-add-periodic-inspection-attachments-step/dialog-add-periodic-inspection-attachments-step.component';
import DialogAddPeriodicInspectionChecklistStep from './steps/dialog-add-periodic-inspection-checklist-step/dialog-add-periodic-inspection-checklist-step.component';
import DialogAddPeriodicInspectionImagesStep from './steps/dialog-add-periodic-inspection-images-step/dialog-add-periodic-inspection-images-step.component';
import DialogAddPeriodicInspectionInfoStep from './steps/dialog-add-periodic-inspection-info-step/dialog-add-periodic-inspection-info-step.component';
import DialogAddPeriodicInspectionServiceStep from './steps/dialog-add-periodic-inspection-service-step/dialog-add-periodic-inspection-service-step.component';
import DialogAddPeriodicInspectionSeverityStep from './steps/dialog-add-periodic-inspection-severity-step/dialog-add-periodic-inspection-severity-step.component';
import DialogAddPeriodicInspectionSignatureStep from './steps/dialog-add-periodic-inspection-signature-step/dialog-add-periodic-inspection-signature-step';

const DialogAddPeriodicInspection = ({
    inputData,
    onSaved,
}: {
    inputData: any;
    onSaved: () => void;
}) => {
    const [step, setStep] = useState<number>(0);
    const [periodicInspectionToAdd, setPeriodicInspectionToAdd] = useState<
        Partial<PeriodicInspectionPayload>
    >({});
    const dispatch = useDispatch();

    const handleNextStep = (
        properties: Partial<PeriodicInspectionPayload>,
        save = false
    ) => {
        setPeriodicInspectionToAdd((prevState) => {
            return {
                ...prevState,
                ...properties,
            };
        });
        if (!save) {
            const checklist = inputData.elementServices.find(
                (e: MonitoringElementService) =>
                    e.id === periodicInspectionToAdd.element_service
            )?.service?.checklist;
            if (step === 1 && (!checklist || checklist.length === 0)) {
                setStep(step + 2);
            } else {
                setStep(step + 1);
            }
        }
        if (save) {
            handleSave({
                ...periodicInspectionToAdd,
                ...properties,
            });
        }
    };

    const handlePreviousStep = () => {
        if (step > 0) {
            const checklist = inputData.elementServices.find(
                (e: MonitoringElementService) =>
                    e.id === periodicInspectionToAdd.element_service
            )?.service?.checklist;
            if (step === 3 && (!checklist || checklist.length === 0)) {
                setStep(step - 2);
            } else {
                setStep(step - 1);
            }
        }
    };

    const handleSave = async (
        periodicInspection: Partial<PeriodicInspectionPayload>
    ) => {
        if (!periodicInspection) {
            dispatch(setIsError(true, 'Dati insufficienti per il salvataggio'));
            return;
        }
        dispatch(
            addPeriodicInspectionStart(periodicInspection, () => {
                onSaved();
                dispatch(closeDialog());
            })
        );
    };

    return (
        <Fragment>
            {step === 0 && (
                <DialogAddPeriodicInspectionServiceStep
                    elementServices={inputData.elementServices}
                    elementServiceSelected={
                        periodicInspectionToAdd.element_service
                    }
                    onNext={handleNextStep}
                />
            )}
            {step === 1 && (
                <DialogAddPeriodicInspectionInfoStep
                    name={periodicInspectionToAdd.name}
                    description={periodicInspectionToAdd.description}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 2 && periodicInspectionToAdd.element_service && (
                <DialogAddPeriodicInspectionChecklistStep
                    service={
                        inputData.elementServices.find(
                            (e: MonitoringElementService) =>
                                e.id === periodicInspectionToAdd.element_service
                        ).service
                    }
                    checklist_values={periodicInspectionToAdd.checklist_values}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 3 && (
                <DialogAddPeriodicInspectionImagesStep
                    images={periodicInspectionToAdd.images}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 4 && (
                <DialogAddPeriodicInspectionAttachmentsStep
                    attachments={periodicInspectionToAdd.attachments}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 5 && (
                <DialogAddPeriodicInspectionSeverityStep
                    severity={periodicInspectionToAdd.severity}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 6 && (
                <DialogAddPeriodicInspectionSignatureStep
                    onPrevious={handlePreviousStep}
                    onNext={(properties) => handleNextStep(properties, true)}
                />
            )}
        </Fragment>
    );
};

export default DialogAddPeriodicInspection;
