import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { MonitoringElement } from '../../../models/monitoring';
import { getElementSvg } from '../../../utils/data/data.utils';
import GBSvgImage from '../../ui/gb-svg-image/gb-svg-image.component';
import { Fragment } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createElementRoute } from '../../../utils/routes/routes.utils';
import { useSelector } from 'react-redux';
import { selectViewMode } from '../../../store/filters/filters.selector';
import { ViewMode } from '../../../store/filters/filters.reducer';

const ElementListItem = ({
    item,
    className,
}: {
    item: MonitoringElement;
    className: string;
}) => {
    const [searchParams] = useSearchParams();

    const paramClassName = searchParams.get('className');
    const paramId = searchParams.get('id');

    const selected =
        paramClassName === className && paramId === item.id.toString();

    const elementSvg = getElementSvg(className);
    const navigate = useNavigate();

    const handleElementClick = (className: string) => {
        navigate(createElementRoute(className, item.id));
    };

    const viewMode = useSelector(selectViewMode);

    return (
        <Fragment>
            <ListItem disablePadding>
                <ListItemButton
                    onClick={() => handleElementClick(className)}
                    selected={selected}
                >
                    <ListItemText
                        primary={
                            <Box display={'flex'} alignItems={'center'} gap={2}>
                                <GBSvgImage
                                    height={30}
                                    width={30}
                                    svgPath={elementSvg}
                                    alt={'Icona elemento'}
                                />
                                <Box flexGrow={1}>
                                    <Typography
                                        variant="h5"
                                        gutterBottom={true}
                                    >
                                        {item.name}
                                    </Typography>
                                    {viewMode === ViewMode.VIREO && (
                                        <Typography
                                            fontWeight={'bold'}
                                            color={
                                                item.matrix?.color ?? 'primary'
                                            }
                                            variant="body2"
                                            sx={{ marginTop: 1 }}
                                        >
                                            {item.matrix?.name}
                                        </Typography>
                                    )}
                                    {viewMode ===
                                        ViewMode.SEGNALAZIONI_SMART && (
                                        <Typography
                                            fontWeight={'bold'}
                                            color={
                                                item.severity?.color ??
                                                'primary'
                                            }
                                            variant="body2"
                                            sx={{ marginTop: 1 }}
                                        >
                                            {item.severity
                                                ? `Uno o più servizi non conformi con criticità ${item.severity.name}`
                                                : 'Tutti i servizi conformi'}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        }
                    />
                </ListItemButton>
            </ListItem>
            <Divider />
        </Fragment>
    );
};

export default ElementListItem;
