import { ExpandMore } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { FiltersState } from '../../../../store/filters/filters.reducer';
import {
    selectMonitoringCampaigns,
    selectMonitoringPhases,
} from '../../../../store/monitoring/monitoring.selector';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '../../../phases-accordion/phases-accordion.styles';

const FilterCampaign = ({
    filtersToApply,
    onChange,
}: {
    filtersToApply: FiltersState;
    onChange: (filtersChanged: Partial<FiltersState>) => void;
}) => {
    const monitoringPhases = useSelector(selectMonitoringPhases);
    const monitoringCampaigns = useSelector(selectMonitoringCampaigns);

    return (
        <Fragment>
            {monitoringPhases.map((phase) => (
                <Accordion key={phase.id}>
                    <AccordionSummary
                        expandIcon={
                            <ExpandMore sx={{ transform: 'rotate(-90deg)' }} />
                        }
                    >
                        {phase.name}
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormGroup>
                            {monitoringCampaigns.map((campaign) => (
                                <FormControlLabel
                                    key={campaign.id}
                                    control={
                                        <Checkbox
                                            checked={filtersToApply.monitoringCampaignsIds.includes(
                                                campaign.id
                                            )}
                                            onChange={(event) => {
                                                let newMonitoringCampaignsIds =
                                                    [
                                                        ...filtersToApply.monitoringCampaignsIds,
                                                    ];
                                                if (event.target.checked) {
                                                    newMonitoringCampaignsIds.push(
                                                        campaign.id
                                                    );
                                                } else {
                                                    newMonitoringCampaignsIds =
                                                        newMonitoringCampaignsIds.filter(
                                                            (c) =>
                                                                c !==
                                                                campaign.id
                                                        );
                                                }
                                                onChange({
                                                    monitoringCampaignsIds:
                                                        newMonitoringCampaignsIds,
                                                });
                                            }}
                                        />
                                    }
                                    label={`Mostra ${campaign.name}`}
                                />
                            ))}
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Fragment>
    );
};

export default FilterCampaign;
