import DialogActions from '@mui/material/DialogActions';
import { ReactNode } from 'react';

const GBDialogActions = ({ children }: { children: ReactNode }) => {
    return (
        <DialogActions sx={{ '& :only-child': { marginLeft: 'auto' } }}>
            {children}
        </DialogActions>
    );
};

export default GBDialogActions;
