import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { ViewMode } from '../../../store/filters/filters.reducer';
import { selectViewMode } from '../../../store/filters/filters.selector';
import ActionsBar from '../../actions-bar/actions-bar.component';
import ElementsList from '../../elements-list/elements-list.component';
import GoToMonitoringButton from '../../go-to-monitoring-button/go-to-monitoring-button.component';
import PhasesAccordion from '../../phases-accordion/phases-accordion.component';
import GBSearchElementBox from '../../ui/gb-search-element-box/gb-search-element-box';
import GBTenantLogo from '../../ui/gb-tenant-logo/gb-tenant-logo.component';
import { FlexBoxFullWHRow } from '../layout.styles';
import { Drawer, DrawerContent, DrawerHeader } from './side-bar.styles';

const SideBar = ({
    opened,
    onChangeOpened,
}: {
    opened: boolean;
    onChangeOpened: (opened: boolean) => void;
}) => {
    const viewMode = useSelector(selectViewMode);

    const handleDrawerToggle = () => {
        onChangeOpened(!opened);
    };

    return (
        <Drawer variant="permanent" open={opened}>
            <DrawerHeader>
                <FlexBoxFullWHRow>
                    <GBTenantLogo />
                </FlexBoxFullWHRow>
                <IconButton onClick={handleDrawerToggle}>
                    {opened ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </DrawerHeader>

            <DrawerContent open={opened}>
                <GoToMonitoringButton />
                <Box my={1} />
                {viewMode === ViewMode.VIREO && (
                    <Fragment>
                        <PhasesAccordion />
                    </Fragment>
                )}
                <Box my={1} />
                <ActionsBar />
                <Box my={1} />
                <GBSearchElementBox />
                <Box my={2} />
                <ElementsList />
            </DrawerContent>
        </Drawer>
    );
};

export default SideBar;
