import { all, call, put, takeLatest } from 'typed-redux-saga/macro';
import { MONITORING_SIMPLE_REPORTS_URL } from '../../constants/api.constants';
import { apiRequestWrapper } from '../../utils/api/api.utils';
import { setIsError } from '../error/error.action';
import { setIsLoading } from '../loading/loading.action';
import { addSimpleReportFailed, AddSimpleReportStart, addSimpleReportSuccess, confirmSimpleReportFailed, ConfirmSimpleReportStart, confirmSimpleReportSuccess, refuseSimpleReportFailed, RefuseSimpleReportStart, refuseSimpleReportSuccess } from './simple-report.action';
import { SIMPLE_REPORT_ACTION_TYPES } from './simple-report.types';


export function* addSimpleReport({
    payload: { simpleReport, onComplete },
}: AddSimpleReportStart) {
    yield* put(setIsLoading(true));
    try {
        yield* call(
            apiRequestWrapper, MONITORING_SIMPLE_REPORTS_URL, 'POST', simpleReport, true
        );
        yield* put(addSimpleReportSuccess());
        onComplete();
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(addSimpleReportFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* refuseSimpleReport({ payload: { id, refusal_notes, onComplete } }: RefuseSimpleReportStart) {
    yield* put(setIsLoading(true));
    try {
        const response = yield* call(
            apiRequestWrapper, `${MONITORING_SIMPLE_REPORTS_URL}${id}/refuse/`, 'PUT', { refusal_notes }
        );

        if (response) {
            yield* put(refuseSimpleReportSuccess());
            onComplete();
        }
        yield* put(setIsLoading(false));
        onComplete();
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(refuseSimpleReportFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* confirmSimpleReport({ payload: { simpleReport, onComplete } }: ConfirmSimpleReportStart) {
    yield* put(setIsLoading(true));
    try {
        const response = yield* call(
            apiRequestWrapper, `${MONITORING_SIMPLE_REPORTS_URL}${simpleReport.simple_report_id}/create_report/`, 'PUT', simpleReport
        );

        if (response) {
            yield* put(confirmSimpleReportSuccess());
            onComplete();
        }
        yield* put(setIsLoading(false));
        onComplete();
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(confirmSimpleReportFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onAddSimpleReportStart() {
    yield* takeLatest(SIMPLE_REPORT_ACTION_TYPES.ADD_SIMPLE_REPORT_START, addSimpleReport);
}

export function* onRefuseSimpleReportStart() {
    yield* takeLatest(SIMPLE_REPORT_ACTION_TYPES.REFUSE_SIMPLE_REPORT_START, refuseSimpleReport);
}

export function* onConfirmSimpleReportStart() {
    yield* takeLatest(SIMPLE_REPORT_ACTION_TYPES.CONFIRM_SIMPLE_REPORT_START, confirmSimpleReport);
}

export function* simpleReportSaga() {
    yield* all([
        call(onAddSimpleReportStart),
        call(onRefuseSimpleReportStart),
        call(onConfirmSimpleReportStart),
    ]);
}
