import { Action, ActionWithPayload, createAction, withMatcher } from '../../utils/reducer/reducer.utils';
import { FILES_ACTION_TYPES } from './files.types';

export type FilesPayload = {
    id: number,
    class_name: string,
    photos?: { file: File; }[],
    attachments?: { file: File; }[],
    onComplete: (response: any) => void;
};

export type AddFilesStart = ActionWithPayload<
    FILES_ACTION_TYPES.ADD_FILES_START, FilesPayload
>;

export type AddFilesSuccess = Action<
    FILES_ACTION_TYPES.ADD_FILES_SUCCESS
>;

export type AddFilesFailed = ActionWithPayload<
    FILES_ACTION_TYPES.ADD_FILES_FAILED,
    string
>;

export type DeleteFileStart = ActionWithPayload<
    FILES_ACTION_TYPES.DELETE_FILE_START, {
        id: number; entityId: number,
        entityClassName: string,
        onComplete: () => void;
    }
>;

export type DeleteFileSuccess = ActionWithPayload<
    FILES_ACTION_TYPES.DELETE_FILE_SUCCESS,
    { fileDeleted: number; }
>;

export type DeleteFileFailed = ActionWithPayload<
    FILES_ACTION_TYPES.DELETE_FILE_FAILED,
    string
>;

export const addFilesStart = withMatcher(
    (filesPayload: FilesPayload): AddFilesStart =>
        createAction(FILES_ACTION_TYPES.ADD_FILES_START, filesPayload)
);

export const addFilesSuccess = withMatcher(
    (): AddFilesSuccess =>
        createAction(FILES_ACTION_TYPES.ADD_FILES_SUCCESS)
);

export const addFilesFailed = withMatcher(
    (error: string): AddFilesFailed =>
        createAction(FILES_ACTION_TYPES.ADD_FILES_FAILED, error)
);

export const deleteFileStart = withMatcher(
    (id: number, entityId: number, entityClassName: string, onComplete: () => void): DeleteFileStart =>
        createAction(FILES_ACTION_TYPES.DELETE_FILE_START, { id, entityId, entityClassName, onComplete })
);

export const deleteFileSuccess = withMatcher(
    (fileDeleted: number): DeleteFileSuccess =>
        createAction(FILES_ACTION_TYPES.DELETE_FILE_SUCCESS, { fileDeleted })
);

export const deleteFileFailed = withMatcher(
    (error: string): DeleteFileFailed =>
        createAction(FILES_ACTION_TYPES.DELETE_FILE_FAILED, error)
);