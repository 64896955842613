import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DatePickerProps } from '@mui/lab';

const GBDatePicker = (props: DatePickerProps<any>) => {
    return (
        <DatePicker
            sx={{
                'input:disabled': {
                    WebkitTextFillColor: (theme) =>
                        `${theme.colors.alpha.black[100]}`,
                },
            }}
            slotProps={{
                textField: {
                    disabled: true,
                },
            }}
            format="DD/MM/YYYY"
            {...props}
        />
    );
};

export default GBDatePicker;
