import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useSelector } from 'react-redux';
import { selectSeverities } from '../../../store/monitoring/monitoring.selector';
import Typography from '@mui/material/Typography';

const GBSeverityRadio = ({
    value,
    variantFilter,
    onChange,
}: {
    value: any;
    variantFilter?: boolean;
    onChange: (newValue: any) => void;
}) => {
    const severities = useSelector(selectSeverities);
    const severityNotNullLabel = variantFilter
        ? 'Uno o più servizi non conformi con criticità'
        : 'Non conforme con criticità';
    const severityNullLabel = variantFilter ? 'Tutti i servizi ' : '';
    const severityNullWord = variantFilter ? 'conformi' : 'Conforme';

    return (
        <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            value={value}
            name="radio-buttons-group"
        >
            {variantFilter && (
                <FormControlLabel
                    key={-2}
                    value="all"
                    control={
                        <Radio
                            onChange={() => {
                                onChange(-2);
                            }}
                        />
                    }
                    label="Nessun filtro sulla conformità"
                />
            )}
            {[...severities]
                .sort((a, b) => b.sort_order - a.sort_order)
                .map((s) => (
                    <FormControlLabel
                        key={s.id}
                        value={s.id}
                        control={
                            <Radio
                                onChange={() => {
                                    onChange(s.id);
                                }}
                            />
                        }
                        label={
                            <Typography variant="body2">
                                {severityNotNullLabel}{' '}
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                        color: s.color,
                                    }}
                                >
                                    {s.name.toLocaleLowerCase()}
                                </span>
                            </Typography>
                        }
                    />
                ))}
            <FormControlLabel
                key={-1}
                value="null"
                control={
                    <Radio
                        onChange={() => {
                            onChange(null);
                        }}
                    />
                }
                label={
                    <Typography variant="body2">
                        {severityNullLabel}
                        <Typography
                            component="span"
                            color={'primary'}
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            {severityNullWord}
                        </Typography>
                    </Typography>
                }
            />
        </RadioGroup>
    );
};

export default GBSeverityRadio;
