import { NotificationsActive } from '@mui/icons-material';
import {
    Badge,
    BadgeProps,
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    Popover,
    Typography,
    styled,
} from '@mui/material';
import moment from 'moment';
import { Fragment, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { markNotificationsAsReadStart } from '../../../store/monitoring/monitoring.action';
import { selectNotifications } from '../../../store/monitoring/monitoring.selector';
import { FlexBoxFullWColumn } from '../../layout/layout.styles';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 5,
        padding: '0 4px',
    },
}));

const GBNotificationsBox = () => {
    const notifications = useSelector(selectNotifications);

    const dispatch = useDispatch();

    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    return (
        <Fragment>
            <IconButton
                ref={ref}
                sx={{ paddingY: 0, width: '76px', height: '100%' }}
                onClick={() => {
                    setOpen(true);
                }}
            >
                {notifications.length > 0 && (
                    <StyledBadge
                        badgeContent={
                            notifications.filter((n) => !n.read).length
                        }
                        color="error"
                    >
                        <NotificationsActive
                            sx={{ width: 40, height: 40 }}
                            color="primary"
                        />
                    </StyledBadge>
                )}
                {notifications.length === 0 && (
                    <NotificationsActive
                        sx={{ width: 40, height: 40 }}
                        color="primary"
                    />
                )}
            </IconButton>
            <Popover
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    maxHeight: '50vh',
                }}
            >
                <Box sx={{ padding: 0.5, maxWidth: 500 }}>
                    {notifications.length > 0 && (
                        <List>
                            {notifications.map((notification, index) => (
                                <Fragment key={notification.id}>
                                    <ListItem
                                        key={notification.id}
                                        disablePadding
                                        data-index={notification.id}
                                        data-read={notification.read}
                                        onClick={() => {
                                            dispatch(
                                                markNotificationsAsReadStart([
                                                    notification.id,
                                                ])
                                            );
                                        }}
                                    >
                                        <ListItemButton
                                            selected={!notification.read}
                                        >
                                            <FlexBoxFullWColumn>
                                                <Typography>
                                                    {moment(
                                                        notification.create_date
                                                    ).format(
                                                        'DD/MM/YYYY HH:mm'
                                                    )}
                                                </Typography>
                                                <Typography variant="h4">
                                                    {notification.subject}
                                                </Typography>
                                                <Typography>
                                                    {notification.message}
                                                </Typography>
                                            </FlexBoxFullWColumn>
                                        </ListItemButton>
                                    </ListItem>
                                    {index < notifications.length - 1 && (
                                        <Divider sx={{ my: 1 }} />
                                    )}
                                </Fragment>
                            ))}
                        </List>
                    )}
                    {notifications.length === 0 && (
                        <Typography variant="h4" padding={1}>
                            Nessuna notifica
                        </Typography>
                    )}
                </Box>
            </Popover>
        </Fragment>
    );
};

export default GBNotificationsBox;
