import { MonitoringMatrix } from '../../models/monitoring';
import { themeColors } from '../../theme/greenbeat-theme';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import { FlexBoxFullWHColumn } from '../layout/layout.styles';

export default function MatricesButtonGroup({
    matrices,
    matrixSelected,
    noInteractions,
    onSelectMatrix,
}: {
    matrices: MonitoringMatrix[];
    matrixSelected: number;
    noInteractions?: boolean;
    onSelectMatrix: (matrixId: number) => void;
}) {
    return (
        <ButtonGroup
            disableRipple={noInteractions}
            fullWidth
            sx={{
                height: '100%',
                overflowX: 'auto',
                boxShadow: [
                    '0px 9px 16px rgba(159, 162, 191, .18)',
                    '0px 4px 10px rgba(0, 0, 0, 0.20)',
                ],
            }}
        >
            {matrices.map((matrix) => (
                <Tooltip title={matrix.name} key={matrix.id}>
                    <Button
                        sx={{
                            minWidth: '10%!important',
                            width: '10%!important',
                            border: 'none',
                            backgroundColor:
                                matrixSelected === matrix.id
                                    ? themeColors.bodyBg
                                    : 'transparent',
                            ':hover': {
                                border: 'none',
                                backgroundColor: noInteractions
                                    ? 'transparent'
                                    : themeColors.bodyBg,
                                cursor: 'default',
                            },
                        }}
                        onClick={() => onSelectMatrix(matrix.id)}
                    >
                        <FlexBoxFullWHColumn sx={{ position: 'relative' }}>
                            <img
                                style={{
                                    position: 'absolute',
                                    left: '50%',
                                    top: '20%',
                                    transform: 'translateX(-50%)',
                                    height: '60%',
                                }}
                                src={
                                    'data:image/svg+xml;charset=utf-8,' +
                                    encodeURIComponent(
                                        matrix.icon.replace(
                                            /fill="[^"]*"/g,
                                            `fill="${
                                                matrix.color ??
                                                themeColors.primary
                                            }"`
                                        )
                                    )
                                }
                                alt={'Icona ' + matrix.name}
                            />
                        </FlexBoxFullWHColumn>
                    </Button>
                </Tooltip>
            ))}
        </ButtonGroup>
    );
}
