import { Add, Delete, Download } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Attachment } from '../../../models/shared';
import { openDialog } from '../../../store/dialog/dialog.action';
import { DIALOG_TYPES } from '../../../store/dialog/dialog.reducer';
import { FlexBoxFullWHColumnBetween } from '../../layout/layout.styles';
import GBBorderTitleBox from '../gb-border-title-box/gb-border-title-box.component';
import GBCardHeader from '../gb-card-header/gb-card-header.component';
import GBCard from '../gb-card/gb-card.component';
import GBTable from '../gb-table/gb-table.component';

const GBAttachments = ({
    entityId,
    entityClassName,
    entityName,
    attachments,
    readonly,
    boxedVersion,
    onAttachmentsAdded,
    onAttachmentDeleted,
}: {
    entityId: number;
    entityClassName: string;
    entityName?: string;
    attachments: Attachment[];
    readonly?: boolean;
    boxedVersion?: boolean;
    onAttachmentsAdded: (response: any) => void;
    onAttachmentDeleted: (attachmentId: number) => void;
}) => {
    const dispatch = useDispatch();

    const handleAddAttachmentsButtonClick = () => {
        dispatch(
            openDialog(
                DIALOG_TYPES.ADD_ATTACHMENTS,
                {
                    id: entityId,
                    className: entityClassName,
                },
                'sm',
                (response) => onAttachmentsAdded(response)
            )
        );
    };

    const handleDownloadButtonClick = (url: string) => {
        window.open(
            process.env.REACT_APP_BASE_URL + url,
            '_blank',
            'noopener,noreferrer'
        );
    };

    const handleDeleteButtonClick = (attachment: Attachment) => {
        dispatch(
            openDialog(
                DIALOG_TYPES.CONFIRM_CANCELLATION,
                {
                    id: attachment.id,
                    name: attachment.file_name,
                    entityId: entityId,
                    entityClassName: entityClassName,
                },
                'sm',
                () => onAttachmentDeleted(attachment.id)
            )
        );
    };

    const columns: GridColDef[] = [
        { field: 'file_name', headerName: 'File', flex: 2 },
        {
            field: 'date',
            headerName: 'Data',
            flex: 1,
        },
        {
            field: 'file',
            headerName: 'Azioni',
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => params.row,
            renderCell: (params) => (
                <Fragment>
                    <IconButton
                        color="primary"
                        onClick={() =>
                            handleDownloadButtonClick(params.value.file)
                        }
                    >
                        <Download />
                    </IconButton>
                    {!readonly && (
                        <IconButton
                            onClick={() =>
                                handleDeleteButtonClick(params.value)
                            }
                            color="error"
                        >
                            <Delete />
                        </IconButton>
                    )}
                </Fragment>
            ),
        },
    ];

    return (
        <Fragment>
            {boxedVersion ? (
                <GBBorderTitleBox
                    title="Documentazione"
                    action={
                        !readonly && (
                            <IconButton
                                onClick={handleAddAttachmentsButtonClick}
                                sx={{ backgroundColor: 'white' }}
                            >
                                <Add color="primary" />
                            </IconButton>
                        )
                    }
                >
                    <FlexBoxFullWHColumnBetween>
                        <Box flexGrow={1} overflow={'auto'} paddingTop={1}>
                            <GBTable
                                rows={attachments}
                                columns={columns}
                                rowSelectedId={undefined}
                                onRowSelected={() => {}}
                                noRowSelection
                            />
                        </Box>
                    </FlexBoxFullWHColumnBetween>
                </GBBorderTitleBox>
            ) : (
                <GBCard>
                    <FlexBoxFullWHColumnBetween>
                        <GBCardHeader>
                            <Typography variant="h3" py={0}>
                                Documentazione {entityName}
                            </Typography>
                            {!readonly && (
                                <IconButton
                                    onClick={handleAddAttachmentsButtonClick}
                                >
                                    <Add color="primary" />
                                </IconButton>
                            )}
                        </GBCardHeader>
                        <Box flexGrow={1} overflow={'auto'} paddingTop={1}>
                            <GBTable
                                rows={attachments}
                                columns={columns}
                                rowSelectedId={undefined}
                                onRowSelected={() => {}}
                                noRowSelection
                            />
                        </Box>
                    </FlexBoxFullWHColumnBetween>
                </GBCard>
            )}
        </Fragment>
    );
};

export default GBAttachments;
