import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import styled from '@mui/material/styles/styled';

export const ActionsBar = styled(Box)(() => ({
    position: 'absolute',
    bottom: '30px',
    right: '30px',
    margin: '0 auto',
    zIndex: 'modal',
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
}));

export const ActionBox = styled(IconButton)(({ theme }) => ({
    width: '43px',
    height: '40px',
    borderRadius: '10px',
    mr: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.alpha.white[100],
    '&:hover': {
        backgroundColor: theme.colors.alpha.white[100],
    },
}));

export const ActionBoxZommIn = styled(ActionBox)(({ theme }) => ({
    borderBottom: '1px solid #D8D8D8',
    borderColor: '#D8D8D8',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
}));

export const ActionBoxZommOut = styled(ActionBox)(() => ({
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
}));
