import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LayoutOperator from '../../components/layout/layout-operator.component';
import LayoutReporter from '../../components/layout/layout-reporter.component';
import LayoutTechnician from '../../components/layout/layout-technician.component';
import Layout from '../../components/layout/layout.component';
import { selectUserProfile } from '../../store/auth/auth.selector';
import { getTenantStart } from '../../store/tenant/tenant.action';
import {
    isGreenbeatAdministrator,
    isGreenbeatOperator,
    isGreenbeatReporter,
    isGreenbeatTechnician,
} from '../../utils/permissions/permissions.utils';

const Navigation = () => {
    const userProfile = useSelector(selectUserProfile);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTenantStart());
    }, [dispatch]);

    return (
        <Fragment>
            {isGreenbeatAdministrator(userProfile!) && <Layout />}
            {isGreenbeatReporter(userProfile!) && <LayoutReporter />}
            {isGreenbeatOperator(userProfile!) && <LayoutOperator />}
            {isGreenbeatTechnician(userProfile!) && <LayoutTechnician />}
        </Fragment>
    );
};

export default Navigation;
