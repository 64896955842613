import Typography from '@mui/material/Typography';
import { FlexBoxFullWRowBetween } from '../layout/layout.styles';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { MONITORING_PAGE_ROUTE } from '../../constants/routes.constants';

const GoToMonitoringButton = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(MONITORING_PAGE_ROUTE);
    };

    return (
        <FlexBoxFullWRowBetween
            alignItems={'center'}
            px={1.5}
            py={1.5}
            sx={{
                backgroundColor: '#EAEAEA6F',
                border: '1px solid #D9D9D9',
                borderRadius: '2px',
                cursor: 'pointer',
            }}
            onClick={handleButtonClick}
        >
            <Typography>Monitoraggio</Typography>
            <ChevronRight />
        </FlexBoxFullWRowBetween>
    );
};

export default GoToMonitoringButton;
