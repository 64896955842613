import { AnyAction } from '@reduxjs/toolkit';
import { getParamsFailed, getParamsSuccess } from './params.action';

export type Param = {
    id: number;
    module: string;
    key: string;
    description: string;
    value: string;
    visible: boolean;
};

export type ParamsState = {
    readonly params: Param[] | null;
    readonly error: string | null;
};

const INITIAL_STATE: ParamsState = {
    params: null,
    error: null,
};

export const paramsReducer = (state = INITIAL_STATE, action: AnyAction) => {

    if (getParamsSuccess.match(action)) {
        return { ...state, params: action.payload.params, error: null };
    }

    if (
        getParamsFailed.match(action)
    ) {
        return { ...state, error: action.payload };
    }

    return state;
};