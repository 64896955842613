import {
    Avatar,
    Button,
    List,
    ListItemAvatar,
    ListItemButton,
    Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SIZE_ICONS } from '../../../../../constants/theme.constants';
import { ElementPayload } from '../../../../../models/monitoring';
import { setIsError } from '../../../../../store/error/error.action';
import { selectMonitoringMatrices } from '../../../../../store/monitoring/monitoring.selector';
import GBDialogActions from '../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../ui/gb-dialog-title/gb-dialog-title.component';
import GBSvgImage from '../../../../ui/gb-svg-image/gb-svg-image.component';

const DialogAddElementMatrixStep = ({
    matrixId,
    onNext,
}: {
    matrixId: number | undefined;
    onNext: (properties: Partial<ElementPayload>) => void;
}) => {
    const dispatch = useDispatch();

    const matrices = useSelector(selectMonitoringMatrices);

    const [matrixSelectedId, setMatrixSelectedId] = useState<
        number | undefined
    >(matrixId);

    const handleMatrixClickButton = (id: number) => {
        setMatrixSelectedId(id);
    };

    const handleNextButtonClick = () => {
        if (!matrixSelectedId) {
            dispatch(setIsError(true, 'Selezionare una matrice'));
            return;
        }
        onNext({ matrixId: matrixSelectedId });
    };

    return (
        <Fragment>
            <GBDialogTitle title="SELEZIONA MATRICE" />
            <GBDialogContent>
                <List>
                    {matrices.map((o: any) => (
                        <ListItemButton
                            key={o.id}
                            selected={matrixSelectedId === o.id}
                            onClick={() => handleMatrixClickButton(o.id)}
                        >
                            <ListItemAvatar
                                sx={{
                                    marginRight: 0.5,
                                }}
                            >
                                <Avatar
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.colors.primary.main,
                                    }}
                                >
                                    <GBSvgImage
                                        height={SIZE_ICONS.services.wizard.h}
                                        width={SIZE_ICONS.services.wizard.w}
                                        svgString={o.icon}
                                        alt={'Icon of ' + o.name}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <Typography>{o.name}</Typography>
                        </ListItemButton>
                    ))}
                </List>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handleNextButtonClick}>AVANTI</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogAddElementMatrixStep;
