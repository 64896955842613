export enum ELEMENT_ACTION_TYPES {
    GET_ELEMENT_START = 'element/GET_ELEMENT_START',
    GET_ELEMENT_SUCCESS = 'element/GET_ELEMENT_SUCCESS',
    GET_ELEMENT_FAILED = 'element/GET_ELEMENT_FAILED',
    ADD_ELEMENT_SERVICE_START = 'element/ADD_ELEMENT_SERVICE_START',
    ADD_ELEMENT_SERVICE_SUCCESS = 'element/ADD_ELEMENT_SERVICE_SUCCESS',
    ADD_ELEMENT_SERVICE_FAILED = 'element/ADD_ELEMENT_SERVICE_FAILED',
    GET_ELEMENT_SERVICE_REPORTS_START = 'element/GET_ELEMENT_SERVICE_REPORTS_START',
    GET_ELEMENT_SERVICE_REPORTS_SUCCESS = 'element/GET_ELEMENT_SERVICE_REPORTS_SUCCESS',
    GET_ELEMENT_SERVICE_REPORTS_FAILED = 'element/GET_ELEMENT_SERVICE_REPORTS_FAILED',
    GET_NEXT_ELEMENT_NAME_BY_CATEGORY_START = 'element/GET_NEXT_ELEMENT_NAME_BY_CATEGORY_START',
    GET_NEXT_ELEMENT_NAME_BY_CATEGORY_SUCCESS = 'element/GET_NEXT_ELEMENT_NAME_BY_CATEGORY_SUCCESS',
    GET_NEXT_ELEMENT_NAME_BY_CATEGORY_FAILED = 'element/GET_NEXT_ELEMENT_NAME_BY_CATEGORY_FAILED',
    GET_NEXT_ELEMENT_NAME_BY_MATRIX_START = 'element/GET_NEXT_ELEMENT_NAME_BY_MATRIX_START',
    GET_NEXT_ELEMENT_NAME_BY_MATRIX_SUCCESS = 'element/GET_NEXT_ELEMENT_NAME_BY_MATRIX_SUCCESS',
    GET_NEXT_ELEMENT_NAME_BY_MATRIX_FAILED = 'element/GET_NEXT_ELEMENT_NAME_BY_MATRIX_FAILED',
    SAVE_NEW_ELEMENT_START = 'element/SAVE_NEW_ELEMENT_START',
    SAVE_NEW_ELEMENT_SUCCESS = 'element/SAVE_NEW_ELEMENT_SUCCESS',
    SAVE_NEW_ELEMENT_FAILED = 'element/SAVE_NEW_ELEMENT_FAILED',
    GET_TECHNICIAN_ELEMENTS_START = 'element/GET_TECHNICIAN_ELEMENTS_START',
    GET_TECHNICIAN_ELEMENTS_SUCCESS = 'element/GET_TECHNICIAN_ELEMENTS_SUCCESS',
    GET_TECHNICIAN_ELEMENTS_FAILED = 'element/GET_TECHNICIAN_ELEMENTS_FAILED',
    UPDATE_ELEMENT_SERVICES_START = 'element/UPDATE_ELEMENT_SERVICES_START',
    UPDATE_ELEMENT_SERVICES_SUCCESS = 'element/UPDATE_ELEMENT_SERVICES_SUCCESS',
    UPDATE_ELEMENT_SERVICES_FAILED = 'element/UPDATE_ELEMENT_SERVICES_FAILED',
}