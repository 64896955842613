import { Close } from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    Fab,
    Grid,
    InputLabel,
    styled,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
    CloseReportPayload,
    MonitoringReport,
} from '../../../models/monitoring';
import { closeDialog } from '../../../store/dialog/dialog.action';
import { setIsError } from '../../../store/error/error.action';
import { selectViewMode } from '../../../store/filters/filters.selector';
import { getMonitoringStart } from '../../../store/monitoring/monitoring.action';
import {
    closeReportStart,
    getReportStart,
} from '../../../store/report/report.action';
import {
    checkExtensionFile,
    checkExtensionImage,
    checkFileSize,
    resizeImage,
} from '../../../utils/file/file.utils';
import { FlexBoxFullWHColumn } from '../../layout/layout.styles';
import GBDialogActions from '../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../ui/gb-dialog-title/gb-dialog-title.component';
import GBDialog from '../../ui/gb-dialog/gb-dialog.component';
import GBGalleryForm from '../../ui/gb-gallery-form/gb-gallery-form.component';
import GBTextArea from '../../ui/gb-text-area/gb-text-area.component';

const DeleteAttachmentButton = styled(Fab)(
    ({ theme }) => `
    & {
        width: 24px;
        min-height: 24px;
        height: 24px;
    }
    & svg{
        font-size: 20px;
        color: white;
    }
`
);

const DialogCloseReport = ({
    inputData,
    onSaved,
}: {
    inputData: any;
    onSaved: () => void;
}) => {
    const dispatch = useDispatch();

    const viewMode = useSelector(selectViewMode);

    const [report, setReport] = useState<MonitoringReport>();
    const [attachments, setAttachments] = useState<any[]>([]);
    const [images, setImages] = useState<any[]>([]);
    const [nextId, setNextId] = useState<number>(0);
    const [dragging, setDragging] = useState(false);
    const [interventionNotes, setInterventionNotes] = useState<string | null>(
        null
    );
    const [closingNotes, setClosingNotes] = useState<string | null>(null);
    const [interventionDate, setInterventionDate] = useState<string | null>(
        null
    );

    useEffect(() => {
        dispatch(
            getReportStart(inputData.reportId, (r) => {
                setReport(r);
                setImages(r.images ?? []);
                setAttachments(r.attachments ?? []);
                setInterventionDate(r.intervention_date);
            })
        );
    }, [inputData.reportId, dispatch]);

    const appendAttachment = (files: any) => {
        let index = nextId;
        let reportAttachments: any = [];
        for (let f of files) {
            if (!checkExtensionFile(f.name)) {
                dispatch(setIsError(true, 'Tipo file non ammesso: ' + f.name));
                return;
            }
            if (!checkFileSize(f)) {
                dispatch(setIsError(true, 'File troppo grande: ' + f.name));
                return;
            }
            reportAttachments.push({
                file: f,
            });
            index++;
        }

        setNextId(index);
        let tmp = attachments;
        tmp = tmp.concat(reportAttachments);
        setAttachments(tmp);
    };

    const handleSaveButtonClick = () => {
        if (!report || (report.is_intervention_external && !interventionDate)) {
            dispatch(setIsError(true, 'Dati insufficienti per il salvataggio'));
            return;
        }

        const payload: CloseReportPayload = {
            report_id: report.id,
            severity_id: null,
            closing_notes: closingNotes,
            attachments: attachments,
            images: images,
        };

        if (report.is_intervention_external) {
            payload.intervention_notes = interventionNotes;
            payload.intervention_date = interventionDate;
        }

        dispatch(
            closeReportStart(payload, () => {
                dispatch(getMonitoringStart(viewMode));
                dispatch(closeDialog());
                onSaved();
            })
        );
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        setDragging(false);
        appendAttachment(e.dataTransfer.files);
    };

    const handleFileChange = (event: any) => {
        appendAttachment(event.target.files);
        event.target.value = '';
    };

    const handleInterventionNotesChange = (
        event: ChangeEvent<HTMLTextAreaElement>
    ) => {
        setInterventionNotes(event.target.value);
    };

    const handleClosingNotesChange = (
        event: ChangeEvent<HTMLTextAreaElement>
    ) => {
        setClosingNotes(event.target.value);
    };

    const handleImagesAdd = async (event: ChangeEvent<HTMLInputElement>) => {
        let imagesToAddTmp = [];
        if (!event.target.files) return;
        for (let f of event.target.files) {
            if (!checkExtensionImage(f.name)) {
                dispatch(
                    setIsError(
                        true,
                        'Estensione immagine non ammessa: ' + f.name
                    )
                );
                return;
            }
            const imageResized = await resizeImage(f);
            imagesToAddTmp.push({
                id: uuidv4(),
                file: imageResized,
                imageUrl: URL.createObjectURL(f),
            });
        }
        setImages([...images, ...imagesToAddTmp]);
        event.target.value = '';
    };

    const handleImageDelete = (imageId: string) => {
        let tmp: any[] = [];
        tmp = images.filter((i) => i.id !== imageId);
        setImages(tmp);
    };

    return (
        <GBDialog>
            <GBDialogTitle title="CHIUDI SEGNALAZIONE" />
            <GBDialogContent height="100vh">
                <FlexBoxFullWHColumn>
                    {report && (
                        <Grid
                            height={1}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="stretch"
                            columnSpacing={2}
                        >
                            <Grid
                                item
                                xs={6}
                                display={'flex'}
                                flexDirection={'column'}
                                gap={2}
                            >
                                <Box
                                    width={1}
                                    sx={{
                                        border: '1px solid rgba(75, 75, 75, 0.5);',
                                        borderRadius: '10px',
                                        padding: '16.5px 14px',
                                        position: 'relative',
                                    }}
                                >
                                    <InputLabel
                                        sx={{
                                            position: 'absolute',
                                            top: -10,
                                            left: 7,
                                            fontSize: '0.75em',
                                            paddingX: '5px',
                                            backgroundColor: 'white',
                                            color: 'rgba(75, 75, 75, 0.5)',
                                        }}
                                    >
                                        Servizio
                                    </InputLabel>
                                    {report.element_service.service.name}
                                </Box>
                                <Box
                                    width={1}
                                    flexGrow={1}
                                    sx={{
                                        border: '1px solid rgba(75, 75, 75, 0.5);',
                                        borderRadius: '10px',
                                        padding: '16.5px 14px',
                                        position: 'relative',
                                    }}
                                >
                                    <InputLabel
                                        sx={{
                                            position: 'absolute',
                                            top: -10,
                                            left: 7,
                                            fontSize: '0.75em',
                                            paddingX: '5px',
                                            backgroundColor: 'white',
                                            color: 'rgba(75, 75, 75, 0.5)',
                                        }}
                                    >
                                        Aggiungi Allegati
                                    </InputLabel>
                                    <Grid container alignItems="center">
                                        <Grid
                                            item
                                            xs={4}
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <input
                                                    type="file"
                                                    //accept="image/*"
                                                    onChange={handleFileChange}
                                                    style={{
                                                        display: 'none',
                                                    }}
                                                    id="file-upload-attachments"
                                                    multiple
                                                />
                                                <label
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    htmlFor="file-upload-attachments"
                                                >
                                                    <Button
                                                        variant="contained"
                                                        component="span"
                                                        sx={{
                                                            height: '52px',
                                                            width: 1,
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                        }}
                                                    >
                                                        Seleziona i file
                                                    </Button>
                                                </label>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            Oppure
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <Card
                                                className={`file-upload ${
                                                    dragging ? 'dragging' : ''
                                                }`}
                                                onDragOver={handleDragOver}
                                                onDragLeave={handleDragLeave}
                                                onDrop={handleDrop}
                                                sx={{
                                                    border: '1px solid',
                                                    borderColor: (theme) =>
                                                        theme.palette.grey[300],
                                                    backgroundColor: (theme) =>
                                                        theme.palette.grey[100],
                                                    width: '100%',
                                                    height: '52px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                {'Trascina qui i file'}
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    {attachments.length > 0 && (
                                        <Box
                                            sx={{
                                                paddingTop: '20px',
                                                paddingBottom: '20px',
                                            }}
                                        >
                                            {'Files caricati (' +
                                                attachments.length +
                                                '):'}
                                        </Box>
                                    )}
                                    <Box sx={{ width: '100%' }}>
                                        {attachments.map(
                                            (o: any, index: any) => (
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        paddingTop: '5px',
                                                        marginLeft: '10px',
                                                    }}
                                                >
                                                    {!o.file_name && (
                                                        <DeleteAttachmentButton
                                                            color="primary"
                                                            onClick={() => {
                                                                let tmp: any[] =
                                                                    [];
                                                                tmp =
                                                                    attachments.filter(
                                                                        (
                                                                            a,
                                                                            i
                                                                        ) =>
                                                                            i !==
                                                                            index
                                                                    );
                                                                setAttachments(
                                                                    tmp
                                                                );
                                                            }}
                                                            sx={{
                                                                marginRight:
                                                                    '20px',
                                                                color: (
                                                                    theme
                                                                ) =>
                                                                    theme
                                                                        .palette
                                                                        .grey[500],
                                                            }}
                                                        >
                                                            <Close />
                                                        </DeleteAttachmentButton>
                                                    )}
                                                    {o.file_name ?? o.file.name}
                                                </Box>
                                            )
                                        )}
                                    </Box>
                                </Box>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={2}
                                    height={200}
                                >
                                    <Box width={0.5} height={1}>
                                        <GBGalleryForm
                                            images={images}
                                            onImagesAdd={handleImagesAdd}
                                            onImageDelete={handleImageDelete}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                display={'flex'}
                                flexDirection={'column'}
                                gap={2}
                            >
                                <Box
                                    width={1}
                                    sx={{
                                        border: '1px solid rgba(75, 75, 75, 0.5);',
                                        borderRadius: '10px',
                                        padding: '16.5px 14px',
                                        position: 'relative',
                                    }}
                                >
                                    <InputLabel
                                        sx={{
                                            position: 'absolute',
                                            top: -10,
                                            left: 7,
                                            fontSize: '0.75em',
                                            paddingX: '5px',
                                            backgroundColor: 'white',
                                            color: 'rgba(75, 75, 75, 0.5)',
                                        }}
                                    >
                                        Nome Segnalazione
                                    </InputLabel>
                                    {report.name}
                                </Box>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={2}
                                >
                                    <Box width={0.5} height={1}>
                                        <Box
                                            width={1}
                                            sx={{
                                                border: '1px solid rgba(75, 75, 75, 0.5);',
                                                borderRadius: '10px',
                                                padding: '16.5px 14px',
                                                position: 'relative',
                                            }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    position: 'absolute',
                                                    top: -10,
                                                    left: 7,
                                                    fontSize: '0.75em',
                                                    paddingX: '5px',
                                                    backgroundColor: 'white',
                                                    color: 'rgba(75, 75, 75, 0.5)',
                                                }}
                                            >
                                                Data Segnalazione
                                            </InputLabel>
                                            {report.reporting_date
                                                ? moment(
                                                      report.reporting_date
                                                  ).format('DD/MM/YYYY')
                                                : ''}
                                        </Box>
                                    </Box>
                                    <Box width={0.5} height={1}>
                                        <Box
                                            width={1}
                                            sx={{
                                                border: '1px solid rgba(75, 75, 75, 0.5);',
                                                borderRadius: '10px',
                                                padding: '16.5px 14px',
                                                position: 'relative',
                                            }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    position: 'absolute',
                                                    top: -10,
                                                    left: 7,
                                                    fontSize: '0.75em',
                                                    paddingX: '5px',
                                                    backgroundColor: 'white',
                                                    color: 'rgba(75, 75, 75, 0.5)',
                                                }}
                                            >
                                                Data Assegnazione
                                            </InputLabel>
                                            {report.assignment_date
                                                ? moment(
                                                      report.assignment_date
                                                  ).format('DD/MM/YYYY')
                                                : ''}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={2}
                                >
                                    <Box width={0.5} height={1}>
                                        {!report.is_intervention_external && (
                                            <Box
                                                width={1}
                                                sx={{
                                                    border: '1px solid rgba(75, 75, 75, 0.5);',
                                                    borderRadius: '10px',
                                                    padding: '16.5px 14px',
                                                    position: 'relative',
                                                }}
                                            >
                                                <InputLabel
                                                    sx={{
                                                        position: 'absolute',
                                                        top: -10,
                                                        left: 7,
                                                        fontSize: '0.75em',
                                                        paddingX: '5px',
                                                        backgroundColor:
                                                            'white',
                                                        color: 'rgba(75, 75, 75, 0.5)',
                                                    }}
                                                >
                                                    Data Intervento
                                                </InputLabel>
                                                {report.intervention_date
                                                    ? moment(
                                                          report.intervention_date
                                                      ).format('DD/MM/YYYY')
                                                    : ''}
                                            </Box>
                                        )}
                                        {report.is_intervention_external && (
                                            <DatePicker
                                                label={'Data Intervento'}
                                                sx={{
                                                    'input:disabled': {
                                                        WebkitTextFillColor: (
                                                            theme
                                                        ) =>
                                                            `${theme.colors.alpha.black[100]}`,
                                                    },
                                                    width: '100%',
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        disabled: true,
                                                    },
                                                    actionBar: {
                                                        actions: ['clear'],
                                                    },
                                                }}
                                                format="DD/MM/YYYY"
                                                value={
                                                    interventionDate
                                                        ? moment(
                                                              interventionDate
                                                          )
                                                        : null
                                                }
                                                onChange={(newValue) => {
                                                    const interventionExpireDateString =
                                                        newValue
                                                            ? newValue?.format(
                                                                  'YYYY-MM-DD'
                                                              )
                                                            : null;
                                                    setInterventionDate(
                                                        interventionExpireDateString
                                                    );
                                                }}
                                            />
                                        )}
                                    </Box>
                                    <Box width={0.5} height={1}>
                                        <Box
                                            width={1}
                                            height={1}
                                            sx={{
                                                border: '1px solid rgba(75, 75, 75, 0.5);',
                                                borderRadius: '10px',
                                                padding: '16.5px 14px',
                                                position: 'relative',
                                            }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    position: 'absolute',
                                                    top: -10,
                                                    left: 7,
                                                    fontSize: '0.75em',
                                                    paddingX: '5px',
                                                    backgroundColor: 'white',
                                                    color: 'rgba(75, 75, 75, 0.5)',
                                                }}
                                            >
                                                Data Scadenza Intervento
                                            </InputLabel>
                                            {report.intervention_expire_date
                                                ? moment(
                                                      report.intervention_expire_date
                                                  ).format('DD/MM/YYYY')
                                                : ''}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={2}
                                >
                                    <Box width={0.5}>
                                        <GBTextArea
                                            disabled
                                            label="Note Segnalazione"
                                            multiline
                                            rows={6}
                                            defaultValue={
                                                report.reporting_notes === ''
                                                    ? 'Nessuna nota disponibile'
                                                    : report.reporting_notes
                                            }
                                        />
                                    </Box>
                                    <Box width={0.5}>
                                        <GBTextArea
                                            disabled
                                            label="Note Assegnazione"
                                            multiline
                                            rows={6}
                                            defaultValue={
                                                report.assignment_notes === ''
                                                    ? 'Nessuna nota disponibile'
                                                    : report.assignment_notes
                                            }
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={2}
                                >
                                    <Box width={0.5}>
                                        {!report.is_intervention_external && (
                                            <GBTextArea
                                                disabled
                                                label="Note Intervento"
                                                multiline
                                                rows={6}
                                                defaultValue={
                                                    report.intervention_notes ===
                                                    ''
                                                        ? 'Nessuna nota disponibile'
                                                        : report.intervention_notes
                                                }
                                            />
                                        )}
                                        {report.is_intervention_external && (
                                            <GBTextArea
                                                label="Note Intervento"
                                                multiline
                                                rows={6}
                                                defaultValue={interventionNotes}
                                                onChange={
                                                    handleInterventionNotesChange
                                                }
                                            />
                                        )}
                                    </Box>
                                    <Box width={0.5}>
                                        <GBTextArea
                                            label="Note Chiusura"
                                            multiline
                                            rows={6}
                                            defaultValue={closingNotes}
                                            onChange={handleClosingNotesChange}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </FlexBoxFullWHColumn>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handleSaveButtonClick}>TERMINA E SALVA</Button>
            </GBDialogActions>
        </GBDialog>
    );
};

export default DialogCloseReport;
