import { MonitoringCampaign } from '../../models/monitoring';
import { ActionWithPayload, createAction, withMatcher } from '../../utils/reducer/reducer.utils';
import { CAMPAIGN_ACTION_TYPES } from './campaign.types';


export type GetCampaignStart = ActionWithPayload<
    CAMPAIGN_ACTION_TYPES.GET_CAMPAIGN_START, {
        id: string;
        onComplete: (campaign: MonitoringCampaign) => void;
    }
>;

export type GetCampaignSuccess = ActionWithPayload<
    CAMPAIGN_ACTION_TYPES.GET_CAMPAIGN_SUCCESS,
    { campaign: MonitoringCampaign; }
>;

export type GetCampaignFailed = ActionWithPayload<
    CAMPAIGN_ACTION_TYPES.GET_CAMPAIGN_FAILED,
    string
>;

export const getCampaignStart = withMatcher(
    (id: string, onComplete: (campaign: MonitoringCampaign) => void): GetCampaignStart =>
        createAction(CAMPAIGN_ACTION_TYPES.GET_CAMPAIGN_START, { id, onComplete })
);

export const getCampaignSuccess = withMatcher(
    (campaign: MonitoringCampaign): GetCampaignSuccess =>
        createAction(CAMPAIGN_ACTION_TYPES.GET_CAMPAIGN_SUCCESS, { campaign })
);

export const getCampaignFailed = withMatcher(
    (error: string): GetCampaignFailed =>
        createAction(CAMPAIGN_ACTION_TYPES.GET_CAMPAIGN_FAILED, error)
);