import { Print } from '@mui/icons-material';
import {
    Box,
    Grid,
    IconButton,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment';
import {
    Monitoring,
    MonitoringElementService,
    MonitoringPeriodicInspectionSimple,
} from '../../../models/monitoring';
import { ENTITIES } from '../../../store/monitoring/monitoring.types';
import { BoxFullWH, FlexBoxFullWHColumn } from '../../layout/layout.styles';
import PeriodicInspectionDocument from '../../periodic-inspection-document/periodic-inspection-document.component';
import GBAttachments from '../../ui/gb-attachments/gb-attachments.components';
import GBCardHeader from '../../ui/gb-card-header/gb-card-header.component';
import GBCard from '../../ui/gb-card/gb-card.component';
import GBDialogContent from '../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../ui/gb-dialog-title/gb-dialog-title.component';
import GBDialog from '../../ui/gb-dialog/gb-dialog.component';
import GBGallery from '../../ui/gb-gallery/gb-gallery.component';
import GBTextArea from '../../ui/gb-text-area/gb-text-area.component';

const DialogPeriodicInspection = ({ inputData }: { inputData: any }) => {
    const {
        periodicInspection,
        service,
        tenant,
        monitoring,
    }: {
        periodicInspection: MonitoringPeriodicInspectionSimple;
        service: MonitoringElementService;
        tenant: any;
        monitoring: Monitoring;
    } = inputData;

    return (
        <GBDialog>
            <GBDialogTitle
                title={periodicInspection.name}
                moreActions={
                    <PDFDownloadLink
                        document={
                            <PeriodicInspectionDocument
                                element={service.element}
                                periodicInspection={periodicInspection}
                                service={service}
                                tenant={tenant}
                                monitoring={monitoring}
                            />
                        }
                        fileName="documento.pdf"
                    >
                        <IconButton
                            sx={{
                                color: (theme) => theme.palette.primary.main,
                            }}
                        >
                            <Print />
                        </IconButton>
                    </PDFDownloadLink>
                }
            />
            <GBDialogContent height="100vh">
                <Grid container spacing={3} sx={{ height: 1 }}>
                    <Grid
                        container
                        columnSpacing={3}
                        item
                        xs={12}
                        sx={{ height: 4 / 7 }}
                    >
                        <Grid item xs={7} sx={{ height: 1 }}>
                            <GBCard>
                                <FlexBoxFullWHColumn overflow={'hidden'}>
                                    <GBCardHeader>
                                        <Typography variant="h3">
                                            Checklist
                                        </Typography>
                                    </GBCardHeader>
                                    <BoxFullWH>
                                        <TableContainer
                                            sx={{
                                                maxHeight: 'calc(100% - 45px)',
                                            }}
                                        >
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="right">
                                                            Parametro
                                                        </TableCell>
                                                        <TableCell>
                                                            Valore
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {periodicInspection.checklist_values.map(
                                                        (cv) => (
                                                            <TableRow
                                                                key={cv.key}
                                                            >
                                                                <TableCell
                                                                    align="right"
                                                                    sx={{
                                                                        maxWidth:
                                                                            '200px',
                                                                    }}
                                                                >
                                                                    {
                                                                        service?.service.checklist.find(
                                                                            (
                                                                                cl
                                                                            ) =>
                                                                                cl.key ===
                                                                                cv.key
                                                                        )?.label
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {cv.value
                                                                        ? 'SI'
                                                                        : 'NO'}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </BoxFullWH>
                                </FlexBoxFullWHColumn>
                            </GBCard>
                        </Grid>
                        <Grid item xs={5} sx={{ height: 1 }}>
                            <GBAttachments
                                entityId={periodicInspection.id}
                                entityClassName={ENTITIES.PERIODIC_INSPECTIONS}
                                entityName={''}
                                attachments={periodicInspection.attachments}
                                onAttachmentsAdded={() => {}}
                                onAttachmentDeleted={() => {}}
                                readonly
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        columnSpacing={3}
                        item
                        xs={12}
                        sx={{ height: 3 / 7 }}
                    >
                        <Grid item xs={7} height={1}>
                            <GBCard>
                                <FlexBoxFullWHColumn p={1} overflow={'auto'}>
                                    <Grid
                                        container
                                        direction="row"
                                        columnSpacing={1}
                                        height={1}
                                    >
                                        <Grid item xs={6}>
                                            <Box
                                                display={'flex'}
                                                flexDirection={'column'}
                                                justifyContent={'space-between'}
                                                height={1}
                                                pb={1}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Nome Ispezione"
                                                    defaultValue={
                                                        periodicInspection.name
                                                    }
                                                    disabled
                                                    sx={{
                                                        'input:disabled': {
                                                            WebkitTextFillColor:
                                                                (theme) =>
                                                                    `${theme.colors.alpha.black[100]}`,
                                                        },
                                                        mb: 2,
                                                    }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    label="Esito Ispezione"
                                                    defaultValue={
                                                        periodicInspection.severity
                                                            ? `Non conforme con criticità ${periodicInspection.severity.name}`
                                                            : 'Conforme'
                                                    }
                                                    disabled
                                                    sx={{
                                                        'input:disabled': {
                                                            WebkitTextFillColor:
                                                                (theme) =>
                                                                    `${theme.colors.alpha.black[100]}`,
                                                        },
                                                        mb: 2,
                                                    }}
                                                />
                                                <GBTextArea
                                                    label="Note Ispezione"
                                                    multiline
                                                    rows={3}
                                                    maxRows={3}
                                                    defaultValue={
                                                        periodicInspection.notes
                                                    }
                                                    disabled
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box
                                                display={'flex'}
                                                flexDirection={'column'}
                                                justifyContent={'space-between'}
                                                height={1}
                                                pb={1}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Data Ispezione"
                                                    defaultValue={moment(
                                                        periodicInspection.create_date
                                                    )
                                                        .locale('it')
                                                        .format('DD/MM/YY')}
                                                    disabled
                                                    sx={{
                                                        'input:disabled': {
                                                            WebkitTextFillColor:
                                                                (theme) =>
                                                                    `${theme.colors.alpha.black[100]}`,
                                                        },
                                                        mb: 2,
                                                    }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    label="Responsabile Ispezione"
                                                    defaultValue={
                                                        periodicInspection
                                                            .create_user.email
                                                    }
                                                    disabled
                                                    sx={{
                                                        'input:disabled': {
                                                            WebkitTextFillColor:
                                                                (theme) =>
                                                                    `${theme.colors.alpha.black[100]}`,
                                                        },
                                                        mb: 2,
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        border: '1px solid rgba(75, 75, 75, 0.5);',
                                                        borderRadius: '10px',
                                                        padding: '16.5px 14px',
                                                        position: 'relative',
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    <InputLabel
                                                        sx={{
                                                            position:
                                                                'absolute',
                                                            top: -10,
                                                            left: 7,
                                                            fontSize: '0.75em',
                                                            paddingX: '5px',
                                                            backgroundColor:
                                                                'white',
                                                            color: 'rgba(75, 75, 75, 0.5)',
                                                        }}
                                                    >
                                                        Firma
                                                    </InputLabel>
                                                    {periodicInspection.signature && (
                                                        <img
                                                            style={{
                                                                objectFit:
                                                                    'contain',
                                                            }}
                                                            src={
                                                                periodicInspection.signature
                                                            }
                                                            alt="Signature"
                                                        />
                                                    )}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </FlexBoxFullWHColumn>
                            </GBCard>
                        </Grid>
                        <Grid item xs={5} height={1}>
                            <GBGallery
                                entityId={periodicInspection.id}
                                entityClassName={ENTITIES.PERIODIC_INSPECTIONS}
                                images={periodicInspection.images}
                                onImagesAdded={() => {}}
                                onImageDeleted={() => {}}
                                readonly
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </GBDialogContent>
        </GBDialog>
    );
};

export default DialogPeriodicInspection;
