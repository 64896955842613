import {
    Box,
    Button,
    List,
    ListItem,
    TextField,
    Typography,
} from '@mui/material';
import moment from 'moment';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    MonitoringReportShort,
    ReportPayload,
} from '../../../../../models/monitoring';
import { setIsError } from '../../../../../store/error/error.action';
import { selectSeverities } from '../../../../../store/monitoring/monitoring.selector';
import { themeColors } from '../../../../../theme/greenbeat-theme';
import {
    FlexBoxFullWHColumn,
    FlexBoxFullWRowBetween,
} from '../../../../layout/layout.styles';
import GBDialogActions from '../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../ui/gb-dialog-title/gb-dialog-title.component';

const DialogUpdateReportWithInterventionReportsStep = ({
    reports,
    reportSelected,
    onPrevious,
    onNext,
}: {
    reports: MonitoringReportShort[];
    reportSelected: MonitoringReportShort | undefined;
    onPrevious: () => void;
    onNext: (properties: Partial<ReportPayload>) => void;
}) => {
    const dispatch = useDispatch();

    const severities = useSelector(selectSeverities);

    const [reportsFiltered, setReportsFiltered] =
        useState<MonitoringReportShort[]>(reports);

    const [currentReport, setCurrentReport] = useState<
        MonitoringReportShort | undefined
    >(reportSelected);

    const handleSearchInputChange = (search: string) => {
        if (search === '') {
            setReportsFiltered(reports);
            return;
        }
        setReportsFiltered(
            reports.filter((report) =>
                report.report_name.toLowerCase().includes(search.toLowerCase())
            )
        );
    };

    const getSeverityColorFromId = (id: number | null) => {
        if (id === null) {
            return themeColors.primary;
        }
        return severities.find((s) => s.id === id)?.color;
    };

    const getSeverityNameFromId = (id: number | null) => {
        if (id === null) {
            return 'CONFORME';
        }
        return 'NON CONFORME';
    };

    const handleReportButtonClick = (report: MonitoringReportShort) => {
        setCurrentReport(report);
    };

    const isReportSelected = (reportId: number) => {
        return currentReport?.report_id === reportId;
    };

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    const handleNextButtonClick = () => {
        if (!currentReport) {
            dispatch(setIsError(true, 'Selezionare una segnalazione'));
            return;
        }
        onNext({ report_selected: currentReport });
    };

    return (
        <Fragment>
            <GBDialogTitle title={'Seleziona segnalazione'} />
            <GBDialogContent>
                <FlexBoxFullWHColumn overflow={'hidden'} pt={1}>
                    <TextField
                        onChange={(e) =>
                            handleSearchInputChange(e.target.value)
                        }
                        fullWidth
                        label="Cerca"
                        type="search"
                    />
                    <Box my={1} />
                    <FlexBoxFullWHColumn overflow={'auto'}>
                        <List disablePadding>
                            {reportsFiltered.map((report) => (
                                <ListItem key={report.report_id} disablePadding>
                                    <Box
                                        my={0.5}
                                        width={1}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        onClick={() =>
                                            handleReportButtonClick(report)
                                        }
                                        sx={{
                                            borderWidth: '2px',
                                            borderStyle: 'solid',
                                            borderColor: (theme) =>
                                                isReportSelected(
                                                    report.report_id
                                                )
                                                    ? getSeverityColorFromId(
                                                          report.report_assignment_severity
                                                      )
                                                    : theme.palette.divider,
                                            borderRadius: 1,
                                            padding: 1,
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <FlexBoxFullWRowBetween>
                                            <Typography>
                                                {moment(
                                                    report.report_assignment_date
                                                )
                                                    .locale('it')
                                                    .format('DD/MM/YYYY')}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: getSeverityColorFromId(
                                                        report.report_assignment_severity
                                                    ),
                                                }}
                                            >
                                                {getSeverityNameFromId(
                                                    report.report_assignment_severity
                                                )}
                                            </Typography>
                                        </FlexBoxFullWRowBetween>
                                        <Box my={0.5} />
                                        <b>{report.report_name}</b>
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                    </FlexBoxFullWHColumn>
                </FlexBoxFullWHColumn>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handlePreviousButtonClick}>INDIETRO</Button>
                <Button onClick={handleNextButtonClick}>AVANTI</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogUpdateReportWithInterventionReportsStep;
