import { Add } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PHASES_PAGE_ROUTE } from '../../constants/routes.constants';
import { selectUserProfile } from '../../store/auth/auth.selector';
import { openDialog } from '../../store/dialog/dialog.action';
import { DIALOG_TYPES } from '../../store/dialog/dialog.reducer';
import { selectMonitoringPhases } from '../../store/monitoring/monitoring.selector';
import { isGreenbeatAdministrator } from '../../utils/permissions/permissions.utils';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from './phases-accordion.styles';

const PhasesAccordion = () => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const monitoringPhases = useSelector(selectMonitoringPhases);

    const userProfile = useSelector(selectUserProfile);

    const dispatch = useDispatch();

    const location = useLocation();

    const navigate = useNavigate();

    const isPhaseSelected = (phaseId: number) => {
        return location.pathname.includes(`/phases/${phaseId}`);
    };

    const handlePhaseListItemClick = (phaseId: number) => {
        navigate(`${PHASES_PAGE_ROUTE}/${phaseId}`);
    };

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary
                aria-controls="panel-phases-content"
                id="panel-phases-header"
            >
                <Typography>Fasi</Typography>
                {isGreenbeatAdministrator(userProfile!) && (
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            dispatch(openDialog(DIALOG_TYPES.ADD_PHASE));
                        }}
                        color="primary"
                        sx={{
                            position: 'absolute',
                            top: 5,
                            right: 5,
                            zIndex: 999,
                        }}
                    >
                        <Add />
                    </IconButton>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <nav aria-label="phases list">
                    <List>
                        {monitoringPhases.map((p) => (
                            <ListItem key={p.id} divider disablePadding>
                                <ListItemButton
                                    sx={{
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                    selected={isPhaseSelected(p.id)}
                                    onClick={() =>
                                        handlePhaseListItemClick(p.id)
                                    }
                                >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            style: {
                                                overflow: 'hidden',
                                            },
                                        }}
                                        primary={
                                            <span
                                                style={{
                                                    wordBreak: 'break-word',
                                                    textWrap: 'wrap',
                                                }}
                                            >
                                                {p.name}
                                            </span>
                                        }
                                    />
                                    <Box my={0.5} />
                                    <ListItemText
                                        primary={
                                            <span
                                                style={{
                                                    fontSize: '0.7rem',
                                                    color: 'rgba(0, 0, 0, 0.54)',
                                                }}
                                            >
                                                {moment(p.start_date)
                                                    .locale('it')
                                                    .format('DD/MM/YYYY')}{' '}
                                                -{' '}
                                                {moment(p.end_date)
                                                    .locale('it')
                                                    .format('DD/MM/YYYY')}
                                            </span>
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </nav>
            </AccordionDetails>
        </Accordion>
    );
};

export default PhasesAccordion;
