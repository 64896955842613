import { GeoJSONFeature, GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import pathSvg from '../../assets/element-path-icon-grey.svg';
import pathSvgPrimary from '../../assets/element-path-icon-primary.svg';
import pointSvg from '../../assets/element-point-icon-grey.svg';
import pointSvgPrimary from '../../assets/element-point-icon-primary.svg';
import zoneSvg from '../../assets/element-zone-icon-grey.svg';
import zoneSvgPrimary from '../../assets/element-zone-icon-primary.svg';
import { PATHS_LAYER_CLASS, POINTS_LAYER_CLASS, ZONES_LAYER_CLASS } from '../../constants/map.constants';
import { Monitoring, MonitoringGeoElement, MonitoringPath, MonitoringPoint, MonitoringReport, MonitoringReportStatus, MonitoringSimpleReport, MonitoringZone } from '../../models/monitoring';

export function fromGeoJSONtoMonitoring(geoJSON: GeoJSONFeatureCollection): Monitoring {
    return {
        id: geoJSON['id'],
        coordinates: geoJSON['geometry']['coordinates'],
        ...geoJSON['properties']
    } as Monitoring;
}

export function fromGeoJSONtoMonitoringSimpleReport(feature: GeoJSONFeature): MonitoringSimpleReport {
    return {
        id: feature['id'],
        coordinates: feature['geometry']['coordinates'],
        ...feature['properties']
    } as MonitoringSimpleReport;
}

export function fromGeoJSONtoMonitoringPoints(geoJSON: GeoJSONFeatureCollection): MonitoringPoint[] {
    return geoJSON['features'].map((p: any) => {
        return {
            id: p['id'],
            coordinates: p['geometry']['coordinates'],
            ...p['properties']
        } as MonitoringPoint;
    });
}

export function fromGeoJSONtoMonitoringZones(geoJSON: GeoJSONFeatureCollection): MonitoringZone[] {
    return geoJSON['features'].map((p: any) => {
        return {
            id: p['id'],
            shapes: p['geometry']['coordinates'],
            ...p['properties']
        } as MonitoringZone;
    });
}

export function fromGeoJSONtoMonitoringPaths(geoJSON: GeoJSONFeatureCollection): MonitoringPath[] {
    return geoJSON['features'].map((p: any) => {
        return {
            id: p['id'],
            linestring: p['geometry']['coordinates'],
            ...p['properties']
        } as MonitoringPath;
    });
}

export function mergeElements(monitoringPoints: GeoJSONFeatureCollection, monitoringZones: GeoJSONFeatureCollection, monitoringPaths: GeoJSONFeatureCollection) {
    const elements: MonitoringGeoElement[] = [
        ...fromGeoJSONtoMonitoringPoints(monitoringPoints),
        ...fromGeoJSONtoMonitoringZones(monitoringZones),
        ...fromGeoJSONtoMonitoringPaths(monitoringPaths)
    ];
    return elements;
}

export function getElementClassName(element: MonitoringGeoElement) {
    if (Object.hasOwn(element, 'coordinates')) return POINTS_LAYER_CLASS;
    if (Object.hasOwn(element, 'shapes')) return ZONES_LAYER_CLASS;
    if (Object.hasOwn(element, 'linestring')) return PATHS_LAYER_CLASS;
    throw Error;
}

export function getElementSvg(className: string, primary: boolean = false) {
    switch (className) {
        case POINTS_LAYER_CLASS: {
            return primary ? pointSvgPrimary : pointSvg;
        }
        case ZONES_LAYER_CLASS: {
            return primary ? zoneSvgPrimary : zoneSvg;
        }
        case PATHS_LAYER_CLASS: {
            return primary ? pathSvgPrimary : pathSvg;
        }
        default:
            return undefined;
    }
}

export const getReportDate = (report: MonitoringReport) => {
    switch (report.status) {
        case MonitoringReportStatus.ASSIGNED:
            return report.assignment_date;
        case MonitoringReportStatus.INTERVENTION:
            return report.intervention_date;
        case MonitoringReportStatus.CLOSED:
            return report.closing_date;
        default:
            return report.reporting_date;
    }
};

export const getReportStatusLabel = (status: MonitoringReportStatus) => {
    switch (status) {
        case MonitoringReportStatus.ASSIGNED:
            return 'ASSEGNATA';
        case MonitoringReportStatus.INTERVENTION:
            return 'INTERVENTO';
        case MonitoringReportStatus.CLOSED:
            return 'CHIUSA';
        default:
            return 'NUOVA';
    }
};
