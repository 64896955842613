
export interface User {
    id: number;
    all_permissions: string[];
    last_login: string;
    is_superuser: true;
    username: string;
    first_name: string;
    last_name: string;
    is_staff: boolean;
    is_active: boolean;
    date_joined: string;
    email: string;
    groups: number[];
    user_permissions: string[];
}

export interface Settings {
    id: number;
    module: string;
    key: string;
    description: string;
    value: string;
    visible: boolean;
}

export interface Severity {
    id: number;
    name: string;
    color?: string;
    sort_order: number;
}

export interface Attachment {
    id: number;
    type: string;
    file: string;
    file_name: string;
    date: string;
    category: any;
}

export interface Notification {
    id: number;
    create_user: string;
    create_date: string;
    message: string;
    subject: string;
    read: boolean;
}

export enum ACTIONS {
    HANDLE_NEW_SIMPLE_REPORT = 'HANDLE_NEW_SIMPLE_REPORT',
    ASSIGN_NEW_REPORT_TO_TECHNICIAN = 'ASSIGN_NEW_REPORT_TO_TECHNICIAN',
    CREATE_INTERVENTION = 'CREATE_INTERVENTION',
    CLOSE_REPORT = 'CLOSE_REPORT'
}

export interface RequiredAction {
    id: number;
    action: string;
    object_id: number;
    object_name: string;
    create_user: User;
    create_date: string;
}

// input options for component MultiSelectChips
export interface MultiSelectOptions {
    id: number;
    label: string;
}
