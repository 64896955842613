import { Box, Button } from '@mui/material';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ElementPayload,
    MonitoringService,
} from '../../../../../models/monitoring';
import { setIsError } from '../../../../../store/error/error.action';
import { selectMonitoringServices } from '../../../../../store/monitoring/monitoring.selector';
import ServiceButton from '../../../../service-button/service-button.component';
import GBDialogActions from '../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../ui/gb-dialog-title/gb-dialog-title.component';

const DialogAddElementServicesStep = ({
    onPrevious,
    onNext,
}: {
    onPrevious: () => void;
    onNext: (properties: Partial<ElementPayload>) => void;
}) => {
    const dispatch = useDispatch();

    const services = useSelector(selectMonitoringServices);

    const [servicesSelected, setServicesSelected] = useState<
        MonitoringService[]
    >([]);

    const isServiceSelected = (service: MonitoringService) => {
        return servicesSelected.find((s) => s.id === service.id) !== undefined;
    };

    const handleServiceButtonClick = (service: MonitoringService) => {
        if (servicesSelected.find((s) => s.id === service.id)) {
            setServicesSelected((prevState) =>
                prevState.filter((s) => s.id !== service.id)
            );
        } else {
            setServicesSelected((prevState) => [...prevState, service]);
        }
    };

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    const handleNextButtonClick = () => {
        if (servicesSelected.length === 0) {
            dispatch(setIsError(true, 'Selezionare almeno un servizio'));
            return;
        }
        onNext({
            services: servicesSelected.map((s) => s.id).join(';'),
        });
    };

    return (
        <Fragment>
            <GBDialogTitle title={'Seleziona servizi'} />
            <GBDialogContent>
                <Box display={'flex'} flexWrap={'wrap'} gap={0.5}>
                    {services.map((e) => (
                        <ServiceButton
                            selected={isServiceSelected(e)}
                            key={e.id}
                            element={e}
                            onClickHandler={(service) =>
                                handleServiceButtonClick(service)
                            }
                        />
                    ))}
                </Box>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handlePreviousButtonClick}>INDIETRO</Button>
                <Button onClick={handleNextButtonClick}>TERMINA E SALVA</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogAddElementServicesStep;
