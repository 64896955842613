import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {
    ActionBox,
    ActionBoxZommIn,
    ActionBoxZommOut,
    ActionsBar,
} from './map-actions-side-bar.styles';
import { Add, Remove } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectMapType } from '../../../store/map/map.selector';
import { MAP_TYPES } from '../../../constants/map.constants';
import mapRoadmap from '../../../assets/map-roadmap.png';
import mapSatellite from '../../../assets/map-satellite.png';
import { setMapType, setMapZoom } from '../../../store/map/map.action';
import { Map } from 'ol';

const MapActionsSideBar = ({ map }: { map: Map }) => {
    const mapType = useSelector(selectMapType);

    const dispatch = useDispatch();

    const handleChangeMapType = () => {
        dispatch(
            setMapType(
                mapType === MAP_TYPES.ROADMAP
                    ? MAP_TYPES.SATELLITE
                    : MAP_TYPES.ROADMAP
            )
        );
    };

    const handleZoomChange = (offset: number) => {
        const currentZoom = map.getView().getZoom();
        if (currentZoom) {
            dispatch(setMapZoom(currentZoom + offset));
        }
    };

    return (
        <ActionsBar>
            <Box>
                <Grid container>
                    <Grid item xs={6}>
                        <ActionBox
                            onClick={handleChangeMapType}
                            sx={{
                                backgroundImage:
                                    mapType === MAP_TYPES.SATELLITE
                                        ? `url(${mapRoadmap})`
                                        : `url(${mapSatellite})`,
                                backgroundSize: 'cover',
                                border: (theme) =>
                                    ` 1px solid ${theme.colors.alpha.white[100]}`,
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box height={'17px'} />
            <ActionBoxZommIn onClick={() => handleZoomChange(1)}>
                <Add />
            </ActionBoxZommIn>
            <ActionBoxZommOut onClick={() => handleZoomChange(-1)}>
                <Remove />
            </ActionBoxZommOut>
        </ActionsBar>
    );
};

export default MapActionsSideBar;
