import { useState } from 'react';
import { useTheme } from '@mui/material/styles';

import { useDispatch, useSelector } from 'react-redux';
import {
    ArrowTabPrimary,
    BoxHorizontalCollapse,
    CustomSwitch,
} from './view-mode-tab.styles';
import { selectViewMode } from '../../store/filters/filters.selector';
import { ViewMode } from '../../store/filters/filters.reducer';
import { resetFilters, setFilters } from '../../store/filters/filters.action';
import { useNavigate } from 'react-router-dom';
import { HOME_PAGE_ROUTE } from '../../constants/routes.constants';

const ViewModeTab = () => {
    const [expanded, setExpanded] = useState(false);

    const theme = useTheme();

    const viewMode = useSelector(selectViewMode);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const isSwitchChecked = () => {
        if (viewMode === ViewMode.SEGNALAZIONI_SMART) return true;
        if (viewMode === ViewMode.VIREO) return false;
        return undefined;
    };

    const handleSwitchChange = () => {
        dispatch(resetFilters());
        dispatch(
            setFilters({
                viewMode: isSwitchChecked()
                    ? ViewMode.VIREO
                    : ViewMode.SEGNALAZIONI_SMART,
            })
        );
        navigate(HOME_PAGE_ROUTE);
    };

    return (
        <BoxHorizontalCollapse expanded={expanded} theme={theme}>
            {!expanded && (
                <ArrowTabPrimary
                    expanded={expanded}
                    onClick={() => setExpanded(!expanded)}
                />
            )}
            {expanded && (
                <CustomSwitch
                    checked={isSwitchChecked()}
                    onChange={handleSwitchChange}
                />
            )}
            {expanded && (
                <ArrowTabPrimary
                    expanded={expanded}
                    onClick={() => setExpanded(!expanded)}
                />
            )}
        </BoxHorizontalCollapse>
    );
};

export default ViewModeTab;
