import { Breakpoint } from '@mui/material';
import { Action, ActionWithPayload, createAction, withMatcher } from '../../utils/reducer/reducer.utils';
import { DIALOG_TYPES } from './dialog.reducer';
import { DIALOG_ACTION_TYPES } from './dialog.types';

export type OpenDialog = ActionWithPayload<
    DIALOG_ACTION_TYPES.OPEN_DIALOG, { dialogType: DIALOG_TYPES; }
>;

export type CloseDialog = Action<
    DIALOG_ACTION_TYPES.CLOSE_DIALOG
>;

export const openDialog = withMatcher(
    (dialogType: DIALOG_TYPES,
        inputData: any = null,
        dialogSize: Breakpoint = 'md',
        onComplete?: (response?: any) => void): OpenDialog =>
        createAction(DIALOG_ACTION_TYPES.OPEN_DIALOG, { dialogType, inputData, dialogSize, onComplete })
);

export const closeDialog = withMatcher(
    (): CloseDialog =>
        createAction(DIALOG_ACTION_TYPES.CLOSE_DIALOG)
);
