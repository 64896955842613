import { Card, CardContent } from '@mui/material';
import { ReactNode } from 'react';

const GBCard = ({
    children,
    noPadding,
}: {
    children: ReactNode;
    noPadding?: boolean;
}) => {
    return (
        <Card sx={{ height: '100%' }}>
            <CardContent
                sx={{
                    height: '100%',
                    padding: noPadding ? '0!important' : '1rem!important',
                }}
            >
                {children}
            </CardContent>
        </Card>
    );
};

export default GBCard;
