import { CheckCircle, Warning } from '@mui/icons-material';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import moment from 'moment';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    MonitoringElementService,
    PeriodicInspectionPayload,
} from '../../../../../models/monitoring';
import { setIsError } from '../../../../../store/error/error.action';
import {
    FlexBoxFullWHColumn,
    FlexBoxFullWRowBetween,
} from '../../../../layout/layout.styles';
import GBDialogActions from '../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../ui/gb-dialog-title/gb-dialog-title.component';

const DialogAddPeriodicInspectionServiceStep = ({
    elementServices,
    elementServiceSelected,
    onNext,
}: {
    elementServices: MonitoringElementService[];
    elementServiceSelected: number | undefined;
    onNext: (properties: Partial<PeriodicInspectionPayload>) => void;
}) => {
    const dispatch = useDispatch();

    const [serviceSelected, setServiceSelected] = useState<number>(
        elementServiceSelected ?? 0
    );

    const [servicesFiltered, setServicesFiltered] = useState<
        MonitoringElementService[]
    >([
        ...elementServices
            .filter((s) => s.expired)
            .sort((a, b) => a.service.name.localeCompare(b.service.name)),
        ...elementServices
            .filter((s) => s.expiring_soon)
            .sort((a, b) => a.service.name.localeCompare(b.service.name)),
        ...elementServices
            .filter((s) => !s.expired && !s.expiring_soon)
            .sort((a, b) => a.service.name.localeCompare(b.service.name)),
    ]);

    const handleSearchInputChange = (search: string) => {
        if (search === '') {
            setServicesFiltered([
                ...elementServices
                    .filter((s) => s.expired)
                    .sort((a, b) =>
                        a.service.name.localeCompare(b.service.name)
                    ),
                ...elementServices
                    .filter((s) => s.expiring_soon)
                    .sort((a, b) =>
                        a.service.name.localeCompare(b.service.name)
                    ),
                ...elementServices
                    .filter((s) => !s.expired && !s.expiring_soon)
                    .sort((a, b) =>
                        a.service.name.localeCompare(b.service.name)
                    ),
            ]);
            return;
        }
        setServicesFiltered(
            [
                ...elementServices
                    .filter((s) => s.expired)
                    .sort((a, b) =>
                        a.service.name.localeCompare(b.service.name)
                    ),
                ...elementServices
                    .filter((s) => s.expiring_soon)
                    .sort((a, b) =>
                        a.service.name.localeCompare(b.service.name)
                    ),
                ...elementServices
                    .filter((s) => !s.expired && !s.expiring_soon)
                    .sort((a, b) =>
                        a.service.name.localeCompare(b.service.name)
                    ),
            ].filter((service) =>
                service.service.name
                    .toLowerCase()
                    .includes(search.toLowerCase())
            )
        );
    };

    const isServiceDisabled = (service: MonitoringElementService) => {
        return !service.expired && !service.expiring_soon;
    };

    const handleServiceButtonClick = (serviceId: string) => {
        setServiceSelected(parseInt(serviceId));
    };

    const handleNextButtonClick = () => {
        if (!serviceSelected) {
            dispatch(setIsError(true, 'Selezionare un servizio'));
            return;
        }
        onNext({
            element_service: serviceSelected,
        });
    };

    return (
        <Fragment>
            <GBDialogTitle title={'Seleziona servizio'} />
            <GBDialogContent>
                <FlexBoxFullWHColumn overflow={'hidden'} pt={1}>
                    <TextField
                        onChange={(e) =>
                            handleSearchInputChange(e.target.value)
                        }
                        fullWidth
                        label="Cerca"
                        type="search"
                    />
                    <Box my={2} />
                    <FlexBoxFullWHColumn overflow={'auto'} px={2}>
                        <FormControl component="fieldset">
                            <RadioGroup
                                value={serviceSelected}
                                onChange={(e) =>
                                    handleServiceButtonClick(e.target.value)
                                }
                            >
                                {servicesFiltered.map((e) => (
                                    <FlexBoxFullWRowBetween
                                        alignItems={'center'}
                                        py={1}
                                        key={e.id}
                                    >
                                        <Box
                                            width={'calc(100% - 100px)'}
                                            sx={{ wordBreak: 'break-word' }}
                                        >
                                            <FormControlLabel
                                                key={e.id}
                                                value={e.id}
                                                control={
                                                    <Radio
                                                        disabled={isServiceDisabled(
                                                            e
                                                        )}
                                                        sx={{
                                                            opacity:
                                                                isServiceDisabled(
                                                                    e
                                                                )
                                                                    ? 0.5
                                                                    : 1,
                                                        }}
                                                    />
                                                }
                                                label={e.service.name}
                                            />
                                        </Box>
                                        <Box width={100}>
                                            <TextField
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        paddingLeft: 0.5,
                                                    },
                                                }}
                                                fullWidth
                                                color={
                                                    e.expired
                                                        ? 'error'
                                                        : e.expiring_soon
                                                        ? 'warning'
                                                        : 'primary'
                                                }
                                                InputProps={{
                                                    startAdornment:
                                                        e.expired ? (
                                                            <Warning color="error" />
                                                        ) : e.expiring_soon ? (
                                                            <Warning color="warning" />
                                                        ) : (
                                                            <CheckCircle />
                                                        ),
                                                }}
                                                inputProps={{
                                                    style: {
                                                        textAlign: 'right',
                                                        fontSize: 10,
                                                    },
                                                }}
                                                value={moment(e.expiring_date)
                                                    .locale('it')
                                                    .format('DD/MM/YYYY')}
                                                focused={
                                                    e.expired || e.expiring_soon
                                                }
                                            />
                                        </Box>
                                    </FlexBoxFullWRowBetween>
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </FlexBoxFullWHColumn>
                </FlexBoxFullWHColumn>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handleNextButtonClick}>AVANTI</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogAddPeriodicInspectionServiceStep;
