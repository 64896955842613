import { Coordinate } from 'ol/coordinate';
import { LineString, MultiPolygon } from 'ol/geom';
import { Attachment, Severity, User } from './shared';

export interface NamedModel {
    name: string;
}

export interface KeyLabelModel {
    key: string;
    label: string;
}

export interface KeyValueModel {
    key: string;
    value: any;
}

export interface WithAttachment {
    files: number[];
    attachments: Attachment[];
    images: Attachment[];
}

export interface Monitoring extends NamedModel, WithAttachment {
    id: number;
    description: string;
    logo: string;
    shapes: MultiPolygon;
}

export interface MonitoringService extends NamedModel {
    id: number;
    description: string;
    additional_info: [{ [key: string]: string; }];
    checklist: KeyLabelModel[];
    elements_count: number;
    reports_count: number;
    periodic_inspections_count: number;
}

export interface MonitoringCategory extends NamedModel {
    id: number;
    code: string;
    icon: string;
    color: string | null;
}

export interface MonitoringMatrix extends NamedModel {
    id: number;
    code: string;
    icon: string;
    color: string | null;
}

export interface MonitoringElement extends NamedModel, WithAttachment {
    id: number;
    code: string;
    description: string;
    address: string;
    services: MonitoringElementService[];
    category: MonitoringCategory | null;
    matrix: MonitoringMatrix | null;
    severity: Severity | null;
    centroid: Coordinate;
}

export interface MonitoringElementSimple {
    id: number;
    name: string;
    className: string;
    image: Attachment | null;
}
export interface MonitoringPoint extends MonitoringElement {
    coordinates: Coordinate;
}

export interface MonitoringZone extends MonitoringElement {
    shapes: MultiPolygon;
}

export interface MonitoringPath extends MonitoringElement {
    linestring: LineString;
}

export type MonitoringGeoElement =
    | MonitoringPoint
    | MonitoringZone
    | MonitoringPath;

export interface MonitoringElementService {
    id: number;
    element: MonitoringElement;
    service: MonitoringService;
    severity: Severity;
    periodic_inspections_start_date: string;
    periodic_inspections_days_interval: number;
    periodic_inspections_days_before_expiration: number;
    periodic_inspections_count: number;
    total_reports_count: number;
    new_reports_count: number;
    assigned_reports_count: number;
    intervention_reports_count: number;
    closed_reports_count: number;
    last_periodic_inspection: MonitoringPeriodicInspectionSimple;
    periodic_inspections: MonitoringPeriodicInspectionSimple[];
    report_assigned_user: User;
    monitoring_report_notes: {
        assignment_notes: string;
        intervention_notes: string;
        reporting_notes: string;
    };
    expiring_soon: boolean;
    expired: boolean;
    expiring_date: string;
}

export enum MonitoringReportStatus {
    NEW = 'NEW',
    ASSIGNED = 'ASSIGNED',
    INTERVENTION = 'INTERVENTION',
    CLOSED = 'CLOSED',
}

export interface MonitoringReport extends NamedModel, WithAttachment {
    id: number;
    element_service: MonitoringElementService;
    periodic_inspection: MonitoringPeriodicInspection;
    reporting_severity: Severity | null;
    assignment_severity: Severity | null;
    intervention_severity: Severity | null;
    closing_severity: Severity | null;
    reporting_notes: string;
    assignment_notes: string;
    intervention_notes: string;
    closing_notes: string;
    reporting_date: string;
    assignment_date: string;
    intervention_date: string;
    intervention_expire_date: string;
    closing_date: string;
    reporting_user: User;
    assignment_user: User;
    intervention_user: User;
    closing_user: User;
    is_intervention_external: boolean;
    intervention_external_referee_name: string;
    status: MonitoringReportStatus;
}

export interface MonitoringSimpleReport extends NamedModel, WithAttachment {
    id: number;
    coordinates: Coordinate;
    notes: string;
    address: string;
}

export interface ManageMonitoringSimpleReportPayload {
    name: string;
    reporting_notes: string;
    reporting_date: string;
    reporting_severity: number | null;
    simple_report_id: number;
    element_service: number;
}

export interface AssignReportPayload {
    report_id: number;
    severity_id: number | null;
    assignment_notes: string | null;
    assignment_user_id: number | null;
    attachments: Attachment[];
    images: Attachment[];
    intervention_expire_date: string | null,
    is_intervention_external: boolean,
    intervention_external_referee_name: string | null,
}

export interface CloseReportPayload {
    report_id: number;
    severity_id: number | null;
    intervention_notes?: string | null;
    intervention_date?: string | null;
    closing_notes: string | null;
    attachments: Attachment[];
    images: Attachment[];
}

export interface MonitoringPeriodicInspection
    extends NamedModel,
    WithAttachment {
    id: number;
    create_user: User;
    create_date: string;
    element_service: MonitoringElementService;
    notes: string;
    severity: number;
}

export interface MonitoringPeriodicInspectionSimple
    extends NamedModel,
    WithAttachment {
    id: number;
    create_user: User;
    create_date: string;
    severity: Severity;
    notes: string;
    checklist_values: KeyValueModel[];
    signature: string;
}

export interface MonitoringPhaseMatrix {
    id: number;
    code: string;
    name: string;
    campaignsNumber: number | null;
    checked: boolean;
    attachments: Attachment[];
    images: Attachment[];
    color: string | null;
    icon: string;
}

export interface MonitoringPhase extends NamedModel {
    id: number;
    code: string;
    description: string;
    start_date: string;
    end_date: string;
    matrices: MonitoringPhaseMatrix[];
    campaigns: number;
    elements: number;
    test_reports: number;
}

export interface MonitoringCampaign extends NamedModel, WithAttachment {
    id: number;
    description: string;
    start_date: string;
    end_date: string;
    points: number[];
    zones: number[];
    paths: number[];
    phase: number;
    matrix: number;
    test_reports: MonitoringTestReport[];
}

export interface MonitoringTestReport extends NamedModel, WithAttachment {
    id: number;
    description: string;
    start_date: string;
    end_date: string;
    campaign: number;
    element: number | null;
}

export interface FeatureMapObj {
    id: number;
    name: string;
    description: string;
    coordinates: Coordinate;
    address: string;
    files: number[];
    images: Attachment[];
    class: string;
    category: MonitoringCategory | null;
    matrix: MonitoringMatrix | null;
    severity: Severity;
}

export interface ElementPayload {
    class_name: string;
    categoryId: number;
    matrixId: number;
    name: string;
    description: string;
    pointAddress: string;
    pointLat: string;
    pointLng: string;
    zoneCoords: Coordinate[][];
    pathCoords: Coordinate[];
    images: any[];
    attachments: any[];
    services: string;
}

export interface PhasePayload {
    name: string;
    code: string;
    description: string;
    start_date: string;
    end_date: string;
    matrices: MonitoringPhaseMatrix[];
    monitoring: number;
};

export interface SimpleReportPayload extends WithAttachment {
    name: string;
    notes: string;
    point_lat: number;
    point_lon: number;
}

export interface ReportPayload extends WithAttachment {
    element_service: number;
    name: string;
    description: string;
    severity: number | null;
    report_selected: MonitoringReportShort;
}

export interface PeriodicInspectionPayload extends WithAttachment {
    element_service: number;
    name: string;
    description: string;
    severity: number | null;
    checklist_values: KeyValueModel[];
    signature: string;
}

export interface MonitoringReportShort {
    report_id: number;
    report_name: string;
    report_reporting_notes: string;
    report_reporting_severity: number | null;
    report_assignment_date: string;
    report_assignment_notes: string;
    report_assignment_severity: number | null;
}

export interface MyAvailableElementService {
    element_service_id: number;
    reports: MonitoringReportShort[];
}

export interface UpdateElementServicePayload {
    services_to_update: UpdateElementService[];
}

export interface UpdateElementService {
    id: number;
    periodic_inspections_days_interval: number;
    periodic_inspections_days_before_expiration: number;
}

