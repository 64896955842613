import { MoreVert, Tune } from '@mui/icons-material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfile } from '../../store/auth/auth.selector';
import { openDialog } from '../../store/dialog/dialog.action';
import { DIALOG_TYPES } from '../../store/dialog/dialog.reducer';
import { isGreenbeatAdministrator } from '../../utils/permissions/permissions.utils';
import ActionsPopoverMenu from './actions-popover-menu/actions-popover-menu.component';

const ActionsBar = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const userProfile = useSelector(selectUserProfile);

    const dispatch = useDispatch();

    const handleFiltersButtonClick = () => {
        dispatch(openDialog(DIALOG_TYPES.FILTERS));
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            {anchorEl && (
                <ActionsPopoverMenu anchorEl={anchorEl} onClose={handleClose} />
            )}
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Box>Lista degli elementi</Box>
                <Box>
                    {
                        <IconButton
                            color="primary"
                            onClick={handleFiltersButtonClick}
                        >
                            <Tune />
                        </IconButton>
                    }
                    {isGreenbeatAdministrator(userProfile!) && (
                        <IconButton color="primary" onClick={handleClick}>
                            <MoreVert />
                        </IconButton>
                    )}
                </Box>
            </Box>
        </Fragment>
    );
};

export default ActionsBar;
