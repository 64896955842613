import { ReactNode } from 'react';
import { FlexBoxFullWRowBetween } from '../../layout/layout.styles';

const GBCardHeader = ({ children }: { children: ReactNode }) => {
    return (
        <FlexBoxFullWRowBetween alignItems={'center'} height={45}>
            {children}
        </FlexBoxFullWRowBetween>
    );
};

export default GBCardHeader;
