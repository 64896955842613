export enum DIALOG_ACTION_TYPES {
    OPEN_DIALOG = 'dialog/OPEN_DIALOG',
    CLOSE_DIALOG = 'dialog/CLOSE_DIALOG',
}

export enum DIALOG_TYPES {
    FILTERS = 'FILTERS',
    ADD_ELEMENT = 'ADD_ELEMENT',
    ADD_ELEMENT_SERVICE = 'ADD_ELEMENT_SERVICE',
    ADD_SIMPLE_REPORT = 'ADD_SIMPLE_REPORT',
    ADD_REPORT = 'ADD_REPORT',
    ADD_PERIODIC_INSPECTION = 'ADD_PERIODIC_INSPECTION',
    ASSIGN_REPORT = 'ASSIGN_REPORT',
    UPDATE_REPORT_WITH_INTERVENTION = 'UPDATE_REPORT_WITH_INTERVENTION',
    CLOSE_REPORT = 'CLOSE_REPORT',
    ADD_PHASE = 'ADD_PHASE',
    ADD_TEST_REPORT = 'ADD_TEST_REPORT',
    ADD_IMAGES = 'ADD_IMAGES',
    ADD_ATTACHMENTS = 'ADD_ATTACHMENTS',
    CONFIRM_CANCELLATION = 'CONFIRM_CANCELLATION',
    CONFIRM_SIMPLE_REPORT_REFUSAL = 'CONFIRM_SIMPLE_REPORT_REFUSAL',
    MANAGE_NEW_SIMPLE_REPORT = 'MANAGE_NEW_SIMPLE_REPORT',
    OPEN_PERIODIC_INSPECTION = 'OPEN_PERIODIC_INSPECTION',
}
