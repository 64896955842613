import TextField from '@mui/material/TextField';
import styled from '@mui/material/styles/styled';

export const TextArea = styled(TextField)(({ theme }) => ({
    '&, .MuiInputBase-root, textarea': {
        height: '100%!important',
        width: '100%!important',
    },
    textarea: {
        WebkitTextFillColor: `${theme.colors.alpha.black[100]}!important`,
    },
}));
