import { Button } from '@mui/material';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ManageMonitoringSimpleReportPayload } from '../../../../models/monitoring';
import { setIsError } from '../../../../store/error/error.action';
import GBDialogActions from '../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../ui/gb-dialog-title/gb-dialog-title.component';
import GBTextArea from '../../../ui/gb-text-area/gb-text-area.component';

const DialogManageNewSimpleReportRefuseStep = ({
    id,
    onNext,
    onConfirm,
}: {
    id: number;
    onNext: (properties: Partial<ManageMonitoringSimpleReportPayload>) => void;
    onConfirm: (id: number, notes: string) => void;
}) => {
    const [notesInput, setNotesInput] = useState<string>('');

    const dispatch = useDispatch();

    const handleNotesChange = (event: any) => {
        setNotesInput(event.target.value);
    };

    const handleCancelButtonClick = () => {
        onNext({});
    };

    const handleConfirmButtonClick = () => {
        if (!notesInput) {
            dispatch(setIsError(true, 'Inserire le note'));
            return;
        }
        onConfirm(id, notesInput);
    };

    return (
        <Fragment>
            <GBDialogTitle title="CONFERMA IL RIFIUTO" />
            <GBDialogContent height="auto">
                <GBTextArea
                    label="Note sul rifiuto"
                    multiline
                    rows={6}
                    sx={{
                        minWidth: '90%',
                        maxWidth: '90%',
                        marginTop: '20px',
                        marginBottom: '20px',
                    }}
                    onChange={handleNotesChange}
                    required={true}
                />
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handleCancelButtonClick}>ANNULLA</Button>
                <Button onClick={handleConfirmButtonClick}>CONFERMA</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogManageNewSimpleReportRefuseStep;
