import { all, call } from 'typed-redux-saga/macro';
import { authSaga } from './auth/auth.saga';
import { campaignSaga } from './campaign/campaign.saga';
import { elementSaga } from './element/element.saga';
import { filesSaga } from './files/files.saga';
import { filtersSaga } from './filters/filters.saga';
import { mapSaga } from './map/map.saga';
import { monitoringSaga } from './monitoring/monitoring.saga';
import { paramsSaga } from './params/params.saga';
import { periodicInspectionSaga } from './periodic-inspection/periodic-inspection.saga';
import { phaseSaga } from './phase/phase.saga';
import { reportSaga } from './report/report.saga';
import { simpleReportSaga } from './simple-report/simple-report.saga';
import { tenantSaga } from './tenant/tenant.saga';

export function* rootSaga() {
    yield* all(
        [
            call(authSaga),
            call(tenantSaga),
            call(paramsSaga),
            call(monitoringSaga),
            call(filtersSaga),
            call(elementSaga),
            call(reportSaga),
            call(periodicInspectionSaga),
            call(simpleReportSaga),
            call(phaseSaga),
            call(campaignSaga),
            call(filesSaga),
            call(mapSaga),
        ]
    );
}
