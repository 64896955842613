import { all, call, put, takeLatest } from 'typed-redux-saga/macro';
import { SETTINGS_URL } from '../../constants/api.constants';
import { PARAMS_KEYS, PARAMS_MODULES } from '../../constants/params.constants';
import { apiRequestWrapper } from '../../utils/api/api.utils';
import { getParamValue } from '../../utils/params/params.utils';
import { setIsError } from '../error/error.action';
import { setIsLoading } from '../loading/loading.action';
import { setMapCenter, setMapZoom } from '../map/map.action';
import { getParamsFailed, getParamsSuccess } from './params.action';
import { PARAMS_ACTION_TYPES } from './params.types';


export function* getParams() {
    yield* put(setIsLoading(true));
    try {
        const paramsResponse = yield* call(
            apiRequestWrapper, SETTINGS_URL, 'GET', null
        );

        if (paramsResponse) {
            yield* put(getParamsSuccess(paramsResponse));
            const mapCenterParam = getParamValue(paramsResponse, PARAMS_MODULES.core, PARAMS_KEYS.initialMapCenter);
            if (mapCenterParam) {
                yield* put(setMapCenter(mapCenterParam.split(',').map((v: any) => parseFloat(v))));
            }
            const mapZoomParam = getParamValue(paramsResponse, PARAMS_MODULES.core, PARAMS_KEYS.initialMapZoom);
            if (mapZoomParam) {
                yield* put(setMapZoom(parseInt(mapZoomParam)));
            }
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getParamsFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onGetParamsStart() {
    yield* takeLatest(PARAMS_ACTION_TYPES.GET_PARAMS_START, getParams);
}

export function* paramsSaga() {
    yield* all([
        call(onGetParamsStart),
    ]);
}
