import { Action } from 'redux-saga';
import { ManageMonitoringSimpleReportPayload, SimpleReportPayload } from '../../models/monitoring';
import { ActionWithPayload, createAction, withMatcher } from '../../utils/reducer/reducer.utils';
import { SIMPLE_REPORT_ACTION_TYPES } from './simple-report.types';


export type AddSimpleReportStart = ActionWithPayload<
    SIMPLE_REPORT_ACTION_TYPES.ADD_SIMPLE_REPORT_START, {
        simpleReport: Partial<SimpleReportPayload>;
        onComplete: () => void;
    }
>;

export type AddSimpleReportSuccess = Action<
    SIMPLE_REPORT_ACTION_TYPES.ADD_SIMPLE_REPORT_SUCCESS
>;

export type AddSimpleReportFailed = ActionWithPayload<
    SIMPLE_REPORT_ACTION_TYPES.ADD_SIMPLE_REPORT_FAILED, string
>;

export type RefuseSimpleReportStart = ActionWithPayload<
    SIMPLE_REPORT_ACTION_TYPES.REFUSE_SIMPLE_REPORT_START, {
        id: number;
        refusal_notes: string;
        onComplete: () => void;
    }
>;

export type RefuseSimpleReportSuccess = Action<
    SIMPLE_REPORT_ACTION_TYPES.REFUSE_SIMPLE_REPORT_SUCCESS
>;

export type RefuseSimpleReportFailed = ActionWithPayload<
    SIMPLE_REPORT_ACTION_TYPES.REFUSE_SIMPLE_REPORT_FAILED, string
>;

export type ConfirmSimpleReportStart = ActionWithPayload<
    SIMPLE_REPORT_ACTION_TYPES.CONFIRM_SIMPLE_REPORT_START, {
        simpleReport: Partial<ManageMonitoringSimpleReportPayload>;
        onComplete: () => void;
    }
>;

export type ConfirmSimpleReportSuccess = Action<
    SIMPLE_REPORT_ACTION_TYPES.CONFIRM_SIMPLE_REPORT_SUCCESS
>;

export type ConfirmSimpleReportFailed = ActionWithPayload<
    SIMPLE_REPORT_ACTION_TYPES.CONFIRM_SIMPLE_REPORT_FAILED, string
>;

export const addSimpleReportStart = withMatcher(
    (simpleReport: Partial<SimpleReportPayload>, onComplete: () => void): AddSimpleReportStart =>
        createAction(SIMPLE_REPORT_ACTION_TYPES.ADD_SIMPLE_REPORT_START, { simpleReport, onComplete })
);

export const addSimpleReportSuccess = withMatcher(
    (): AddSimpleReportSuccess =>
        createAction(SIMPLE_REPORT_ACTION_TYPES.ADD_SIMPLE_REPORT_SUCCESS)
);

export const addSimpleReportFailed = withMatcher(
    (error: string): AddSimpleReportFailed =>
        createAction(SIMPLE_REPORT_ACTION_TYPES.ADD_SIMPLE_REPORT_FAILED, error)
);

export const refuseSimpleReportStart = withMatcher(
    (id: number, refusal_notes: string, onComplete: () => void): RefuseSimpleReportStart =>
        createAction(SIMPLE_REPORT_ACTION_TYPES.REFUSE_SIMPLE_REPORT_START, { id, refusal_notes, onComplete })
);

export const refuseSimpleReportSuccess = withMatcher(
    (): RefuseSimpleReportSuccess =>
        createAction(SIMPLE_REPORT_ACTION_TYPES.REFUSE_SIMPLE_REPORT_SUCCESS)
);

export const refuseSimpleReportFailed = withMatcher(
    (error: string): RefuseSimpleReportFailed =>
        createAction(SIMPLE_REPORT_ACTION_TYPES.REFUSE_SIMPLE_REPORT_FAILED, error)
);

export const confirmSimpleReportStart = withMatcher(
    (simpleReport: Partial<ManageMonitoringSimpleReportPayload>, onComplete: () => void): ConfirmSimpleReportStart =>
        createAction(SIMPLE_REPORT_ACTION_TYPES.CONFIRM_SIMPLE_REPORT_START, { simpleReport, onComplete })
);

export const confirmSimpleReportSuccess = withMatcher(
    (): ConfirmSimpleReportSuccess =>
        createAction(SIMPLE_REPORT_ACTION_TYPES.CONFIRM_SIMPLE_REPORT_SUCCESS)
);

export const confirmSimpleReportFailed = withMatcher(
    (error: string): ConfirmSimpleReportFailed =>
        createAction(SIMPLE_REPORT_ACTION_TYPES.CONFIRM_SIMPLE_REPORT_FAILED, error)
);