import { AttachFile, Close } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../store/dialog/dialog.action';
import { setIsError } from '../../../store/error/error.action';
import { addFilesStart } from '../../../store/files/files.action';
import {
    checkExtensionFile,
    checkFileSize,
} from '../../../utils/file/file.utils';
import { BoxFullW, FlexBoxFullWColumn } from '../../layout/layout.styles';
import GBDialogActions from '../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../ui/gb-dialog-title/gb-dialog-title.component';
import {
    DeleteImageButton,
    DropAttachmentsCard,
} from './dialog-add-attachments.styles';

const DialogAddAttachments = ({
    inputData,
    onAttachmentsAdded,
}: {
    inputData: any;
    onAttachmentsAdded: (response: any) => void;
}) => {
    const [attachmentsToAdd, setAttachmentsToAdd] = useState<any[]>([]);
    const [nextAttachmentId, setNextAttachmentId] = useState<number>(0);
    const [dragging, setDragging] = useState(false);

    const dispatch = useDispatch();

    const handleCancelButtonClick = () => {
        dispatch(closeDialog());
    };

    const handleSaveButtonClick = () => {
        if (attachmentsToAdd.length === 0) {
            dispatch(setIsError(true, "Inserire almeno un'allegato"));
            return;
        }
        dispatch(
            addFilesStart({
                id: inputData.id,
                class_name: inputData.className,
                attachments: attachmentsToAdd,
                onComplete: (response) => {
                    onAttachmentsAdded(response);
                    dispatch(closeDialog());
                },
            })
        );
    };

    const appendAttachment = (files: any) => {
        let index = nextAttachmentId;
        let attachmentsToAddTmp: any = [];
        for (let f of files) {
            // check file type
            if (!checkExtensionFile(f.name)) {
                dispatch(setIsError(true, 'Tipo file non ammesso: ' + f.name));
                return;
            }
            if (!checkFileSize(f)) {
                dispatch(setIsError(true, 'File troppo grande: ' + f.name));
                return;
            }
            attachmentsToAddTmp.push({
                id: index,
                file: f,
                imageUrl: URL.createObjectURL(f),
            });
            index++;
        }
        setNextAttachmentId(index);
        setAttachmentsToAdd([...attachmentsToAdd, ...attachmentsToAddTmp]);
    };

    const handleFileChange = (event: any) => {
        appendAttachment(event.target.files);
        event.target.value = '';
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
        setDragging(true);
    };

    // Gestisci l'evento di uscita dall'area di "drag and drop"
    const handleDragLeave = () => {
        setDragging(false);
    };

    // Gestisci l'evento di rilascio del file nell'area di "drag and drop"
    const handleDrop = (e: any) => {
        e.preventDefault();
        setDragging(false);
        appendAttachment(e.dataTransfer.files);
    };

    return (
        <Fragment>
            <GBDialogTitle title="ALLEGA DOCUMENTAZIONE" />
            <GBDialogContent>
                <Typography textAlign={'center'} mb={2}>
                    Estensioni ammesse: .pdf, .docx, .txt (MAX 50MB)
                </Typography>
                <Grid
                    container
                    flexDirection={'column'}
                    rowSpacing={1}
                    alignItems="center"
                >
                    <Grid item display="flex" justifyContent="center">
                        <FlexBoxFullWColumn>
                            <input
                                type="file"
                                //accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="file-upload"
                                multiple
                            />
                            <label htmlFor="file-upload">
                                <Button
                                    variant="contained"
                                    startIcon={<AttachFile />}
                                    component="span"
                                    sx={{
                                        height: '53px',
                                    }}
                                >
                                    Seleziona i file
                                </Button>
                            </label>
                        </FlexBoxFullWColumn>
                    </Grid>
                    <Grid item xs={2} display="flex" justifyContent="center">
                        Oppure
                    </Grid>
                    <Grid item width={1}>
                        <DropAttachmentsCard
                            className={`file-upload ${
                                dragging ? 'dragging' : ''
                            }`}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                        >
                            {'Trascina qui i file'}
                        </DropAttachmentsCard>
                    </Grid>
                </Grid>
                <Box my={3} />
                {attachmentsToAdd.length > 0 && (
                    <Box>
                        {'Files caricati (' + attachmentsToAdd.length + '):'}
                    </Box>
                )}
                <BoxFullW>
                    {attachmentsToAdd.map((o: any, index: any) => (
                        <Box key={index} my={1}>
                            <DeleteImageButton
                                color="primary"
                                onClick={() => {
                                    setAttachmentsToAdd(
                                        attachmentsToAdd.filter(
                                            (p: any) => p.id !== o.id
                                        )
                                    );
                                }}
                                sx={{
                                    marginRight: (theme) => theme.spacing(1),
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <Close />
                            </DeleteImageButton>
                            {o.file.name}
                        </Box>
                    ))}
                </BoxFullW>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handleCancelButtonClick}>ANNULLA</Button>
                <Button onClick={handleSaveButtonClick}>TERMINA E SALVA</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogAddAttachments;
