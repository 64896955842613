import { Box, Button, TextField } from '@mui/material';
import { ChangeEvent, Fragment, useState } from 'react';
import {
    MonitoringReportShort,
    ReportPayload,
} from '../../../../../models/monitoring';
import { FlexBoxFullWColumn } from '../../../../layout/layout.styles';
import GBDialogActions from '../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../ui/gb-dialog-title/gb-dialog-title.component';
import GBTextArea from '../../../../ui/gb-text-area/gb-text-area.component';

const DialogUpdateReportWithInterventionInfoStep = ({
    report,
    description,
    onPrevious,
    onNext,
}: {
    report: MonitoringReportShort;
    description: string | undefined;
    onPrevious: () => void;
    onNext: (properties: Partial<ReportPayload>) => void;
}) => {
    const [descriptionInput, setDescriptionInput] = useState<
        string | undefined
    >(description);

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    const handleNextButtonClick = () => {
        onNext({ description: descriptionInput });
    };

    const handleDescriptionChange = (
        event: ChangeEvent<HTMLTextAreaElement>
    ) => {
        setDescriptionInput(event.target.value);
    };

    return (
        <Fragment>
            <GBDialogTitle title="INSERISCI INFORMAZIONI" />
            <GBDialogContent>
                <FlexBoxFullWColumn>
                    <TextField
                        disabled
                        fullWidth
                        label="Nome"
                        defaultValue={report.report_name}
                    />
                    <Box my={1} />
                    <GBTextArea
                        label="Note Intervento"
                        multiline
                        rows={6}
                        defaultValue={descriptionInput}
                        onChange={handleDescriptionChange}
                    />
                </FlexBoxFullWColumn>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handlePreviousButtonClick}>INDIETRO</Button>
                <Button onClick={handleNextButtonClick}>AVANTI</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogUpdateReportWithInterventionInfoStep;
