import { Box, Button, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment, { Moment } from 'moment';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MonitoringService } from '../../../models/monitoring';
import { closeDialog } from '../../../store/dialog/dialog.action';
import { addElementServiceStart } from '../../../store/element/element.action';
import { selectMonitoringServices } from '../../../store/monitoring/monitoring.selector';
import ServiceButton from '../../service-button/service-button.component';
import GBDatePicker from '../../ui/gb-date-picker/gb-date-picker.component';
import GBDialogActions from '../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../ui/gb-dialog-title/gb-dialog-title.component';

const DialogAddElementService = ({
    inputData,
    onServiceAdded,
}: {
    inputData: any;
    onServiceAdded: () => void;
}) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const monitoringServices = useSelector(selectMonitoringServices);

    const [serviceSelected, setServiceSelected] =
        useState<MonitoringService | null>();
    const [dateStart, setDateStart] = useState<string>(
        moment(new Date()).format('YYYY-MM-DD')
    );
    const [daysInterval, setDaysInterval] = useState<number | null>(365);
    const [daysForExpiration, setDaysForExpiration] = useState<number | null>(
        30
    );

    const title = serviceSelected
        ? 'Configura ispezioni periodiche'
        : 'Aggiungi servizio';

    const servicesAvailable = () => {
        const currentPointServicesIds = (
            inputData.currentElementServices as MonitoringService[]
        ).map((o) => o.id);
        let listServicesAvailable: MonitoringService[] = [];

        for (let service of monitoringServices) {
            if (!currentPointServicesIds.includes(service.id)) {
                listServicesAvailable.push(service);
            }
        }
        return listServicesAvailable;
    };

    const handleCancelButtonClick = () => {
        dispatch(closeDialog());
    };

    const handleSaveButtonClick = () => {
        dispatch(
            addElementServiceStart(
                inputData.elementId,
                inputData.elementClassName,
                serviceSelected!.id,
                dateStart!,
                daysInterval!,
                daysForExpiration!,
                () => {
                    onServiceAdded();
                    dispatch(closeDialog());
                }
            )
        );
    };

    return (
        <Fragment>
            <GBDialogTitle title={title} />
            <GBDialogContent>
                {!serviceSelected && (
                    <Box display={'flex'} flexWrap={'wrap'} gap={0.5}>
                        {servicesAvailable().map((e) => (
                            <ServiceButton
                                selected={false}
                                key={e.id}
                                element={e}
                                onClickHandler={(service) => {
                                    setServiceSelected(service);
                                }}
                            />
                        ))}
                    </Box>
                )}
                {serviceSelected && (
                    <Box sx={{ textAlign: 'center' }}>
                        <Box>
                            <Typography>Data inizio ispezioni</Typography>
                            <Box my={2} />
                            <GBDatePicker
                                slotProps={{
                                    textField: {
                                        disabled: true,
                                    },
                                }}
                                format="DD/MM/YYYY"
                                value={moment(dateStart)}
                                onChange={(newValue: Moment) => {
                                    const dateStartString = newValue
                                        ? newValue?.format('YYYY-MM-DD')
                                        : moment(dateStart).format(
                                              'YYYY-MM-DD'
                                          );
                                    setDateStart(dateStartString);
                                }}
                            />
                        </Box>
                        <Box height={theme.spacing(3)}></Box>
                        <Box>
                            <Typography>Intervallo tra le ispezioni</Typography>
                            <Box my={2} />
                            <TextField
                                type="number"
                                onChange={(e) =>
                                    setDaysInterval(
                                        e.target.value !== ''
                                            ? parseInt(e.target.value)
                                            : null
                                    )
                                }
                                defaultValue={daysInterval}
                            />
                        </Box>
                        <Box height={theme.spacing(3)}></Box>
                        <Box>
                            <Typography>
                                Minimo giorni preavviso scadenza
                            </Typography>
                            <Box my={2} />
                            <TextField
                                type="number"
                                onChange={(e) =>
                                    setDaysForExpiration(
                                        e.target.value !== ''
                                            ? parseInt(e.target.value)
                                            : null
                                    )
                                }
                                defaultValue={daysForExpiration}
                            />
                        </Box>
                    </Box>
                )}
                <Box height={theme.spacing(3)}></Box>
            </GBDialogContent>
            <GBDialogActions>
                {!serviceSelected && (
                    <Button onClick={handleCancelButtonClick}>ANNULLA</Button>
                )}
                {serviceSelected && (
                    <Button
                        aria-label="close"
                        onClick={() => {
                            setServiceSelected(null);
                            setDateStart(
                                moment(new Date()).format('YYYY-MM-DD')
                            );
                            setDaysInterval(365);
                            setDaysForExpiration(30);
                        }}
                    >
                        INDIETRO
                    </Button>
                )}
                {serviceSelected && (
                    <Button
                        disabled={
                            dateStart === null ||
                            daysInterval === null ||
                            daysForExpiration === null
                        }
                        onClick={handleSaveButtonClick}
                    >
                        TERMINA E SALVA
                    </Button>
                )}
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogAddElementService;
