export const API_PATH = '/api/backend/';
export const BACKEND_BASE_URL = process.env.REACT_APP_BASE_URL + API_PATH;

export const LOGIN_URL = `${BACKEND_BASE_URL}o/token/`;
export const USER_PROFILE_URL = `${BACKEND_BASE_URL}users/me/`;
export const BACKEND_CLIENT_ID = 'it.netcreo.greenbeat';
export const BACKEND_GRANT_TYPE = 'password';

export const TENANT_APIVIEW_URL = `${BACKEND_BASE_URL}tenant/`;

export const SETTINGS_URL = `${BACKEND_BASE_URL}settings/`;
export const ATTACHMENTS_URL = `${BACKEND_BASE_URL}attachments/`;

export const GEOCODING_APIVIEW_URL = `${BACKEND_BASE_URL}geocoding/`;
export const MONITORING_APIVIEW_URL = `${BACKEND_BASE_URL}monitoring/`;
export const MONITORING_ELEMENTS_URL = `${BACKEND_BASE_URL}monitoring_elements/`;
export const MONITORING_REPORTS_URL = `${BACKEND_BASE_URL}monitoring_reports/`;
export const MONITORING_PERIODIC_INSPECTION_URL = `${BACKEND_BASE_URL}monitoring_periodic_inspections/`;
export const MONITORING_PHASES_URL = `${BACKEND_BASE_URL}monitoring_phases/`;
export const MONITORING_CAMPAIGNS_URL = `${BACKEND_BASE_URL}monitoring_campaigns/`;
export const MONITORING_TEST_REPORTS_URL = `${BACKEND_BASE_URL}monitoring_test_reports/`;
export const MONITORING_SIMPLE_REPORTS_URL = `${BACKEND_BASE_URL}monitoring_simple_reports/`;
export const MARK_NOTIFICATIONS_AS_READ_URL = `${BACKEND_BASE_URL}mark_notifications_as_read/`;
export const UPDATE_ELEMENT_SERVICES_APIVIEW_URL = `${BACKEND_BASE_URL}update_services/`;
export const REQUIRED_ACTIONS_URL = `${BACKEND_BASE_URL}required_actions/`;

export const ADD_ELEMENT_SERVICE_PATH = 'add_service/';
export const GET_NEXT_ELEMENT_ID_BY_CATEGORY = 'get_next_id_by_category/';
export const GET_NEXT_ELEMENT_ID_BY_MATRIX = 'get_next_id_by_matrix/';
export const GET_TECHNICIAN_ELEMENTS_PATH = 'get_technician_elements/';
export const ASSIGN_PATH = 'assign/';
export const UPDATE_WITH_INTERVENTION_PATH = 'update_with_intervention/';
export const CLOSE_PATH = 'close/';
