import moment from 'moment';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ManageMonitoringSimpleReportPayload,
    MonitoringElementService,
} from '../../../models/monitoring';
import { closeDialog } from '../../../store/dialog/dialog.action';
import { selectViewMode } from '../../../store/filters/filters.selector';
import {
    getMonitoringStart,
    getRequiredActionsStart,
} from '../../../store/monitoring/monitoring.action';
import {
    confirmSimpleReportStart,
    refuseSimpleReportStart,
} from '../../../store/simple-report/simple-report.action';
import DialogManageNewSimpleReportAssignElementStep from './steps/dialog-manage-new-simple-report-assign-element-step';
import DialogManageNewSimpleReportConfirmStep from './steps/dialog-manage-new-simple-report-confirm-step';
import DialogManageNewSimpleReportRefuseStep from './steps/dialog-manage-new-simple-report-refuse-step';
import DialogManageNewSimpleReportServiceStep from './steps/dialog-manage-new-simple-report-service-step/dialog-manage-new-simple-report-service-step.component';
import DialogManageNewSimpleReportSeverityStep from './steps/dialog-manage-new-simple-report-severity-step/dialog-manage-new-simple-report-severity-step.component';

const DialogManageNewSimpleReport = ({
    inputData,
    onRefuse,
    onSave,
}: {
    inputData: any;
    onRefuse: () => void;
    onSave: () => void;
}) => {
    const viewMode = useSelector(selectViewMode);

    const [step, setStep] = useState<number>(1);
    const [report, setReport] = useState<
        Partial<ManageMonitoringSimpleReportPayload>
    >({
        simple_report_id: inputData.id,
        name: inputData.name,
        reporting_notes: inputData.notes,
        reporting_date: moment(new Date()).format('YYYY-MM-DD'),
    });
    const [selectedElementId, setSelectedElementId] = useState<number | null>(
        null
    );
    const [selectedElementName, setSelectedElementName] = useState<
        string | null
    >(null);
    const [selectedElementServices, setSelectedElementServices] = useState<
        MonitoringElementService[]
    >([]);

    const dispatch = useDispatch();

    const handleNextStep = (
        properties: Partial<ManageMonitoringSimpleReportPayload>,
        save = false
    ) => {
        setReport((prevState) => {
            return {
                ...prevState,
                ...properties,
            };
        });
        if (!save) {
            setStep(step + 1);
        }
        if (save) {
            handleSave({
                ...report,
                ...properties,
            });
        }
    };

    const handlePreviousStep = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const handleSave = async (
        report: Partial<ManageMonitoringSimpleReportPayload>
    ) => {
        dispatch(
            confirmSimpleReportStart(report, () => {
                dispatch(getRequiredActionsStart());
                dispatch(getMonitoringStart(viewMode));
                dispatch(closeDialog());
                onRefuse();
            })
        );
    };

    const handleRefuse = (id: number, notes: string) => {
        dispatch(
            refuseSimpleReportStart(id, notes, () => {
                dispatch(getRequiredActionsStart());
                dispatch(getMonitoringStart(viewMode));
                dispatch(closeDialog());
                onRefuse();
            })
        );
    };

    return (
        <Fragment>
            {step === 0 && (
                <DialogManageNewSimpleReportRefuseStep
                    id={inputData.id}
                    onNext={handleNextStep}
                    onConfirm={handleRefuse}
                />
            )}
            {step === 1 && (
                <DialogManageNewSimpleReportConfirmStep
                    id={inputData.id}
                    name={inputData.name}
                    notes={inputData.notes}
                    images={inputData.images}
                    onNext={handleNextStep}
                    onPrevious={handlePreviousStep}
                />
            )}
            {step === 2 && (
                <DialogManageNewSimpleReportAssignElementStep
                    selectedElementId={selectedElementId}
                    selectedElementName={selectedElementName}
                    simpleReportId={inputData.id}
                    onElementSelected={(id, name, elementServices) => {
                        setSelectedElementId(id);
                        setSelectedElementName(name);
                        setSelectedElementServices(elementServices);
                    }}
                    onElementUnselected={() => {
                        setSelectedElementId(null);
                        setSelectedElementName(null);
                    }}
                    onNext={handleNextStep}
                    onPrevious={handlePreviousStep}
                />
            )}
            {step === 3 && selectedElementId && (
                <DialogManageNewSimpleReportServiceStep
                    elementServices={selectedElementServices}
                    elementServiceSelected={0}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 4 && (
                <DialogManageNewSimpleReportSeverityStep
                    onPrevious={handlePreviousStep}
                    onNext={(properties) => handleNextStep(properties, true)}
                />
            )}
        </Fragment>
    );
};

export default DialogManageNewSimpleReport;
