import { takeLatest, put, all, call } from 'typed-redux-saga/macro';
import { MAP_ACTION_TYPES } from './map.types';
import { GeocodeAddressStart, ReverseGeocodeCoordsStart, geocodeAddressFailed, geocodeAddressSuccess, reverseGeocodeCoordsFailed, reverseGeocodeCoordsSuccess } from './map.action';
import { apiRequestWrapper } from '../../utils/api/api.utils';
import { GEOCODING_APIVIEW_URL } from '../../constants/api.constants';
import { setIsLoading } from '../loading/loading.action';
import { setIsError } from '../error/error.action';

export function* geocodeAddress({
    payload: { address },
}: GeocodeAddressStart) {
    yield* put(setIsLoading(true));
    try {
        const geocodingResponse = yield* call(
            apiRequestWrapper, `${GEOCODING_APIVIEW_URL}?address=${address}`, 'GET', null
        );

        if (geocodingResponse) {
            yield* put(geocodeAddressSuccess(geocodingResponse.result.address, geocodingResponse.result.lng, geocodingResponse.result.lat));
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(geocodeAddressFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* reverseGeocodeCoords({
    payload: { lat, lng },
}: ReverseGeocodeCoordsStart) {
    yield* put(setIsLoading(true));
    try {
        const reverseGeocodingResponse = yield* call(
            apiRequestWrapper, `${GEOCODING_APIVIEW_URL}?lat=${lng}&lng=${lat}`, 'GET', null
        );

        if (reverseGeocodingResponse) {
            yield* put(reverseGeocodeCoordsSuccess(reverseGeocodingResponse.result.address,
                reverseGeocodingResponse.result.lng, reverseGeocodingResponse.result.lat));
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(reverseGeocodeCoordsFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onGeocodeAddressStart() {
    yield* takeLatest(MAP_ACTION_TYPES.GEOCODE_ADDRESS_START, geocodeAddress);
}

export function* onReverseGeocodeAddressStart() {
    yield* takeLatest(MAP_ACTION_TYPES.REVERSE_GEOCODE_COORDS_START, reverseGeocodeCoords);
}

export function* mapSaga() {
    yield* all([
        call(onGeocodeAddressStart),
        call(onReverseGeocodeAddressStart),
    ]);
}