import LockOpenTwoTone from '@mui/icons-material/LockOpenTwoTone';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import { Fragment, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LOGIN_PAGE_ROUTE } from '../../../constants/routes.constants';
import { signOut } from '../../../store/auth/auth.action';
import { selectUserProfile } from '../../../store/auth/auth.selector';
import {
    MenuUserBox,
    MenuUserBoxDescription,
    MenuUserBoxLabel,
    UserBoxButton,
    UserBoxText,
} from './gb-user-box.styles';

const GbUserBox = () => {
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const userProfile = useSelector(selectUserProfile);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const doLogout = () => {
        navigate(LOGIN_PAGE_ROUTE);
        dispatch(signOut());
    };

    const splitEmail = userProfile!.email.split('@');
    const firstLetter = userProfile!.first_name
        ? userProfile!.first_name[0]
        : splitEmail[0][0];
    const secondLetter = userProfile!.last_name
        ? userProfile!.last_name[0]
        : splitEmail[1][0];
    const textAvatar = `${firstLetter.toUpperCase()} ${secondLetter.toUpperCase()}`;

    return (
        <Fragment>
            <UserBoxButton
                color="secondary"
                ref={ref}
                onClick={() => setOpen(true)}
            >
                <Avatar alt={textAvatar} sx={{ m: 1, bgcolor: 'primary.main' }}>
                    {textAvatar}
                </Avatar>
            </UserBoxButton>
            <Popover
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuUserBox sx={{ minWidth: 210 }} display="flex">
                    <Avatar
                        variant="rounded"
                        alt={`${userProfile!.first_name} ${
                            userProfile!.last_name
                        }`}
                    />
                    <UserBoxText>
                        <MenuUserBoxLabel variant="h5">{`${
                            userProfile!.first_name
                        } ${userProfile!.last_name}`}</MenuUserBoxLabel>
                        <MenuUserBoxDescription variant="body2">
                            {`${userProfile!.email}`}
                        </MenuUserBoxDescription>
                    </UserBoxText>
                </MenuUserBox>
                <Divider sx={{ mb: 0 }} />
                <Divider />
                <Box sx={{ m: 1 }}>
                    <Button onClick={doLogout} color="primary" fullWidth>
                        <LockOpenTwoTone sx={{ mr: 1 }} />
                        Sign out
                    </Button>
                </Box>
            </Popover>
        </Fragment>
    );
};

export default GbUserBox;
