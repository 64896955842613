import { Box, Button } from '@mui/material';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import { PeriodicInspectionPayload } from '../../../../../models/monitoring';
import { setIsError } from '../../../../../store/error/error.action';
import {
    FlexBoxFullH,
    FlexBoxFullWHColumn,
} from '../../../../layout/layout.styles';
import GBDialogActions from '../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../ui/gb-dialog-title/gb-dialog-title.component';

const DialogAddPeriodicInspectionSignatureStep = ({
    onPrevious,
    onNext,
}: {
    onPrevious: () => void;
    onNext: (properties: Partial<PeriodicInspectionPayload>) => void;
}) => {
    const dispatch = useDispatch();

    let sigPad: SignatureCanvas | null;
    const [signature, setSignature] = useState<string | null>(null);

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    const handleNextButtonClick = () => {
        if (!signature) {
            dispatch(setIsError(true, 'Inserire la firma'));
            return;
        }
        onNext({
            signature: signature,
        });
    };

    return (
        <Fragment>
            <GBDialogTitle title="INSERISCI FIRMA" />
            <GBDialogContent>
                <FlexBoxFullWHColumn>
                    <FlexBoxFullH width={1} justifyContent={'center'}>
                        <Box
                            border={'1px solid rgba(75, 75, 75, 0.5)'}
                            borderRadius={'10px'}
                            padding={'15px 15px'}
                            position={'relative'}
                            width={1}
                        >
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    position: 'absolute',
                                    bottom: 10,
                                    right: 10,
                                }}
                                onClick={() => {
                                    sigPad?.clear();
                                    setSignature(null);
                                }}
                            >
                                RESET
                            </Button>
                            <Box
                                sx={{
                                    minHeight: {
                                        xs: '300px',
                                        sm: '300px',
                                        md: '300px',
                                    },

                                    maxHeight: {
                                        xs: '300px',
                                        sm: '300px',
                                        md: '300px',
                                    },
                                }}
                            >
                                <SignatureCanvas
                                    onEnd={(e) =>
                                        setSignature(
                                            sigPad
                                                ?.getTrimmedCanvas()
                                                .toDataURL('image/png') ?? null
                                        )
                                    }
                                    ref={(ref) => {
                                        sigPad = ref;
                                    }}
                                    canvasProps={{
                                        width: '248',
                                        height: '300',
                                        className: 'sigCanvas',
                                    }}
                                />
                            </Box>
                        </Box>
                    </FlexBoxFullH>
                </FlexBoxFullWHColumn>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handlePreviousButtonClick}>INDIETRO</Button>
                <Button onClick={handleNextButtonClick}>TERMINA E SALVA</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogAddPeriodicInspectionSignatureStep;
