import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetFilters } from '../../../store/filters/filters.action';
import { selectViewMode } from '../../../store/filters/filters.selector';
import { resetMap } from '../../../store/map/map.action';
import { getMonitoringStart } from '../../../store/monitoring/monitoring.action';
import { getParamsStart } from '../../../store/params/params.action';
import { getTenantStart } from '../../../store/tenant/tenant.action';
import { selectTenant } from '../../../store/tenant/tenant.selector';

const GBTenantLogo = () => {
    const dispatch = useDispatch();

    const tenant = useSelector(selectTenant);

    const viewMode = useSelector(selectViewMode);

    const handleTenantLogoClick = () => {
        dispatch(resetMap());
        dispatch(resetFilters());
        dispatch(getTenantStart());
        dispatch(getMonitoringStart(viewMode));
        dispatch(getParamsStart());
    };

    useEffect(() => {
        dispatch(getTenantStart());
    }, [dispatch]);

    return (
        <Box width={1} height={1} display={'flex'} alignItems={'center'} py={1}>
            {tenant && (
                <img
                    onClick={handleTenantLogoClick}
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        cursor: 'pointer',
                    }}
                    src={`${process.env.REACT_APP_BASE_URL}${tenant.logo}`}
                    alt={'Logo cliente'}
                />
            )}
        </Box>
    );
};

export default GBTenantLogo;
