import { createSelector } from 'reselect';

import { RootState } from '../store';

import { LoadingState } from './loading.reducer';

export const selectLoadingReducer = (state: RootState): LoadingState => state.loading;

export const selectIsLoading = createSelector(
    selectLoadingReducer,
    (loading) => loading.isLoading
);





