import { MonitoringCampaign, MonitoringPhase, MonitoringTestReport } from '../../models/monitoring';
import { Action, ActionWithPayload, createAction, withMatcher } from '../../utils/reducer/reducer.utils';
import { PHASE_ACTION_TYPES } from './phase.types';


export type GetPhaseStart = ActionWithPayload<
    PHASE_ACTION_TYPES.GET_PHASE_START, { id: string; onComplete: (phase: MonitoringPhase) => void; }
>;

export type GetPhaseSuccess = ActionWithPayload<
    PHASE_ACTION_TYPES.GET_PHASE_SUCCESS,
    { phase: MonitoringPhase; }
>;

export type GetPhaseFailed = ActionWithPayload<
    PHASE_ACTION_TYPES.GET_PHASE_FAILED,
    string
>;

export type AddPhaseStart = ActionWithPayload<
    PHASE_ACTION_TYPES.ADD_PHASE_START,
    { phase: Partial<MonitoringPhase>; campaigns: Partial<MonitoringCampaign>[]; onComplete: (id: string) => void; }
>;

export type AddPhaseSuccess = ActionWithPayload<
    PHASE_ACTION_TYPES.ADD_PHASE_SUCCESS,
    { newPhaseId: number; }
>;

export type AddPhaseFailed = ActionWithPayload<
    PHASE_ACTION_TYPES.ADD_PHASE_FAILED,
    string
>;

export type GetPhaseCampaignsStart = ActionWithPayload<
    PHASE_ACTION_TYPES.GET_PHASE_CAMPAIGNS_START, { phaseId: string; matrixId: string | null; onComplete: (campaigns: MonitoringCampaign[]) => void; }
>;

export type GetPhaseCampaignsSuccess = ActionWithPayload<
    PHASE_ACTION_TYPES.GET_PHASE_CAMPAIGNS_SUCCESS,
    { campaigns: MonitoringCampaign[]; }
>;

export type GetPhaseCampaignsFailed = ActionWithPayload<
    PHASE_ACTION_TYPES.GET_PHASE_CAMPAIGNS_FAILED,
    string
>;

export type GetPhaseCampaignTestReportsStart = ActionWithPayload<
    PHASE_ACTION_TYPES.GET_PHASE_CAMPAIGN_TEST_REPORTS_START,
    { elementId: number; campaignId: string; onComplete: (testReports: MonitoringTestReport[]) => void; }
>;

export type GetPhaseCampaignTestReportsSuccess = ActionWithPayload<
    PHASE_ACTION_TYPES.GET_PHASE_CAMPAIGN_TEST_REPORTS_SUCCESS,
    { testReports: MonitoringTestReport[]; }
>;

export type GetPhaseCampaignTestReportsFailed = ActionWithPayload<
    PHASE_ACTION_TYPES.GET_PHASE_CAMPAIGN_TEST_REPORTS_FAILED,
    string
>;

export type AddPhaseCampaignTestReportStart = ActionWithPayload<
    PHASE_ACTION_TYPES.ADD_PHASE_CAMPAIGN_TEST_REPORT_START,
    { testReport: Partial<MonitoringTestReport>; onComplete: () => void; }
>;

export type AddPhaseCampaignTestReportSuccess = Action<
    PHASE_ACTION_TYPES.ADD_PHASE_CAMPAIGN_TEST_REPORT_SUCCESS
>;

export type AddPhaseCampaignTestReportFailed = ActionWithPayload<
    PHASE_ACTION_TYPES.ADD_PHASE_CAMPAIGN_TEST_REPORT_FAILED,
    string
>;

export const getPhaseStart = withMatcher(
    (id: string, onComplete: (phase: MonitoringPhase) => void): GetPhaseStart =>
        createAction(PHASE_ACTION_TYPES.GET_PHASE_START, { id, onComplete })
);

export const getPhaseSuccess = withMatcher(
    (phase: MonitoringPhase): GetPhaseSuccess =>
        createAction(PHASE_ACTION_TYPES.GET_PHASE_SUCCESS, { phase })
);

export const getPhaseFailed = withMatcher(
    (error: string): GetPhaseFailed =>
        createAction(PHASE_ACTION_TYPES.GET_PHASE_FAILED, error)
);

export const addPhaseStart = withMatcher(
    (phase: Partial<MonitoringPhase>, campaigns: Partial<MonitoringCampaign>[], onComplete: (id: string) => void): AddPhaseStart =>
        createAction(PHASE_ACTION_TYPES.ADD_PHASE_START, { phase, campaigns, onComplete })
);

export const addPhaseSuccess = withMatcher(
    (newPhaseId: number): AddPhaseSuccess =>
        createAction(PHASE_ACTION_TYPES.ADD_PHASE_SUCCESS, { newPhaseId })
);

export const addPhaseFailed = withMatcher(
    (error: string): AddPhaseFailed =>
        createAction(PHASE_ACTION_TYPES.ADD_PHASE_FAILED, error)
);

export const getPhaseCampaignsStart = withMatcher(
    (phaseId: string, matrixId: string | null, onComplete: (campaigns: MonitoringCampaign[]) => void): GetPhaseCampaignsStart =>
        createAction(PHASE_ACTION_TYPES.GET_PHASE_CAMPAIGNS_START, { phaseId, matrixId, onComplete })
);

export const getPhaseCampaignsSuccess = withMatcher(
    (campaigns: MonitoringCampaign[]): GetPhaseCampaignsSuccess =>
        createAction(PHASE_ACTION_TYPES.GET_PHASE_CAMPAIGNS_SUCCESS, { campaigns })
);

export const getPhaseCampaignsFailed = withMatcher(
    (error: string): GetPhaseCampaignsFailed =>
        createAction(PHASE_ACTION_TYPES.GET_PHASE_CAMPAIGNS_FAILED, error)
);

export const getPhaseCampaignTestReportsStart = withMatcher(
    (elementId: number, campaignId: string, onComplete: (testReports: MonitoringTestReport[]) => void): GetPhaseCampaignTestReportsStart =>
        createAction(PHASE_ACTION_TYPES.GET_PHASE_CAMPAIGN_TEST_REPORTS_START, { elementId, campaignId, onComplete })
);

export const getPhaseCampaignTestReportsSuccess = withMatcher(
    (testReports: MonitoringTestReport[]): GetPhaseCampaignTestReportsSuccess =>
        createAction(PHASE_ACTION_TYPES.GET_PHASE_CAMPAIGN_TEST_REPORTS_SUCCESS, { testReports })
);

export const getPhaseCampaignTestReportsFailed = withMatcher(
    (error: string): GetPhaseCampaignTestReportsFailed =>
        createAction(PHASE_ACTION_TYPES.GET_PHASE_CAMPAIGN_TEST_REPORTS_FAILED, error)
);

export const addPhaseCampaignTestReportStart = withMatcher(
    (testReport: Partial<MonitoringTestReport>, onComplete: () => void): AddPhaseCampaignTestReportStart =>
        createAction(PHASE_ACTION_TYPES.ADD_PHASE_CAMPAIGN_TEST_REPORT_START, { testReport, onComplete })
);

export const addPhaseCampaignTestReportSuccess = withMatcher(
    (): AddPhaseCampaignTestReportSuccess =>
        createAction(PHASE_ACTION_TYPES.ADD_PHASE_CAMPAIGN_TEST_REPORT_SUCCESS)
);

export const addPhaseCampaignTestReportFailed = withMatcher(
    (error: string): AddPhaseCampaignTestReportFailed =>
        createAction(PHASE_ACTION_TYPES.ADD_PHASE_CAMPAIGN_TEST_REPORT_FAILED, error)
);
