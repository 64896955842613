import { Sync } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfile } from '../../../store/auth/auth.selector';
import { getTechnicianElementsStart } from '../../../store/element/element.action';
import { selectViewMode } from '../../../store/filters/filters.selector';
import { getMonitoringStart } from '../../../store/monitoring/monitoring.action';
import { isGreenbeatTechnician } from '../../../utils/permissions/permissions.utils';

const GBSyncBox = () => {
    const dispatch = useDispatch();

    const viewMode = useSelector(selectViewMode);
    const userProfile = useSelector(selectUserProfile);

    const syncData = () => {
        dispatch(getMonitoringStart(viewMode));
        if (userProfile && isGreenbeatTechnician(userProfile)) {
            dispatch(getTechnicianElementsStart(() => {}));
        }
    };

    return (
        <IconButton
            onClick={syncData}
            sx={{ paddingY: 0, width: '76px', height: '100%' }}
        >
            <Sync sx={{ width: 40, height: 40 }} color="primary" />
        </IconButton>
    );
};

export default GBSyncBox;
