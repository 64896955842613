export enum REPORT_ACTION_TYPES {
    GET_REPORT_START = 'report/GET_REPORT_START',
    GET_REPORT_SUCCESS = 'report/GET_REPORT_SUCCESS',
    GET_REPORT_FAILED = 'report/GET_REPORT_FAILED',
    ADD_REPORT_START = 'report/ADD_REPORT_START',
    ADD_REPORT_SUCCESS = 'report/ADD_REPORT_SUCCESS',
    ADD_REPORT_FAILED = 'report/ADD_REPORT_FAILED',
    ASSIGN_REPORT_START = 'report/ASSIGN_REPORT_START',
    ASSIGN_REPORT_SUCCESS = 'report/ASSIGN_REPORT_SUCCESS',
    ASSIGN_REPORT_FAILED = 'report/ASSIGN_REPORT_FAILED',
    UPDATE_REPORT_WITH_INTERVENTION_START = 'report/UPDATE_REPORT_WITH_INTERVENTION_START',
    UPDATE_REPORT_WITH_INTERVENTION_SUCCESS = 'report/UPDATE_REPORT_WITH_INTERVENTION_SUCCESS',
    UPDATE_REPORT_WITH_INTERVENTION_FAILED = 'report/UPDATE_REPORT_WITH_INTERVENTION_FAILED',
    CLOSE_REPORT_START = 'report/CLOSE_REPORT_START',
    CLOSE_REPORT_SUCCESS = 'report/CLOSE_REPORT_SUCCESS',
    CLOSE_REPORT_FAILED = 'report/CLOSE_REPORT_FAILED',
}