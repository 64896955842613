import {
    Box,
    Button,
    FormControlLabel,
    FormGroup,
    InputLabel,
} from '@mui/material';
import { ChangeEvent, Fragment, useState } from 'react';
import {
    KeyValueModel,
    MonitoringService,
    PeriodicInspectionPayload,
} from '../../../../../models/monitoring';
import {
    FlexBoxFullH,
    FlexBoxFullWHColumn,
} from '../../../../layout/layout.styles';
import GBDialogActions from '../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../ui/gb-dialog-title/gb-dialog-title.component';
import { ChecklistSwitch } from './dialog-add-periodic-inspection-checklist-step.styles';

const DialogAddPeriodicInspectionChecklistStep = ({
    service,
    checklist_values,
    onPrevious,
    onNext,
}: {
    service: MonitoringService;
    checklist_values: KeyValueModel[] | undefined;
    onPrevious: () => void;
    onNext: (properties: Partial<PeriodicInspectionPayload>) => void;
}) => {
    const [currentChecklistValues, setCurrentChecklistValues] = useState<
        KeyValueModel[]
    >(
        service.checklist.map((checklistItem) => {
            const checklistValue = checklist_values?.find(
                (item) => item.key === checklistItem.key
            );
            return {
                key: checklistItem.key,
                value: checklistValue ? checklistValue.value : false,
            };
        })
    );

    const handleChecklistValueChange = (
        e: ChangeEvent<HTMLInputElement>,
        key: string
    ) => {
        const newChecklistValues = currentChecklistValues.map((item) => {
            if (item.key === key) {
                return {
                    key: key,
                    value: e.target.checked,
                };
            }
            return item;
        });
        setCurrentChecklistValues(newChecklistValues);
    };

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    const handleNextButtonClick = () => {
        onNext({ checklist_values: currentChecklistValues });
    };

    return (
        <Fragment>
            <GBDialogTitle title="COMPILA CHECKLIST" />
            <GBDialogContent>
                <FlexBoxFullWHColumn>
                    <FlexBoxFullH width={1} justifyContent={'center'}>
                        <Box
                            border={'1px solid rgba(75, 75, 75, 0.5)'}
                            borderRadius={'10px'}
                            padding={'15px 15px'}
                            position={'relative'}
                            width={1}
                        >
                            <InputLabel
                                sx={{
                                    position: 'absolute',
                                    top: -10,
                                    left: 7,
                                    fontSize: '0.75em',
                                    fontWeight: 'bold',
                                    paddingX: '5px',
                                    backgroundColor: 'white',
                                    color: (theme) =>
                                        theme.palette.text.primary,
                                }}
                            >
                                {service.name}
                            </InputLabel>
                            <Box
                                sx={{
                                    height: '100%',
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                }}
                            >
                                <FormGroup sx={{ pl: 1 }}>
                                    {service.checklist.map((checklistItem) => (
                                        <FormControlLabel
                                            key={checklistItem.key}
                                            control={
                                                <ChecklistSwitch
                                                    checked={
                                                        currentChecklistValues.find(
                                                            (item) =>
                                                                item.key ===
                                                                checklistItem.key
                                                        )?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        handleChecklistValueChange(
                                                            e,
                                                            checklistItem.key
                                                        )
                                                    }
                                                    sx={{ m: 1, mr: 2 }}
                                                />
                                            }
                                            label={checklistItem.label}
                                        />
                                    ))}
                                </FormGroup>
                            </Box>
                        </Box>
                    </FlexBoxFullH>
                </FlexBoxFullWHColumn>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handlePreviousButtonClick}>INDIETRO</Button>
                <Button onClick={handleNextButtonClick}>AVANTI</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogAddPeriodicInspectionChecklistStep;
