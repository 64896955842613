import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import LayoutLogin from '../../components/layout/layout-login.component';
import { getTenantStart } from '../../store/tenant/tenant.action';

const NavigationLogin = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTenantStart());
    }, [dispatch]);

    return <LayoutLogin />;
};

export default NavigationLogin;
