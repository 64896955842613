import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const GBLoadingSpinner = () => {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: () => 1500 }} open>
            <CircularProgress color="primary" />
        </Backdrop>
    );
};

export default GBLoadingSpinner;
