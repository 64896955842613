import { ReactNode } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { GreenbeatTheme } from './greenbeat-theme';

export default function GreenbeatThemeProvider({
    children,
}: {
    children: ReactNode;
}) {
    return (
        <ThemeProvider theme={GreenbeatTheme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
}
