import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { PhasePayload } from '../../../../../models/monitoring';
import GBTextArea from '../../../../ui/gb-text-area/gb-text-area.component';

const DialogAddPhaseInfo = ({
    phase,
    onChange,
}: {
    phase: Partial<PhasePayload>;
    onChange: (phase: Partial<PhasePayload>) => void;
}) => {
    let phaseCode = phase.code ?? null;
    let phaseName = phase.name ?? null;
    let phaseDescription = phase.description ?? null;
    let phaseStartDate = moment(phase.start_date ?? new Date())
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0);
    let phaseEndDate = phase.end_date
        ? moment(phase.end_date)
              .set('hour', 23)
              .set('minute', 59)
              .set('second', 59)
        : moment(new Date())
              .set('hour', 23)
              .set('minute', 59)
              .set('second', 59)
              .add(1, 'months');

    const onChangeField = () => {
        const phaseToUpdate: Partial<PhasePayload> = {
            start_date: phaseStartDate.format('YYYY-MM-DD'),
            end_date: phaseEndDate.format('YYYY-MM-DD'),
            matrices: phase.matrices,
        };
        phaseCode && (phaseToUpdate.code = phaseCode);
        phaseName && (phaseToUpdate.name = phaseName);
        phaseDescription && (phaseToUpdate.description = phaseDescription);
        onChange(phaseToUpdate);
    };

    return (
        <Grid container rowSpacing={2} columnSpacing={2} pt={1}>
            <Grid item xs={6}>
                <TextField
                    required
                    label="Codice fase"
                    defaultValue={phaseCode}
                    onChange={(e) => {
                        phaseCode = e.target.value;
                        onChangeField();
                    }}
                    sx={{
                        width: '100%',
                    }}
                    inputProps={{ maxLength: 30 }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    required
                    label="Nome fase"
                    defaultValue={phaseName}
                    onChange={(e) => {
                        phaseName = e.target.value;
                        onChangeField();
                    }}
                    sx={{
                        width: '100%',
                    }}
                    inputProps={{ maxLength: 30 }}
                />
            </Grid>
            <Grid item xs={6}>
                <DatePicker
                    label="Data Inizio"
                    sx={{
                        width: '100%',
                        'input:disabled': {
                            WebkitTextFillColor: (theme) =>
                                `${theme.colors.alpha.black[100]}`,
                        },
                    }}
                    slotProps={{
                        textField: {
                            disabled: true,
                        },
                        popper: {
                            placement: 'bottom-end',
                        },
                    }}
                    format="DD/MM/YYYY"
                    value={phaseStartDate}
                    onChange={(newValue) => {
                        phaseStartDate = newValue!
                            .set('hour', 0)
                            .set('minute', 0)
                            .set('second', 0);
                        onChangeField();
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <DatePicker
                    label="Data Fine"
                    sx={{
                        width: '100%',
                        'input:disabled': {
                            WebkitTextFillColor: (theme) =>
                                `${theme.colors.alpha.black[100]}`,
                        },
                    }}
                    slotProps={{
                        textField: {
                            disabled: true,
                        },
                        popper: {
                            placement: 'bottom-end',
                        },
                    }}
                    format="DD/MM/YYYY"
                    value={phaseEndDate}
                    onChange={(newValue) => {
                        phaseEndDate = newValue!
                            .set('hour', 23)
                            .set('minute', 59)
                            .set('second', 59);
                        onChangeField();
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <GBTextArea
                    label="Descrizione"
                    multiline
                    rows={6}
                    defaultValue={phaseDescription}
                    onChange={(e) => {
                        phaseDescription = e.target.value;
                        onChangeField();
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default DialogAddPhaseInfo;
