import { createSelector } from 'reselect';

import { RootState } from '../store';

import { FiltersState } from './filters.reducer';

export const selectFiltersReducer = (state: RootState): FiltersState => state.filters;

export const selectViewMode = createSelector(
    selectFiltersReducer,
    (filters) => filters.viewMode
);

export const selectSearch = createSelector(
    selectFiltersReducer,
    (filters) => filters.search
);

export const selectShowMonitoringPoints = createSelector(
    selectFiltersReducer,
    (filters) => filters.showMonitoringPoints
);

export const selectShowMonitoringZones = createSelector(
    selectFiltersReducer,
    (filters) => filters.showMonitoringZones
);

export const selectShowMonitoringPaths = createSelector(
    selectFiltersReducer,
    (filters) => filters.showMonitoringPaths
);

export const selectMonitoringCategoriesIds = createSelector(
    selectFiltersReducer,
    (filters) => filters.monitoringCategoriesIds
);

export const selectMonitoringMatricesIds = createSelector(
    selectFiltersReducer,
    (filters) => filters.monitoringMatricesIds
);

export const selectMonitoringServicesIds = createSelector(
    selectFiltersReducer,
    (filters) => filters.monitoringServicesIds
);



