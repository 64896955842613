export enum PHASE_ACTION_TYPES {
    GET_PHASE_START = 'phase/GET_PHASE_START',
    GET_PHASE_SUCCESS = 'phase/GET_PHASE_SUCCESS',
    GET_PHASE_FAILED = 'phase/GET_PHASE_FAILED',
    ADD_PHASE_START = 'phase/ADD_PHASE_START',
    ADD_PHASE_SUCCESS = 'phase/ADD_PHASE_SUCCESS',
    ADD_PHASE_FAILED = 'phase/ADD_PHASE_FAILED',
    GET_PHASE_CAMPAIGNS_START = 'phase/GET_PHASE_CAMPAIGNS_START',
    GET_PHASE_CAMPAIGNS_SUCCESS = 'phase/GET_PHASE_CAMPAIGNS_SUCCESS',
    GET_PHASE_CAMPAIGNS_FAILED = 'phase/GET_PHASE_CAMPAIGNS_FAILED',
    GET_PHASE_CAMPAIGN_TEST_REPORTS_START = 'phase/GET_PHASE_CAMPAIGN_TEST_REPORTS_START',
    GET_PHASE_CAMPAIGN_TEST_REPORTS_SUCCESS = 'phase/GET_PHASE_CAMPAIGN_TEST_REPORTS_SUCCESS',
    GET_PHASE_CAMPAIGN_TEST_REPORTS_FAILED = 'phase/GET_PHASE_CAMPAIGN_TEST_REPORTS_FAILED',
    ADD_PHASE_CAMPAIGN_TEST_REPORT_START = 'phase/ADD_PHASE_CAMPAIGN_TEST_REPORT_START',
    ADD_PHASE_CAMPAIGN_TEST_REPORT_SUCCESS = 'phase/ADD_PHASE_CAMPAIGN_TEST_REPORT_SUCCESS',
    ADD_PHASE_CAMPAIGN_TEST_REPORT_FAILED = 'phase/ADD_PHASE_CAMPAIGN_TEST_REPORT_FAILED',
}