import { ReactNode } from 'react';
import { BootstrapDialog } from './gb-dialog.styles';
import { useSelector } from 'react-redux';
import { selectDialogSize } from '../../../store/dialog/dialog.selector';

const GBDialog = ({ children }: { children: ReactNode }) => {
    const dialogSize = useSelector(selectDialogSize);

    return (
        <BootstrapDialog open={true} fullWidth maxWidth={dialogSize}>
            {children}
        </BootstrapDialog>
    );
};

export default GBDialog;
