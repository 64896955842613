import { Coordinate } from 'ol/coordinate';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SimpleReportPayload } from '../../../models/monitoring';
import { closeDialog } from '../../../store/dialog/dialog.action';
import { setIsError } from '../../../store/error/error.action';
import { addSimpleReportStart } from '../../../store/simple-report/simple-report.action';
import DialogAddSimpleReportImagesStep from './steps/dialog-add-simple-report-images-step/dialog-add-simple-report-images-step.component';
import DialogAddSimpleReportInfoStep from './steps/dialog-add-simple-report-info-step/dialog-add-simple-report-info-step.component';

const DialogAddSimpleReport = ({
    inputData,
    onSaved,
}: {
    inputData: any;
    onSaved: () => void;
}) => {
    const [step, setStep] = useState<number>(0);
    const [simpleReportToAdd, setSimpleReportToAdd] = useState<
        Partial<SimpleReportPayload>
    >({});
    const dispatch = useDispatch();

    const handleNextStep = (
        properties: Partial<SimpleReportPayload>,
        save = false
    ) => {
        setSimpleReportToAdd((prevState) => {
            return {
                ...prevState,
                ...properties,
            };
        });
        if (!save) {
            setStep(step + 1);
        }
        if (save) {
            handleSave({
                ...simpleReportToAdd,
                ...properties,
            });
        }
    };

    const handlePreviousStep = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const handleSave = async (simpleReport: Partial<SimpleReportPayload>) => {
        if (!simpleReport) {
            dispatch(setIsError(true, 'Dati insufficienti per il salvataggio'));
            return;
        }
        simpleReport.point_lat = (inputData.coordinates as Coordinate)[1];
        simpleReport.point_lon = (inputData.coordinates as Coordinate)[0];
        dispatch(
            addSimpleReportStart(simpleReport, () => {
                onSaved();
                dispatch(closeDialog());
            })
        );
    };

    return (
        <Fragment>
            {step === 0 && (
                <DialogAddSimpleReportInfoStep
                    name={simpleReportToAdd.name}
                    description={simpleReportToAdd.notes}
                    onNext={handleNextStep}
                />
            )}
            {step === 1 && (
                <DialogAddSimpleReportImagesStep
                    images={simpleReportToAdd.images}
                    onPrevious={handlePreviousStep}
                    onNext={(properties) => handleNextStep(properties, true)}
                />
            )}
        </Fragment>
    );
};

export default DialogAddSimpleReport;
