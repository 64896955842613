import { all, call, put, takeLatest } from 'typed-redux-saga/macro';
import { TENANT_APIVIEW_URL } from '../../constants/api.constants';
import { apiRequestWrapper } from '../../utils/api/api.utils';
import { setIsError } from '../error/error.action';
import { setIsLoading } from '../loading/loading.action';
import { getTenantFailed, getTenantSuccess } from './tenant.action';
import { Tenant } from './tenant.reducer';
import { TENANT_ACTION_TYPES } from './tenant.types';


export function* getTenant() {
    yield* put(setIsLoading(true));
    try {
        const tenantResponse = yield* call(
            apiRequestWrapper, TENANT_APIVIEW_URL, 'GET', null
        );

        const tenant = tenantResponse as Tenant;
        if (tenant) {
            yield* put(getTenantSuccess(tenant));
        } else {
            yield* put(getTenantFailed('Nessun cliente trovato'));
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getTenantFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onGetTenantStart() {
    yield* takeLatest(TENANT_ACTION_TYPES.GET_TENANT_START, getTenant);
}

export function* tenantSaga() {
    yield* all([
        call(onGetTenantStart),
    ]);
}
