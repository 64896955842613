import { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import { Notification, RequiredAction } from '../../models/shared';
import { Action, ActionWithPayload, createAction, withMatcher } from '../../utils/reducer/reducer.utils';
import { ViewMode } from '../filters/filters.reducer';
import { ENTITIES, MONITORING_ACTION_TYPES } from './monitoring.types';


export type GetMonitoringStart = ActionWithPayload<
    MONITORING_ACTION_TYPES.GET_MONITORING_START, { viewMode: ViewMode; }
>;

export type GetMonitoringSuccess = ActionWithPayload<
    MONITORING_ACTION_TYPES.GET_MONITORING_SUCCESS,
    { monitoring: GeoJSONFeatureCollection; }
>;

export type GetMonitoringFailed = ActionWithPayload<
    MONITORING_ACTION_TYPES.GET_MONITORING_FAILED,
    string
>;

export type MarkNotificationsAsReadStart = ActionWithPayload<
    MONITORING_ACTION_TYPES.MARK_NOTIFICATIONS_AS_READ_START, number[]
>;

export type MarkNotificationsAsReadSuccess = ActionWithPayload<
    MONITORING_ACTION_TYPES.MARK_NOTIFICATIONS_AS_READ_SUCCESS,
    { notifications: Notification[]; }
>;

export type MarkNotificationsAsReadFailed = ActionWithPayload<
    MONITORING_ACTION_TYPES.MARK_NOTIFICATIONS_AS_READ_FAILED,
    string
>;

export type GetRequiredActionsStart = Action<
    MONITORING_ACTION_TYPES.GET_REQUIRED_ACTIONS_START
>;

export type GetRequiredActionsSuccess = ActionWithPayload<
    MONITORING_ACTION_TYPES.GET_REQUIRED_ACTIONS_SUCCESS,
    { requiredActions: RequiredAction[]; }
>;

export type GetRequiredActionsFailed = ActionWithPayload<
    MONITORING_ACTION_TYPES.GET_REQUIRED_ACTIONS_FAILED,
    string
>;

export type DeleteEntityStart = ActionWithPayload<
    MONITORING_ACTION_TYPES.DELETE_ENTITY_START, { id: number; entity: ENTITIES; onComplete: () => void; }
>;

export type DeleteEntitySuccess = Action<
    MONITORING_ACTION_TYPES.DELETE_ENTITY_SUCCESS
>;

export type DeleteEntityFailed = ActionWithPayload<
    MONITORING_ACTION_TYPES.DELETE_ENTITY_FAILED,
    string
>;

export const getMonitoringStart = withMatcher(
    (viewMode: ViewMode): GetMonitoringStart =>
        createAction(MONITORING_ACTION_TYPES.GET_MONITORING_START, { viewMode })
);

export const getMonitoringSuccess = withMatcher(
    (monitoring: GeoJSONFeatureCollection): GetMonitoringSuccess =>
        createAction(MONITORING_ACTION_TYPES.GET_MONITORING_SUCCESS, { monitoring })
);

export const getMonitoringFailed = withMatcher(
    (error: string): GetMonitoringFailed =>
        createAction(MONITORING_ACTION_TYPES.GET_MONITORING_FAILED, error)
);

export const markNotificationsAsReadStart = withMatcher(
    (notificationsIds: number[]): MarkNotificationsAsReadStart =>
        createAction(MONITORING_ACTION_TYPES.MARK_NOTIFICATIONS_AS_READ_START, notificationsIds)
);

export const markNotificationsAsReadSuccess = withMatcher(
    (notifications: Notification[]): MarkNotificationsAsReadSuccess =>
        createAction(MONITORING_ACTION_TYPES.MARK_NOTIFICATIONS_AS_READ_SUCCESS, { notifications })
);

export const markNotificationsAsReadFailed = withMatcher(
    (error: string): MarkNotificationsAsReadFailed =>
        createAction(MONITORING_ACTION_TYPES.MARK_NOTIFICATIONS_AS_READ_FAILED, error)
);

export const getRequiredActionsStart = withMatcher(
    (): GetRequiredActionsStart =>
        createAction(MONITORING_ACTION_TYPES.GET_REQUIRED_ACTIONS_START)
);

export const getRequiredActionsSuccess = withMatcher(
    (requiredActions: RequiredAction[]): GetRequiredActionsSuccess =>
        createAction(MONITORING_ACTION_TYPES.GET_REQUIRED_ACTIONS_SUCCESS, { requiredActions })
);

export const getRequiredActionsFailed = withMatcher(
    (error: string): GetRequiredActionsFailed =>
        createAction(MONITORING_ACTION_TYPES.GET_REQUIRED_ACTIONS_FAILED, error)
);

export const deleteEntityStart = withMatcher(
    (id: number, entity: ENTITIES, onComplete: () => void): DeleteEntityStart =>
        createAction(MONITORING_ACTION_TYPES.DELETE_ENTITY_START, { id, entity, onComplete })
);

export const deleteEntitySuccess = withMatcher(
    (): DeleteEntitySuccess =>
        createAction(MONITORING_ACTION_TYPES.DELETE_ENTITY_SUCCESS)
);

export const deleteEntityFailed = withMatcher(
    (error: string): DeleteEntityFailed =>
        createAction(MONITORING_ACTION_TYPES.DELETE_ENTITY_FAILED, error)
);
