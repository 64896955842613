import { Theme } from '@mui/material';
import apply from 'ol-mapbox-style';
import Feature, { FeatureLike } from 'ol/Feature';
import Map from 'ol/Map';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import View from 'ol/View';
import { Coordinate } from 'ol/coordinate';
import GeoJSON, { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import { Point } from 'ol/geom';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Icon, RegularShape, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { RefObject } from 'react';
import markerBlackSvg from '../../assets/marker-black.svg';
import markerGreenSvg from '../../assets/marker-green.svg';
import markerWhiteSvg from '../../assets/marker-white.svg';
import myLocationSvg from '../../assets/my-location-icon.svg';
import simpleReportSvg from '../../assets/simple-report-icon.svg';
import {
    ELEMENTS_LAYER_CLASSES,
    GENERIC_POINT_LAYER_CLASS,
    MAPBOX_ROADMAP_URL,
    MAPBOX_SATELLITE_URL,
    MAP_PROJECTION_OL,
    MAP_TYPES,
    MONITORING_LAYER_CLASS,
    MONITORING_ZONE_COLORS,
    PATHS_LAYER_CLASS,
    POINTS_LAYER_CLASS,
    SIMPLE_REPORTS_LAYER_CLASS,
    WKTOPTIONS,
    ZONES_COLORS,
    ZONES_LAYER_CLASS
} from '../../constants/map.constants';
import { SIZE_ICONS } from '../../constants/theme.constants';
import { MonitoringSimpleReport } from '../../models/monitoring';
import { ViewMode } from '../../store/filters/filters.reducer';

export async function initMap(mapElement: RefObject<HTMLDivElement>): Promise<Map> {
    const map = new Map({
        target: mapElement.current ?? undefined,
        layers: [],
        view: new View({
            projection: MAP_PROJECTION_OL,
        }),
        controls: [],
        overlays: [],
    });

    await apply(map, MAPBOX_ROADMAP_URL, {});

    const groupRoadmap = new LayerGroup({
        visible: true,
        properties: { mapType: MAP_TYPES.ROADMAP },
    });
    map.addLayer(groupRoadmap);
    map.getLayers()
        .getArray()
        .slice()
        .forEach(function (layer) {
            if (!(layer instanceof LayerGroup)) {
                map.removeLayer(layer);
                groupRoadmap.getLayers().push(layer);
            }
        });

    await apply(map, MAPBOX_SATELLITE_URL);

    const groupSatellite = new LayerGroup({
        visible: false,
        properties: { mapType: MAP_TYPES.SATELLITE },
    });
    map.addLayer(groupSatellite);
    map.getLayers()
        .getArray()
        .slice()
        .forEach(function (layer) {
            if (!(layer instanceof LayerGroup)) {
                map.removeLayer(layer);
                groupSatellite.getLayers().push(layer);
            }
        });
    return map;
}

export function getMonitoringStyle(): Style {
    const fill = new Fill({
        color: MONITORING_ZONE_COLORS.fill.color.unselected,
    });
    const stroke = new Stroke({
        width: MONITORING_ZONE_COLORS.stroke.width,
        color: MONITORING_ZONE_COLORS.stroke.color.unselected,
    });
    const style = new Style({
        fill: fill,
        stroke: stroke,
    });
    return style;
};


export function buildMonitoringLayer(monitoring: GeoJSONFeatureCollection) {
    const monitoringsFeatures = new GeoJSON().readFeatures(
        monitoring,
        WKTOPTIONS
    );
    const source = new VectorSource({
        features: monitoringsFeatures as any,
    });
    const layer = new VectorLayer({
        visible: true,
        source: source,
        style: getMonitoringStyle(),
        className: MONITORING_LAYER_CLASS,
        zIndex: 1000,
    });
    return layer;
}

export function getPointStyle(feature: FeatureLike, theme: Theme, viewMode: ViewMode) {
    if (feature.getProperties().hide) {
        return new Style({});
    }
    const severity = feature.getProperties()['severity'];
    const color = viewMode === ViewMode.VIREO ?
        feature.getProperties()['matrix']?.color ?? theme.colors.primary.main :
        severity
            ? severity['color']
            : theme.colors.primary.main;
    const stylePoint = [
        new Style({
            image: new Icon({
                src: markerBlackSvg,
                width: 45,
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                anchor: [0.5, 1.05],
            }),
        }),
        new Style({
            image: new Icon({
                color: color,
                src: markerWhiteSvg,
                width: 49,
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                anchor: [0.5, 1],
            }),
        }),
    ];
    if (viewMode === ViewMode.VIREO && feature.getProperties()['matrix']) {
        const iconString =
            feature.getProperties()['matrix']['icon'];
        if (SIZE_ICONS.services.homeMap.shadow.enable) {
            stylePoint.push(
                new Style({
                    image: new Icon({
                        height: SIZE_ICONS.services.homeMap.shadow
                            .h,
                        src:
                            'data:image/svg+xml;charset=utf-8,' +
                            encodeURIComponent(iconString),
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'fraction',
                        anchor: SIZE_ICONS.services.homeMap.shadow
                            .anchor,
                        color: SIZE_ICONS.services.homeMap.shadow
                            .color,
                    }),
                })
            );
        }
        stylePoint.push(
            new Style({
                image: new Icon({
                    height: SIZE_ICONS.services.homeMap.h,
                    src:
                        'data:image/svg+xml;charset=utf-8,' +
                        encodeURIComponent(iconString),
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    anchor: SIZE_ICONS.services.homeMap.anchor,
                }),
            })
        );
    }
    if (viewMode === ViewMode.SEGNALAZIONI_SMART && feature.getProperties()['category']) {
        const iconString =
            feature.getProperties()['category']['icon'];
        if (SIZE_ICONS.services.homeMap.shadow.enable) {
            stylePoint.push(
                new Style({
                    image: new Icon({
                        height: SIZE_ICONS.services.homeMap.shadow
                            .h,
                        src:
                            'data:image/svg+xml;charset=utf-8,' +
                            encodeURIComponent(iconString),
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'fraction',
                        anchor: SIZE_ICONS.services.homeMap.shadow
                            .anchor,
                        color: SIZE_ICONS.services.homeMap.shadow
                            .color,
                    }),
                })
            );
        }
        stylePoint.push(
            new Style({
                image: new Icon({
                    height: SIZE_ICONS.services.homeMap.h,
                    src:
                        'data:image/svg+xml;charset=utf-8,' +
                        encodeURIComponent(iconString),
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    anchor: SIZE_ICONS.services.homeMap.anchor,
                }),
            })
        );
    }
    return stylePoint;
}

export function buildMonitoringPointsLayer(monitoringPoints: GeoJSONFeatureCollection, theme: Theme, viewMode: ViewMode) {
    const monitoringPointsFeatures = new GeoJSON().readFeatures(
        monitoringPoints,
        WKTOPTIONS
    );
    const source = new VectorSource({
        features: monitoringPointsFeatures as any,
    });
    const layer = new VectorLayer<VectorSource>({
        visible: true,
        source: source,
        style: (feature) => getPointStyle(feature, theme, viewMode),
        className: POINTS_LAYER_CLASS,
        zIndex: 4000,
    });
    return layer;
}

export function getZoneStyle(feature: FeatureLike, theme: Theme, viewMode: ViewMode) {
    const severity = feature.getProperties()['severity'];
    const color = (viewMode === ViewMode.VIREO ?
        feature.getProperties()['matrix']?.color ?? theme.colors.primary.main :
        severity
            ? severity['color']
            : theme.colors.primary.main).substring(0, 7) + '33';

    const zoneFill = new Fill({
        color: color,
    });
    const zoneStroke = new Stroke({
        width: ZONES_COLORS.stroke.width,
        color: viewMode === ViewMode.VIREO ?
            feature.getProperties()['matrix']?.color ?? theme.colors.primary.main : severity ? severity.color : theme.colors.primary.main,
    });
    const zoneStyle = new Style({
        fill: zoneFill,
        stroke: zoneStroke,
    });
    return zoneStyle;
}

export function buildMonitoringZonesLayer(monitoringZones: GeoJSONFeatureCollection, theme: Theme, viewMode: ViewMode) {
    const monitoringZonesFeatures = new GeoJSON().readFeatures(
        monitoringZones,
        WKTOPTIONS
    );

    const source = new VectorSource({
        features: monitoringZonesFeatures as any,
    });
    let layer = new VectorLayer({
        visible: true,
        source: source,
        style: (feature) => getZoneStyle(feature, theme, viewMode),
        className: ZONES_LAYER_CLASS,
        zIndex: 2000,
    });
    return layer;
}

export function getPathStyle(feature: FeatureLike, theme: Theme, viewMode: ViewMode) {
    if (feature.getProperties().hide) {
        return new Style({});
    }
    const severity = feature.getProperties()['severity'];
    const color = viewMode === ViewMode.VIREO ?
        feature.getProperties()['matrix']?.color ?? theme.colors.primary.main :
        severity
            ? severity['color']
            : theme.colors.primary.main;
    return new Style({
        fill: new Fill({
            color: `${theme.colors.primary.main}`,
        }),
        stroke: new Stroke({
            color: color,
            lineDash: [0.1, 6],
            width: 3,
        }),
        image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0.7)',
            }),
            fill: new Fill({
                color: `${theme.colors.primary.main}`,
            }),
        }),
    });
}

export function buildMonitoringPathsLayer(monitoringPaths: GeoJSONFeatureCollection, theme: Theme, viewMode: ViewMode) {
    const monitoringPathsFeatures = new GeoJSON().readFeatures(
        monitoringPaths,
        WKTOPTIONS
    );

    const source = new VectorSource({
        features: monitoringPathsFeatures as any,
    });

    let layer = new VectorLayer({
        visible: true,
        source: source,
        className: PATHS_LAYER_CLASS,
        style: (feature) => getPathStyle(feature, theme, viewMode),
        zIndex: 3000,
    });
    return layer;
}

export function buildGenericPointLayer(coords: Coordinate) {
    const point = new Point(coords);
    const iconStyle = new Style({
        image: new Icon({
            width: 32,
            src: markerGreenSvg,
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            anchor: [0.55, 1.05],
        }),
    });
    let feature = new Feature(point);
    feature.setStyle(iconStyle);
    // create layer with marker
    let vectorLayer = new VectorLayer({
        source: new VectorSource({
            features: [feature],
        }),
        className: GENERIC_POINT_LAYER_CLASS,
        zIndex: 5000,
    });
    vectorLayer.setVisible(true);
    return vectorLayer;
};

export function buildMonitoringSimpleReportsLayer(monitoringSimpleReports: MonitoringSimpleReport) {
    const monitoringSimpleReportsFeatures = new GeoJSON().readFeatures(
        monitoringSimpleReports,
        WKTOPTIONS
    );
    const iconStyle = new Style({
        image: new Icon({
            width: 32,
            src: simpleReportSvg,
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            anchor: [0.5, 0.8],
        }),
    });
    const source = new VectorSource({
        features: monitoringSimpleReportsFeatures as any,
    });
    let layer = new VectorLayer({
        source: source,
        className: 'simpleReports',
        zIndex: 2500,
        style: iconStyle,
    });
    // add on map
    layer.setVisible(true);
    return layer;
};

export function getElementTargeted(map: Map, event: MapBrowserEvent<UIEvent>) {
    return map.forEachFeatureAtPixel(
        event.pixel,
        function (feature, layer) {
            if (ELEMENTS_LAYER_CLASSES.includes(layer.getClassName())) {
                return {
                    feature: feature as any,
                    event,
                    className: layer.getClassName(),
                };
            }
        }
    );
}

export function getSimpleReportTargeted(map: Map, event: MapBrowserEvent<UIEvent>) {
    return map.forEachFeatureAtPixel(
        event.pixel,
        function (feature, layer) {
            if (SIMPLE_REPORTS_LAYER_CLASS.includes(layer.getClassName())) {
                return {
                    feature: feature as any,
                    event,
                    className: layer.getClassName(),
                };
            }
        }
    );
}

export function getCenterOfExtent(Extent: number[]) {
    var X = Extent[0] + (Extent[2] - Extent[0]) / 2;
    var Y = Extent[1] + (Extent[3] - Extent[1]) / 2;
    return [X, Y];
}


export function getZoneDrawedLayer() {
    let layer = new VectorLayer({
        source: new VectorSource(),
        className: 'new-zone',
        zIndex: 9999999,
    });
    // add on map
    layer.setVisible(true);
    return layer;
};

export function getZoneDrawingModifyStyle() {
    return new Style({
        image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
                color: 'rgba(42, 44, 59, 0.7)',
            }),
            fill: new Fill({
                color: 'rgba(42, 44, 59, 0.4)',
            }),
        }),
        text: new Text({
            text: 'Trascina per modificare',
            font: '12px Calibri,sans-serif',
            fill: new Fill({
                color: 'rgba(255, 255, 255, 1)',
            }),
            backgroundFill: new Fill({
                color: 'rgba(42, 44, 59, 0.7)',
            }),
            padding: [2, 2, 2, 2],
            textAlign: 'left',
            offsetX: 15,
        }),
    });
};

export function getZoneDrawingLabelStyle() {
    return new Style({
        text: new Text({
            font: '14px Calibri,sans-serif',
            fill: new Fill({
                color: 'rgba(255, 255, 255, 1)',
            }),
            backgroundFill: new Fill({
                color: 'rgba(42, 44, 59, 0.7)',
            }),
            padding: [3, 3, 3, 3],
            textBaseline: 'bottom',
            offsetY: -15,
        }),
        image: new RegularShape({
            radius: 8,
            points: 3,
            angle: Math.PI,
            displacement: [0, 10],
            fill: new Fill({
                color: 'rgba(42, 44, 59, 0.7)', // punta tooltip
            }),
        }),
    });
};

export function getZoneDrawingTipStyle() {
    return new Style({
        text: new Text({
            font: '12px Calibri,sans-serif',
            fill: new Fill({
                color: 'rgba(255, 255, 255, 1)',
            }),
            backgroundFill: new Fill({
                color: 'rgba(42, 44, 59, 0.4)',
            }),
            padding: [2, 2, 2, 2],
            textAlign: 'left',
            offsetX: 15,
        }),
    });
};

export function getZoneDrawingSegmentStyle() {
    return new Style({
        text: new Text({
            font: '12px Calibri,sans-serif',
            fill: new Fill({
                color: 'rgba(255, 255, 255, 1)', // testo tooltip seg
            }),
            backgroundFill: new Fill({
                color: 'rgba(42, 44, 59, 0.4)', // sfondo
            }),
            padding: [2, 2, 2, 2],
            textBaseline: 'bottom',
            offsetY: -12,
        }),
        image: new RegularShape({
            radius: 6,
            points: 3,
            angle: Math.PI,
            displacement: [0, 8],
            fill: new Fill({
                color: 'rgba(42, 44, 59, 0.4)', // punta tooltip segmento
            }),
        }),
    });
};

export async function getCurrentPosition() {
    return new Promise<{ lat: number, lon: number; } | null>((resolve) => {
        try {
            navigator.geolocation.getCurrentPosition((position) => {
                resolve({
                    lat: position.coords.latitude,
                    lon: position.coords.longitude
                });
                return;
            });
        }
        catch (e) {
            console.log(e);
            resolve(null);
        }
    });
}

export async function buildUserPositionLayer() {
    const positionUser = await getCurrentPosition();
    if (positionUser != null) {
        const point = new Point(
            fromLonLat([positionUser.lon, positionUser.lat])
        );
        const iconStyle = new Style({
            image: new Icon({
                width: 32,
                src: myLocationSvg,
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                anchor: [0.5, 0.8],
            }),
        });
        let feature = new Feature(point);
        feature.setStyle(iconStyle);
        let vectorLayer = new VectorLayer({
            source: new VectorSource({
                features: [feature],
            }),
            className: 'point',
            zIndex: 4000,
        });
        vectorLayer.setVisible(true);
        return vectorLayer;
    }
}



