import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FiltersState } from '../../../../store/filters/filters.reducer';
import { useSelector } from 'react-redux';
import { selectMonitoringMatrices } from '../../../../store/monitoring/monitoring.selector';

const FilterMatrix = ({
    filtersToApply,
    onChange,
}: {
    filtersToApply: FiltersState;
    onChange: (filtersChanged: Partial<FiltersState>) => void;
}) => {
    const monitoringMatrices = useSelector(selectMonitoringMatrices);

    return (
        <FormGroup>
            {monitoringMatrices.map((matrix) => (
                <FormControlLabel
                    key={matrix.id}
                    control={
                        <Checkbox
                            checked={filtersToApply.monitoringMatricesIds.includes(
                                matrix.id
                            )}
                            onChange={(event) => {
                                let newMonitoringMatricesIds = [
                                    ...filtersToApply.monitoringMatricesIds,
                                ];
                                if (event.target.checked) {
                                    newMonitoringMatricesIds.push(matrix.id);
                                } else {
                                    newMonitoringMatricesIds =
                                        newMonitoringMatricesIds.filter(
                                            (c) => c !== matrix.id
                                        );
                                }
                                onChange({
                                    monitoringMatricesIds:
                                        newMonitoringMatricesIds,
                                });
                            }}
                        />
                    }
                    label={`Mostra ${matrix.name}`}
                />
            ))}
        </FormGroup>
    );
};

export default FilterMatrix;
