import { AnyAction } from '@reduxjs/toolkit';
import { getUserProfileFailed, getUserProfileSuccess, signInFailed, signInSuccess } from './auth.action';

export type User = {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    roles: string[];
};

export type AuthState = {
    readonly accessToken: string | null;
    readonly userProfile: User | null;
    readonly isLoading: boolean;
    readonly error: string | null;
};

const INITIAL_STATE: AuthState = {
    accessToken: null,
    userProfile: null,
    isLoading: false,
    error: null,
};

export const authReducer = (state = INITIAL_STATE, action: AnyAction) => {
    if (getUserProfileSuccess.match(action) || signInSuccess.match(action)) {
        return { ...state, ...action.payload, error: null };
    }

    if (
        signInFailed.match(action) ||
        getUserProfileFailed.match(action)
    ) {
        return { ...state, error: action.payload, accessToken: null, userProfile: null };
    }

    return state;
};