import { Action, ActionWithPayload, createAction, withMatcher } from '../../utils/reducer/reducer.utils';
import { Param } from './params.reducer';
import { PARAMS_ACTION_TYPES } from './params.types';

export type GetParamsStart = Action<
    PARAMS_ACTION_TYPES.GET_PARAMS_START
>;

export type GetParamsSuccess = ActionWithPayload<
    PARAMS_ACTION_TYPES.GET_PARAMS_SUCCESS,
    { params: Param[]; }
>;

export type GetParamsFailed = ActionWithPayload<
    PARAMS_ACTION_TYPES.GET_PARAMS_FAILED,
    string
>;

export const getParamsStart = withMatcher(
    (): GetParamsStart =>
        createAction(PARAMS_ACTION_TYPES.GET_PARAMS_START)
);

export const getParamsSuccess = withMatcher(
    (params: Param[]): GetParamsSuccess =>
        createAction(PARAMS_ACTION_TYPES.GET_PARAMS_SUCCESS, { params })
);

export const getParamsFailed = withMatcher(
    (error: string): GetParamsFailed =>
        createAction(PARAMS_ACTION_TYPES.GET_PARAMS_FAILED, error)
);