import { Box, Button, Card, CardContent, Grid, useTheme } from '@mui/material';
import { Feature, Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Polygon } from 'ol/geom';
import { Draw, Modify } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import { transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Style } from 'ol/style';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    MAP_PROJECTION_OL,
    MAP_PROJECTION_WGS84,
    MONITORING_LAYER_CLASS,
    ZONES_LAYER_CLASS,
} from '../../../../../../constants/map.constants';
import {
    PARAMS_KEYS,
    PARAMS_MODULES,
} from '../../../../../../constants/params.constants';
import {
    ElementPayload,
    MonitoringElement,
} from '../../../../../../models/monitoring';
import { setIsError } from '../../../../../../store/error/error.action';
import { selectViewMode } from '../../../../../../store/filters/filters.selector';
import {
    selectMonitoring,
    selectMonitoringZones,
} from '../../../../../../store/monitoring/monitoring.selector';
import { selectParams } from '../../../../../../store/params/params.selector';
import { applyViewModeFilterToElement } from '../../../../../../utils/filters/filters.utils';
import {
    buildMonitoringLayer,
    buildMonitoringZonesLayer,
    getZoneStyle,
    initMap,
} from '../../../../../../utils/map/map.utils';
import { getParamValue } from '../../../../../../utils/params/params.utils';
import { FlexBoxFullWHColumn } from '../../../../../layout/layout.styles';
import GBDialogActions from '../../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../../ui/gb-dialog-title/gb-dialog-title.component';

const DialogAddElementLocationZoneMap = ({
    zoneCoords,
    onPrevious,
    onNext,
}: {
    zoneCoords: Coordinate[][] | undefined;
    onPrevious: () => void;
    onNext: (properties: Partial<ElementPayload>) => void;
}) => {
    const dispatch = useDispatch();

    const theme = useTheme();

    const monitoring = useSelector(selectMonitoring);
    const monitoringZones = useSelector(selectMonitoringZones);
    const params = useSelector(selectParams);
    const viewMode = useSelector(selectViewMode);

    const [map, setMap] = useState<Map>();
    const mapElement = useRef<HTMLDivElement>(null);
    const [coordsGrouped, setCoordsGrouped] = useState<Coordinate[][]>(
        zoneCoords ?? []
    );

    const reinitPageMap = useCallback(async () => {
        if (mapElement.current?.innerHTML) {
            mapElement.current.innerHTML = '';
        }
        const _map = await initMap(mapElement);
        const mapZoomParam = getParamValue(
            params,
            PARAMS_MODULES.core,
            PARAMS_KEYS.initialMapZoom
        );
        if (mapZoomParam) {
            _map.getView().setZoom(parseInt(mapZoomParam));
        }
        const mapCenterParam = getParamValue(
            params,
            PARAMS_MODULES.core,
            PARAMS_KEYS.initialMapCenter
        );
        if (mapCenterParam) {
            _map.getView().setCenter(
                transform(
                    mapCenterParam.split(',').map((v: any) => parseFloat(v)),
                    MAP_PROJECTION_WGS84,
                    MAP_PROJECTION_OL
                )
            );
        }
        setMap(_map);
    }, [params]);

    useEffect(() => {
        if (!map) return;
        map.getInteractions().forEach((i) => {
            if (i instanceof Draw || i instanceof Modify) {
                map.removeInteraction(i);
            }
        });
        map.getLayers().forEach(function (layer) {
            if (layer && layer.getClassName() === 'zone-drawed') {
                map.removeLayer(layer);
            }
        });

        const coordsTransformed = coordsGrouped.map((c) =>
            c.map((cc) =>
                transform(cc, MAP_PROJECTION_WGS84, MAP_PROJECTION_OL)
            )
        );
        const polygon = new Polygon(coordsTransformed);
        const feature = new Feature(polygon);
        const source = new VectorSource({
            features: [feature],
        });

        const draw = new Draw({
            source: source,
            type: 'Polygon',
            condition: (e) => {
                const features = map.getFeaturesAtPixel(e.pixel, {
                    layerFilter: function (layer) {
                        return layer.getClassName() === MONITORING_LAYER_CLASS;
                    },
                });
                return features && features.length > 0;
            },
        });

        draw.on('drawend', (event) => {
            let line = (
                event.feature.getGeometry() as Polygon
            ).getCoordinates()[0];
            let coords = line.map((c: Coordinate) =>
                transform(c, MAP_PROJECTION_OL, MAP_PROJECTION_WGS84)
            );
            setCoordsGrouped((prevState) => [...prevState, coords]);
        });

        const vector = new VectorLayer({
            source: source,
            className: 'zone-drawed',
            style: (feature) => getZoneStyle(feature, theme, viewMode),
        });

        const modify = new Modify({ source: source });
        map.addInteraction(modify);
        map.addLayer(vector);
        map.addInteraction(draw);
    }, [coordsGrouped, map, theme, viewMode]);

    useEffect(() => {
        reinitPageMap();
    }, [reinitPageMap]);

    useEffect(() => {
        if (map && monitoring && theme) {
            map.getLayers().forEach((layer) => {
                if (layer && layer.getClassName() === MONITORING_LAYER_CLASS) {
                    map.removeLayer(layer);
                }
            });
            map.addLayer(buildMonitoringLayer(monitoring));
        }
    }, [monitoring, map, theme]);

    useEffect(() => {
        if (map && monitoringZones && theme) {
            map.getLayers().forEach((layer) => {
                if (layer && layer.getClassName() === ZONES_LAYER_CLASS) {
                    map.removeLayer(layer);
                }
            });
            const zonesLayer = buildMonitoringZonesLayer(
                monitoringZones,
                theme,
                viewMode
            );
            zonesLayer
                .getSource()
                ?.getFeatures()
                .forEach((feature) => {
                    const element =
                        feature.getProperties() as MonitoringElement;
                    feature.setStyle(
                        applyViewModeFilterToElement(element, viewMode)
                            ? getZoneStyle(feature, theme, viewMode)
                            : new Style({})
                    );
                });
            map.addLayer(zonesLayer);
        }
    }, [monitoringZones, map, theme, viewMode]);

    const handleResetButtonClick = () => {
        if (map) {
            setCoordsGrouped([]);
        }
    };

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    const handleNextButtonClick = () => {
        if (coordsGrouped.length === 0) {
            dispatch(setIsError(true, 'Nessuna zona disegnata'));
            return;
        }
        onNext({ zoneCoords: coordsGrouped });
    };

    return (
        <Fragment>
            <GBDialogTitle title="LOCALIZZA POSIZIONE" />
            <GBDialogContent height="80vh">
                <FlexBoxFullWHColumn>
                    <Box height={9 / 10}>
                        <Card
                            sx={{
                                height: '100%',
                            }}
                        >
                            <CardContent
                                sx={{
                                    p: 0,
                                    height: '100%',
                                    position: 'relative',
                                }}
                                className="noActions"
                                ref={mapElement}
                            />
                        </Card>
                    </Box>
                    <Box height={1 / 10}>
                        <Grid item xs>
                            <Box marginTop={2} />
                            <Button
                                autoFocus
                                variant="contained"
                                onClick={() => handleResetButtonClick()}
                                sx={{
                                    float: 'right',
                                }}
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Box>
                </FlexBoxFullWHColumn>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handlePreviousButtonClick}>INDIETRO</Button>
                <Button onClick={handleNextButtonClick}>AVANTI</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogAddElementLocationZoneMap;
