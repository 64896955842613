import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { MultiSelectOptions } from '../../../models/shared';
import Typography from '@mui/material/Typography';
import { Fragment, useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const GBMultiSelect = ({
    options,
    defaultValues,
    onSelect,
    title,
    label,
}: {
    options: MultiSelectOptions[];
    defaultValues: string[];
    onSelect: (o: MultiSelectOptions[]) => void;
    title: string | null;
    label: string;
}) => {
    const [optionsSelected, setOptionsSelected] =
        useState<string[]>(defaultValues);

    const updateList = (event: SelectChangeEvent) => {
        // send to parent complete list
        var list: MultiSelectOptions[] = [];
        const newOptionsSelected = event.target.value as any;
        newOptionsSelected.forEach((os: any) => {
            list.push({
                id: options.filter((o) => o.label === os)[0].id,
                label: os,
            });
        });
        onSelect(list);
        // update buffer list
        setOptionsSelected(newOptionsSelected);
    };

    return (
        <Fragment>
            {title && (
                <Typography
                    variant="h4"
                    sx={{
                        marginLeft: '12px',
                        paddingTop: '15px',
                        paddingBottom: '10px',
                    }}
                >
                    {title}
                </Typography>
            )}
            <FormControl sx={{ width: 1 }}>
                <InputLabel id="multiple-chip-label">{label}</InputLabel>
                <Select
                    labelId="multiple-chip-label"
                    id="multiple-chip"
                    multiple
                    value={optionsSelected as any}
                    onChange={updateList}
                    input={
                        <OutlinedInput
                            id="select-multiple-chip"
                            label={label}
                        />
                    }
                    renderValue={(o) => (
                        <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                        >
                            {optionsSelected.map((os) => (
                                <Chip key={os} label={os} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {options.map((o) => (
                        <MenuItem key={o.id} value={o.label}>
                            {o.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Fragment>
    );
};

export default GBMultiSelect;
