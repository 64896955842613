export enum MAP_ACTION_TYPES {
    SET_MAP_TYPE = 'map/SET_MAP_TYPE',
    SET_MAP_ZOOM = 'map/SET_MAP_ZOOM',
    SET_MAP_CENTER = 'map/SET_MAP_CENTER',
    RESET_MAP = 'map/RESET_MAP',
    GEOCODE_ADDRESS_START = 'map/GEOCODE_ADDRESS_START',
    GEOCODE_ADDRESS_SUCCESS = 'map/GEOCODE_ADDRESS_SUCCESS',
    GEOCODE_ADDRESS_FAILED = 'map/GEOCODE_ADDRESS_FAILED',
    REVERSE_GEOCODE_COORDS_START = 'map/REVERSE_GEOCODE_COORDS_START',
    REVERSE_GEOCODE_COORDS_SUCCESS = 'map/REVERSE_GEOCODE_COORDS_SUCCESS',
    REVERSE_GEOCODE_COORDS_FAILED = 'map/REVERSE_GEOCODE_COORDS_FAILED',
    CLEAN_GEOCODING_ERROR = 'map/CLEAN_GEOCODING_ERROR',
}