import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    ManageMonitoringSimpleReportPayload,
    MonitoringElementService,
} from '../../../../../models/monitoring';
import { setIsError } from '../../../../../store/error/error.action';
import { FlexBoxFullWHColumn } from '../../../../layout/layout.styles';
import GBDialogActions from '../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../ui/gb-dialog-title/gb-dialog-title.component';

const DialogManageNewSimpleReportServiceStep = ({
    elementServices,
    elementServiceSelected,
    onNext,
    onPrevious,
}: {
    elementServices: MonitoringElementService[];
    elementServiceSelected: number | undefined;
    onNext: (properties: Partial<ManageMonitoringSimpleReportPayload>) => void;
    onPrevious: () => void;
}) => {
    const dispatch = useDispatch();

    const [serviceSelected, setServiceSelected] = useState<number | undefined>(
        elementServiceSelected ?? 0
    );

    const [servicesFiltered, setServicesFiltered] = useState<
        MonitoringElementService[]
    >(
        [...elementServices].sort((a, b) =>
            a.service.name.localeCompare(b.service.name)
        )
    );

    const handleServiceButtonClick = (serviceId: string) => {
        setServiceSelected(parseInt(serviceId));
    };

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    const handleNextButtonClick = () => {
        if (!serviceSelected) {
            dispatch(setIsError(true, 'Selezionare un servizio'));
            return;
        }
        onNext({
            element_service: serviceSelected,
        });
    };

    const handleSearchInputChange = (search: string) => {
        if (search === '') {
            setServicesFiltered(
                [...elementServices].sort((a, b) =>
                    a.service.name.localeCompare(b.service.name)
                )
            );
            return;
        }
        setServicesFiltered(
            [...elementServices]
                .sort((a, b) => a.service.name.localeCompare(b.service.name))
                .filter((service) =>
                    service.service.name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                )
        );
    };

    return (
        <Fragment>
            <GBDialogTitle title={'Seleziona servizio'} />
            <GBDialogContent>
                <FlexBoxFullWHColumn overflow={'hidden'} pt={1}>
                    <TextField
                        onChange={(e) =>
                            handleSearchInputChange(e.target.value)
                        }
                        fullWidth
                        label="Cerca"
                        type="search"
                    />
                    <Box my={1} />
                    <FlexBoxFullWHColumn overflow={'auto'} px={2}>
                        <FormControl component="fieldset">
                            <RadioGroup
                                value={serviceSelected}
                                onChange={(e) =>
                                    handleServiceButtonClick(e.target.value)
                                }
                            >
                                {servicesFiltered.map((e) => (
                                    <FormControlLabel
                                        key={e.id}
                                        value={e.id}
                                        control={<Radio />}
                                        label={e.service.name}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </FlexBoxFullWHColumn>
                </FlexBoxFullWHColumn>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handlePreviousButtonClick}>INDIETRO</Button>
                <Button onClick={handleNextButtonClick}>AVANTI</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogManageNewSimpleReportServiceStep;
