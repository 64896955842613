import { Button } from '@mui/material';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../store/dialog/dialog.action';
import { deleteFileStart } from '../../../store/files/files.action';
import { deleteEntityStart } from '../../../store/monitoring/monitoring.action';
import GBDialogActions from '../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../ui/gb-dialog-content/gb-dialog-content.component';

const DialogConfirmationCancellation = ({
    inputData,
    onDeleted,
}: {
    inputData: any;
    onDeleted: () => void;
}) => {
    const dispatch = useDispatch();

    const handleCancelButtonClick = () => {
        dispatch(closeDialog());
    };

    const handleConfirmButtonClick = () => {
        if (inputData.isEntity) {
            dispatch(
                deleteEntityStart(
                    inputData.entityId,
                    inputData.entityClassName,
                    () => {
                        onDeleted();
                        dispatch(closeDialog());
                    }
                )
            );
        } else {
            dispatch(
                deleteFileStart(
                    inputData.id,
                    inputData.entityId,
                    inputData.entityClassName,
                    () => {
                        onDeleted();
                        dispatch(closeDialog());
                    }
                )
            );
        }
    };

    return (
        <Fragment>
            <GBDialogContent height="auto">
                Sicuro di voler confermare l'eliminazione di {inputData.name}?
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handleCancelButtonClick}>ANNULLA</Button>
                <Button onClick={handleConfirmButtonClick}>CONFERMA</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogConfirmationCancellation;
