import { all, call, put, takeLatest } from 'typed-redux-saga/macro';
import { MONITORING_PERIODIC_INSPECTION_URL } from '../../constants/api.constants';
import { apiRequestWrapper } from '../../utils/api/api.utils';
import { setIsError } from '../error/error.action';
import { setIsLoading } from '../loading/loading.action';
import { addPeriodicInspectionFailed, AddPeriodicInspectionStart, addPeriodicInspectionSuccess } from './periodic-inspection.action';
import { PERIODIC_INSPECTION_ACTION_TYPES } from './periodic-inspection.types';


export function* addPeriodicInspection({
    payload: { periodicInspection, onComplete },
}: AddPeriodicInspectionStart) {
    yield* put(setIsLoading(true));
    const periodicInspectionPayload: any = periodicInspection;
    periodicInspectionPayload.checklist_values = JSON.stringify(periodicInspection.checklist_values);
    try {
        yield* call(
            apiRequestWrapper, MONITORING_PERIODIC_INSPECTION_URL, 'POST', periodicInspection, true
        );
        yield* put(addPeriodicInspectionSuccess());
        onComplete();
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(addPeriodicInspectionFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onAddPeriodicInspectionStart() {
    yield* takeLatest(PERIODIC_INSPECTION_ACTION_TYPES.ADD_PERIODIC_INSPECTION_START, addPeriodicInspection);
}

export function* periodicInspectionSaga() {
    yield* all([
        call(onAddPeriodicInspectionStart),
    ]);
}
