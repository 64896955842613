import {
    Document,
    Font,
    Image,
    Page,
    StyleSheet,
    Text,
    View,
} from '@react-pdf/renderer';
import moment from 'moment';
import { useEffect, useState } from 'react';
import logoGreenBeat from '../../assets/greenbeat-logo.png';
import {
    Monitoring,
    MonitoringElement,
    MonitoringElementService,
    MonitoringPeriodicInspectionSimple,
} from '../../models/monitoring';
import { Attachment } from '../../models/shared';
import { Tenant } from '../../store/tenant/tenant.reducer';

Font.register({
    family: 'Open Sans',
    fonts: [
        {
            src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
        },
        {
            src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
            fontWeight: 600,
        },
        {
            src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
            fontWeight: 700,
        },
    ],
});

// Create styles
const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        fontWeight: 'bold',
        fontSize: 30,
    },
    text: {
        fontWeight: 'bold',
        fontSize: '14pt',
        textAlign: 'center',
        marginTop: '5px',
    },
    content: {
        flexGrow: 1,
    },
    body: { paddingLeft: '10mm', paddingRight: '10mm' },
    bodyWithFooter: {
        paddingLeft: '10mm',
        paddingRight: '10mm',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
    },
});

function HeaderComponent({
    clientLogoUrl,
    pageNumber,
}: {
    clientLogoUrl: string | undefined;
    pageNumber: number;
}) {
    return (
        <View
            style={{
                height: '31mm',
                border: '1pt solid black',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <View
                style={{
                    width: '70%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRight: '1pt solid black',
                    height: '100%',
                }}
            >
                <View style={{ height: '17mm', width: '100%' }}>
                    <Image
                        style={{
                            objectFit: 'contain',
                        }}
                        src={logoGreenBeat}
                    />
                </View>

                <Text style={styles.text}>VERBALE DI ISPEZIONE</Text>
            </View>
            <View
                style={{
                    width: '30%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                {clientLogoUrl && (
                    <View style={{ height: '17mm', width: '100%' }}>
                        <Image
                            style={{
                                objectFit: 'contain',
                            }}
                            src={clientLogoUrl}
                        />
                    </View>
                )}
                <Text style={styles.text}>Pag. {pageNumber}</Text>
            </View>
        </View>
    );
}

function PageTitleComponent({
    title,
    periodicInspection,
}: {
    title: string;
    periodicInspection: MonitoringPeriodicInspectionSimple;
}) {
    return (
        <View
            style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '16mm',
                marginBottom: '16mm',
            }}
        >
            <Text
                style={{
                    fontSize: '20pt',
                    fontWeight: 'bold',
                }}
            >
                {title}
            </Text>
            <Text
                style={{
                    fontSize: '12pt',
                    fontWeight: 'bold',
                }}
            >
                {periodicInspection.name} del{' '}
                {moment(periodicInspection.create_date)
                    .locale('it')
                    .format('DD/MM/YYYY')}
            </Text>
        </View>
    );
}

function FooterComponent({
    tenant,
    monitoring,
}: {
    tenant: Tenant;
    monitoring: Monitoring;
}) {
    return (
        <View
            style={{
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderTop: '0.5pt solid black',
                height: '13mm',
            }}
        >
            <Text
                style={{
                    fontSize: '12pt',
                    fontWeight: 'bold',
                    color: '#878787',
                }}
            >
                {tenant.name}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#878787' }}>
                {monitoring.name}
            </Text>
        </View>
    );
}

const PeriodicInspectionDocument = ({
    element,
    periodicInspection,
    service,
    tenant,
    monitoring,
}: {
    element: MonitoringElement;
    periodicInspection: MonitoringPeriodicInspectionSimple;
    service: MonitoringElementService;
    tenant: Tenant;
    monitoring: Monitoring;
}) => {
    const [clientLogoUrl, setClientLogoUrl] = useState<string>();

    useEffect(() => {
        async function fetchImage() {
            if (tenant) {
                setClientLogoUrl(
                    `${process.env.REACT_APP_BASE_URL}${tenant.logo}`
                );
            }
        }
        fetchImage();
    }, [tenant]);

    const galleryRowSize = 3;
    let galleryRows: Attachment[][] = [];
    if (periodicInspection.images) {
        for (
            let i = 0;
            i < periodicInspection.images.length;
            i += galleryRowSize
        ) {
            galleryRows.push(
                periodicInspection.images.slice(i, i + galleryRowSize)
            );
        }
    }
    return (
        <Document style={{ fontFamily: 'Open Sans' }}>
            <Page size="A4">
                <View style={styles.section}>
                    <HeaderComponent
                        clientLogoUrl={clientLogoUrl}
                        pageNumber={1}
                    />
                    <View style={styles.content}>
                        <PageTitleComponent
                            title="INFORMAZIONI"
                            periodicInspection={periodicInspection}
                        />
                        <View style={styles.body}>
                            <Text style={{ marginBottom: '8mm' }}>
                                <Text style={{ fontWeight: 'semibold' }}>
                                    Elemento:
                                </Text>{' '}
                                {element.name}
                            </Text>
                            <Text style={{ marginBottom: '8mm' }}>
                                <Text style={{ fontWeight: 'semibold' }}>
                                    Servizio:
                                </Text>{' '}
                                {service.service.name}
                            </Text>
                            <Text style={{ marginBottom: '8mm' }}>
                                <Text style={{ fontWeight: 'semibold' }}>
                                    Esito:
                                </Text>{' '}
                                {periodicInspection.severity
                                    ? `Non conforme con criticità ${periodicInspection.severity.name}`
                                    : 'Conforme'}
                            </Text>
                            <Text style={{ marginBottom: '8mm' }}>
                                <Text style={{ fontWeight: 'semibold' }}>
                                    Note ispezione:
                                </Text>{' '}
                                {periodicInspection.notes}
                            </Text>
                            <Text style={{ marginBottom: '8mm' }}>
                                <Text style={{ fontWeight: 'semibold' }}>
                                    Responsabile ispezione:
                                </Text>{' '}
                                {periodicInspection.create_user.email}
                            </Text>
                        </View>
                    </View>
                    <FooterComponent tenant={tenant} monitoring={monitoring} />
                </View>
            </Page>
            <Page size="A4">
                <View style={styles.section}>
                    <HeaderComponent
                        clientLogoUrl={clientLogoUrl}
                        pageNumber={2}
                    />
                    <View style={styles.content}>
                        <PageTitleComponent
                            title="CHECKLIST"
                            periodicInspection={periodicInspection}
                        />
                        <View style={styles.bodyWithFooter}>
                            <View>
                                {periodicInspection.checklist_values &&
                                    periodicInspection.checklist_values.map(
                                        (cv) => (
                                            <Text
                                                key={cv.key}
                                                style={{ marginBottom: '8mm' }}
                                            >
                                                <Text
                                                    style={{
                                                        fontWeight: 'semibold',
                                                    }}
                                                >
                                                    {
                                                        service.service.checklist.find(
                                                            (cl) =>
                                                                cl.key ===
                                                                cv.key
                                                        )?.label
                                                    }
                                                </Text>{' '}
                                                {cv.value ? 'SI' : 'NO'}
                                            </Text>
                                        )
                                    )}
                            </View>
                            <View
                                style={{
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                    paddingBottom: '10mm',
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: '20pt',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    FIRMA
                                </Text>
                                {periodicInspection.signature && (
                                    <Image
                                        style={{ width: 100 }}
                                        src={periodicInspection.signature}
                                    />
                                )}
                            </View>
                        </View>
                    </View>
                    <FooterComponent tenant={tenant} monitoring={monitoring} />
                </View>
            </Page>
            <Page size="A4">
                <View style={styles.section}>
                    <HeaderComponent
                        clientLogoUrl={clientLogoUrl}
                        pageNumber={3}
                    />
                    <View style={styles.content}>
                        <PageTitleComponent
                            title="GALLERY"
                            periodicInspection={periodicInspection}
                        />
                        <View style={styles.body}>
                            {galleryRows.map((row, index) => (
                                <View
                                    key={index}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: `flex-start`,
                                        marginBottom: '10mm',
                                    }}
                                >
                                    {row.map((image, imageIndex) => (
                                        <View
                                            key={image.id}
                                            style={{
                                                width: '52mm',
                                                height: '52mm',
                                                border: '1px solid black',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginRight: `${
                                                    imageIndex !== 2
                                                        ? '10mm'
                                                        : 0
                                                }`,
                                            }}
                                        >
                                            <Image
                                                key={image.id}
                                                style={{
                                                    objectFit: 'contain',
                                                }}
                                                src={`${process.env.REACT_APP_BASE_URL}${image.file}`}
                                            />
                                        </View>
                                    ))}
                                </View>
                            ))}
                        </View>
                    </View>
                    <FooterComponent tenant={tenant} monitoring={monitoring} />
                </View>
            </Page>
        </Document>
    );
};

export default PeriodicInspectionDocument;
