import { Close } from '@mui/icons-material';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../store/dialog/dialog.action';

const GBDialogTitle = ({
    title,
    moreActions,
}: {
    title: string;
    moreActions?: ReactNode;
}) => {
    const dispatch = useDispatch();

    const handleCloseButtonClick = () => {
        dispatch(closeDialog());
    };

    return (
        <DialogTitle>
            <Box>{title}</Box>
            <Box>
                {moreActions}
                <IconButton
                    aria-label="close"
                    onClick={handleCloseButtonClick}
                    size={'small'}
                    sx={{
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </Box>
        </DialogTitle>
    );
};

export default GBDialogTitle;
