import { Add, Delete } from '@mui/icons-material';
import { Button, InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import {
    BoxFullWH,
    FlexBoxFullWHColumn,
    FlexBoxFullWHColumnBetween,
    FlexBoxFullWRowBetween,
} from '../../layout/layout.styles';
import './gb-gallery-form.styles.scss';

const GBGalleryFormContent = ({
    images,
    currentIndex,
    onCarouselChange,
}: {
    images: any[];
    currentIndex: number | undefined;
    onCarouselChange: (newIndex: number | undefined) => void;
}) => {
    const theme = useTheme();

    const handleOnCarouselChange = (newIndex: number | undefined) => {
        onCarouselChange(newIndex);
    };

    return (
        <BoxFullWH>
            {images.length === 0 && (
                <FlexBoxFullWHColumn justifyContent={'center'}>
                    <Typography textAlign={'center'}>
                        Nessuna immagine da mostrare
                    </Typography>
                </FlexBoxFullWHColumn>
            )}
            {images.length > 0 && (
                <Carousel
                    index={currentIndex}
                    onChange={handleOnCarouselChange}
                    fullHeightHover
                    navButtonsWrapperProps={{
                        className: 'carousel-nav-button-wrapper',
                    }}
                    navButtonsProps={{
                        className: 'carousel-nav-button',
                        style: {
                            background: theme.colors.primary.main,
                            marginTop: -10,
                        },
                    }}
                    activeIndicatorIconButtonProps={{
                        style: {
                            color: theme.colors.primary.main,
                        },
                    }}
                    indicatorContainerProps={{
                        style: {
                            position: 'absolute',
                            bottom: 0,
                            zIndex: 1,
                        },
                    }}
                    autoPlay={false}
                    sx={{ height: '100%' }}
                >
                    {images.map((image, i) => (
                        <img
                            key={i}
                            style={{
                                position: 'absolute',
                                maxWidth: '100%',
                                maxHeight: 'calc(100% - 20px)',
                                top: 'calc(50% - 10px)',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                            src={
                                image.imageUrl
                                    ? image.imageUrl
                                    : `${process.env.REACT_APP_BASE_URL}${image.file}`
                            }
                            alt={image.file_name}
                        />
                    ))}
                </Carousel>
            )}
        </BoxFullWH>
    );
};

const GBGalleryForm = ({
    images,
    onImagesAdd,
    onImageDelete,
}: {
    images: any[];
    onImagesAdd: (event: ChangeEvent<HTMLInputElement>) => void;
    onImageDelete: (imageId: string) => void;
}) => {
    const [currentIndex, setCurrentIndex] = useState<number | undefined>();

    const handleOnCarouselChange = (now: number | undefined) => {
        if (now !== undefined) {
            setCurrentIndex(now);
        }
    };

    useEffect(() => {
        if (images.length > 0) {
            setCurrentIndex(0);
        } else {
            setCurrentIndex(undefined);
        }
    }, [images]);

    return (
        <Fragment>
            <Box
                width={1}
                height={1}
                sx={{
                    border: '1px solid rgba(75, 75, 75, 0.5);',
                    borderRadius: '10px',
                    padding: '16.5px 14px',
                    position: 'relative',
                }}
            >
                <InputLabel
                    sx={{
                        position: 'absolute',
                        top: -10,
                        left: 7,
                        fontSize: '0.75em',
                        paddingX: '5px',
                        backgroundColor: 'white',
                        color: 'rgba(75, 75, 75, 0.5)',
                    }}
                >
                    Gallery
                </InputLabel>
                <FlexBoxFullWHColumnBetween position={'relative'}>
                    <FlexBoxFullWRowBetween position={'absolute'} zIndex={999}>
                        <input
                            type="file"
                            capture="environment"
                            accept="image/*"
                            onChange={onImagesAdd}
                            style={{ display: 'none' }}
                            id="file-upload"
                            multiple
                        />
                        <label htmlFor="file-upload">
                            <Button
                                variant="outlined"
                                startIcon={<Add />}
                                component="span"
                                sx={{
                                    border: 'none',
                                    '&:hover': {
                                        border: 'none',
                                    },
                                    minWidth: 'auto',
                                    padding: 0,
                                    margin: 0,
                                    '& span': { margin: 0 },
                                }}
                            />
                        </label>
                        {currentIndex !== undefined &&
                            images[currentIndex] &&
                            images[currentIndex].imageUrl && (
                                <IconButton
                                    onClick={() => {
                                        onImageDelete(images[currentIndex].id);
                                    }}
                                    sx={{ padding: 0 }}
                                >
                                    <Delete color="error" />
                                </IconButton>
                            )}
                    </FlexBoxFullWRowBetween>
                    <GBGalleryFormContent
                        images={images}
                        currentIndex={currentIndex}
                        onCarouselChange={handleOnCarouselChange}
                    />
                </FlexBoxFullWHColumnBetween>
            </Box>
        </Fragment>
    );
};

export default GBGalleryForm;
