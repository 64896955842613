export const SIZE_ICONS = {
    'services': {
        'wizard': {
            'h': 20,
            'w': 20
        },
        'homeMap': {
            'h': 17, //20,
            'w': 17, //20,
            'anchor': [0.5, 2.6], //[0.5, 1.8]
            'shadow': {
                'enable': false,
                'h': 17,
                'w': 17,
                'anchor': [0.4, 1.95],
                'color': '#777777'
            }
        }
    },
    'action': {
        'pointDetail': {
            'top': 9, //7,
            'left': 8, //6,
            'onAvatar': {
                'h': 13, //20
                'w': 13, //20
            },
            'onMarker': {
                'h': 16, //20
                'w': 16, //20
            },
            'fontSize': 18
        }
    }
};