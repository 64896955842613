import { useTheme } from '@mui/material';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import { transform } from 'ol/proj';
import { Style } from 'ol/style';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    MAP_PROJECTION_OL,
    MAP_PROJECTION_WGS84,
    MAP_TYPES,
    MONITORING_LAYER_CLASS,
    PATHS_LAYER_CLASS,
    POINTS_LAYER_CLASS,
    SIMPLE_REPORTS_LAYER_CLASS,
    ZONES_LAYER_CLASS,
} from '../../../constants/map.constants';
import { MonitoringElementService } from '../../../models/monitoring';
import { ViewMode } from '../../../store/filters/filters.reducer';
import { selectFiltersReducer } from '../../../store/filters/filters.selector';
import {
    setMapCenter,
    setMapType,
    setMapZoom,
} from '../../../store/map/map.action';
import {
    selectMapCenter,
    selectMapType,
    selectMapZoom,
} from '../../../store/map/map.selector';
import {
    selectMonitoring,
    selectMonitoringPaths,
    selectMonitoringPoints,
    selectMonitoringSimpleReports,
    selectMonitoringZones,
} from '../../../store/monitoring/monitoring.selector';
import { isFeatureVisible } from '../../../utils/filters/filters.utils';
import {
    buildMonitoringLayer,
    buildMonitoringPathsLayer,
    buildMonitoringPointsLayer,
    buildMonitoringSimpleReportsLayer,
    buildMonitoringZonesLayer,
    getZoneStyle,
    initMap,
} from '../../../utils/map/map.utils';
import { BoxFullWH, FlexBoxFullWHColumn } from '../../layout/layout.styles';
import MapActionsSideBar from '../map-actions-side-bar/map-actions-side-bar.component';
import MapElementSelectedBox from '../map-element-selected-box/map-element-selected-box.component';
import { MapContainer } from './map-wrapper.styles';

const MapWrapperSimpleReportAssignElement = ({
    simpleReportId,
    selectedElementName,
    onFeatureSelected,
    onFeatureUnselected,
}: {
    simpleReportId: number;
    selectedElementName: string | null;
    onFeatureSelected: (
        id: number,
        name: string,
        elementServices: MonitoringElementService[]
    ) => void;
    onFeatureUnselected: () => void;
}) => {
    const theme = useTheme();

    const dispatch = useDispatch();

    const mapElement = useRef<HTMLDivElement>(null);

    const [map, setMap] = useState<Map>();

    const monitoring = useSelector(selectMonitoring);
    const monitoringPoints = useSelector(selectMonitoringPoints);
    const monitoringZones = useSelector(selectMonitoringZones);
    const monitoringPaths = useSelector(selectMonitoringPaths);
    const monitoringSimpleReports = useSelector(selectMonitoringSimpleReports);
    const filters = useSelector(selectFiltersReducer);

    const mapType = useSelector(selectMapType);
    const mapZoom = useSelector(selectMapZoom);
    const mapCenter = useSelector(selectMapCenter);

    const reinitPageMap = useCallback(async () => {
        if (mapElement.current?.innerHTML) {
            mapElement.current.innerHTML = '';
        }
        const _map = await initMap(mapElement);
        setMap(_map);
    }, []);

    useEffect(() => {
        reinitPageMap();
    }, [reinitPageMap]);

    useEffect(() => {
        return () => {
            if (map) {
                let mapTypeTmp = MAP_TYPES.ROADMAP;
                map.getLayers().forEach((layer) => {
                    const layerMapType = layer.getProperties()['mapType'];
                    if (layerMapType) {
                        mapTypeTmp = layer.getVisible()
                            ? layerMapType
                            : mapTypeTmp;
                    }
                });
                dispatch(setMapType(mapTypeTmp));
                dispatch(setMapZoom(map.getView().getZoom()!));
                dispatch(
                    setMapCenter(
                        transform(
                            map.getView().getCenter()!,
                            MAP_PROJECTION_OL,
                            MAP_PROJECTION_WGS84
                        )
                    )
                );
            }
        };
    }, [map, dispatch]);

    useEffect(() => {
        if (map) {
            map.getLayers().forEach((layer) => {
                const layerMapType = layer.getProperties()['mapType'];
                if (layerMapType) {
                    layer.setVisible(layerMapType === mapType);
                }
            });
        }
    }, [map, mapType]);

    useEffect(() => {
        if (map) {
            setTimeout(
                () =>
                    map.getView().animate({
                        zoom: mapZoom,
                    }),
                0
            );
        }
    }, [map, mapZoom]);

    useEffect(() => {
        if (map) {
            map.getView().setCenter(
                transform(mapCenter, MAP_PROJECTION_WGS84, MAP_PROJECTION_OL)
            );
        }
    }, [map, mapCenter]);

    useEffect(() => {
        if (map && monitoring && theme) {
            map.getLayers().forEach((layer) => {
                if (layer && layer.getClassName() === MONITORING_LAYER_CLASS) {
                    map.removeLayer(layer);
                }
            });
            map.addLayer(buildMonitoringLayer(monitoring));
        }
    }, [monitoring, map, theme]);

    useEffect(() => {
        if (map && monitoringPoints && theme) {
            map.getLayers().forEach((layer) => {
                if (layer && layer.getClassName() === POINTS_LAYER_CLASS) {
                    map.removeLayer(layer);
                }
            });
            map.addLayer(
                buildMonitoringPointsLayer(
                    monitoringPoints,
                    theme,
                    filters.viewMode
                )
            );
        }
    }, [monitoringPoints, map, theme, filters.viewMode]);

    useEffect(() => {
        if (map && monitoringZones && theme) {
            map.getLayers().forEach((layer) => {
                if (layer && layer.getClassName() === ZONES_LAYER_CLASS) {
                    map.removeLayer(layer);
                }
            });
            map.addLayer(
                buildMonitoringZonesLayer(
                    monitoringZones,
                    theme,
                    filters.viewMode
                )
            );
        }
    }, [monitoringZones, map, theme, filters.viewMode]);

    useEffect(() => {
        if (map && monitoringPaths && theme) {
            map.getLayers().forEach((layer) => {
                if (layer && layer.getClassName() === PATHS_LAYER_CLASS) {
                    map.removeLayer(layer);
                }
            });
            map.addLayer(
                buildMonitoringPathsLayer(
                    monitoringPaths,
                    theme,
                    filters.viewMode
                )
            );
        }
    }, [monitoringPaths, map, theme, filters.viewMode]);

    useEffect(() => {
        if (
            map &&
            monitoringSimpleReports &&
            theme &&
            filters.viewMode === ViewMode.SEGNALAZIONI_SMART
        ) {
            map.getLayers().forEach((layer) => {
                if (
                    layer &&
                    layer.getClassName() === SIMPLE_REPORTS_LAYER_CLASS
                ) {
                    map.removeLayer(layer);
                }
            });
            const simpleReportFeature = monitoringSimpleReports.features.find(
                (feature: any) => feature.id === simpleReportId
            );

            map.addLayer(
                buildMonitoringSimpleReportsLayer({
                    ...monitoringSimpleReports,
                    features: [simpleReportFeature],
                })
            );
        }
    }, [monitoringSimpleReports, simpleReportId, map, theme, filters.viewMode]);

    useEffect(() => {
        if (map) {
            map.getLayers().forEach((layer: any) => {
                if (layer.getClassName() === POINTS_LAYER_CLASS) {
                    layer
                        .getSource()
                        ?.getFeatures()
                        .forEach((feature: Feature) => {
                            feature.setProperties({
                                hide: !isFeatureVisible(
                                    feature,
                                    filters,
                                    POINTS_LAYER_CLASS
                                ),
                            });
                        });
                }
                if (layer.getClassName() === ZONES_LAYER_CLASS) {
                    layer
                        .getSource()
                        ?.getFeatures()
                        .forEach((feature: Feature) => {
                            feature.setStyle(
                                isFeatureVisible(
                                    feature,
                                    filters,
                                    ZONES_LAYER_CLASS
                                )
                                    ? getZoneStyle(
                                          feature,
                                          theme,
                                          filters.viewMode
                                      )
                                    : new Style({})
                            );
                        });
                }
                if (layer.getClassName() === PATHS_LAYER_CLASS) {
                    layer
                        .getSource()
                        ?.getFeatures()
                        .forEach((feature: Feature) => {
                            feature.setProperties({
                                hide: !isFeatureVisible(
                                    feature,
                                    filters,
                                    PATHS_LAYER_CLASS
                                ),
                            });
                        });
                }
            });
        }
    }, [map, filters, theme]);

    return (
        <FlexBoxFullWHColumn>
            <BoxFullWH position={'relative'}>
                <MapContainer ref={mapElement}></MapContainer>
                {map && <MapActionsSideBar map={map} />}
            </BoxFullWH>
            <MapElementSelectedBox
                map={map}
                selectedElementName={selectedElementName}
                onSelectElement={onFeatureSelected}
                onUnselectElement={onFeatureUnselected}
            />
        </FlexBoxFullWHColumn>
    );
};

export default MapWrapperSimpleReportAssignElement;
