import { createSelector } from 'reselect';

import { RootState } from '../store';

import { AuthState } from './auth.reducer';

export const selectAuthReducer = (state: RootState): AuthState => state.auth;

export const selectAccessToken = createSelector(
    selectAuthReducer,
    (user) => user.accessToken
);

export const selectUserProfile = createSelector(
    selectAuthReducer,
    (user) => user.userProfile
);

export const selectError = createSelector(
    selectAuthReducer,
    (user) => user.error
);
