import { Box } from '@mui/material';
import logoGreenbeatNoText from '../../../assets/greenbeat-logo-no-text.svg';
import logoGreenbeat from '../../../assets/greenbeat-logo.svg';
import { FlexBoxFullHColumnBetween } from '../../layout/layout.styles';
const { version } = require('../../../../package.json');

const GBGreenbeatBox = ({ noText }: { noText?: boolean }) => {
    return (
        <FlexBoxFullHColumnBetween
            width={noText ? 60 : 150}
            position={'relative'}
        >
            <img
                src={noText ? logoGreenbeatNoText : logoGreenbeat}
                alt="Logo Greenbeat"
                style={{ maxWidth: '100%' }}
            />
            <Box
                position={'absolute'}
                bottom={0}
                right={0}
                color={(theme) => theme.palette.text.primary}
                fontSize={10}
            >
                <span>v. {version}</span>
            </Box>
        </FlexBoxFullHColumnBetween>
    );
};

export default GBGreenbeatBox;
