import { Action, ActionWithPayload, createAction, withMatcher } from '../../utils/reducer/reducer.utils';
import { Tenant } from './tenant.reducer';
import { TENANT_ACTION_TYPES } from './tenant.types';

export type GetTenantStart = Action<
    TENANT_ACTION_TYPES.GET_TENANT_START
>;

export type GetTenantSuccess = ActionWithPayload<
    TENANT_ACTION_TYPES.GET_TENANT_SUCCESS,
    { tenant: Tenant; }
>;

export type GetTenantFailed = ActionWithPayload<
    TENANT_ACTION_TYPES.GET_TENANT_FAILED,
    string
>;

export const getTenantStart = withMatcher(
    (): GetTenantStart =>
        createAction(TENANT_ACTION_TYPES.GET_TENANT_START)
);

export const getTenantSuccess = withMatcher(
    (tenant: Tenant): GetTenantSuccess =>
        createAction(TENANT_ACTION_TYPES.GET_TENANT_SUCCESS, { tenant })
);

export const getTenantFailed = withMatcher(
    (error: string): GetTenantFailed =>
        createAction(TENANT_ACTION_TYPES.GET_TENANT_FAILED, error)
);