import { configureStore, Middleware, Dispatch } from '@reduxjs/toolkit';
import {
    persistStore, persistReducer, PersistConfig
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import createSagaMiddleware, { AnyAction, SagaMiddleware } from 'redux-saga';

import { rootSaga } from './root-saga';

import { rootReducer } from './root-reducer';
import { createWhitelistFilter } from 'redux-persist-transform-filter';

export type RootState = ReturnType<typeof rootReducer>;

type ExtendedPersistConfig = PersistConfig<RootState> & {
    whitelist: (keyof RootState)[];
};

const persistConfig: ExtendedPersistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'monitoring', 'filters', 'map'],
    transforms: [
        createWhitelistFilter('map', ['mapType', 'mapZoom', 'mapCenter'])
    ]
};

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleWares = [
    sagaMiddleware,
    process.env.NODE_ENV !== 'production' && logger,
].filter((middleware) => Boolean(middleware)) as (SagaMiddleware<object> | Middleware<{}, any, Dispatch<AnyAction>>)[];

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middleWares),
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
