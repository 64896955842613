import { Box, Button, Step, StepLabel, Stepper } from '@mui/material';
import moment from 'moment';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PHASES_PAGE_ROUTE } from '../../../constants/routes.constants';
import {
    MonitoringCampaign,
    MonitoringPhaseMatrix,
    PhasePayload,
} from '../../../models/monitoring';
import { closeDialog } from '../../../store/dialog/dialog.action';
import { setIsError } from '../../../store/error/error.action';
import { selectViewMode } from '../../../store/filters/filters.selector';
import { getMonitoringStart } from '../../../store/monitoring/monitoring.action';
import {
    selectMonitoringMatrices,
    selectMonitoringPaths,
    selectMonitoringPhases,
    selectMonitoringPoints,
    selectMonitoringZones,
} from '../../../store/monitoring/monitoring.selector';
import { addPhaseStart } from '../../../store/phase/phase.action';
import {
    fromGeoJSONtoMonitoringPaths,
    fromGeoJSONtoMonitoringPoints,
    fromGeoJSONtoMonitoringZones,
} from '../../../utils/data/data.utils';
import { BoxFullWH, FlexBoxFullWHColumn } from '../../layout/layout.styles';
import GBDialogActions from '../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../ui/gb-dialog-title/gb-dialog-title.component';
import DialogAddPhaseCampaigns from './steps/dialog-add-phase-campaigns/dialog-add-phase-campaigns.component';
import DialogAddPhaseInfo from './steps/dialog-add-phase-info/dialog-add-phase-info.component';
import DialogAddPhaseMatrices from './steps/dialog-add-phase-matrices/dialog-add-phase-matrices.component';
import DialogAddPhaseRecap from './steps/dialog-add-phase-recap/dialog-add-phase-recap.component';

const DialogAddPhase = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const viewMode = useSelector(selectViewMode);
    const monitoringPoints = useSelector(selectMonitoringPoints);
    const monitoringZones = useSelector(selectMonitoringZones);
    const monitoringPaths = useSelector(selectMonitoringPaths);
    const monitoringPhases = useSelector(selectMonitoringPhases);
    const monitoringMatrices = useSelector(selectMonitoringMatrices);

    const initPhase = () => {
        return {
            start_date: moment(new Date())
                .set('hour', 0)
                .set('minute', 0)
                .set('second', 0)
                .format('YYYY-MM-DD'),
            end_date: moment(new Date())
                .set('hour', 23)
                .set('minute', 59)
                .set('second', 59)
                .add(1, 'months')
                .format('YYYY-MM-DD'),
            matrices: monitoringMatrices.map((c) => {
                return {
                    id: c.id,
                    code: c.code,
                    name: c.name,
                    campaignsNumber: 1,
                    checked: false,
                };
            }),
        } as Partial<PhasePayload>;
    };

    const [step, setStep] = useState<number>(0);
    const [phaseToAdd, setPhaseToAdd] =
        useState<Partial<PhasePayload>>(initPhase);
    const [campaignsToAdd, setCampaignsToAdd] = useState<
        Partial<MonitoringCampaign>[]
    >([]);
    const [campaignsUpdated, setCampaignsUpdated] = useState<
        Partial<MonitoringCampaign>[]
    >([]);

    const steps = [
        'Inserisci informazioni',
        'Seleziona matrici',
        'Associa elementi',
    ];

    const handleNext = () => {
        let validCurrentStep = true;
        let msg = '';
        let sep = '';
        const phaseDays =
            moment(phaseToAdd.end_date!).diff(
                moment(phaseToAdd.start_date),
                'days'
            ) + 1;
        function getCampaignsFromMatrix(phaseMatrix: MonitoringPhaseMatrix) {
            const campaignDays = Math.floor(
                phaseDays / phaseMatrix.campaignsNumber!
            );
            let campaigns: Partial<MonitoringCampaign>[] = [];
            let currentStartDate = phaseToAdd.start_date;
            for (let i = 0; i < phaseMatrix.campaignsNumber!; i++) {
                campaigns.push({
                    name: `${phaseToAdd.code!}_${
                        phaseMatrix.code ?? phaseMatrix.name
                    }_${i + 1}`,
                    start_date: currentStartDate,
                    end_date:
                        i === phaseMatrix.campaignsNumber! - 1
                            ? phaseToAdd.end_date
                            : moment(currentStartDate)
                                  .add(campaignDays - 1, 'days')
                                  .format('YYYY-MM-DD'),
                    matrix: phaseMatrix.id,
                    points: fromGeoJSONtoMonitoringPoints(monitoringPoints)
                        .filter(
                            (p) => p.matrix && p.matrix.id === phaseMatrix.id
                        )
                        .map((p) => p.id),
                    zones: fromGeoJSONtoMonitoringZones(monitoringZones)
                        .filter(
                            (z) => z.matrix && z.matrix.id === phaseMatrix.id
                        )
                        .map((z) => z.id),
                    paths: fromGeoJSONtoMonitoringPaths(monitoringPaths)
                        .filter(
                            (p) => p.matrix && p.matrix.id === phaseMatrix.id
                        )
                        .map((p) => p.id),
                });
                currentStartDate = moment(currentStartDate)
                    .add(campaignDays, 'days')
                    .format('YYYY-MM-DD');
            }
            return campaigns;
        }
        switch (step) {
            case 0:
                if (
                    !phaseToAdd.name ||
                    !phaseToAdd.code ||
                    !phaseToAdd.start_date ||
                    !phaseToAdd.end_date
                ) {
                    validCurrentStep = false;
                    msg += 'Alimentare i campi obbligatori';
                    sep = '; ';
                }
                if (
                    moment(phaseToAdd.end_date).diff(
                        phaseToAdd.start_date,
                        'days'
                    ) <= 0
                ) {
                    validCurrentStep = false;
                    msg +=
                        sep +
                        'La data fine non può essere inferiore o uguale alla data inizio';
                }
                if (
                    phaseToAdd.code &&
                    monitoringPhases
                        .map((p) => p.code.toUpperCase())
                        .includes(phaseToAdd.code!.toUpperCase())
                ) {
                    validCurrentStep = false;
                    msg += sep + 'Codice già in uso';
                }
                break;
            case 1:
                let numberChecked = 0;
                let invalidChecked = 0;
                phaseToAdd.matrices?.forEach((c) => {
                    if (c.checked) {
                        numberChecked++;
                        if (
                            c.campaignsNumber === null ||
                            c.campaignsNumber <= 0
                        ) {
                            invalidChecked++;
                        }
                    }
                });
                if (numberChecked === 0) {
                    validCurrentStep = false;
                    msg += sep + 'Selezionare almeno una matrice';
                    sep = '; ';
                }
                if (invalidChecked > 0) {
                    validCurrentStep = false;
                    msg +=
                        sep +
                        'Il numero campagne delle matrici selezionate deve essere maggiore di 0';
                }
                if (numberChecked > 0 && invalidChecked === 0) {
                    let campaigns: Partial<MonitoringCampaign>[] = [];

                    phaseToAdd.matrices
                        ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                        .filter((c) => c.checked)
                        .forEach((c) => {
                            campaigns = campaigns.concat(
                                getCampaignsFromMatrix(c)
                            );
                        });

                    setCampaignsToAdd(campaigns);
                    setCampaignsUpdated(campaigns);
                }

                break;
        }

        if (!validCurrentStep) {
            dispatch(setIsError(true, msg));
            return;
        }
        setStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        if (step === 3) {
            setCampaignsToAdd(campaignsUpdated);
        }
        setStep((prevStep) => prevStep - 1);
    };

    const handleReset = () => {
        setStep(0);
        setPhaseToAdd(initPhase);
    };

    const handleSave = async () => {
        const campaignsCompacted = campaignsUpdated.map((c) => {
            return {
                ...c,
                points: c.points,
                zones: c.zones,
                paths: c.paths,
            };
        });
        const payload = {
            phase: {
                code: phaseToAdd.code,
                name: phaseToAdd.name,
                description: phaseToAdd.description,
                start_date: phaseToAdd.start_date,
                end_date: phaseToAdd.end_date,
                matrices: (phaseToAdd.matrices ?? []).filter((c) => c.checked),
            },
            campaigns: campaignsCompacted,
        };
        await new Promise(() => {
            dispatch(
                addPhaseStart(payload.phase, payload.campaigns, (id: any) => {
                    dispatch(getMonitoringStart(viewMode));
                    dispatch(closeDialog());
                    navigate(`${PHASES_PAGE_ROUTE}/${id}`);
                })
            );
        });
    };

    return (
        <Fragment>
            <GBDialogTitle
                title="AGGIUNGI FASE"
                moreActions={<Button onClick={handleReset}>RESET</Button>}
            />
            <GBDialogContent height="100vh">
                <FlexBoxFullWHColumn>
                    <Stepper
                        activeStep={step}
                        alternativeLabel
                        sx={{
                            backgroundColor: (theme) =>
                                theme.colors.alpha.white[100],
                        }}
                    >
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <Box my={1} />
                    <BoxFullWH overflow={'auto'}>
                        {step === 0 && (
                            <DialogAddPhaseInfo
                                phase={phaseToAdd}
                                onChange={(phase) => setPhaseToAdd(phase)}
                            />
                        )}
                        {step === 1 && (
                            <DialogAddPhaseMatrices
                                phase={phaseToAdd}
                                onChange={(phase) => setPhaseToAdd(phase)}
                            />
                        )}
                        {step === 2 && (
                            <DialogAddPhaseCampaigns
                                campaigns={campaignsToAdd}
                                onChange={(campaigns) => {
                                    setCampaignsUpdated(campaigns);
                                }}
                            />
                        )}
                        {step === 3 && (
                            <DialogAddPhaseRecap
                                phase={phaseToAdd}
                                campaigns={campaignsUpdated}
                            />
                        )}
                    </BoxFullWH>
                </FlexBoxFullWHColumn>
            </GBDialogContent>
            <GBDialogActions>
                {step !== 0 && (
                    <Button aria-label="close" onClick={handleBack}>
                        INDIETRO
                    </Button>
                )}
                {step === steps.length ? (
                    <Button onClick={handleSave}>TERMINA E SALVA</Button>
                ) : (
                    <Button onClick={handleNext}>AVANTI</Button>
                )}
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogAddPhase;
