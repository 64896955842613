import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FiltersState } from '../../../../store/filters/filters.reducer';

const FilterElementType = ({
    filtersToApply,
    onChange,
}: {
    filtersToApply: FiltersState;
    onChange: (filtersChanged: Partial<FiltersState>) => void;
}) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={filtersToApply.showMonitoringPoints}
                        onChange={(event) => {
                            onChange({
                                showMonitoringPoints: event.target.checked,
                            });
                        }}
                    />
                }
                label="Mostra punti"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={filtersToApply.showMonitoringZones}
                        onChange={(event) => {
                            onChange({
                                showMonitoringZones: event.target.checked,
                            });
                        }}
                    />
                }
                label="Mostra zone"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={filtersToApply.showMonitoringPaths}
                        onChange={(event) => {
                            onChange({
                                showMonitoringPaths: event.target.checked,
                            });
                        }}
                    />
                }
                label="Mostra percorsi"
            />
        </FormGroup>
    );
};

export default FilterElementType;
