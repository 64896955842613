import { Fab } from '@mui/material';
import styled from '@mui/material/styles/styled';

export const DeleteImageButton = styled(Fab)(({ theme }) => ({
    '&': {
        width: 32,
        minHeight: 32,
        height: 32,
    },

    '& svg': {
        fontSize: 24,
        color: 'white',
    },
}));
