import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import { MonitoringService } from '../../models/monitoring';

const RoundedButton = styled(Button)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: theme.colors.alpha.black[100],
    fontWeight: 500,
    borderColor: theme.palette.primary.main,
    borderRadius: '23px',
}));

const ServiceButton = ({
    element,
    selected,
    disabled,
    onClickHandler,
}: {
    element: MonitoringService;
    selected: boolean;
    disabled?: boolean;
    onClickHandler: (element: MonitoringService) => void;
}) => {
    const theme = useTheme();

    return (
        <RoundedButton
            disabled={disabled}
            key={element.id}
            variant="outlined"
            sx={[
                selected && {
                    backgroundColor: `${theme.colors.primary.main}`,
                    color: `${theme.colors.alpha.white[100]}`,
                    borderColor: `${theme.colors.alpha.white[100]}`,
                    '&:hover': {
                        color: `${theme.colors.alpha.white[100]}`,
                        backgroundColor: `${theme.colors.primary.main}`,
                    },
                },
            ]}
            onClick={(e) => onClickHandler(element)}
        >
            {element.name}
        </RoundedButton>
    );
};

export default ServiceButton;
