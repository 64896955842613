import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FiltersState } from '../../../../store/filters/filters.reducer';
import { useSelector } from 'react-redux';
import { selectMonitoringCategories } from '../../../../store/monitoring/monitoring.selector';

const FilterCategory = ({
    filtersToApply,
    onChange,
}: {
    filtersToApply: FiltersState;
    onChange: (filtersChanged: Partial<FiltersState>) => void;
}) => {
    const monitoringCategories = useSelector(selectMonitoringCategories);

    return (
        <FormGroup>
            {monitoringCategories.map((category) => (
                <FormControlLabel
                    key={category.id}
                    control={
                        <Checkbox
                            checked={filtersToApply.monitoringCategoriesIds.includes(
                                category.id
                            )}
                            onChange={(event) => {
                                let newMonitoringCategoriesIds = [
                                    ...filtersToApply.monitoringCategoriesIds,
                                ];
                                if (event.target.checked) {
                                    newMonitoringCategoriesIds.push(
                                        category.id
                                    );
                                } else {
                                    newMonitoringCategoriesIds =
                                        newMonitoringCategoriesIds.filter(
                                            (c) => c !== category.id
                                        );
                                }
                                onChange({
                                    monitoringCategoriesIds:
                                        newMonitoringCategoriesIds,
                                });
                            }}
                        />
                    }
                    label={`Mostra ${category.name}`}
                />
            ))}
        </FormGroup>
    );
};

export default FilterCategory;
