import { AnyAction } from '@reduxjs/toolkit';
import { getTenantFailed, getTenantSuccess } from './tenant.action';

export type Tenant = {
    id: number;
    name: string;
    logo: string;
    additional_logo: string;
};

export type TenantState = {
    readonly tenant: Tenant | null;
    readonly error: string | null;
};

const INITIAL_STATE: TenantState = {
    tenant: null,
    error: null,
};

export const tenantReducer = (state = INITIAL_STATE, action: AnyAction) => {

    if (getTenantSuccess.match(action)) {
        return { ...state, tenant: action.payload.tenant, error: null };
    }

    if (
        getTenantFailed.match(action)
    ) {
        return { ...state, error: action.payload };
    }

    return state;
};