import { Action, ActionWithPayload, createAction, withMatcher } from '../../utils/reducer/reducer.utils';
import { MAP_ACTION_TYPES } from './map.types';
import { MAP_TYPES } from '../../constants/map.constants';


export type SetMapType = ActionWithPayload<
    MAP_ACTION_TYPES.SET_MAP_TYPE, { mapType: MAP_TYPES; }
>;

export type SetMapZoom = ActionWithPayload<
    MAP_ACTION_TYPES.SET_MAP_ZOOM, { mapZoom: number; }
>;

export type SetMapCenter = ActionWithPayload<
    MAP_ACTION_TYPES.SET_MAP_CENTER, { mapCenter: number[]; }
>;

export type ResetMap = Action<
    MAP_ACTION_TYPES.RESET_MAP
>;

export type GeocodeAddressStart = ActionWithPayload<
    MAP_ACTION_TYPES.GEOCODE_ADDRESS_START, { address: string; }
>;

export type GeocodeAddressSuccess = ActionWithPayload<
    MAP_ACTION_TYPES.GEOCODE_ADDRESS_SUCCESS,
    {
        geocodingAddress: string;
        geocodingLatitude: string,
        geocodingLongitude: string,
    }
>;

export type GeocodeAddressFailed = ActionWithPayload<
    MAP_ACTION_TYPES.GEOCODE_ADDRESS_FAILED,
    string
>;

export type ReverseGeocodeCoordsStart = ActionWithPayload<
    MAP_ACTION_TYPES.REVERSE_GEOCODE_COORDS_START, { lat: string; lng: string; }
>;

export type ReverseGeocodeCoordsSuccess = ActionWithPayload<
    MAP_ACTION_TYPES.REVERSE_GEOCODE_COORDS_SUCCESS,
    {
        geocodingAddress: string;
        geocodingLatitude: string,
        geocodingLongitude: string,
    }
>;

export type ReverseGeocodeCoordsFailed = ActionWithPayload<
    MAP_ACTION_TYPES.REVERSE_GEOCODE_COORDS_FAILED,
    string
>;

export type CleanGeocodingError = Action<
    MAP_ACTION_TYPES.CLEAN_GEOCODING_ERROR
>;

export const setMapType = withMatcher(
    (mapType: MAP_TYPES): SetMapType =>
        createAction(MAP_ACTION_TYPES.SET_MAP_TYPE, { mapType })
);

export const setMapZoom = withMatcher(
    (mapZoom: number): SetMapZoom =>
        createAction(MAP_ACTION_TYPES.SET_MAP_ZOOM, { mapZoom })
);

export const setMapCenter = withMatcher(
    (mapCenter: number[]): SetMapCenter =>
        createAction(MAP_ACTION_TYPES.SET_MAP_CENTER, { mapCenter })
);

export const resetMap = withMatcher(
    (): ResetMap =>
        createAction(MAP_ACTION_TYPES.RESET_MAP)
);

export const geocodeAddressStart = withMatcher(
    (address: string): GeocodeAddressStart =>
        createAction(MAP_ACTION_TYPES.GEOCODE_ADDRESS_START, { address })
);

export const geocodeAddressSuccess = withMatcher(
    (geocodingAddress: string, geocodingLatitude: string, geocodingLongitude: string): GeocodeAddressSuccess =>
        createAction(MAP_ACTION_TYPES.GEOCODE_ADDRESS_SUCCESS, { geocodingAddress, geocodingLatitude, geocodingLongitude })
);

export const geocodeAddressFailed = withMatcher(
    (error: string): GeocodeAddressFailed =>
        createAction(MAP_ACTION_TYPES.GEOCODE_ADDRESS_FAILED, error)
);

export const reverseGeocodeCoordsStart = withMatcher(
    (lat: string, lng: string): ReverseGeocodeCoordsStart =>
        createAction(MAP_ACTION_TYPES.REVERSE_GEOCODE_COORDS_START, { lat, lng })
);

export const reverseGeocodeCoordsSuccess = withMatcher(
    (geocodingAddress: string,
        geocodingLatitude: string,
        geocodingLongitude: string): ReverseGeocodeCoordsSuccess =>
        createAction(MAP_ACTION_TYPES.REVERSE_GEOCODE_COORDS_SUCCESS, { geocodingAddress, geocodingLatitude, geocodingLongitude })
);

export const reverseGeocodeCoordsFailed = withMatcher(
    (error: string): ReverseGeocodeCoordsFailed =>
        createAction(MAP_ACTION_TYPES.REVERSE_GEOCODE_COORDS_FAILED, error)
);

export const cleanGeocodingError = withMatcher(
    (): CleanGeocodingError =>
        createAction(MAP_ACTION_TYPES.CLEAN_GEOCODING_ERROR)
);