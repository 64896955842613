import { Fragment, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';

import { Engineering } from '@mui/icons-material';
import { Divider } from '@mui/material';
import Badge from '@mui/material/Badge';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { MonitoringSimpleReport } from '../../../models/monitoring';
import { ACTIONS, RequiredAction } from '../../../models/shared';
import { openDialog } from '../../../store/dialog/dialog.action';
import { DIALOG_TYPES } from '../../../store/dialog/dialog.types';
import { getTechnicianElementsStart } from '../../../store/element/element.action';
import { selectViewMode } from '../../../store/filters/filters.selector';
import { getMonitoringStart } from '../../../store/monitoring/monitoring.action';
import {
    selectMonitoringSimpleReports,
    selectRequiredActions,
} from '../../../store/monitoring/monitoring.selector';
import { fromGeoJSONtoMonitoringSimpleReport } from '../../../utils/data/data.utils';

const GBRequiredActionsBox = () => {
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const theme = useTheme();

    const dispatch = useDispatch();

    const viewMode = useSelector(selectViewMode);

    const requiredActions = useSelector(selectRequiredActions);
    const monitoringSimpleReports = useSelector(selectMonitoringSimpleReports);

    const getSimpleReport = (id: number): MonitoringSimpleReport => {
        const found = monitoringSimpleReports.features.find(
            (p: any) => p.id === id
        );
        return fromGeoJSONtoMonitoringSimpleReport(found);
    };

    const handleActionButtonClick = (action: RequiredAction) => {
        switch (action.action) {
            case ACTIONS.HANDLE_NEW_SIMPLE_REPORT:
                dispatch(
                    openDialog(
                        DIALOG_TYPES.MANAGE_NEW_SIMPLE_REPORT,
                        getSimpleReport(action.object_id),
                        'md',
                        () => {
                            setOpen(false);
                        }
                    )
                );
                break;
            case ACTIONS.ASSIGN_NEW_REPORT_TO_TECHNICIAN:
                dispatch(
                    openDialog(
                        DIALOG_TYPES.ASSIGN_REPORT,
                        {
                            reportId: action.object_id,
                        },
                        'lg',
                        () => {
                            setOpen(false);
                        }
                    )
                );
                break;
            case ACTIONS.CREATE_INTERVENTION:
                dispatch(
                    getTechnicianElementsStart((elements) => {
                        const technicianReports =
                            elements.my_available_services.flatMap(
                                (service) => service.reports
                            );
                        const report = technicianReports.find(
                            (report) => report.report_id === action.object_id
                        );
                        if (!report) return;
                        dispatch(
                            openDialog(
                                DIALOG_TYPES.UPDATE_REPORT_WITH_INTERVENTION,
                                {
                                    reportSelected: report,
                                },
                                'lg',
                                () => {
                                    setOpen(false);
                                    dispatch(getMonitoringStart(viewMode));
                                }
                            )
                        );
                    })
                );
                break;
            case ACTIONS.CLOSE_REPORT:
                dispatch(
                    openDialog(
                        DIALOG_TYPES.CLOSE_REPORT,
                        {
                            reportId: action.object_id,
                        },
                        'lg',
                        () => {
                            setOpen(false);
                        }
                    )
                );
                break;
            default:
                break;
        }
    };

    const getUserRoleLabel = (action: RequiredAction) => {
        switch (action.action) {
            case ACTIONS.HANDLE_NEW_SIMPLE_REPORT:
                return 'Segnalatore';
            case ACTIONS.ASSIGN_NEW_REPORT_TO_TECHNICIAN:
                return 'Operatore';
            case ACTIONS.CREATE_INTERVENTION:
                return 'Amministratore';
            case ACTIONS.CLOSE_REPORT:
                return 'Tecnico';
            default:
                return '';
        }
    };

    const getActionLabel = (action: RequiredAction) => {
        switch (action.action) {
            case ACTIONS.HANDLE_NEW_SIMPLE_REPORT:
                return 'Gestisci segnalazione semplice';
            case ACTIONS.ASSIGN_NEW_REPORT_TO_TECHNICIAN:
                return 'Assegna segnalazione';
            case ACTIONS.CREATE_INTERVENTION:
                return 'Crea intervento';
            case ACTIONS.CLOSE_REPORT:
                return 'Chiudi segnalazione';
            default:
                return '';
        }
    };

    return (
        <>
            {requiredActions && (
                <Fragment>
                    <IconButton
                        sx={{
                            padding: 0,
                            margin: 0,
                            width: '76px',
                            height: '100%',
                        }}
                        size="large"
                        aria-label="notifications"
                        ref={ref}
                        onClick={() => (requiredActions ? setOpen(true) : null)}
                    >
                        {requiredActions.length > 0 && (
                            <Badge
                                badgeContent={requiredActions.length}
                                color="error"
                            >
                                <Engineering
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        color: theme.colors.primary.main,
                                    }}
                                />
                            </Badge>
                        )}
                        {requiredActions.length === 0 && (
                            <Engineering
                                sx={{
                                    width: 40,
                                    height: 40,
                                    color: theme.colors.primary.main,
                                }}
                            />
                        )}
                    </IconButton>
                    <Popover
                        anchorEl={ref.current}
                        onClose={() => setOpen(false)}
                        open={isOpen}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Box sx={{ padding: 0.5, maxWidth: 500 }}>
                            {requiredActions.length > 0 && (
                                <List>
                                    {requiredActions.map((action, index) => (
                                        <Box key={action.id}>
                                            <ListItem disablePadding>
                                                {action.action && (
                                                    <ListItemButton
                                                        onClick={() =>
                                                            handleActionButtonClick(
                                                                action
                                                            )
                                                        }
                                                    >
                                                        <Box
                                                            display={'flex'}
                                                            justifyContent={
                                                                'space-between'
                                                            }
                                                            alignItems={
                                                                'center'
                                                            }
                                                            width={1}
                                                        >
                                                            <Box
                                                                width={3 / 4}
                                                                mr={5}
                                                            >
                                                                <Typography>
                                                                    {moment(
                                                                        action.create_date
                                                                    )
                                                                        .locale(
                                                                            'it'
                                                                        )
                                                                        .format(
                                                                            'DD/MM/YY HH:mm:ss'
                                                                        )}
                                                                </Typography>
                                                                <Typography>
                                                                    {
                                                                        action.object_name
                                                                    }
                                                                </Typography>
                                                                <Typography>
                                                                    <Typography
                                                                        variant="h4"
                                                                        fontWeight={
                                                                            'normal'
                                                                        }
                                                                    >
                                                                        <Box
                                                                            fontWeight={
                                                                                'bolder'
                                                                            }
                                                                        >
                                                                            {getUserRoleLabel(
                                                                                action
                                                                            )}
                                                                            <br />
                                                                            <span
                                                                                style={{
                                                                                    fontWeight:
                                                                                        'normal',
                                                                                }}
                                                                            >
                                                                                {action
                                                                                    .create_user
                                                                                    .first_name &&
                                                                                action
                                                                                    .create_user
                                                                                    .last_name
                                                                                    ? action
                                                                                          .create_user
                                                                                          .first_name +
                                                                                      ' ' +
                                                                                      action
                                                                                          .create_user
                                                                                          .last_name
                                                                                    : action
                                                                                          .create_user
                                                                                          .email}
                                                                            </span>
                                                                        </Box>
                                                                    </Typography>
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography
                                                                    color={
                                                                        'primary'
                                                                    }
                                                                    textAlign={
                                                                        'center'
                                                                    }
                                                                    fontWeight={
                                                                        'bolder'
                                                                    }
                                                                    lineHeight={
                                                                        1
                                                                    }
                                                                >
                                                                    {getActionLabel(
                                                                        action
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </ListItemButton>
                                                )}
                                            </ListItem>
                                            {index <
                                                requiredActions.length - 1 && (
                                                <Divider />
                                            )}
                                        </Box>
                                    ))}
                                    {requiredActions.length === 0 && (
                                        <ListItem>
                                            <ListItemButton>
                                                <Typography>
                                                    Nessuna azione richiesta
                                                </Typography>
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                </List>
                            )}
                            {requiredActions.length === 0 && (
                                <Typography variant="h4" padding={1}>
                                    Nessuna azione richiesta
                                </Typography>
                            )}
                        </Box>
                    </Popover>
                </Fragment>
            )}
        </>
    );
};

export default GBRequiredActionsBox;
