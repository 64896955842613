import { DataGrid } from '@mui/x-data-grid';
import styled from '@mui/material/styles/styled';

export const GBDataGrid = styled(DataGrid)(() => ({
    '.MuiDataGrid-virtualScroller': {
        overflowX: 'hidden',

        '.MuiDataGrid-virtualScrollerContent': {
            height: '100%!important',

            '.MuiDataGrid-row:hover': {
                cursor: 'pointer',
            },
        },
    },
    '.MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        outline: 'none!important',
    },
}));
