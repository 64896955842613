import { AddAPhoto, Close } from '@mui/icons-material';
import { Card, CardMedia, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MAX_NUMBER_OF_IMAGES_AT_A_TIME } from '../../../../../constants/files.constants';
import { ReportPayload } from '../../../../../models/monitoring';
import { setIsError } from '../../../../../store/error/error.action';
import {
    checkExtensionImage,
    resizeImage,
} from '../../../../../utils/file/file.utils';
import { BoxFullWH } from '../../../../layout/layout.styles';
import GBDialogActions from '../../../../ui/gb-dialog-actions/gb-dialog-actions.component';
import GBDialogContent from '../../../../ui/gb-dialog-content/gb-dialog-content.component';
import GBDialogTitle from '../../../../ui/gb-dialog-title/gb-dialog-title.component';
import { DeleteImageButton } from '../../../dialog-add-images/dialog-add-images.styles';

const DialogUpdateReportWithInterventionImagesStep = ({
    images,
    onPrevious,
    onNext,
}: {
    images: any[] | undefined;
    onPrevious: () => void;
    onNext: (properties: Partial<ReportPayload>) => void;
}) => {
    const dispatch = useDispatch();

    const [imagesToAdd, setImagesToAdd] = useState<any[]>(images ?? []);
    const [nextImageId, setNextImageId] = useState(0);

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    const handleNextButtonClick = () => {
        if (imagesToAdd.length === 0) {
            dispatch(setIsError(true, "Caricare almeno un'immagine"));
            return;
        }
        onNext({ images: imagesToAdd });
    };

    const handleFileChange = async (event: any) => {
        let index = nextImageId;
        let imagesToAddTmp = [];
        for (let f of event.target.files) {
            if (!checkExtensionImage(f.name)) {
                dispatch(
                    setIsError(
                        true,
                        'Estensione immagine non ammessa: ' + f.name
                    )
                );
                return;
            }
            if (
                [...imagesToAdd, ...imagesToAddTmp].length >=
                MAX_NUMBER_OF_IMAGES_AT_A_TIME
            ) {
                dispatch(
                    setIsError(true, 'Numero massimo di immagini raggiunto')
                );
                return;
            }
            const imageResized = await resizeImage(f);
            imagesToAddTmp.push({
                id: index,
                file: imageResized,
                imageUrl: URL.createObjectURL(f),
            });
            index++;
        }
        setImagesToAdd([...imagesToAdd, ...imagesToAddTmp]);
        setNextImageId(index);
        event.target.value = '';
    };

    return (
        <Fragment>
            <GBDialogTitle title="AGGIUNGI IMMAGINI" />
            <GBDialogContent>
                <Typography textAlign={'center'} mb={2}>
                    Estensioni ammesse: .jpg, .jpeg, .png, .svg
                    <br />
                    <br />
                    Numero massimo immagini: {MAX_NUMBER_OF_IMAGES_AT_A_TIME}
                </Typography>
                <Grid container alignItems="center">
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <input
                            type="file"
                            capture="environment"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            id="file-upload"
                            multiple
                        />
                        <label htmlFor="file-upload">
                            <Button
                                variant="contained"
                                startIcon={<AddAPhoto />}
                                component="span"
                                sx={{
                                    height: '53px',
                                }}
                            >
                                Carica immagini
                            </Button>
                        </label>
                    </Grid>
                </Grid>
                <Box my={3} />
                <Grid
                    container
                    display={'grid'}
                    gridTemplateColumns={'repeat(3, 1fr)'}
                    spacing={4}
                >
                    {imagesToAdd.map((o: any, index: number) => (
                        <Grid item key={index} height={'100px'}>
                            <BoxFullWH position={'relative'}>
                                <Card sx={{ height: '100%' }}>
                                    <CardMedia
                                        component="img"
                                        image={o.imageUrl}
                                        alt="Image"
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                        }}
                                    ></CardMedia>
                                </Card>
                                <DeleteImageButton
                                    color="primary"
                                    onClick={() => {
                                        setImagesToAdd(
                                            imagesToAdd.filter(
                                                (p: any) => p.id !== o.id
                                            )
                                        );
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        right: -12,
                                        top: -12,
                                        zIndex: 1,
                                        color: (theme) =>
                                            theme.palette.grey[500],
                                    }}
                                >
                                    <Close />
                                </DeleteImageButton>
                            </BoxFullWH>
                        </Grid>
                    ))}
                </Grid>
            </GBDialogContent>
            <GBDialogActions>
                <Button onClick={handlePreviousButtonClick}>INDIETRO</Button>
                <Button onClick={handleNextButtonClick}>AVANTI</Button>
            </GBDialogActions>
        </Fragment>
    );
};

export default DialogUpdateReportWithInterventionImagesStep;
