export enum MONITORING_ACTION_TYPES {
    GET_MONITORING_START = 'monitoring/GET_MONITORING_START',
    GET_MONITORING_SUCCESS = 'monitoring/GET_MONITORING_SUCCESS',
    GET_MONITORING_FAILED = 'monitoring/GET_MONITORING_FAILED',
    MARK_NOTIFICATIONS_AS_READ_START = 'monitoring/MARK_NOTIFICATIONS_AS_READ_START',
    MARK_NOTIFICATIONS_AS_READ_SUCCESS = 'monitoring/MARK_NOTIFICATIONS_AS_READ_SUCCESS',
    MARK_NOTIFICATIONS_AS_READ_FAILED = 'monitoring/MARK_NOTIFICATIONS_AS_READ_FAILED',
    GET_REQUIRED_ACTIONS_START = 'monitoring/GET_REQUIRED_ACTIONS_START',
    GET_REQUIRED_ACTIONS_SUCCESS = 'monitoring/GET_REQUIRED_ACTIONS_SUCCESS',
    GET_REQUIRED_ACTIONS_FAILED = 'monitoring/GET_REQUIRED_ACTIONS_FAILED',
    DELETE_ENTITY_START = 'monitoring/DELETE_ENTITY_START',
    DELETE_ENTITY_SUCCESS = 'monitoring/DELETE_ENTITY_SUCCESS',
    DELETE_ENTITY_FAILED = 'monitoring/DELETE_ENTITY_FAILED',
}

export enum ENTITIES {
    ELEMENTS = 'elements',
    POINTS = 'points',
    PATHS = 'paths',
    ZONES = 'zones',
    SIMPLE_REPORTS = 'simpleReports',
    REPORTS = 'reports',
    PERIODIC_INSPECTIONS = 'periodicInspections',
    CAMPAIGNS = 'campaigns',
    TEST_REPORTS = 'testReports',
    MONITORING = 'monitoring',
    MATRICES = 'matrices',
}
