import { Breakpoint } from '@mui/material';
import { AnyAction } from '@reduxjs/toolkit';
import { closeDialog, openDialog } from './dialog.action';
import { DIALOG_TYPES } from './dialog.types';


export type DialogState = {
    dialogOpened: boolean;
    dialogType: DIALOG_TYPES | undefined;
    dialogSize: Breakpoint;
    inputData: any;
    onComplete: (response?: any) => void;
};

const INITIAL_STATE: DialogState = {
    dialogOpened: false,
    dialogType: undefined,
    dialogSize: 'md',
    inputData: null,
    onComplete: () => { },
};

export const dialogReducer = (state = INITIAL_STATE, action: AnyAction) => {
    if (
        openDialog.match(action)) {
        return { ...state, dialogOpened: true, ...action.payload };
    }
    if (
        closeDialog.match(action)) {
        return { ...state, dialogOpened: false, dialogType: undefined };
    }

    return state;
};
export { DIALOG_TYPES };
