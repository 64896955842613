import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../store/auth/auth.selector';
import { selectDialogOpened } from '../../store/dialog/dialog.selector';
import { setIsError } from '../../store/error/error.action';
import {
    selectErrorMessage,
    selectIsError,
} from '../../store/error/error.selector';
import DialogWrapper from '../dialogs/dialog-wrapper.component';
import GbSnackbar from '../ui/gb-snackbar/gb-snackbar.component';
import ViewModeTab from '../view-mode-tab/view-mode-tab.component';
import ContentWrapper from './content-wrapper/content-wrapper.component';
import { FlexBoxFullWHRow } from './layout.styles';
import SideBar from './side-bar/side-bar.component';
import TopBar from './top-bar/top-bar.component';

export default function Layout() {
    const dispatch = useDispatch();

    const error = useSelector(selectIsError);
    const errorMessage = useSelector(selectErrorMessage);
    const dialogOpened = useSelector(selectDialogOpened);
    const accessToken = useSelector(selectAccessToken);

    const [sidebarOpened, setSidebarOpened] = useState(true);

    const handleCloseSnackbar = () => {
        dispatch(setIsError(false, null));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <FlexBoxFullWHRow position={'relative'} overflow={'hidden'}>
                {accessToken && <ViewModeTab />}
                {error && (
                    <GbSnackbar
                        open={true}
                        msg={errorMessage}
                        onClose={handleCloseSnackbar}
                    />
                )}
                {dialogOpened && <DialogWrapper />}
                <TopBar sidebarOpened={sidebarOpened} />
                {accessToken && (
                    <SideBar
                        opened={sidebarOpened}
                        onChangeOpened={(opened: boolean) =>
                            setSidebarOpened(opened)
                        }
                    />
                )}
                <ContentWrapper />
            </FlexBoxFullWHRow>
        </LocalizationProvider>
    );
}
