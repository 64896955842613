import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import {
    MonitoringPhaseMatrix,
    PhasePayload,
} from '../../../../../models/monitoring';
import { selectMonitoringMatrices } from '../../../../../store/monitoring/monitoring.selector';
import { themeColors } from '../../../../../theme/greenbeat-theme';

const DialogAddPhaseMatrices = ({
    phase,
    onChange,
}: {
    phase: Partial<PhasePayload>;
    onChange: (phase: Partial<PhasePayload>) => void;
}) => {
    const monitoringMatrices = useSelector(selectMonitoringMatrices);

    const onChangeMatrix = (matrixId: number) => {
        let phaseToUpdate = structuredClone(phase);
        const checked = (phase.matrices ?? []).find(
            (c: MonitoringPhaseMatrix) => c.id === matrixId
        )?.checked;
        if (checked !== undefined) {
            phaseToUpdate.matrices!.find(
                (c: MonitoringPhaseMatrix) => c.id === matrixId
            )!.checked = !checked;
        }
        onChange(phaseToUpdate);
    };

    const onChangeCampaignsNumber = (
        categoryId: number,
        campaignsNumberString: string
    ) => {
        let phaseToUpdate = structuredClone(phase);
        const campaignsNumber: number | null = Number.isNaN(
            parseInt(campaignsNumberString)
        )
            ? null
            : parseInt(campaignsNumberString);
        phaseToUpdate.matrices!.find(
            (c: MonitoringPhaseMatrix) => c.id === categoryId
        )!.campaignsNumber = campaignsNumber;
        onChange(phaseToUpdate);
    };

    return (
        <List sx={{ width: 1, padding: 0 }}>
            {phase.matrices
                ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((category) => (
                    <ListItem
                        key={category.id}
                        secondaryAction={
                            <TextField
                                label="Numero Campagne"
                                type="number"
                                onChange={(e) => {
                                    onChangeCampaignsNumber(
                                        category.id,
                                        e.target.value
                                    );
                                }}
                                value={category.campaignsNumber}
                                size="small"
                                inputProps={{
                                    style: {
                                        textAlign: 'right',
                                    },
                                }}
                            />
                        }
                        disablePadding
                    >
                        <ListItemButton
                            role={undefined}
                            onClick={() => onChangeMatrix(category.id)}
                            dense
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={category.checked}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': `checkbox-list-label-${category.id.toString()}`,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemIcon>
                                <img
                                    height={25}
                                    width={25}
                                    src={
                                        'data:image/svg+xml;charset=utf-8,' +
                                        encodeURIComponent(
                                            monitoringMatrices
                                                .find(
                                                    (c) => c.id === category.id
                                                )!
                                                .icon.replace(
                                                    /fill="[^"]*"/g,
                                                    `fill="${
                                                        monitoringMatrices.find(
                                                            (c) =>
                                                                c.id ===
                                                                category.id
                                                        )!.color ??
                                                        themeColors.primary
                                                    }"`
                                                )
                                        )
                                    }
                                    alt={'Icon of ' + category.name}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={`checkbox-list-label-${category.id.toString()}`}
                                primary={category.name}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
        </List>
    );
};

export default DialogAddPhaseMatrices;
