import { PeriodicInspectionPayload } from '../../models/monitoring';
import { Action, ActionWithPayload, createAction, withMatcher } from '../../utils/reducer/reducer.utils';
import { PERIODIC_INSPECTION_ACTION_TYPES } from './periodic-inspection.types';



export type AddPeriodicInspectionStart = ActionWithPayload<
    PERIODIC_INSPECTION_ACTION_TYPES.ADD_PERIODIC_INSPECTION_START,
    { periodicInspection: Partial<PeriodicInspectionPayload>; onComplete: () => void; }
>;

export type AddPeriodicInspectionSuccess = Action<
    PERIODIC_INSPECTION_ACTION_TYPES.ADD_PERIODIC_INSPECTION_SUCCESS
>;

export type AddPeriodicInspectionFailed = ActionWithPayload<
    PERIODIC_INSPECTION_ACTION_TYPES.ADD_PERIODIC_INSPECTION_FAILED,
    string
>;

export const addPeriodicInspectionStart = withMatcher(
    (periodicInspection: Partial<PeriodicInspectionPayload>, onComplete: () => void): AddPeriodicInspectionStart =>
        createAction(PERIODIC_INSPECTION_ACTION_TYPES.ADD_PERIODIC_INSPECTION_START, { periodicInspection, onComplete })
);

export const addPeriodicInspectionSuccess = withMatcher
    ((): AddPeriodicInspectionSuccess =>
        createAction(PERIODIC_INSPECTION_ACTION_TYPES.ADD_PERIODIC_INSPECTION_SUCCESS)
    );

export const addPeriodicInspectionFailed = withMatcher(
    (error: string): AddPeriodicInspectionFailed =>
        createAction(PERIODIC_INSPECTION_ACTION_TYPES.ADD_PERIODIC_INSPECTION_FAILED, error)
);