import { Box } from '@mui/material';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { selectTenant } from '../../../store/tenant/tenant.selector';
import GBGreenbeatBox from '../../ui/gb-greenbeat-box/gb-greenbeat-box.component';
import { FlexBoxFullWRowBetween } from '../layout.styles';
import { AppBar, Toolbar } from './top-bar.styles';

const TopBarLogin = () => {
    const tenant = useSelector(selectTenant);

    return (
        <Fragment>
            <AppBar
                sx={{
                    zIndex: 900,
                }}
            >
                <Toolbar sidebarOpened={false} sx={{ margin: 0 }}>
                    <FlexBoxFullWRowBetween
                        height={1}
                        alignItems={'center'}
                        justifyContent={'between'}
                    >
                        <Box
                            height={1}
                            display={'flex'}
                            alignItems={'center'}
                            gap={4}
                        >
                            {tenant?.logo && (
                                <Box
                                    height={'100%'}
                                    width={100}
                                    display={'flex'}
                                    flexShrink={0}
                                    alignItems={'center'}
                                >
                                    <img
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                        }}
                                        src={`${process.env.REACT_APP_BASE_URL}${tenant.logo}`}
                                        alt={'Logo secondario cliente'}
                                    />
                                </Box>
                            )}
                        </Box>
                        <GBGreenbeatBox />
                    </FlexBoxFullWRowBetween>
                </Toolbar>
            </AppBar>
        </Fragment>
    );
};

export default TopBarLogin;
