import { createSelector } from 'reselect';

import { RootState } from '../store';

import { DialogState } from './dialog.reducer';

export const selectDialogReducer = (state: RootState): DialogState => state.dialog;

export const selectDialogOpened = createSelector(
    selectDialogReducer,
    (dialog) => dialog.dialogOpened
);

export const selectDialogType = createSelector(
    selectDialogReducer,
    (dialog) => dialog.dialogType
);

export const selectDialogSize = createSelector(
    selectDialogReducer,
    (dialog) => dialog.dialogSize
);

export const selectInputData = createSelector(
    selectDialogReducer,
    (dialog) => dialog.inputData
);

export const selectOnComplete = createSelector(
    selectDialogReducer,
    (dialog) => dialog.onComplete
);




