import { Box } from '@mui/material';
import { Map } from 'ol';
import { useEffect } from 'react';
import { MonitoringElementService } from '../../../models/monitoring';
import { getElementTargeted } from '../../../utils/map/map.utils';

const MapElementSelectedBox = ({
    map,
    selectedElementName,
    onSelectElement,
    onUnselectElement,
}: {
    map: Map | undefined;
    selectedElementName: string | null;
    onSelectElement: (
        id: number,
        name: string,
        elementServices: MonitoringElementService[]
    ) => void;
    onUnselectElement: () => void;
}) => {
    useEffect(() => {
        if (!map) {
            return;
        }
        map.on('click', (evt) => {
            var elementFound = getElementTargeted(map, evt);
            if (elementFound) {
                const { feature } = elementFound;
                onSelectElement(
                    feature.getId() as number,
                    feature.getProperties()['name'],
                    feature.getProperties()['services']
                );
            } else {
                onUnselectElement();
            }
        });
    }, [map, onSelectElement, onUnselectElement]);

    return (
        <Box p={2} textAlign={'center'}>
            {selectedElementName
                ? selectedElementName
                : 'Nessun elemento selezionato'}
        </Box>
    );
};

export default MapElementSelectedBox;
