import { takeLatest, put, all, call, select } from 'typed-redux-saga/macro';

import { AUTH_ACTION_TYPES } from './auth.types';

import {
    signInSuccess,
    signInFailed,
    SignInStart,
    SignInSuccess,
    getUserProfileSuccess,
    getUserProfileFailed,
    getUserProfileStart,
} from './auth.action';
import { getUserProfileAfterSignIn, signInWithEmailAndPassword } from '../../utils/api/auth.utils';
import { selectAccessToken, selectUserProfile } from './auth.selector';
import { setIsLoading } from '../loading/loading.action';
import { setIsError } from '../error/error.action';

export function* signIn({
    payload: { email, password },
}: SignInStart) {
    yield* put(setIsLoading(true));
    try {
        const accessToken = yield* call(
            signInWithEmailAndPassword,
            email,
            password
        );

        if (accessToken) {
            yield* put(signInSuccess(accessToken));
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(signInFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* getUserProfile({
    payload: { accessToken },
}: SignInSuccess) {
    yield* put(setIsLoading(true));
    try {

        yield* put(getUserProfileStart(accessToken));

        const userProfile = yield* call(
            getUserProfileAfterSignIn, accessToken
        );

        if (userProfile) {
            yield* put(getUserProfileSuccess(accessToken, userProfile));
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getUserProfileFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* isUserAuthenticated() {

    const accessToken = yield* select(selectAccessToken);
    const userProfile = yield* select(selectUserProfile);
    if (!accessToken || !userProfile) {
        yield* put(signInFailed('Check autenticazione fallito'));
        return;
    }
    yield* put(getUserProfileSuccess(accessToken, userProfile));

}

export function* onSignInStart() {
    yield* takeLatest(AUTH_ACTION_TYPES.SIGN_IN_START, signIn);
}

export function* onSignInSuccess() {
    yield* takeLatest(AUTH_ACTION_TYPES.SIGN_IN_SUCCESS, getUserProfile);
}

export function* authSaga() {
    yield* all([
        call(onSignInStart),
        call(onSignInSuccess),
    ]);
}
