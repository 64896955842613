import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../store/dialog/dialog.action';
import { DIALOG_TYPES } from '../../../store/dialog/dialog.reducer';
import {
    PATHS_LAYER_CLASS,
    POINTS_LAYER_CLASS,
    ZONES_LAYER_CLASS,
} from '../../../constants/map.constants';

const ActionsPopoverMenu = ({
    anchorEl,
    onClose,
}: {
    anchorEl: HTMLButtonElement | null;
    onClose: () => void;
}) => {
    const dispatch = useDispatch();

    const listButtons = [
        {
            id: 'Wizards_AddNewPoint',
            label: 'Aggiungi Punto',
            action: () => {
                dispatch(
                    openDialog(
                        DIALOG_TYPES.ADD_ELEMENT,
                        { className: POINTS_LAYER_CLASS },
                        'md'
                    )
                );
            },
        },
        {
            id: 'Wizards_AddNewZone',
            label: 'Aggiungi Zona',
            action: () => {
                dispatch(
                    openDialog(
                        DIALOG_TYPES.ADD_ELEMENT,
                        { className: ZONES_LAYER_CLASS },
                        'md'
                    )
                );
            },
        },
        {
            id: 'Wizards_AddNewPath',
            label: 'Aggiungi Percorso',
            action: () => {
                dispatch(
                    openDialog(
                        DIALOG_TYPES.ADD_ELEMENT,
                        { className: PATHS_LAYER_CLASS },
                        'md'
                    )
                );
            },
        },
    ];

    return (
        <Popover
            open={true}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            {listButtons.map((o: any) => (
                <Box key={o.id}>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => {
                                onClose();
                                o.action();
                            }}
                        >
                            {o.label}
                        </ListItemButton>
                    </ListItem>
                </Box>
            ))}
        </Popover>
    );
};

export default ActionsPopoverMenu;
