import { AnyAction } from '@reduxjs/toolkit';
import { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import { MonitoringCampaign, MonitoringCategory, MonitoringMatrix, MonitoringPhase, MonitoringService } from '../../models/monitoring';
import { Notification, RequiredAction, Severity } from '../../models/shared';
import { User } from '../auth/auth.reducer';
import { deleteEntityFailed, getMonitoringFailed, getMonitoringSuccess, getRequiredActionsSuccess, markNotificationsAsReadFailed, markNotificationsAsReadSuccess } from './monitoring.action';


export type MonitoringState = {
    readonly monitoring: GeoJSONFeatureCollection | null;
    readonly monitoringPoints: GeoJSONFeatureCollection | null;
    readonly monitoringZones: GeoJSONFeatureCollection | null;
    readonly monitoringPaths: GeoJSONFeatureCollection | null;
    readonly monitoringSimpleReports: GeoJSONFeatureCollection | null;
    readonly monitoringCategories: MonitoringCategory[];
    readonly monitoringMatrices: MonitoringMatrix[];
    readonly monitoringServices: MonitoringService[];
    readonly monitoringPhases: MonitoringPhase[];
    readonly monitoringCampaigns: MonitoringCampaign[];
    readonly severities: Severity[];
    readonly notifications: Notification[];
    readonly requiredActions: RequiredAction[];
    readonly expiringElements: {
        points: GeoJSONFeatureCollection | null;
        zones: GeoJSONFeatureCollection | null;
        paths: GeoJSONFeatureCollection | null;
    };
    readonly technicians: User[];
    readonly error: string | null;
};

const INITIAL_STATE: MonitoringState = {
    monitoring: null,
    monitoringPoints: null,
    monitoringZones: null,
    monitoringPaths: null,
    monitoringSimpleReports: null,
    monitoringCategories: [],
    monitoringMatrices: [],
    monitoringServices: [],
    monitoringPhases: [],
    monitoringCampaigns: [],
    severities: [],
    notifications: [],
    requiredActions: [],
    expiringElements: {
        points: null,
        zones: null,
        paths: null,
    },
    technicians: [],
    error: null,
};

export const monitoringReducer = (state = INITIAL_STATE, action: AnyAction) => {
    if (getMonitoringSuccess.match(action)) {
        return { ...state, ...action.payload.monitoring, error: null };
    }

    if (markNotificationsAsReadSuccess.match(action)) {
        return { ...state, ...action.payload.notifications, error: null };
    }

    if (getRequiredActionsSuccess.match(action)) {
        return { ...state, requiredActions: action.payload.requiredActions, error: null };
    }

    if (
        getMonitoringFailed.match(action) ||
        markNotificationsAsReadFailed.match(action) ||
        deleteEntityFailed.match(action)
    ) {
        return { ...state, error: action.payload };
    }

    return state;
};
