import { createSelector } from 'reselect';

import { RootState } from '../store';

import { MonitoringState } from './monitoring.reducer';

export const selectMonitoringReducer = (state: RootState): MonitoringState => state.monitoring;

export const selectMonitoring = createSelector(
    selectMonitoringReducer,
    (monitoring) => monitoring.monitoring
);

export const selectMonitoringPoints = createSelector(
    selectMonitoringReducer,
    (monitoring) => monitoring.monitoringPoints
);

export const selectMonitoringZones = createSelector(
    selectMonitoringReducer,
    (monitoring) => monitoring.monitoringZones
);

export const selectMonitoringPaths = createSelector(
    selectMonitoringReducer,
    (monitoring) => monitoring.monitoringPaths
);

export const selectMonitoringSimpleReports = createSelector(
    selectMonitoringReducer,
    (monitoring) => monitoring.monitoringSimpleReports
);

export const selectMonitoringCategories = createSelector(
    selectMonitoringReducer,
    (monitoring) => monitoring.monitoringCategories
);

export const selectMonitoringMatrices = createSelector(
    selectMonitoringReducer,
    (monitoring) => monitoring.monitoringMatrices
);

export const selectMonitoringServices = createSelector(
    selectMonitoringReducer,
    (monitoring) => monitoring.monitoringServices
);

export const selectMonitoringPhases = createSelector(
    selectMonitoringReducer,
    (monitoring) => monitoring.monitoringPhases
);

export const selectMonitoringCampaigns = createSelector(
    selectMonitoringReducer,
    (monitoring) => monitoring.monitoringCampaigns
);

export const selectSeverities = createSelector(
    selectMonitoringReducer,
    (monitoring) => monitoring.severities
);

export const selectNotifications = createSelector(
    selectMonitoringReducer,
    (monitoring) => monitoring.notifications
);

export const selectRequiredActions = createSelector(
    selectMonitoringReducer,
    (monitoring) => monitoring.requiredActions
);

export const selectExpiringElements = createSelector(
    selectMonitoringReducer,
    (monitoring) => monitoring.expiringElements
);

export const selectTechnicians = createSelector(
    selectMonitoringReducer,
    (monitoring) => monitoring.technicians
);