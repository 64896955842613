import Typography from '@mui/material/Typography';
import { Fragment } from 'react';
import { FiltersState } from '../../../../store/filters/filters.reducer';
import GBSeverityRadio from '../../../ui/gb-severity-radio/gb-severity-radio.component';

const FilterSeverity = ({
    filtersToApply,
    onChange,
}: {
    filtersToApply: FiltersState;
    onChange: (filtersChanged: Partial<FiltersState>) => void;
}) => {
    return (
        <Fragment>
            <Typography gutterBottom={true} marginBottom={1}>
                Lo status globale di criticità di un elemento è dato dallo
                status di criticità più alto fra tutti i suoi servizi.
            </Typography>
            <GBSeverityRadio
                variantFilter
                value={
                    (filtersToApply.severityId === -2
                        ? 'all'
                        : filtersToApply.severityId) ?? null
                }
                onChange={(newValue) => onChange({ severityId: newValue })}
            />
        </Fragment>
    );
};

export default FilterSeverity;
